import gql from 'graphql-tag';

const REGISTER_NEW_USER = gql`
  mutation RegisterNewUser($input: RegisterNewUserInput!) {
    registerNewUser(input: $input) {
      token
      errors {
        field
        messages
      }
    }
  }
`;

export default REGISTER_NEW_USER;
