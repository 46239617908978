import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.palette.error.main,
    fontSize: '.85rem',
    margin: theme.legacySpacing(2, 0),
    textAlign: 'center',
  },
}));

function GeneralError({ children }) {
  const classes = useStyles();
  return <p className={classes.container}>{children}</p>;
}

export default GeneralError;
