import React from 'react';

import { Button, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import PATHS from '../../constants/paths';
import useExercisesStore from '../../storages/exercises/exercises';
import useSubjectStore from '../../storages/subject';
import { Id } from '../../types/Id';
import AddExerciseToChosenButton from '../AddExerciseToChosenButton/AddExerciseToChosenButton';
import { ExercisePathHook, ExercisePathExampleFunction } from './ExercisePath.logic';
import useStyles from './ExercisePath.styles';

type Props = {
  subject?: { id: Id; name: string };
  section?: { id: Id; name: string };
  subsection?: { id: Id; name: string };
  index?: number;
};

const ExercisePath: React.FC<Props> = ({ index, subject, section, subsection }) => {
  const navigate = useNavigate();
  // @ts-ignore
  const { setSectionAndSubsection } = useExercisesStore();
  const { subject: selectedSubject } = useSubjectStore();

  const onExercisePathClick = () => {
    //   TODO sections and subsections based on id to enable it
    if (section && selectedSubject?.pk === subject?.id) {
      setSectionAndSubsection({ section: section.id, subsection: subsection?.id });
      navigate(PATHS.EXERCISES);
    }
  };

  const classes = useStyles();

  if (!section) return null;

  const labelParts = [subject?.name, section?.name, subsection?.name].filter(element => !!element);

  return (
    <div className={classes.root}>
      <div className={classes.exercisePathButtonWrapper}>
        <Button
          className={classes.exercisePathButton}
          // disabled={selectedSubject?.pk !== subject?.id}
          disabled
          variant='text'
          color='primary'
          onClick={onExercisePathClick}
        >
          <Typography className={classes.label}>{labelParts.join(' > ')}</Typography>
        </Button>
      </div>
      {index && <AddExerciseToChosenButton index={index} subject={subject?.id as number} />}
    </div>
  );
};

export default ExercisePath;
