import React, { useState } from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { ReactComponent as Close } from 'assets/icons/general/close.svg';
import FloatingButton from 'components/FloatingButton/FloatingButton';
import { useUserDataContext } from 'context/UserDataContext';
import useBodyLock from 'services/common/useBodyLock/useBodyLock';
import useUserPreferencesStore from 'storages/userPreferences';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

const ANIMATION_TIME = 100;

const useStyles = makeStyles(theme => ({
  tables: {
    height: '100vh',
    width: '100vw',
    top: 0,
    zIndex: theme.zIndex.modal,
    position: 'fixed',
    overflowY: 'scroll',
    display: ({ isOpen }) => (isOpen ? 'flex' : 'none'),
    background: 'white',
    justifyContent: 'center',
    padding: ({ fromCustomContent }) => fromCustomContent && '1.5rem',
  },
  image: {
    width: '100%',
    display: 'block',
    maxWidth: ({ fullWidth }) => (fullWidth ? '100%' : '1192px'),
    userSelect: 'none',
  },
  close: {
    width: '1rem',
    height: '1rem',
    fill: theme.palette.primary.main,
  },
  closeButton: {
    top: '1rem',
    right: '1.5rem',
    transform: 'translate3d(0,0,0)',
  },
  changeTablesButton: {
    position: 'fixed',
    bottom: '1rem',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 201,
    whiteSpace: 'pre',
  },
  transformComponentPrefixes: {
    height: '-moz-fit-content',
    width: '-moz-fit-content',
    minHeight: '100%',
  },
}));

function Tables({ imagePath, isOpen, close, onLoad, fromCustomContent, customContent, fullWidth, subjectCode }) {
  const { logEvent } = useUserLogContext();
  const { showNewTables, setShowNewTables } = useUserPreferencesStore();
  useBodyLock(isOpen, '#tables');
  const { isMobile } = useUserDataContext();
  const [zoomed, setZoomed] = useState(false);

  const onZoom = ({ state }) => {
    setTimeout(() => setZoomed(state.scale > 1), ANIMATION_TIME);
  };

  const toggleTablesCurriculum = () => {
    logEvent('toggle-tables', { newTables: !showNewTables });
    setShowNewTables(prev => !prev);
  };

  const imageSource = fromCustomContent ? customContent : imagePath;
  const classes = useStyles({ isOpen, fromCustomContent, fullWidth });

  return (
    <div className={classes.tables} id='tables'>
      {subjectCode !== 'GE' && !fromCustomContent && (
        <Button className={classes.changeTablesButton} color='primary' onClick={toggleTablesCurriculum} variant='contained'>
          {showNewTables ? 'Używaj starych tablic' : 'Używaj nowych tablic'}
        </Button>
      )}
      <TransformWrapper
        centerOnInit
        doubleClick={{
          mode: zoomed ? 'reset' : 'zoomIn',
          step: 1,
          animationTime: ANIMATION_TIME,
          disabled: !isMobile,
        }}
        onZoom={onZoom}
        panning={{ disabled: !zoomed, paddingSize: 0, maxScale: 2 }}
        wheel={{ disabled: true }}
      >
        <TransformComponent contentClass={classes.transformComponentPrefixes} wrapperClass={classes.transformComponentPrefixes}>
          <div>
            {imageSource &&
              imageSource.map(image => (
                <img key={image} alt='tablice pomocnicze' className={classes.image} onLoad={onLoad} src={image} />
                //  TODO: handle onLoad array
              ))}
          </div>
        </TransformComponent>
      </TransformWrapper>
      <FloatingButton className={classes.closeButton} onClick={close} position='fixed'>
        <Close className={classes.close} />
      </FloatingButton>
    </div>
  );
}

export default Tables;
