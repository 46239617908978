import axios from 'axios';

import { ClassroomExercisesTableHeaderValues } from 'components/ClassroomExercisesTable/ClassroomExercisesTable.logic';
import { StudentsMarksTableHeaderValues } from 'components/StudentDetails/StudentDetails.logic';
import createUrlWithParams from 'services/common/createUrlWithParams/createUrlWithParams';

import type { ComplexQueryParams, DefaultApiResponse, PaginatedApiResponse, PaginatedData } from '../_types';
import {
  parseClassroomForUI,
  parseSchoolForUI,
  parseTeacherForUI,
  parseClassroomExercisesMarksForUI,
  parseStudentsForUI,
  parseStudentMarksParamsForBE,
  parsePaginatedClassroomMarksParamsForBE,
  parseStudentMarksForUI,
  parseAllStudentsMarksParamsForBE,
  parseClassroomExercisesMarksSummaryForUI,
  parseClassroomMarksParamsForBE,
} from './teacher.parsers';
import type {
  Classroom,
  ClassroomDto,
  School,
  SchoolDto,
  Teacher,
  TeacherDto,
  ClassroomExercisesMarks,
  ClassroomExercisesMarksDto,
  Student,
  StudentDto,
  StudentMarksQueryFilters,
  StudentMark,
  StudentMarkDto,
  ClassroomExercisesMarksSummaryDto,
} from './teacher.types';
import { ClassroomExercisesMarksSummary, ClassroomMarksFilters, ClassroomMarksFiltersKeys, StudentsMarksFilters } from './teacher.types';

const endpoints = {
  classrooms: 'schools/teacher/classrooms/',
  schools: 'schools/teacher/schools/',
  students: 'schools/teacher/students/',
  teachers: 'schools/teacher/teachers/',
};

type GetClassroomsQuery = () => Promise<Classroom>;
type GetSchoolsQuery = () => Promise<School>;
type GetStudentsQuery = () => Promise<Student[]>;
type GetStudentMarksQuery = () => Promise<PaginatedData<StudentMark>>;
type GetTeachersQuery = () => Promise<Teacher>;
type GetClassroomExercisesMarksQuery = () => Promise<PaginatedData<ClassroomExercisesMarks>>;
type GetClassroomExercisesMarksSummaryQuery = () => Promise<ClassroomExercisesMarksSummary>;

const teacherApi = {
  getClassroom:
    (classroomId: string | number): GetClassroomsQuery =>
    async () => {
      const response = await axios.get<GetClassroomsQuery, DefaultApiResponse<ClassroomDto>>(`${endpoints.classrooms}${classroomId}/`);
      return parseClassroomForUI(response.data);
    },
  getClassroomExercisesMarks:
    (
      classroomId: string | number,
      params: ComplexQueryParams<ClassroomMarksFiltersKeys, ClassroomExercisesTableHeaderValues>,
    ): GetClassroomExercisesMarksQuery =>
    async () => {
      const response = await axios.get<GetClassroomExercisesMarksQuery, PaginatedApiResponse<ClassroomExercisesMarksDto>>(
        createUrlWithParams(`${endpoints.classrooms}${classroomId}/exercises_summary/`, parsePaginatedClassroomMarksParamsForBE(params)),
      );
      return parseClassroomExercisesMarksForUI(response.data);
    },
  getClassroomExercisesMarksSummary:
    (classroomId: string | number, params: ClassroomMarksFilters): GetClassroomExercisesMarksSummaryQuery =>
    async () => {
      const response = await axios.get<GetClassroomExercisesMarksSummaryQuery, DefaultApiResponse<ClassroomExercisesMarksSummaryDto>>(
        createUrlWithParams(`${endpoints.classrooms}${classroomId}/marks_count/`, parseClassroomMarksParamsForBE(params)),
      );
      return parseClassroomExercisesMarksSummaryForUI(response.data);
    },
  getSchool:
    (schoolId: string | number): GetSchoolsQuery =>
    async () => {
      const response = await axios.get<GetSchoolsQuery, DefaultApiResponse<SchoolDto>>(`${endpoints.schools}${schoolId}/`);
      return parseSchoolForUI(response.data);
    },
  getStudentMarks:
    (studentId: string | number, params?: ComplexQueryParams<StudentsMarksFilters, StudentsMarksTableHeaderValues>): GetStudentMarksQuery =>
    async () => {
      const response = await axios.get<GetStudentMarksQuery, PaginatedApiResponse<StudentMarkDto>>(
        createUrlWithParams(`${endpoints.students}${studentId}/marks/`, parseStudentMarksParamsForBE(params)),
      );
      return parseStudentMarksForUI(response.data);
    },
  getStudents:
    (classroomId: string | number, params?: StudentMarksQueryFilters): GetStudentsQuery =>
    async () => {
      const response = await axios.get<GetStudentsQuery, PaginatedApiResponse<StudentDto>>(
        createUrlWithParams(endpoints.students, { classroom: classroomId, limit: 250, ...parseAllStudentsMarksParamsForBE(params) }),
      );
      return parseStudentsForUI(response.data.results);
    },
  getTeacher:
    (teacherId: string | number): GetTeachersQuery =>
    async () => {
      const response = await axios.get<GetTeachersQuery, DefaultApiResponse<TeacherDto>>(`${endpoints.teachers}${teacherId}/`);
      return parseTeacherForUI(response.data);
    },
};

export default teacherApi;
