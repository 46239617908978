import React, { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import GET_SUBJECT_PROGRESS from 'api/graphql/queries/user/GET_SUBJECT_PROGRESS';
import useSubjectStore from 'storages/subject';
import DashboardCard from 'uniqueComponents/Dashboard/DashboardCard/DashboardCard';
import safeJSONParser from 'utils/safeJSONParser/safeJSONParser';

const getStrokeFirstPaint = (part, R) => 2 * Math.PI * R * part;
const getStrokeDasharray = (part, R) => `${getStrokeFirstPaint(part, R)} ${2 * Math.PI * R}`;

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    paddingTop: '1.5rem',
  },
  loaderWrapper: {
    height: ({ externalD }) => externalD,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    strokeWidth: ({ strokeWidth }) => strokeWidth,
    strokeOpacity: 1,
    fill: 'none',
  },
  explain: {
    stroke: theme.palette.custom.red,
  },
  hard: {
    stroke: theme.palette.custom.orange,
  },
  easy: {
    stroke: theme.palette.primary.main,
  },
  list: {
    listStyle: 'none',
    paddingLeft: 0,
    '& li': {
      position: 'relative',
      paddingLeft: '25px',
      marginBottom: '3px',
    },
    '& li:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: '15px',
      width: '15px',
      borderRadius: '100%',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '& li:nth-child(1):before': {
      background: theme.palette.primary.main,
    },
    '& li:nth-child(2):before': {
      background: theme.palette.custom.orange,
    },
    '& li:nth-child(3):before': {
      background: theme.palette.custom.red,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '.9rem',
      '& li': {
        paddingLeft: '20px',
      },
      '& li:before': {
        height: '12px',
        width: '12px',
      },
    },
  },
  chartWrapper: {
    position: 'relative',
  },
  chartCounter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontWeight: 700,
    transform: 'translate(-50%, -50%)',
  },
  noDataInfo: {
    textAlign: 'center',
    padding: '0 1rem',
  },
  coverCircle: {
    strokeWidth: ({ strokeWidth }) => strokeWidth,
    strokeOpacity: 1,
    fill: '#fff',
  },
  coverCircleExternal: {
    strokeWidth: '2px',
    stroke: '#fff',
    fill: 'transparent',
  },
}));

const Chart = ({ loading, userProgress, noData }) => {
  const smallScreen = useMediaQuery('(max-width: 440px)');

  const circle = useMemo(() => {
    const R = smallScreen ? 40 : 70;
    const strokeWidth = smallScreen ? 10 : 16;
    const externalR = R + strokeWidth;
    const externalD = externalR * 2;
    return {
      strokeWidth,
      externalR,
      externalD,
      circleProps: {
        cy: externalR,
        cx: externalR,
        r: R,
        transform: `rotate(-90) translate(-${externalD})`,
      },
      externalCover: smallScreen ? R + 6 : R + 8,
      internalCover: smallScreen ? R - 4 : R - 7,
    };
  }, [smallScreen]);

  const classes = useStyles({ externalD: circle.externalD, strokeWidth: circle.strokeWidth });
  switch (true) {
    case loading:
      return (
        <div className={classes.loaderWrapper}>
          <CircularProgress size={45} />
        </div>
      );
    case !!userProgress && !noData:
      return (
        <div className={classes.chartWrapper}>
          <svg
            height={circle.externalD}
            viewBox={`0 0 ${circle.externalD} ${circle.externalD}`}
            width={circle.externalD}
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle className={clsx(classes.circle, classes.easy)} shapeRendering='geometricPrecision' {...circle.circleProps} />
            <circle
              className={clsx(classes.circle, classes.explain)}
              shapeRendering='geometricPrecision'
              strokeDasharray={getStrokeDasharray(userProgress.explain.ratio, circle.circleProps.r)}
              {...circle.circleProps}
            />
            <circle
              className={clsx(classes.circle, classes.hard)}
              shapeRendering='geometricPrecision'
              strokeDasharray={getStrokeDasharray(userProgress.hard.ratio, circle.circleProps.r)}
              {...circle.circleProps}
            />
            <circle className={classes.coverCircle} {...circle.circleProps} r={circle.internalCover} />
            <circle className={classes.coverCircleExternal} {...circle.circleProps} r={circle.externalCover} />
          </svg>
          <div className={classes.chartCounter}>{userProgress.sum}</div>
        </div>
      );
    default:
      return <p className={classes.noDataInfo}>Oznacz więcej zadań, żeby zobaczyć statystyki!</p>;
  }
};

Chart.defaultProps = {
  loading: false,
  noData: false,
  userProgress: null,
};

const SimpleStatistics = () => {
  const { subject } = useSubjectStore();

  const { data, loading } = useQuery(GET_SUBJECT_PROGRESS, {
    variables: { subject: subject?.id },
    fetchPolicy: 'cache-and-network',
  });
  const [userProgress, setUserProgress] = useState(null);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const userProgressString = data?.userProgress;
    if (userProgressString) {
      const userProgressObj = safeJSONParser(userProgressString);
      const { explain, hard, easy } = userProgressObj;
      const sum = explain.count + hard.count + easy.count;
      if (sum === 0) {
        setUserProgress(userProgressObj);
        setNoData(true);
        return;
      }
      userProgressObj.easy.ratio = 1;
      userProgressObj.explain.ratio = (hard.count + explain.count) / sum;
      userProgressObj.hard.ratio = hard.count / sum;
      userProgressObj.sum = sum;
      setUserProgress(userProgressObj);
    }
  }, [data]);

  const classes = useStyles();
  return (
    <DashboardCard ariaLabel='Statystyki oznaczonych zadań' className={classes.card} clickable={false} title='Oznaczone zadania:'>
      <div className={classes.wrapper}>
        <Chart loading={loading} noData={noData} userProgress={userProgress} />
        {!loading && userProgress && !noData && (
          <ul className={classes.list}>
            <li>
              {userProgress.easy.label}
              :&nbsp;
              <b>{userProgress.easy.count}</b>
            </li>
            <li>
              {userProgress.hard.label}
              :&nbsp;
              <b>{userProgress.hard.count}</b>
            </li>
            <li>
              {userProgress.explain.label.replace(' ', '\xa0')}
              :&nbsp;
              <b>{userProgress.explain.count}</b>
            </li>
          </ul>
        )}
      </div>
    </DashboardCard>
  );
};

export default SimpleStatistics;
