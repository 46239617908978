import React from 'react';

import { CircularProgress } from '@material-ui/core';

import useStyles from './Loader.styles';

type Props = {
  staticDisplay?: boolean;
};

const Loader: React.FC<Props> = ({ staticDisplay }) => {
  const classes = useStyles();

  if (staticDisplay) {
    return (
      <div className={classes.staticLoader}>
        <CircularProgress size={30} />
      </div>
    );
  }

  return (
    <div className={classes.loader}>
      <CircularProgress size={30} />
    </div>
  );
};

export default Loader;
