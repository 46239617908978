import { UrlString } from 'types/UrlString';
import { Uuid } from 'types/Uuid';

import { DateString } from '../../../types/DateString';
import { Id } from '../../../types/Id';

export enum ExerciseFileTypes {
  hint = 'p',
  answer = 'o',
  question = 't',
  attachment = 'a',
}

export type ExerciseUserMark = 'easy' | 'hard' | 'explain';

export type ExerciseFiles = Record<ExerciseFileTypes, UrlString[]>;

export type ExerciseLevel = 'PP' | 'PR';

export type ExerciseResourceCategory = 'youtube' | 'wiki' | 'other' | 'zpe';

export type ExerciseFileDto = {
  uuid: string;
  png_file: string;
  cropped_png_file: string;
  file_type: ExerciseFileTypes;
  file_index: number;
};

export type ExerciseFile = {
  uuid: string;
  pngFile: string;
  croppedPngFile: string;
  fileType: ExerciseFileTypes;
  fileIndex: number;
};

export type ExerciseDto = {
  uuid: Uuid;
  subject: number;
  section: number;
  subsection: number;
  tags: [number];
  level: ExerciseLevel;
  date: string;
  source: string;
  files: ExerciseFileDto[];
  import_file_identifier: string;
  index: number;
  external_sources: {
    id: number;
    category: ExerciseResourceCategory;
    created_at: string;
    description: string;
    index: number;
    is_main: boolean;
    is_section_related: boolean;
    updated_at: string;
    url: string;
    user_rating: string;
  }[];
  audio: string;
  video: string;
  global_id: string;
  user_mark: null | {
    exercise_index: number;
    exercise_section_name: string;
    exercise_subject_name: string;
    exercise_subsection_name: string;
    exercise_uuid: Uuid;
    id: Id;
    mark: ExerciseUserMark;
    created_at: DateString;
    updated_at: DateString;
  };
};

export type Exercise = {
  uuid: Uuid;
  subject: number;
  section: number;
  subsection: number;
  tags: [number];
  level: ExerciseLevel;
  date: Date;
  source: string;
  files: ExerciseFiles;
  importFileIdentifier: string;
  index: number;
  externalSources: {
    id: number;
    category: ExerciseResourceCategory;
    createdAt: Date;
    description: string;
    index: number;
    isMain: boolean;
    isSectionRelated: boolean;
    updatedAt: Date;
    url: string;
    userRating: string;
  }[];
  audio: string;
  video: string;
  // TODO remove if get rid of GQL
  globalId: string;
  userMark: null | ExerciseUserMark;
};
