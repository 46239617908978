import React, { useEffect, useMemo } from 'react';

import { Button } from '@material-ui/core';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CommercialUserDataFormInput } from 'api/rest/users/users.types';
import FormCheckboxInput from 'components/_forms/FormCheckboxInput/FormCheckboxInput';
import FormSelectInput from 'components/_forms/FormSelectInput/FormSelectInput';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import LoaderButton from 'components/LoaderButton/LoaderButton';
import useUpdateMeMutation from 'hooks/apiCalls/useUpdateMeMutation/useUpdateMeMutation';
import useAccountTypeOptionsQuery from 'services/common/useAccountTypeOptionsQuery/useAccountTypeOptionsQuery';

import useStyles from './CommercialUserDataForm.styles';

type Props = {
  defaultValues: CommercialUserDataFormInput;
};

// TODO extract common logic with UserInfoDialog and SchoolUserDataForm
const CommercialUserDataForm: React.FC<Props> = ({ defaultValues }) => {
  const { control, handleSubmit, formState, watch, reset, setValue } = useForm<CommercialUserDataFormInput>({
    defaultValues,
  });

  const { accountTypeOptions, classOptions, ageRangeOptions } = useAccountTypeOptionsQuery();

  const { updateMeMutation } = useUpdateMeMutation({ onSuccess: newValues => reset(newValues as CommercialUserDataFormInput) });

  const accountTypeValue = watch('accountType');

  const isTeacher = useMemo(() => /(n|k)/.test(accountTypeValue), [accountTypeValue]);

  const onSubmit: SubmitHandler<CommercialUserDataFormInput> = input => {
    const mutationData = {
      accountType: input.accountType,
      newsletter: input.newsletter,
      ...(isTeacher ? { ageRange: input.ageRange } : { classroom: input.classroom }),
    };
    updateMeMutation.mutate(mutationData);
  };

  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <FormSelectInput required control={control} label='Czym się zajmujesz?' name='accountType' options={accountTypeOptions || []} />
        {accountTypeValue && isTeacher && (
          <FormSelectInput required control={control} label='Wiek' name='ageRange' options={ageRangeOptions || []} />
        )}
        {accountTypeValue && !isTeacher && (
          <FormSelectInput required control={control} label='Klasa' name='classroom' options={classOptions || []} />
        )}
        <FormCheckboxInput control={control} name='newsletter' label='Wyrażam zgodę na przesyłanie newslettera' />
        <LoaderButton isLoading={updateMeMutation.isLoading} className={classes.saveButton}>
          <Button disabled={!formState.isValid || !formState.isDirty} type='submit' size='small' color='primary' variant='contained'>
            Zapisz
          </Button>
        </LoaderButton>
      </FormWrapper>
    </form>
  );
};

export default CommercialUserDataForm;
