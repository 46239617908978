import useExercisesToDisplay_sets from 'storages/exercisesToDisplay/useExercisesToDisplay_sets';

import useFeatureChecker from '../useFeatureChecker/useFeatureChecker';

export type ExerciseDataSources = 'common_store';

// TODO to remove on unify
const useExerciseDataSourceResolver = (source: ExerciseDataSources) => {
  const { useDataStore } = useFeatureChecker();

  switch (source) {
    case 'common_store': {
      return useExercisesToDisplay_sets;
    }
    default:
      return useDataStore;
  }
};

export default useExerciseDataSourceResolver;
