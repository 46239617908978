import sourcesStorage from 'persistedStorages/sourcesStorage';

type SourcesForInput = { source?: string; sourceId?: string };

const prepareSourcesForInput: () => SourcesForInput = () => {
  const result: SourcesForInput = {};
  const { source, sourceId, referrer } = sourcesStorage.get() || {};

  if (sourceId) result.sourceId = sourceId;
  if (referrer || source) result.source = [referrer, source].filter(v => !!v).join('>');

  return result;
};

export default prepareSourcesForInput;
