import { ChosenExercisesCoreStore } from './chosenExercises.types';

const chosenExercisesInitialStorage: ChosenExercisesCoreStore = {
  exercises: [],
  exerciseCursor: 0,
  currentExerciseShape: { t: 0, o: 0, p: 0, a: 0 },
  currentExercise: null,
  fileType: 't',
  exercisesCount: null,
  subjectExercisesCount: null,
  allExercisesCompleted: false,
  chosenExercises: {},
  subjectCursors: {},
  mark: null,
};

export default chosenExercisesInitialStorage;
