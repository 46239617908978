import axios from 'axios';

import { DefaultApiResponse } from '../_types';
import { parseSubsectionsListForUI, parseSubsectionForUI } from './subsections.parsers';
import { SubsectionDto, Subsection } from './subsections.types';

const endpoints = {
  subsections: 'exercises/subsections/',
};

type GetSubsectionsListQuery = () => Promise<Subsection[]>;
type GetSubsectionQuery = () => Promise<Subsection>;

const subsectionsApi = {
  getSubsectionsList: (): GetSubsectionsListQuery => async () => {
    const response = await axios.get<GetSubsectionsListQuery, DefaultApiResponse<SubsectionDto[]>>(endpoints.subsections);
    return parseSubsectionsListForUI(response.data);
  },

  getSubsection:
    (subsectionId: number): GetSubsectionQuery =>
    async () => {
      const response = await axios.get<GetSubsectionQuery, DefaultApiResponse<SubsectionDto>>(`${endpoints.subsections}${subsectionId}/`);
      return parseSubsectionForUI(response.data);
    },
};

export default subsectionsApi;
