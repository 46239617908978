import gql from 'graphql-tag';

const ADD_EXERCISE_DRAW = gql`
  mutation UpdateExerciseData($input: UpdateExerciseDataInput!) {
    updateExerciseData(input: $input) {
      id
    }
  }
`;

export default ADD_EXERCISE_DRAW;
