import axios from 'axios';

import { queryString } from 'api/graphql/mutations/user/UPDATE_PASSWORD';

import { SetNewPassword } from './setNewPassword.types';

type PostMutation = (data: SetNewPassword) => Promise<{ success: true } | { success: false; message: string }>;

const setNewPasswordApi = {
  set: (): PostMutation => async input => {
    const data = {
      variables: { input },
      query: queryString,
    };
    const response = await axios({
      url: process.env.REACT_APP_GRAPHQL_URL,
      method: 'post',
      data,
    });

    const { errors } = response.data.data.updateUserPassword;
    if (errors.length) {
      return {
        success: false,
        message: errors[0].messages[0],
      };
    }
    return { success: true, message: '' };
  },
};

export default setNewPasswordApi;
