import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(8),
  },
  classroomTile: {
    padding: theme.spacing(6, 4),
    background: theme.palette.common.white,
    display: 'grid',
    gridGap: theme.spacing(4),
  },
  buttonsContainer: {
    display: 'flex',
    gridGap: theme.spacing(6),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  icon: {
    width: '28px',
    height: '28px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(4),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

export default useStyles;
