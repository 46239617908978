import gql from 'graphql-tag';

const REQUEST_RESET_PASSWORD = gql`
  mutation RequestResetPassword($loginIdentifier: String!, $redirectUrl: String) {
    requestResetPassword(loginIdentifier: $loginIdentifier, redirectUrl: $redirectUrl) {
      sent
    }
  }
`;

export default REQUEST_RESET_PASSWORD;
