import gql from 'graphql-tag';

const GET_EXERCISE_NOTE = gql`
  query GetExerciseNote($uuid: String!) {
    getExercise(uuid: $uuid) {
      __typename
      userData {
        note
      }
    }
  }
`;

export default GET_EXERCISE_NOTE;
