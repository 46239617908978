import React from 'react';

import { Dialog, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import image from 'assets/decorative_pics/processing_payment.png';
import { USER_SETTINGS_DIALOGS } from 'constants/paths';
import DialogGrid from 'containers/DialogGrid/DialogGrid';
import useTrimUrl from 'services/common/useTrimUrl/useTrimUrl';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.8rem',
    },
  },
  image: {
    margin: '0 auto',
    maxHeight: '320px',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '190px',
    },
  },
}));

function PaymentSuccessDialog({ onClose }) {
  const trimUrl = useTrimUrl();

  const closeWithTrim = () => {
    trimUrl(USER_SETTINGS_DIALOGS.SUCCESS);
    onClose();
  };

  const classes = useStyles();
  return (
    <Dialog onClose={closeWithTrim} open>
      <DialogGrid cancelClick={closeWithTrim} hideButtons hideCancelButton title='Dziękujemy!'>
        <Grid className={classes.wrapper} container spacing={10}>
          <Grid item>
            Dziękujemy! Twoja płatność jest przetwarzana. Otrzymasz e-mail z potwierdzeniem dokonania transakcji. Odśwież stronę, aby
            sprawdzić status swojej subskrypcji.
          </Grid>
          <Grid item justify='center' xs={12}>
            <img alt='' className={classes.image} src={image} />
          </Grid>
        </Grid>
      </DialogGrid>
    </Dialog>
  );
}

export default PaymentSuccessDialog;
