import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme => ({
  root: {
    maxWidth: '350px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '305px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))(FormControl);
