import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { Chip, CircularProgress, Typography, useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { useQuery } from 'react-query';

import exercisesSetApi from 'api/rest/exercisesSets/exercisesSet';
import { ExercisesSetDetails } from 'api/rest/exercisesSets/exercisesSet.types';
import { Option } from 'components/_inputs/_types/Option';
import SelectInput from 'components/_inputs/SelectInput/SelectInput';
import EditSetDialog from 'components/EditExercisesSetDialog/EditExercisesSetDialog';
import { useGlobalDialogContext } from 'context/GlobalDialogContext';
import { resolveSubjectNameFromSubjectNumber } from 'services/common/subjectParsers/subjectParsers';
import useExercisesSetsStore from 'storages/exercisesSets/exercisesSets';
import useExercisesToDisplay_sets from 'storages/exercisesToDisplay/useExercisesToDisplay_sets';

import { useUserLogContext } from '../../../context/UserLogContext/UserLogContext';
import useLayoutStore from '../../../storages/layout';
import { Uuid } from '../../../types/Uuid';
import useStyles from './ChosenExercisesFilters.styles';

const NEW_SET_CHOICE = 'CREATE_NEW_SET';

const ChosenExercisesFilters = () => {
  const { logEvent } = useUserLogContext();

  const { GLOBAL_DIALOG, addToGlobalDialogQueue } = useGlobalDialogContext();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const openEditDialog = () => setIsEditDialogOpen(true);
  const closeEditDialog = () => setIsEditDialogOpen(false);

  const { data: exercisesSets } = useQuery(
    exercisesSetApi.getAll.generateQueryKey(),
    exercisesSetApi.getAll.request(),
    exercisesSetApi.getAll.requestBaseSettings,
  );

  const classes = useStyles();

  const exercisesSetsOptions: Option[] = useMemo(() => {
    if (exercisesSets) {
      return [
        ...exercisesSets.map(({ id, name }) => ({ value: id, label: name })),
        {
          value: NEW_SET_CHOICE,
          label: (
            <span className={classes.addNewSetElement}>
              <AddCircleOutlinedIcon className={classes.addNewSetElementIcon} fontSize='small' />
              <span>Stwórz nowy zestaw</span>
            </span>
          ),
        },
      ];
    }
    return [];
  }, [exercisesSets]);

  const { dataState, setCurrentSetId, currentSetId, currentSetData, refreshExercisesInSelectedSet } = useExercisesSetsStore();
  const { setCursorByUuid, cursor } = useExercisesToDisplay_sets();

  const onSelectedSetChange = ({ target }: ChangeEvent<any>) => {
    if (target.value === NEW_SET_CHOICE) {
      const existingSets = exercisesSets ? exercisesSets.map(({ name }) => name) : [];
      const onCreate = ({ id }: ExercisesSetDetails) => setCurrentSetId(id);
      addToGlobalDialogQueue({
        type: GLOBAL_DIALOG.CREATE_NEW_EXERCISES_SET,
        props: { existingSets, onCreate },
      });
    } else {
      setCurrentSetId(target.value);
      logEvent('exercises-sets-select-set');
    }
  };

  const { closeMobileFilterDrawer } = useLayoutStore();
  // @ts-ignore
  const downSM = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const onChipClick = (uuid: Uuid) => {
    setCursorByUuid(uuid);
    logEvent('exercises-sets-select-exercise-by-id');
    if (downSM) closeMobileFilterDrawer();
  };

  const renderButtons = () => {
    if (dataState === 'FETCHING') return <CircularProgress className={classes.filtersLoader} size={30} />;
    if (!currentSetData || dataState === 'ERROR') return 'Wystąpił problem z pobraniem danych...';

    return (
      <div className={classes.buttonsWrapper}>
        {currentSetData?.exercises.map(({ index, uuid, subject }, indexInArray) => (
          <Chip
            key={uuid}
            onClick={() => onChipClick(uuid)}
            color={indexInArray === cursor ? 'primary' : 'default'}
            label={
              <Typography className={classes.chipLabel}>
                {resolveSubjectNameFromSubjectNumber(subject, 'short')} {index}
              </Typography>
            }
          />
        ))}
      </div>
    );
  };

  useEffect(() => {
    refreshExercisesInSelectedSet();
  }, []);

  return (
    <section className={classes.wrapper}>
      <div className={classes.headingWrapper}>
        <Typography className={classes.heading} color='primary' component='h3'>
          Zestawy zadań
        </Typography>
      </div>
      <div className={classes.selectWrapper}>
        <SelectInput label='Wybierz zestaw zadań' options={exercisesSetsOptions} onChange={onSelectedSetChange} value={currentSetId} />
        <IconButton className={classes.editButton} onClick={openEditDialog} disabled={!currentSetId || !currentSetData} color='primary'>
          <EditIcon fontSize='small' />
        </IconButton>
      </div>
      {currentSetId && renderButtons()}
      {isEditDialogOpen && <EditSetDialog onClose={closeEditDialog} />}
    </section>
  );
};

export default ChosenExercisesFilters;
