import parseExerciseFilesForUI from '../_commonParsers/parseExerciseFilesForUI';
import type {
  ExercisesSetDto,
  ExercisesSet,
  ExercisesSetDetails,
  ExercisesSetDetailsDto,
  CreateExercisesSetDataDto,
  UpdateExercisesSetData,
  UpdateExercisesSetDataDto,
  AppendExercisesSetData,
  AppendExercisesSetDataDto,
} from './exercisesSet.types';
import { CreateExercisesSetData } from './exercisesSet.types';

export const parseExercisesSetForFE = (raw: ExercisesSetDto[]): ExercisesSet[] => raw;

export const parseExercisesSetDetailsForFE = (raw: ExercisesSetDetailsDto): ExercisesSetDetails => ({
  exercises: raw.exercises.map(({ uuid, subject, index, global_id, files }) => ({
    globalId: global_id,
    files: parseExerciseFilesForUI(files),
    uuid,
    subject,
    index,
  })),
  id: raw.id,
  name: raw.name,
});

export const parseCreateExercisesSetForBE = (dataFormUI: CreateExercisesSetData): CreateExercisesSetDataDto => dataFormUI;

export const parseUpdateExercisesSetForBE = (dataFormUI: UpdateExercisesSetData): UpdateExercisesSetDataDto => dataFormUI;

export const parseAppendExercisesSetForBE = (dataFormUI: AppendExercisesSetData): AppendExercisesSetDataDto => ({
  index: dataFormUI.exerciseIndex,
  subject_id: dataFormUI.subject,
});
