import React, { useMemo } from 'react';

import { CircularProgress, Drawer, Typography } from '@material-ui/core';
import { isNil } from 'lodash';
import { useQuery } from 'react-query';

import exercisesApi from 'api/rest/exercises/exercises';
import { ExerciseFileTypes, ExerciseLevel } from 'api/rest/exercises/exercises.types';
import sectionsApi from 'api/rest/sections/sections';
import subjectsApi from 'api/rest/subjects/subjects';
import subsectionsApi from 'api/rest/subsections/subsections';
import DrawerWrapper from 'components/DrawerWrapper/DrawerWrapper';
import QUERY from 'constants/queryKeys';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import useExerciseDetailsStore from 'storages/exerciseDetails';
import useSubjectStore from 'storages/subject';
import type { Uuid } from 'types/Uuid';
import ChosenExercisesButton from 'uniqueComponents/ChosenExercises/ChosenExercisesButton/ChosenExercisesButton';

import useStyles from './ExerciseDetailsDrawer.styles';

const resolveSource = (sourceRaw: string, levelRaw: ExerciseLevel) => {
  const result = [sourceRaw];
  if (levelRaw === 'PP') result.push('Poziom podstawowy');
  if (levelRaw === 'PR') result.push('Poziom rozszerzony');
  return result.join(': ');
};

const prepareExercisePath = (...parts: (string | undefined)[]) => parts.filter(part => !!part).join(' > ');

const ExerciseDetailsDrawer: React.FC = () => {
  const { logEvent, curryLogEvent } = useUserLogContext();

  const { isExerciseDetailsVisible, closeExerciseDetails, currentExerciseId } = useExerciseDetailsStore();

  const { data: subjectData } = useQuery(QUERY.GET_SUBJECTS_LIST, subjectsApi.getSubjectsList(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60, // 1h
  });

  const { data: exerciseData, isLoading } = useQuery(
    [QUERY.GET_SINGLE_EXERCISE, currentExerciseId],
    exercisesApi.getSingle(currentExerciseId as Uuid),
    {
      enabled: !isNil(currentExerciseId),
    },
  );

  const { data: sectionData } = useQuery(
    [QUERY.GET_SINGLE_SECTION, exerciseData?.section],
    sectionsApi.getSection(exerciseData?.section as number),
    {
      enabled: !isNil(exerciseData?.section),
    },
  );

  const { data: subsectionData } = useQuery(
    [QUERY.GET_SINGLE_SUBSECTION, exerciseData?.subsection],
    subsectionsApi.getSubsection(exerciseData?.subsection as number),
    {
      enabled: !isNil(exerciseData?.subsection),
    },
  );

  // const { subject } = useSubjectStore();
  // const isSubjectConflict = useMemo(() => {
  //   if (!subject || !exerciseData) return true;
  //   return subject.pk !== exerciseData.subject;
  // }, [subject, exerciseData]);

  // @ts-ignore
  // const { setSectionAndSubsection } = useExercisesStore();
  // const navigate = useNavigate();
  // const onExercisePathClick = useCallback(() => {
  //   if (sectionData) {
  //     setSectionAndSubsection({ section: sectionData?.id, subsection: subsectionData?.id });
  //     navigate(PATHS.EXERCISES);
  //   }
  // }, [sectionData, subsectionData]);

  const exerciseSubject = useMemo(() => {
    if (!subjectData || !exerciseData) return undefined;

    return subjectData.find(({ id }) => id === exerciseData.subject)?.name;
  }, [subjectData, exerciseData]);

  const classes = useStyles();
  return (
    <Drawer
      anchor='right'
      open={isExerciseDetailsVisible}
      onClose={curryLogEvent(closeExerciseDetails, 'exercise-drawer-close', { exerciseUuid: currentExerciseId })}
      className={classes.root}
    >
      <DrawerWrapper
        className={classes.wrapper}
        title={exerciseData ? `Zadanie nr. ${exerciseData.index}` : ''}
        onClose={curryLogEvent(closeExerciseDetails, 'exercise-drawer-close', { exerciseUuid: currentExerciseId })}
      >
        {isLoading || !exerciseData ? (
          <div className={classes.loaderWrapper}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <div className={classes.exerciseInfoWrapper}>
            <div className={classes.header}>
              <div className={classes.title}>
                <Typography variant='subtitle1'>
                  {/* TODO: use it when REST api will be used in exercise */}
                  {/* <ButtonBase disableRipple disabled={isSubjectConflict} onClick={onExercisePathClick}> */}
                  {/*  {sectionData?.name} {'>'} {subsectionData?.name} */}
                  {/* </ButtonBase> */}
                  {prepareExercisePath(exerciseSubject, sectionData?.name, subsectionData?.name)}
                </Typography>
                <Typography variant='caption'>{resolveSource(exerciseData.source, exerciseData.level)}</Typography>
              </div>
              <div>
                {exerciseData && (
                  <ChosenExercisesButton
                    exerciseToAdd={{ uuid: exerciseData.uuid, index: exerciseData.index, subject: exerciseData.subject }}
                  />
                )}
              </div>
            </div>
            <div className={classes.imageWrapper}>
              {exerciseData.files[ExerciseFileTypes.question].map(src => (
                <img key={src} className={classes.image} src={src} alt='' />
              ))}
            </div>
          </div>
        )}
      </DrawerWrapper>
    </Drawer>
  );
};

export default ExerciseDetailsDrawer;
