import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    height: '.8rem',
    marginRight: theme.spacing(3),
  },
  customErrorWrapper: {
    display: 'grid',
    gap: theme.spacing(6),
  },
  customErrorInfo: {
    fontWeight: 500,
  },
}));

export default useStyles;
