import React from 'react';

import ExercisesNavigation from 'uniqueComponents/Exercises/ExercisesNavigation/ExercisesNavigation';
import ExercisesResultCounter from 'uniqueComponents/Exercises/ExercisesResultCounter/ExercisesResultCounter';
import PrintPanel from 'components/PrintPanel/PrintPanel';
import ExerciseViewHeaderWrapper from 'components/ExerciseViewHeaderWrapper/ExerciseViewHeaderWrapper';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
import useNavigation from '../../../../services/exercises/useNavigation/useNavigation';

// TODO legacy component
const ExerciseHeader = () => {
  const { useDataStore } = useFeatureChecker();

  const { fileType, exercisesCount, exerciseCursor, mark, allExercisesCompleted, currentExercise } = useDataStore();
  const { previous, nextWithMarking, buttonsState, fileTypeChange, currentName } = useNavigation(allExercisesCompleted);

  return (
    <ExerciseViewHeaderWrapper>
      <ExercisesResultCounter />
      <PrintPanel exercisesCount={exercisesCount} />
      <ExercisesNavigation
        actions={{
          onPrevious: previous,
          onNext: nextWithMarking,
          fileTypeChange,
        }}
        buttonsEnableState={buttonsState}
        currentExerciseId={currentExercise?.id}
        exerciseName={currentName}
        exercisesCount={exercisesCount}
        exercisesCursor={exerciseCursor}
        fileType={fileType}
        isMarked={!!mark}
      />
    </ExerciseViewHeaderWrapper>
  );
};

export default ExerciseHeader;
