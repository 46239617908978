import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

interface UserPreferencesStore {
  showNewTables: boolean;
  setShowNewTables: () => void;
  hideMarking: boolean;
  setHideMarking: (newVal: boolean) => void;
}

const userPreferencesStore = persist<UserPreferencesStore>(
  set => ({
    // TABLES SETTING
    showNewTables: true,
    setShowNewTables: () => set(state => ({ showNewTables: !state.showNewTables })),

    // SHEETS SETTINGS
    hideMarking: false,
    setHideMarking: hideMarking => set({ hideMarking }),
  }),
  {
    name: 'userPreferences',
    getStorage: () => localStorage,
  },
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useUserPreferencesStore = create(devtools(userPreferencesStore, { name: 'USER PREFERENCES', enabled: !isProduction }));

export default useUserPreferencesStore;
