import gql from 'graphql-tag';

const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $token: String!, $uidb: String!) {
    confirmResetPassword(password: $password, token: $token, uidb: $uidb) {
      ok
    }
  }
`;

export default RESET_PASSWORD;
