import React from 'react';

import { FormControl, InputLabel, Select, MenuItem, SelectProps } from '@material-ui/core';

import { Option } from 'components/_inputs/_types/Option';
import InputFooter from 'components/_inputs/InputFooter/InputFooter';

export type Props = Omit<SelectProps, 'error'> & {
  label?: string;
  error?: string;
  disabled?: boolean;
  options?: Option[];
  className?: string;
};

const SelectInput: React.FC<Props> = ({ label, error, options, disabled, className, ...rest }) => {
  const labelId = `select_label_${label}`;
  return (
    <FormControl size='small' color='primary' disabled={disabled} error={!!error} variant='outlined' fullWidth className={className}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select label={label} disabled={disabled || !options} labelId={labelId} {...rest}>
        {options &&
          options.map(({ value, label: optionLabel }) => (
            <MenuItem key={value} value={value}>
              {optionLabel}
            </MenuItem>
          ))}
      </Select>
      <InputFooter isError={!!error} text={error} />
    </FormControl>
  );
};

export default SelectInput;
