import gql from 'graphql-tag';

const GET_EXERCISES_LIST_FOR_SHEETS = gql`
  query getExercisesListForSheets(
    $limit: Int
    $offset: Int
    $orderBy: [String]
    $subject: ID!
    $curriculum: [String]!
    $year: Int!
    $month: Int!
  ) {
    sheetExercises(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      subject: $subject
      curriculum: $curriculum
      year: $year
      month: $month
    ) {
      id
      importFileIdentifier
      index
    }
  }
`;

export default GET_EXERCISES_LIST_FOR_SHEETS;
