import { useLocation, useNavigate } from 'react-router-dom';

import ensureIsArray from 'utils/ensureIsArray/ensureIsArray';

export default () => {
  const location = useLocation();
  const navigate = useNavigate();

  return toTrim => {
    const toTrimArray = ensureIsArray(toTrim);
    const currentPath = location.pathname;
    let newPath = currentPath;
    toTrimArray.forEach(stringToTrim => {
      if (currentPath.indexOf(stringToTrim) > 0) newPath = newPath.replace(`/${stringToTrim}`, '');
    });
    navigate(newPath);
  };
};
