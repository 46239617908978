import { useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import messages from 'constants/dictionaries/messages';

import useCommitError from 'services/common/useCommitError/useCommitError';

export default (query, options, errorMsg = messages.ERROR.FETCHING(), successMsg = null) => {
  const commitError = useCommitError();
  const { enqueueSnackbar } = useSnackbar();
  const response = useQuery(query, options);

  if (response.errors || response.error) {
    const error = response.error ? response.error : {};
    const errors = response.errors ? response.errors : {};
    commitError({ ...error, ...errors }, errorMsg, 'apiCall: graphQL error');
  } else if (successMsg) {
    enqueueSnackbar(successMsg, { variant: 'success' });
  }
  return response;
};
