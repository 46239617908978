import React, { useRef } from 'react';

import { Chip, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import clsx from 'clsx';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

import useStyles from './EditExercisesSetExerciseRow.styles';

type Props = {
  index: number;
  id: string;
  onRemove: () => void;
  onClick: () => void;
  onMove: (dragIndex: number, hoverIndex: number) => void;
  color: 'primary' | 'default';
  label: string;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const ITEM_TYPE = 'exercise';

const EditExercisesSetExerciseRow: React.FC<Props> = ({ index, onClick, onRemove, color, label, id, onMove }) => {
  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onMove(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ITEM_TYPE,
    item: () => ({ id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(dragRef);
  drop(preview(previewRef));

  const classes = useStyles();
  return (
    <div className={clsx(classes.exerciseRow, isDragging && classes.exerciseRowInDragging)} ref={previewRef} data-handler-id={handlerId}>
      <Chip onClick={onClick} color={color} label={<Typography>{label}</Typography>} />
      <IconButton innerRef={dragRef} className={clsx(classes.actionIcon, classes.dragIcon)} size='small' color='primary'>
        <DragIndicatorIcon fontSize='small' />
      </IconButton>
      <IconButton className={classes.actionIcon} size='small' color='primary' onClick={onRemove}>
        <DeleteIcon fontSize='small' />
      </IconButton>
    </div>
  );
};

export default EditExercisesSetExerciseRow;
