import React, { ChangeEvent } from 'react';

import { Tab, Tabs } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import * as DOMPurify from 'dompurify';
import { useQuery } from 'react-query';
import { generatePath, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import documentsApi, { documentsApiKeys } from 'api/rest/documents/documents';
import { DocumentsType, documents as possibleDocumentTypes } from 'api/rest/documents/documents.types';
import PageLoader from 'components/PageLoader/PageLoader';
import PageTitle from 'components/PageTitle/PageTitle';
import SomethingWentWrong from 'components/SomethingWentWrong/SomethingWentWrong';
import PATHS from 'constants/paths';

import useStyles from './DocumentsPage.styles';

const DocumentsPage: React.FC = () => {
  const { documentType } = useParams() as { documentType: DocumentsType };
  const location = useLocation();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(documentsApiKeys.getDocuments(documentType), () => documentsApi.getDocuments(documentType));

  const classes = useStyles();

  const handleChange = (_: ChangeEvent<any>, newValue: string) => {
    navigate(newValue);
    if (newValue !== location.pathname) window.scrollTo(0, 0);
  };

  if (!possibleDocumentTypes.includes(documentType)) return <Navigate replace state={{ from: location }} to='/' />;

  if (!data && !isLoading) {
    return <SomethingWentWrong />;
  }
  return (
    <div className={classes.wrapper}>
      <PageTitle>Dokumenty</PageTitle>
      <Tabs className={classes.header} value={location.pathname} onChange={handleChange} indicatorColor='primary' textColor='primary'>
        <Tab value={`/${generatePath(PATHS.DOCUMENTS, { documentType: 'terms' })}`} label='Regulamin' />
        <Tab value={`/${generatePath(PATHS.DOCUMENTS, { documentType: 'privacy_policy' })}`} label='Polityka Prywatności' />
      </Tabs>
      {isLoading ? (
        <PageLoader />
      ) : (
        <main
          className={classes.main}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data.content),
          }}
        />
      )}
    </div>
  );
};

export default DocumentsPage;
