import React, { useEffect, useMemo, useState } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as Pin } from 'assets/icons/general/pin.svg';
import { useGlobalDialogContext } from 'context/GlobalDialogContext';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    background: 'none',
    border: 'none',
    display: 'flex',
    padding: '.25rem 0 .25rem .5rem',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  name: {
    fontSize: '.85rem',
    marginLeft: theme.spacing(1),
    color: theme.palette.text.disabled,

    [theme.breakpoints.down('md')]: {
      fontSize: '.65rem',
    },
  },
  pin: {
    width: '1rem',
    height: '1rem',
    '& .general-pin-1': {
      fill: ({ isMarked }) => (isMarked ? theme.palette.primary.main : theme.palette.grey['300']),
    },
    '& .general-pin-2': {
      fill: theme.palette.common.white,
    },
  },
}));

function ExercisesName({ name: nameFromProps, isMarked, currentExerciseId, cacheStrategy = 'apollo' }) {
  const [name, setName] = useState(nameFromProps);
  useEffect(() => {
    if (nameFromProps) setName(nameFromProps);
  }, [nameFromProps]);

  const { addToGlobalDialogQueue, GLOBAL_DIALOG } = useGlobalDialogContext();
  const showModalMark = () =>
    addToGlobalDialogQueue({ type: GLOBAL_DIALOG.MARK_EXERCISE, props: { exerciseToMark: currentExerciseId, cacheStrategy } });

  const onClick = () => {
    showModalMark(currentExerciseId);
  };

  const classes = useStyles({ isMarked });
  const [splitName, , url] = useMemo(() => name.split(' '), [name]);
  const goToUrl = () => window.open(url);
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.name} component='h2' fontSize='s' noWrap onDoubleClick={isProd ? null : goToUrl}>
        {isProd ? name : splitName}
      </Typography>
      {currentExerciseId && (
        <button className={classes.button} onClick={onClick} type='button'>
          <Pin className={classes.pin} title='Oznacz zadanie' />
        </button>
      )}
    </div>
  );
}

ExercisesName.defaultProps = {
  name: '',
  isMarked: false,
};

export default ExercisesName;
