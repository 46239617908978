import React from 'react';

import SomethingWentWrong from 'components/SomethingWentWrong/SomethingWentWrong';
import logError from 'utils/logError/logError';
import DataPersist, { DATA_PERSIST_KEY } from '../../utils/dataPersist/dataPersist';

const shouldFlushDataStorage = new DataPersist(DATA_PERSIST_KEY.SHOULD_FLUSH_STORAGES, 'sessionStorage');

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logError({ error, errorInfo });
  }

  render() {
    const { state, props } = this;
    if (state.hasError) return <SomethingWentWrong />;
    shouldFlushDataStorage.set(false);
    return props.children;
  }
}

export default ErrorBoundary;
