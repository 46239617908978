import React, { useMemo } from 'react';

import { isNil } from 'lodash';
import { useQuery } from 'react-query';

import usersApi from 'api/rest/users/users';
import QUERY from 'constants/queryKeys';
import { parseSubjectCodeToSubjectNumber } from 'services/common/subjectParsers/subjectParsers';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
import useSubjectStore from 'storages/subject';
import { SupportedSubjects } from 'types/Subject';
import ChosenExercisesButton from 'uniqueComponents/ChosenExercises/ChosenExercisesButton/ChosenExercisesButton';

import useStyles from './AddExerciseToChosenButton.styles';

type Props = {
  minimal?: boolean;
  disabled?: boolean;

  subject?: number;
  index?: number;
};

const AddExerciseToChosenButton: React.FC<Props> = ({ minimal, disabled, subject, index }) => {
  const { data } = useQuery(QUERY.GET_ME, usersApi.getMe());
  const premiumUser = useMemo(() => data?.hasPremiumAccess, [data]);

  const { useDataStore } = useFeatureChecker();
  const { subject: subjectFallback } = useSubjectStore();
  const { currentExercise } = useDataStore();

  const styles = useStyles({ minimal });
  const subjectUnified = isNil(subject) ? parseSubjectCodeToSubjectNumber(subjectFallback?.subjectCode as SupportedSubjects) : subject;
  const indexUnified = isNil(index) ? currentExercise?.index : index;

  if (!premiumUser || !subjectUnified || !indexUnified) return null;
  return (
    <div className={styles.wrapper}>
      <ChosenExercisesButton
        disabled={disabled}
        exerciseToAdd={{
          index: indexUnified,
          subject: subjectUnified,
        }}
      />
    </div>
  );
};

export default AddExerciseToChosenButton;
