import React from 'react';

import { Typography } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

import useStyles from './DrawerWrapper.styles';

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  title?: string;
  className?: string;
};

const DrawerWrapper: React.FC<Props> = ({ children, onClose, title, className }) => {
  const classes = useStyles({ hasTitle: !!title });
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <Typography color='primary'>{title}</Typography>
        <ButtonBase onClick={onClose} className={classes.button}>
          <CloseIcon />
        </ButtonBase>
      </div>
      {children}
    </div>
  );
};

export default DrawerWrapper;
