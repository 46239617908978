import { PaginatedData, PaginatedDataDto } from '../_types';

const paginatedResponseParser = <I, O>(paginatedData: PaginatedDataDto<I>, resultsParser: (data: I[]) => O[]): PaginatedData<O> => ({
  hasPrevious: !!paginatedData.previous,
  hasNext: !!paginatedData.next,
  count: paginatedData.count,
  results: resultsParser(paginatedData.results),
});

export default paginatedResponseParser;
