import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { Subject } from 'types/Subject';

import useChosenExercisesStore from './chosenExercises/chosenExercises';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

interface SubjectStore {
  subject: Subject | null;
  setSubject: (subject: Subject) => void;
}

const subjectStore = persist<SubjectStore>(
  set => ({
    subject: null,
    setSubject: (subject: Subject) => {
      // will be fixed when chosenExercises will be typed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      useChosenExercisesStore.setState(({ subjectCursors }) => ({ exerciseCursor: subjectCursors[subject.subjectCode] || 0 }));
      set({ subject });
    },
  }),
  {
    name: `${process.env.REACT_APP_VERSION}_subject`,
    getStorage: () => localStorage,
  },
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useSubjectStore = create(devtools(subjectStore, { name: 'SUBJECT', enabled: !isProduction }));

export default useSubjectStore;
