import React from 'react';

import { useSnackbar } from 'notistack';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import setNewPassword from 'api/rest/setNewPassword/setNewPassword';
import MUTATION from 'constants/mutationsKeys';

type NewPasswordFormInput = {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

type SetNewPasswordDialogHook = (params: { onClose: () => void }) => {
  newPasswordValue: string;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  control: Control<NewPasswordFormInput>;
  disabled: boolean;
  isLoading: boolean;
};

const useSetNewPasswordDialog: SetNewPasswordDialogHook = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const newPasswordMutation = useMutation(MUTATION.SET_NEW_PASSWORD, setNewPassword.set());

  const { control, handleSubmit, formState, watch } = useForm<NewPasswordFormInput>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    },
  });

  const newPasswordValue = watch('newPassword');

  const onSubmit: SubmitHandler<NewPasswordFormInput> = async formData => {
    try {
      const response = await newPasswordMutation.mutateAsync(formData);
      if (!response.success) {
        enqueueSnackbar(response.message, { variant: 'error' });
      } else {
        enqueueSnackbar('Hasło poprawnie zmienone', { variant: 'success' });
        onClose();
      }
    } catch (e) {
      enqueueSnackbar('Coś poszło nie tak...', { variant: 'error' });
    }
  };

  return {
    newPasswordValue,
    onSubmit: handleSubmit(onSubmit),
    control,
    disabled: !formState.isDirty,
    isLoading: newPasswordMutation.isLoading || newPasswordMutation.isLoading,
  };
};
export { useSetNewPasswordDialog };
