import React, { useMemo } from 'react';

import { Select } from '@material-ui/core';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

import FiltersFormControl from 'components/FiltersFormControl/FiltersFormControl';
import { useUserDataContext } from 'context/UserDataContext';
import parseValuesToSelect from 'services/common/parseValuesToSelect/parseValuesToSelect';
import useSheetsStore from 'storages/sheets/sheets';

import FiltersBlock from 'uniqueComponents/Exercises/ExercisesFilters/FiltersBlock/FiltersBlock';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

const getMonthName = month => format(new Date(0, month), 'LLLL', { locale: pl });

function SheetsMonth() {
  const { logEvent } = useUserLogContext();

  const { sheets, year, setMonth, month } = useSheetsStore();
  const { isMobile } = useUserDataContext();

  const onChange = ({ target: { value } }) => {
    logEvent('select-filter-click', { filter: 'Arkusze - miesiąc', value, feature: 'sheets' });
    setMonth(value);
  };

  const months = useMemo(() => {
    if (!sheets || !year) return [];
    const data = sheets[year] ? sheets[year].map(value => ({ value, label: getMonthName(value - 1) })) : [];
    return parseValuesToSelect({ data, isMobile, placeholder: 'Wybierz...' });
  }, [sheets, year]);

  return (
    <FiltersBlock heading='Miesiąc'>
      <FiltersFormControl size='small' variant='outlined'>
        <Select disabled={!year} displayEmpty native={isMobile} onChange={onChange} value={month || ''}>
          {months}
        </Select>
      </FiltersFormControl>
    </FiltersBlock>
  );
}

export default SheetsMonth;
