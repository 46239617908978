import React from 'react';

import { CircularProgress, Paper } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import ClassroomExercisesTable from 'components/ClassroomExercisesTable/ClassroomExercisesTable';
import ExercisesStats from 'components/ExercisesStats/ExercisesStats';
import PageTitle from 'components/PageTitle/PageTitle';

import { useExercisesMarksSummaryData, useClassroomExercisesData, useNavigationUpdate } from './ClassroomExercisesPage.logic';
import useStyles from './ClassroomExercisesPage.styles';

const ClassroomExercisesPage: React.FC = () => {
  useNavigationUpdate();
  const { classroomId } = useParams() as { classroomId: string };
  const { data: tableData, isLoading: tableDataLoading, tableProps } = useClassroomExercisesData(classroomId);
  const { data: summaryData, isLoading: summaryDataLoading } = useExercisesMarksSummaryData(classroomId);

  const classes = useStyles();
  const isLoading = tableDataLoading || summaryDataLoading;

  return (
    <>
      <PageTitle>Zadania klasy</PageTitle>
      {isLoading ? (
        <CircularProgress size={30} />
      ) : (
        <div className={classes.root}>
          <ExercisesStats
            stats={[
              { label: 'Do wyjaśnienia', background: '#FF530D', value: `${summaryData?.explain || '0'}` },
              { label: 'Trudne', background: '#F2AF0D', value: `${summaryData?.hard || '0'}` },
              { label: 'Łatwe', background: '#009FE3', value: `${summaryData?.easy || '0'}` },
            ]}
          />
          <Paper>
            <ClassroomExercisesTable data={tableData} isLoading={isLoading} tableProps={tableProps} />
          </Paper>
        </div>
      )}
    </>
  );
};

export default ClassroomExercisesPage;
