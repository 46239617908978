import { useMemo } from 'react';

import { ExerciseFileTypes } from 'api/rest/exercises/exercises.types';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import useExercisesToDisplay_sets from 'storages/exercisesToDisplay/useExercisesToDisplay_sets';

const useModernNavigation = () => {
  const { logEvent } = useUserLogContext();
  const { decreaseCursor, increaseCursor, setSelectedType, currentExercise, isFirst, isLast } = useExercisesToDisplay_sets();

  const onPrevious = () => {
    logEvent('previous-exercise-click');

    decreaseCursor();
  };
  const onNext = () => {
    logEvent('next-exercise-click');

    increaseCursor();
  };

  const fileTypeChange = (d: ExerciseFileTypes) => () => setSelectedType(d);

  const buttonsState = useMemo(
    () => ({
      previous: !!currentExercise && !isFirst,
      next: !!currentExercise && !isLast,
      answer: !!currentExercise,
      hint: !!currentExercise && !!currentExercise?.files[ExerciseFileTypes.hint].length,
      question: !!currentExercise,
    }),
    [currentExercise, isFirst, isLast],
  );

  return {
    exerciseName: currentExercise?.importFileIdentifier || '',
    actions: {
      onPrevious,
      onNext,
      fileTypeChange,
    },
    buttonsState,
  };
};

export default useModernNavigation;
