import React from 'react';

import { FormHelperText, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './InputFooter.styles';

type Props = {
  isError?: boolean;
  text?: string;
  className?: string;
  name?: string;
};

const InputFooter: React.FC<Props> = ({ isError, text, className, name }) => {
  const classes = useStyles();
  return (
    <FormHelperText className={clsx(!text && classes.empty, className)}>
      <Typography
        color={isError ? 'error' : 'textSecondary'}
        data-testid={`${name}_error`}
        variant='caption'
        className={clsx(!isError && classes.helperText)}
      >
        {text}
      </Typography>
    </FormHelperText>
  );
};

export default InputFooter;
