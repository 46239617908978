import React, { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import pageTransition from 'styles/animations/pageTransition';

const PageTransitionWrapper = ({ children }) => (
  // const loca
  // TODO use it and test it
  // console.log(action);
  //
  // const [s, ss] = useState(false);
  //
  // useEffect()

  <motion.div {...pageTransition} animate='animate' exit='exit' initial='initial'>
    {children}
  </motion.div>
);
export default PageTransitionWrapper;
