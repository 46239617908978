import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { useLazyQuery } from '@apollo/client';
import { FormControl, Grid, Select, TextField, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { generatePath } from 'react-router-dom';

import image from 'assets/decorative_pics/books-laptop.png';
import ButtonWithLoader from 'components/ButtonWithLoader/ButtonWithLoader';
import ButtonRound from 'components/ButtonRound/ButtonRound';
import mmEmail from 'constants/dictionaries/contactData';
import messages from 'constants/dictionaries/messages';
import { useUserDataContext } from 'context/UserDataContext';
import CREATE_TRANSACTION from 'api/graphql/mutations/subscriptions/CREATE_TRANSACTION';
import VERIFY_PROMOCODE from 'api/graphql/mutations/subscriptions/VERIFY_PROMOCODE';
import GET_ACCESS_TYPES from 'api/graphql/queries/user/GET_ACCESS_TYPES';
import parseValuesToSelect from 'services/common/parseValuesToSelect/parseValuesToSelect';
import parsePrice from 'utils/parsePrice/parsePrice';
import logError from 'utils/logError/logError';
import prepareSourcesForInput from 'services/common/prepareSourcesForInput/prepareSourcesForInput';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import { useQueryClient } from 'react-query';
import QUERY from 'constants/queryKeys';
import PATHS from 'constants/paths';

const useStyles = makeStyles(theme => ({
  grid: {
    margin: '10px 0',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  bold: {
    fontWeight: 700,
  },
  boldColor: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  priceDetails: {
    paddingTop: theme.legacySpacing(8),
    borderTop: `thin solid ${theme.palette.grey[400]}`,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    rowGap: theme.legacySpacing(3),
    textAlign: 'left',
  },
  price: {
    justifySelf: 'flex-end',
  },
  centered: {
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
  },
  additionalInfo: {
    fontSize: '12px',
    color: theme.palette.text.hint,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  image: {
    maxWidth: '180px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  promoCodeWrapper: {
    marginTop: '2rem',
    display: 'grid',
    gridGap: theme.legacySpacing(4),
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    fontWeight: 700,
    '@media(max-width: 380px)': {
      marginTop: '1rem',
      gridTemplateColumns: 'auto',
    },
  },
  promoCodeInput: {
    '& .MuiOutlinedInput-input': {
      fontSize: '.875rem',
    },
  },
  promoCodeError: {
    color: theme.palette.error.main,
    fontWeight: 400,
  },
}));

const SubscriptionPurchase = () => {
  const queryClient = useQueryClient();
  const { logEvent } = useUserLogContext();
  const { data: accessTypesData } = useQuery(GET_ACCESS_TYPES);
  const [refetchAccessTypes, { data: freshAccessTypes }] = useLazyQuery(GET_ACCESS_TYPES, { fetchPolicy: 'network-only' });
  const [getPaymentUrl, { loading }] = useMutation(CREATE_TRANSACTION);
  const [checkPromoCode, { loading: promoCodeLoading }] = useMutation(VERIFY_PROMOCODE);

  const { isMobile } = useUserDataContext();
  const { enqueueSnackbar } = useSnackbar();

  const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const downXs = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const [accessTypes, setAccessTypes] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanObject, setSelectedPlanObject] = useState(null);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeError, setPromoCodeError] = useState(null);
  const [promoPlan, setPromoPlan] = useState(null);
  const [useP24, setUseP24] = useState(true);

  const updateAccessTypes = rawAccessTypes => {
    const initialAccessType = rawAccessTypes[0]?.id;
    setAccessTypes(rawAccessTypes);
    setSelectedPlan(initialAccessType);
  };

  useEffect(() => {
    if (accessTypesData) updateAccessTypes(accessTypesData.accessTypes);
  }, [accessTypesData]);

  useEffect(() => {
    if (freshAccessTypes) updateAccessTypes(freshAccessTypes.accessTypes);
  }, [freshAccessTypes]);

  useEffect(() => {
    const newPlan = accessTypes.find(({ id }) => selectedPlan === id);
    if (newPlan) setSelectedPlanObject(newPlan);
  }, [selectedPlan, accessTypes]);

  const onPlanChange = ({ target }) => {
    try {
      logEvent('select-access-type', { plan: accessTypes.find(({ id }) => id === target.value).name });
    } catch (e) {
      if (process.env.REACT_APP_ENVIRONMENT !== 'production') throw Error('Event error: cannot find access type');
    }
    setSelectedPlan(target.value);
  };

  const onPayClick = async () => {
    try {
      logEvent('pay-click');
      const input = { accessTypeId: selectedPlan, ...prepareSourcesForInput() };
      if (promoPlan && promoPlan.length) input.promoCode = promoCode || null;
      const res = await getPaymentUrl({ variables: { input } });
      const { paymentUrl, subscriptionCreated } = res.data.createTransaction;
      if (!paymentUrl && useP24) {
        throw new Error('Issue with fetching payment url');
      }
      if (useP24) {
        window.location = paymentUrl;
      } else if (subscriptionCreated) {
        await queryClient.invalidateQueries(QUERY.GET_ME);
      }
    } catch (e) {
      enqueueSnackbar(messages.ERROR.PROCESSING('żądania'), { variant: 'error' });
      logError({ message: 'Issue with fetching payment url' });
    }
  };

  const onPromoCodeChange = ({ target }) => {
    setPromoCodeError(null);
    setPromoCode(target.value.trim());
  };

  const onPromoCodeCheckClick = async () => {
    try {
      const { data } = await checkPromoCode({ variables: { input: { promoCode: promoCode || null } } });
      if (data.verifyPromoCode.ok) {
        logEvent('add-promo-code', { success: true });
        const { accessType, isPaymentRequired } = data.verifyPromoCode;
        setPromoPlan([accessType]);
        setTimeout(() => {
          // TODO use useEffect to update selected value
          setSelectedPlan(accessType.id);
          setSelectedPlanObject(accessType);
          setUseP24(isPaymentRequired);
        }, 200);
      } else {
        logEvent('add-promo-code', { success: false });
        setPromoCodeError('Kod promocyjny niepoprawny');
      }
    } catch (e) {
      enqueueSnackbar(messages.ERROR.PROCESSING('kodu'), { variant: 'error' });
      logError({ message: 'Issue with promocode check' });
    }
  };

  const onPromoCancelClick = () => {
    refetchAccessTypes();
    setUseP24(true);
    setPromoCode(null);
    setPromoPlan(null);
  };

  const classes = useStyles({ promoPlan });
  return (
    <Grid className={classes.grid} container spacing={6}>
      <Grid item xs={12}>
        Obecnie korzystasz z:
        {downXs ? <br /> : ' '}
        <span className={classes.bold}>Plan Darmowy</span>
      </Grid>
      <Grid item xs={12}>
        Aby otrzymać dostęp do pełnej bazy przedmiotów wybierz jeden z <span className={classes.boldColor}>Planów Premium:</span>
      </Grid>
      <Grid container item xs={12}>
        {selectedPlan && selectedPlanObject && (
          <Grid container item md={7} spacing={8} xs={12}>
            <Grid item xs={12}>
              <FormControl hiddenLabel size='small' variant='outlined'>
                <Select className={classes.centered} displayEmpty native={isMobile} onChange={onPlanChange} value={selectedPlan || ''}>
                  {parseValuesToSelect({
                    data: promoPlan || accessTypes,
                    isMobile,
                    dictionary: {
                      label: ({ name, price }) => `${name} - ${parsePrice(price)} z VAT`,
                      value: 'id',
                    },
                  })}
                </Select>
              </FormControl>
              <div className={clsx(classes.promoCodeWrapper, classes.centered)}>
                {promoPlan ? (
                  'Kod aktywowany!'
                ) : (
                  <FormControl label='Kod promocyjny' size='small' variant='outlined'>
                    <TextField
                      className={classes.promoCodeInput}
                      label='Kod promocyjny'
                      name='promoCode'
                      onChange={onPromoCodeChange}
                      size='small'
                      value={promoCode}
                      variant='outlined'
                    />
                  </FormControl>
                )}
                <ButtonWithLoader disabled={!promoCode || promoCodeError} loading={promoCodeLoading}>
                  <ButtonRound
                    color='primary'
                    onClick={promoPlan ? onPromoCancelClick : onPromoCodeCheckClick}
                    size='small'
                    variant='contained'
                  >
                    {promoPlan ? 'Anuluj' : 'Zastosuj'}
                  </ButtonRound>
                </ButtonWithLoader>
                {promoCodeError && <p className={classes.promoCodeError}>{promoCodeError}</p>}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={clsx(classes.priceDetails, classes.centered)}>
                {useP24 && (
                  <>
                    <div>Kwota netto:</div>
                    <div className={classes.price}>{parsePrice(selectedPlanObject.priceNetto)}</div>
                    <div>VAT 23%</div>
                    <div className={classes.price}>{parsePrice(selectedPlanObject.price - selectedPlanObject.priceNetto)}</div>
                  </>
                )}
                <div className={classes.boldColor}>RAZEM:</div>
                <div className={clsx(classes.boldColor, classes.price)}>{parsePrice(selectedPlanObject.price)}</div>
              </div>
            </Grid>
            <Grid container item justify={downSm ? 'center' : 'flex-start'} xs={12}>
              <ButtonWithLoader loading={loading}>
                <ButtonRound color='primary' onClick={onPayClick} variant='contained'>
                  {useP24 ? 'Zapłać z Przelewy24' : 'Aktywuj plan'}
                </ButtonRound>
              </ButtonWithLoader>
            </Grid>
            <Grid className={classes.additionalInfo} item xs={12}>
              Zamówienie możesz anulować w przeciągu 14 dni od daty zakupu poprzez wysłanie wiadomości e-mail na adres{' '}
              <a href={`mailto:${mmEmail}`}>{mmEmail}.</a> Szczegóły w{' '}
              <a href={`/${generatePath(PATHS.DOCUMENTS, { documentType: 'terms' })}`} rel='noreferrer noopener' target='_blank'>
                Regulaminie
              </a>
              .
            </Grid>
          </Grid>
        )}
        <Grid alignItems='flex-end' container item justify='center' md={5}>
          <img alt='' className={classes.image} src={image} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubscriptionPurchase;
