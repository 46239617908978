import { makeStyles } from '@material-ui/core';

type StylesProps = {
  selected: boolean;
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(4),
    paddingTop: theme.spacing(6),
    margin: '0 auto',
    width: '100%',
    maxWidth: '420px',
  },
  ratesWrapper: {
    justifySelf: 'center',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gridGap: theme.spacing(4),
  },
  buttons: {
    justifySelf: 'center',
    justifyContent: 'center',
    display: 'grid',
    gridGap: theme.spacing(4),
    paddingTop: theme.spacing(4),
    gridTemplateColumns: 'repeat(2, 70px)',
  },
  rateString: {
    marginBottom: theme.spacing(3),
  },
  buttonIcon: {
    height: '70px',
    width: '70px',
    borderRadius: '100%',
    border: 'none',
    cursor: 'pointer',
    padding: '0',

    '& #icon_rating_bg': {
      transition: theme.transitions.create(['opacity', 'box-shadow'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.standard,
      }),
      opacity: ({ selected }: StylesProps) => (selected ? 0.15 : 0.65),
    },

    '&:hover #icon_rating_bg': {
      opacity: 1,
    },

    '&.selected #icon_rating_bg': {
      opacity: 1,
    },

    [theme.breakpoints.down('sm')]: {
      height: '60px',
      width: '60px',
    },
  },
}));

export default useStyles;
