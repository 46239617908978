import React, { JSXElementConstructor, ReactElement } from 'react';

import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { LoaderButtonModifyChildren } from './LoaderButton.logic';
import useStyles from './LoaderButton.styles';

type Props = {
  children: ReactElement<{ className: string; disabled: boolean }, JSXElementConstructor<{ className: string; disabled: boolean }>>;
  isLoading: boolean;
  className?: string;
};

const LoaderButton: React.FC<Props> = ({ children, isLoading, className }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.wrapper, className)}>
      {LoaderButtonModifyChildren(children, classes.button, isLoading)}
      {isLoading && <CircularProgress className={classes.progressIndicator} size={18} />}
    </div>
  );
};

export default LoaderButton;
