import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 99,
  },
  wrapper: {
    width: '100vw',
    maxWidth: '768px',
  },
  loaderWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '768px',
  },
  header: {
    position: 'sticky',
    top: '52px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    background: theme.palette.grey[100],
    padding: theme.spacing(1, 0, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  title: {
    maxWidth: '620px',
  },
  exerciseInfoWrapper: {
    display: 'grid',
    gridGap: theme.spacing(4),
    padding: theme.spacing(0, 4),
  },
  imageWrapper: {
    background: theme.palette.common.white,
    padding: theme.spacing(3, 2),
  },
  image: {
    maxWidth: '100%',
  },
  tooltip: {
    textAlign: 'center',
  },
}));

export default useStyles;
