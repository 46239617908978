import React from 'react';

import { Theme, useMediaQuery } from '@material-ui/core';

import Container from 'containers/Container/Container';

import ExercisesSetsDesktop from '../ExercisesSetsDesktop/ExercisesSetsDesktop';
import ExercisesSetsMobile from '../ExercisesSetsMobile/ExercisesSetsMobile';
import useStyles from './ExercisesSetsContent.styles';

const ExercisesSetsContent = () => {
  const classes = useStyles();
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Container className={classes.container} width='narrow'>
      {downSm ? <ExercisesSetsMobile /> : <ExercisesSetsDesktop />}
    </Container>
  );
};

export default ExercisesSetsContent;
