import axios from 'axios';

import { Option } from '../../../components/_inputs/_types/Option';
import { DefaultApiResponse } from '../_types';
import { parseSubjectsListForUI, parseSubjectForUI, parseSubjectsListForDropdown } from './subjects.parsers';
import { Subject, SubjectDetails, SubjectDetailsDto, SubjectDto } from './subjects.types';

const endpoints = {
  subjects: 'exercises/subjects/',
};

type GetSubjectsListQuery = () => Promise<Subject[]>;
type GetSubjectsListForDropdownQuery = () => Promise<Option[]>;
type GetSubject = () => Promise<SubjectDetails>;

const subjectsApi = {
  getSubject:
    (subjectId: number | string): GetSubject =>
    async () => {
      const response = await axios.get<GetSubject, DefaultApiResponse<SubjectDetailsDto>>(`${endpoints.subjects}${subjectId}/`);
      return parseSubjectForUI(response.data);
    },
  getSubjectsList: (): GetSubjectsListQuery => async () => {
    const response = await axios.get<GetSubjectsListQuery, DefaultApiResponse<SubjectDto[]>>(endpoints.subjects);
    return parseSubjectsListForUI(response.data);
  },
  getSubjectsListForDropdown:
    (addSelectAllOption: boolean): GetSubjectsListForDropdownQuery =>
    async () => {
      const response = await axios.get<GetSubjectsListQuery, DefaultApiResponse<SubjectDto[]>>(endpoints.subjects);
      return parseSubjectsListForDropdown(response.data, addSelectAllOption);
    },
};

export default subjectsApi;
