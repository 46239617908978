import gql from 'graphql-tag';

const GET_FILTER_TAGS = gql`
  query getFilterTags($id: ID!, $section: String, $subsection: String, $filters: [String], $curriculum: [String]) {
    getSubject(id: $id) {
      id
      tagCategories {
        name
        id
        tags(section: $section, subsection: $subsection, tags: $filters, curriculum: $curriculum) {
          id
          name
          exercisesCount
        }
      }
    }
  }
`;

export default GET_FILTER_TAGS;
