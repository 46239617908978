import React from 'react';

import { CircularProgress } from '@material-ui/core';

import FullScreen from 'containers/FullScreen/FullScreen';
import PageTransitionWrapper from 'containers/PageTransitionWrapper/PageTransitionWrapper';

function PageLoader() {
  return (
    <PageTransitionWrapper>
      <FullScreen>
        <CircularProgress size={60} />
      </FullScreen>
    </PageTransitionWrapper>
  );
}

export default PageLoader;
