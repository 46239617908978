import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  actionIcon: {
    pointerEvents: 'none',
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  exerciseRow: {
    display: 'grid',
    gridTemplateColumns: '100px auto auto',
    gap: theme.spacing(1),

    '&:hover $actionIcon': {
      opacity: 1,
      pointerEvents: 'auto',
    },
  },
  exerciseRowInDragging: {
    opacity: 0,
  },
  dragIcon: {
    cursor: 'grab',
  },
}));

export default useStyles;
