import React, { useState } from 'react';

import { Button, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    cursor: ({ isSheet }) => (isSheet ? 'pointer' : 'auto'),
  },
  counter: {
    fontSize: '.85rem',
    fontWeight: 'bold',
  },
  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    gridGap: theme.spacing(1, 5),
    padding: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    wrapper: {
      display: 'none',
    },
  },
}));

const getButtons = exercises => {
  const result = [];
  if (exercises) {
    exercises.forEach(({ importFileIdentifier }, index) => {
      let name = importFileIdentifier;
      if (!isProd) {
        [name] = name.split(' ');
      }
      [name] = name.split('-z').reverse();
      result.push({ name, index });
    });
  }
  return result;
};

function ExercisesPagination({ total, exerciseCursor }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = ({ currentTarget }) => setAnchorEl(currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { useDataStore, isSheet } = useFeatureChecker();
  const { setExerciseCursor, exercises } = useDataStore();
  const setExercise = cursor => () => {
    handleClose();
    setExerciseCursor(cursor);
  };

  const classes = useStyles({ isSheet });

  if (!total) return null;
  return (
    <>
      <div aria-describedby={id} className={classes.wrapper} onClick={handleClick}>
        {exerciseCursor < total && (
          <Typography className={classes.counter} component='span'>
            {`${exerciseCursor + 1}\xa0z\xa0${total}`}
          </Typography>
        )}
      </div>
      {isSheet && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          id={id}
          onClose={handleClose}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.buttonsContainer}>
            {getButtons(exercises).map(({ index, name }) => (
              <Button key={name} onClick={setExercise(index)}>
                {name}
              </Button>
            ))}
          </div>
        </Popover>
      )}
    </>
  );
}

ExercisesPagination.defaultProps = {
  total: null,
  exerciseCursor: null,
};

export default ExercisesPagination;
