type TutorialVideo = {
  title: string;
  src: string;
  toc: string[];
};

const getTutorialVideos = (): TutorialVideo[] => [
  {
    title: 'Szkolenie dla uczniów na start',
    src: 'https://youtu.be/nZ1XIw9su80',
    toc: [
      'jak nauczyciel może Ci pomóc, gdy korzystasz z aplikacji',
      'jak zwinnie przygotowywać się do kartkówek i sprawdzianów',
      'jak zorganizować i korzystać z zeszytu błędów w aplikacji',
    ],
  },
  {
    title: 'Filtrowanie zadań- kiedy korzystać',
    src: 'https://youtu.be/YBlf02sbaqo',
    toc: [
      'jak działają filtry',
      'gdzie znajdę spis treści',
      'w jakiej kolejności wyświetlają się zadania',
      'jak, kiedy i po co wracać do zadań trudnych',
      'kiedy skorzystać z czasowników operacyjnych',
    ],
  },
  {
    title: 'Przycisk tablice / przycisk pomoce',
    src: 'https://youtu.be/-EAf7rhEGtQ',
    toc: [
      'jak korzystać z tablic maturalnych w chemii, biologii, fizyce',
      'jak korzystać z tablic maturalnych z matematyki',
      'jak korzystać z barwnych załączników do zadań z geografii',
    ],
  },
  {
    title: 'Miejsce na notatkę',
    src: 'https://youtu.be/qVDhDrIRWGk',
    toc: ['jak działa zapisywanie notatek', 'kto widzi Twoje notatki', 'jak wykorzystać pole notatek dla własnej korzyści'],
  },
  {
    title: 'Funkcja rysowania, czyli tablet graficzny, Ipad zamiast papieru?',
    src: 'https://youtu.be/pJ4IzMajCE4',
    toc: ['jak działa funkcja rysowania', 'kto skorzysta na tej funkcji', 'na jakich urządzeniach działa'],
  },
  {
    title: 'Drukowanie zadań  jak pracować z wydrukiem',
    src: 'https://youtu.be/RhzjxUXERMw',
    toc: [
      'jak działa funkcja drukowania zadań',
      'jak rozwiązywać zadania korzystając z wydruku',
      'gdzie mi się przyda zmiana odstępu pomiędzy zadaniami',
    ],
  },
  {
    title: 'Arkusze maturalne, kiedy robić i od jakich zacząć',
    src: 'https://youtu.be/HtnGkPbmDkg',
    toc: [
      'jak działają arkusze i jak płynnie przeskakiwać pomiędzy zadaniami',
      'kiedy zacząć rozwiązywać arkusze',
      'od jakich arkuszy zacząć',
    ],
  },
  // TODO - exercisesSet change it
  {
    title: 'Zapisane zadania  generowanie sprawdzianów, kartkówek, prac domowych',
    src: 'https://youtu.be/0jinxqlc-To',
    toc: [
      'jak działają zapisane zadania',
      'jak wygenerować sprawdzian/kartkówkę, aby uczniowie mogli się przygotować',
      'jak wygenerować specjalny zestaw zadań na pracę domową',
      'jak wyszukiwać zadania po numerze id',
    ],
  },
  {
    title: 'Jak i dlaczego oceniać nagrania video ',
    src: 'https://youtu.be/3gsQyF085yY',
    toc: ['jak oceniać rozwiązania video'],
  },
  {
    title: 'Jak zgłosić problemy z aplikacją',
    src: 'https://youtu.be/bBNKOC_2Q5E',
    toc: ['jakie problemy można zgłaszać', 'gdzie trafiają zgłoszenia'],
  },
  {
    title: 'Jak podzielić się opinią lub sugestią',
    src: 'https://youtu.be/oqU-CmdHtDI',
    toc: ['jak z poziomu aplikacji przesłać opinię lub sugestię', 'na jaki adres email możesz do nas pisać ws aplikacji'],
  },
];

export { getTutorialVideos };
