import React from 'react';

import { ClassroomExercisesMarks } from 'api/rest/teacher/teacher.types';
import type { TableHeader } from 'components/ExercisesTable/ExercisesTable';

const HEADER = {
  EXERCISE_NUMBER: 'index',
  EXPLAIN: 'explain_marks',
  HARD: 'hard_marks',
  EASY: 'easy_marks',
};

type ClassroomExercisesTableHeaderKeys = keyof typeof HEADER;
export type ClassroomExercisesTableHeaderValues = typeof HEADER[ClassroomExercisesTableHeaderKeys];

const classroomExercisesHeaders: TableHeader<ClassroomExercisesTableHeaderValues>[] = [
  { label: 'Numer zadania', id: HEADER.EXERCISE_NUMBER, sortable: false },
  { label: 'Do wyjaśnienia', id: HEADER.EXPLAIN, sortable: true },
  { label: 'Trudne', id: HEADER.HARD, sortable: true },
  { label: 'Łatwe', id: HEADER.EASY, sortable: true },
];

const dataMapping = ({ index, easyMarks, hardMarks, explainMarks }: ClassroomExercisesMarks): React.ReactNode[] => [
  index,
  explainMarks,
  hardMarks,
  easyMarks,
];

export { dataMapping, classroomExercisesHeaders };
