import create from 'zustand';
import { devtools } from 'zustand/middleware';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

export interface ClassroomFiltersStore {
  subject: string;
  since: Date | null;
  until: Date | null;
  update: (key: keyof ClassroomFiltersStore, value: string | Date) => void;
}

const classroomFiltersStore = devtools<ClassroomFiltersStore>(
  set => ({
    subject: '',
    since: null,
    until: null,
    update: (key, value) => set({ [key]: value }),
  }),
  { name: 'CLASSROOM FILTERS STORE', enabled: !isProduction },
);

const useClassroomFiltersStore = create(classroomFiltersStore);

export default useClassroomFiltersStore;
