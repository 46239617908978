import { useMutation } from '@apollo/react-hooks';

import messages from 'constants/dictionaries/messages';
import { useAuthContext } from 'context/AuthContext';
import SOCIAL_AUTH from 'api/graphql/mutations/user/SOCIAL_AUTH';
import useSafeMutation from 'services/common/useSafeMutation/useSafeMutation';
import useCommitError from 'services/common/useCommitError/useCommitError';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

const useFacebookAuth = () => {
  const { logEvent } = useUserLogContext();
  const [loginSocialMutation] = useMutation(SOCIAL_AUTH);
  const { onLogin } = useAuthContext();
  const commitError = useCommitError();
  const safeMutation = useSafeMutation();

  return async ({ accessToken, picture, ...rest }) => {
    const variables = {
      accessToken,
      provider: 'facebook',
    };
    if (accessToken) {
      const { data } = await safeMutation(
        () => loginSocialMutation({ variables }),
        'Nie udało się utworzyć konta przy pomocy facebooka. Spróbuj za pomocą email.',
      );
      if (data.socialAuth.token) {
        logEvent('login-fb');
        onLogin(data.socialAuth.token, { avatar: picture.data.url });
      }
    } else {
      commitError({ message: 'Facebook login issue', props: rest }, messages.ERROR.LOGIN(), 'useFacebookAuth');
    }
  };
};

export default useFacebookAuth;
