import React from 'react';

import PATHS from 'constants/paths';
import PremiumExercisesPageChecker from 'containers/PremiumExercisesPageChecker/PremiumExercisesPageChecker';

function SheetsContent() {
  return <PremiumExercisesPageChecker from={PATHS.SHEETS} />;
}

export default SheetsContent;
