import { makeStyles } from '@material-ui/core/styles';

type StyleParams = {
  showUnreadIndicator: boolean;
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: `${theme.spacing(0, 3)} !important`,
    maxWidth: '100%',
    '&::before': {
      display: 'none',
    },
  },
  accordionContentExpanded: {
    margin: 0,
  },
  header: {
    padding: theme.spacing(0, 1),
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridGap: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
  },
  unreadIndicator: {
    height: '10px',
    width: '10px',
    background: theme.palette.custom.red,
    borderRadius: '100%',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    opacity: ({ showUnreadIndicator }: StyleParams) => (showUnreadIndicator ? 1 : 0),
  },
  content: {
    padding: theme.spacing(3),
  },
}));

export default useStyles;
