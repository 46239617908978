import React, { useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    transition: '.25s ease .05s',
    border: ({ loading }) => (loading ? 'thin solid transparent' : `thin solid ${theme.palette.grey['300']}`),
  },
  image: {
    transition: '.25s ease .05s',
    opacity: ({ loading }) => (loading ? 0 : 1),
  },
}));

function ImageWithLoader({ src, className, alt, commitLoad, commitError, ...rest }) {
  const [loading, setLoading] = useState(true);
  const image = useRef();
  const onLoad = e => {
    setLoading(false);
    commitLoad(e);
  };
  const onCopy = async () => {
    // TODO: feature flags
    if (process.env.REACT_APP_ENVIRONMENT === 'production') return;
    window.open(src, '_blank').focus();
  };
  const classes = useStyles({ loading });
  return (
    <div className={classes.wrapper} onDoubleClick={onCopy}>
      <img
        ref={image}
        alt={alt}
        className={clsx(classes.image, className)}
        onError={commitError}
        onErrorCapture={commitError}
        onLoad={onLoad}
        src={src}
        {...rest}
      />
    </div>
  );
}

ImageWithLoader.defaultProps = {
  alt: '',
  className: '',
};

export default ImageWithLoader;
