import React, { useMemo } from 'react';

import { Dialog, Theme, useMediaQuery, Slider, Typography, FormControlLabel, Switch } from '@material-ui/core';

import DialogGrid from 'containers/DialogGrid/DialogGrid';
import { ExerciseToDisplay } from 'storages/exercisesToDisplay/_exercisesToDisplay_base';

import PrintExercisesContent from './PrintExercisesContent';
import { usePrintDialogHook } from './PrintExercisesDialog.logic';
import useStyles from './PrintExercisesDialog.styles';

export type ExerciseFromResponse = {
  id: string;
  section: { name: string } | null;
  subsection: { name: string } | null;
  index: number;
  files: {
    croppedPngFile: string;
    fileType: string;
  }[];
};

export type ExerciseToPrint = {
  id: string;
  section: { name: string } | null;
  subsection: { name: string } | null;
  index: number;
  files: string[];
};

type Props = {
  open: boolean;
  onClose: () => void;
  exercises?: ExerciseToDisplay[];
};

const minExtraSpace = 0;
const maxExtraSpace = 10;

const PrintExercisesDialog: React.FC<Props> = ({ onClose, open, exercises }) => {
  const downXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const { forms, handlers, exercisesToPrint, componentRef } = usePrintDialogHook({ minExtraSpace, open, exercises });

  const classes = useStyles();
  return (
    <Dialog fullScreen={downXs} onClose={onClose} open={open}>
      <DialogGrid
        cancelClick={onClose}
        okClick={handlers.handlePrint}
        okProps={{ disabled: !exercisesToPrint || !exercisesToPrint.length }}
        hideCloseButton
        okLabel='Drukuj'
        title='Wydrukuj wybrany zestaw zadań'
      >
        <div className={classes.form}>
          <FormControlLabel
            classes={{ root: classes.switchRoot }}
            labelPlacement='top'
            control={<Switch checked={forms.onePerPage} onChange={forms.onOnePerPageChange} color='primary' />}
            label='Jedno zadanie na stronie'
          />
          <div>
            <Typography id='slider'>Dodatkowe miejsce na notatki pod każdym zadaniem</Typography>
            <div className={classes.sliderRow}>
              <Slider min={minExtraSpace} step={0.5} max={maxExtraSpace} value={forms.extraSpace} onChange={forms.onSpaceChange} />
              <Typography id='slider' className={classes.sliderLabel} color='primary'>
                {forms.extraSpace.toFixed(1)} cm
              </Typography>
            </div>
          </div>
          {/* <Button onClick={handlers.handlePdf}>Pobierz PDF</Button> */}
          <PrintExercisesContent
            ref={componentRef}
            exercisesToPrint={exercisesToPrint}
            extraSpace={forms.extraSpace}
            onePerPage={forms.onePerPage}
          />
        </div>
      </DialogGrid>
    </Dialog>
  );
};

export default PrintExercisesDialog;
