import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.grey[100],
  },
  container: {
    margin: theme.spacing(12, 0),
  },
  contentWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 640px',
  },
}));

export default useStyles;
