import React, { EventHandler, FC, useCallback, useMemo, useState } from 'react';

import { Popover, Portal } from '@material-ui/core';

import { ReactComponent as ArrowLeft } from 'assets/icons/general/arrow-left_white.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/general/arrow-right_white.svg';
import { ReactComponent as CheckMark } from 'assets/icons/general/check-mark_white.svg';
import { ReactComponent as Exercise } from 'assets/icons/general/exercises_white.svg';
import { ReactComponent as Filters } from 'assets/icons/general/filters.svg';
import { ReactComponent as Tables } from 'assets/icons/general/tables_white.svg';
import ButtonRound from 'components/ButtonRound/ButtonRound';
import { useTablesContext } from 'context/TablesContext';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
import useSubjectStore from 'storages/subject';

import { ExerciseFileTypes } from '../../api/rest/exercises/exercises.types';
import useLayoutStore from '../../storages/layout';
import NavigationItem from './_components/NavigationItem/NavigationItem';
import useStyles from './NavigationMobile.styles';

type Props = {
  actions: {
    fileTypeChange: (newType: ExerciseFileTypes) => () => void;
    onNext: () => void;
    onPrevious: () => void;
  };
  buttonsEnableState: {
    hint: boolean;
    previous: boolean;
    next: boolean;
    answer: boolean;
    question: boolean;
  };
  fileType: ExerciseFileTypes;
  hasExercises: boolean;
  subjectCode?: string;
  hasAttachments: boolean;
};

const NavigationMobile: FC<Props> = ({ hasAttachments, buttonsEnableState, actions, subjectCode, fileType, hasExercises }) => {
  const { isMobileFilterDrawerVisible, closeMobileFilterDrawer, openMobileFilterDrawer } = useLayoutStore();

  const { subject: subjectFromStore } = useSubjectStore();
  const subject = useMemo(() => subjectCode || subjectFromStore?.subjectCode, [subjectCode, subjectFromStore]);

  // @ts-ignore
  const { openTables } = useTablesContext();

  const [solutionAnchor, setSolutionAnchor] = useState<HTMLElement | null>(null);
  const solutionOpen = Boolean(solutionAnchor);
  const popoverId = solutionOpen ? 'SolutionPopover' : undefined;
  const solutionClick = (event: { currentTarget: HTMLElement }) => setSolutionAnchor(event.currentTarget);
  const solutionClose = () => setSolutionAnchor(null);
  const solutionChoseHandler = useCallback(
    (newType: ExerciseFileTypes) => {
      actions.fileTypeChange(newType)();
      solutionClose();
    },
    [actions.fileTypeChange],
  );

  const openTablesAndClosePopover = () => {
    openTables();
  };

  const shouldRenderTables = useMemo(() => subject !== 'GE', [subject]);

  const renderAttachment = () => (
    // @ts-ignore
    <NavigationItem disabled={!hasAttachments} icon={Tables} onClick={openTablesAndClosePopover}>
      Pomoce
    </NavigationItem>
  );

  const shouldEnableTables = useMemo(() => subject && ['CH', 'B', 'FI', 'M', 'MR'].includes(subject), [subject]);

  const renderTables = () => (
    // @ts-ignore
    <NavigationItem disabled={!shouldEnableTables} icon={Tables} onClick={openTablesAndClosePopover}>
      Tablice
    </NavigationItem>
  );

  const classes = useStyles();
  return (
    <Portal>
      <nav className={classes.wrapper}>
        {/* @ts-ignore */}
        <NavigationItem
          disabled={!buttonsEnableState.previous || isMobileFilterDrawerVisible}
          icon={ArrowLeft}
          onClick={actions.onPrevious}
        >
          Cofnij
        </NavigationItem>
        {/* @ts-ignore */}
        <NavigationItem disabled={!buttonsEnableState.next || isMobileFilterDrawerVisible} icon={ArrowRight} onClick={actions.onNext}>
          Dalej
        </NavigationItem>
        {/* @ts-ignore */}
        <NavigationItem
          aria-describedby={popoverId}
          disabled={!buttonsEnableState.answer || isMobileFilterDrawerVisible}
          icon={fileType === ExerciseFileTypes.question ? CheckMark : Exercise}
          onClick={fileType === ExerciseFileTypes.question ? solutionClick : actions.fileTypeChange(ExerciseFileTypes.question)}
        >
          {fileType === ExerciseFileTypes.question ? 'Odpowiedź' : 'Treść'}
        </NavigationItem>
        {/* @ts-ignore */}
        <NavigationItem
          disabled={isMobileFilterDrawerVisible && !hasExercises}
          icon={isMobileFilterDrawerVisible ? Exercise : Filters}
          onClick={isMobileFilterDrawerVisible ? closeMobileFilterDrawer : openMobileFilterDrawer}
        >
          {isMobileFilterDrawerVisible ? 'Zadania' : 'Filtry'}
        </NavigationItem>
        {shouldRenderTables ? renderTables() : renderAttachment()}
        <Popover
          anchorEl={solutionAnchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={classes.popover}
          disableScrollLock
          elevation={0}
          id={popoverId}
          marginThreshold={0}
          onClose={solutionClose}
          open={solutionOpen}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <ButtonRound className={classes.popoverButton} onClick={() => solutionChoseHandler(ExerciseFileTypes.answer)} size='small'>
            Odpowiedź
          </ButtonRound>
          <ButtonRound
            className={classes.popoverButton}
            disabled={!buttonsEnableState.hint}
            onClick={() => solutionChoseHandler(ExerciseFileTypes.hint)}
            size='small'
          >
            Podpowiedź
          </ButtonRound>
          <ButtonRound className={classes.popoverButton} color='primary' onClick={solutionClose} size='small' variant='outlined'>
            Zamknij
          </ButtonRound>
        </Popover>
      </nav>
    </Portal>
  );
};

export default NavigationMobile;
