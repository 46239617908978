import React from 'react';

import { Helmet } from 'react-helmet';

type Props = {
  children: string;
};

const PageTitle: React.FC<Props> = ({ children }) => (
  <Helmet>
    <title>{`MM | ${children}`}</title>
  </Helmet>
);

export default PageTitle;
