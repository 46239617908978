import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SubjectIcon from 'components/SubjectIcon/SubjectIcon';
import useSubjectStore from 'storages/subject';
import ExercisesName from 'uniqueComponents/Exercises/ExercisesName/ExercisesName';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.legacySpacing(1, 0),
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    minHeight: '40px',
  },
  counter: {
    display: 'flex',
    alignItems: 'center',
  },
  exerciseIndex: {
    fontSize: '.875em',
  },
  subjectCount: {
    marginLeft: theme.legacySpacing(2),
    fontSize: '.9em',
    color: theme.palette.text.disabled,
  },
  exerciseName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

function ExerciseHeaderMobile({
  exerciseNumber,
  exercisesCount,
  isMarked,
  currentExerciseId,
  exerciseName,
  cacheStrategy,
  subjectCode = null,
}) {
  const { subject } = useSubjectStore();

  const classes = useStyles();
  const hasExercisesCount = useMemo(() => !exercisesCount || `${exercisesCount}` !== '0', [exercisesCount]);
  return (
    <div className={classes.wrapper}>
      <div className={classes.counter}>
        {hasExercisesCount && <SubjectIcon iconName={subjectCode || subject?.subjectCode} size='xs' />}
        {hasExercisesCount ? (
          <Typography className={classes.subjectCount} component='span' noWrap>
            ({exerciseNumber}
            &nbsp;z&nbsp;
            {exercisesCount})
          </Typography>
        ) : (
          <Typography className={classes.subjectCount} component='span' noWrap>
            Brak zadań
          </Typography>
        )}
      </div>
      <div className={classes.exerciseName}>
        <ExercisesName
          cacheStrategy={cacheStrategy}
          currentExerciseId={currentExerciseId}
          isMarked={isMarked}
          loading={exercisesCount > 0}
          name={exerciseName}
        />
      </div>
    </div>
  );
}

export default ExerciseHeaderMobile;
