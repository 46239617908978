import { useCallback, useEffect, useMemo } from 'react';

import { useQuery } from 'react-query';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import exercisesApi from 'api/rest/exercises/exercises';
import { Exercise } from 'api/rest/exercises/exercises.types';
import queryClient from 'api/rest/queryClient';
import sectionsApi from 'api/rest/sections/sections';
import subjectsApi from 'api/rest/subjects/subjects';
import subsectionsApi from 'api/rest/subsections/subsections';
import QUERY from 'constants/queryKeys';
import isProduction from 'services/common/isProduction';

import useExercisesSetsStore from '../exercisesSets/exercisesSets';
import exercisesToDisplayBase from './_exercisesToDisplay_base';

const storageName = 'exercises_to_display_sets';

const storage = exercisesToDisplayBase(storageName);

// TODO prawodpodobnie najlepszym rozwiązaniem bedzie używanie jednego wspólnego store tutaj, a każda zmiana funkcji będzie to aktualizować
// TODO więc będize wymagana zmiana => usunięcie tej implementacji i dołączenie do exercisesToDisplayBase ew. dorzucenie wartości jaka funkcjonalnosć jest wybrana

// @ts-ignore
export const useSetsStorage = isProduction ? create(storage) : create(devtools(storage, { name: storageName }));

const useExercisesToDisplay_sets = () => {
  const exercisesToDisplayStorage = useSetsStorage();
  const { cursor, exercises, setCurrentExercise, onError } = exercisesToDisplayStorage;
  const { currentSetId } = useExercisesSetsStore();

  const exerciseUuid = useMemo(() => exercises[cursor]?.uuid, [cursor, exercises]);

  const { data: exerciseData } = useQuery([QUERY.GET_SINGLE_EXERCISE, exerciseUuid, currentSetId], exercisesApi.getSingle(exerciseUuid), {
    enabled: !!exerciseUuid,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const updateCurrentExercise = useCallback(async (exercise?: Exercise) => {
    if (!exercise) return;
    try {
      const staleTime = 1000 * 60 * 60;
      const [subjectDetails, sectionDetails, subsectionDetails] = await Promise.all([
        queryClient.fetchQuery([QUERY.GET_SINGLE_SUBJECT, exercise.subject], subjectsApi.getSubject(exercise.subject), { staleTime }),
        queryClient.fetchQuery([QUERY.GET_SINGLE_SECTION, exercise.section], sectionsApi.getSection(exercise.section), { staleTime }),
        exercise.subsection
          ? queryClient.fetchQuery([QUERY.GET_SINGLE_SUBSECTION, exercise.subsection], subsectionsApi.getSubsection(exercise.subsection), {
              staleTime,
            })
          : undefined,
      ]);
      setCurrentExercise({
        audioSource: exercise.audio || undefined,
        index: exercise.index,
        uuid: exercise.uuid,
        globalId: exercise.globalId,
        subject: subjectDetails,
        section: sectionDetails,
        subsection: subsectionDetails,
        files: exercise.files,
        externalSources: exercise.externalSources,
        importFileIdentifier: exercise.importFileIdentifier,
        userMark: exercise.userMark,
      });
    } catch (e) {
      onError();
    }
  }, []);

  useEffect(() => {
    updateCurrentExercise(exerciseData);
  }, [exerciseData]);

  return exercisesToDisplayStorage;
};

export default useExercisesToDisplay_sets;
