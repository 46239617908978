import { get, isString } from 'lodash';

import authManager from '../authManager/authManager';

type ErrObj = {
  title: string;
  date: Date;
  raw: Error;
  message?: string;
  customMessage?: string;
  userAgent?: string;
  url?: string;
  store?: {
    exercises?: any[];
    exercisesLength?: number;
  };
  subject?: object;
  userData?: string;
};

type LogError = (data: Error, place: string) => void;

const logError: LogError = (data, place = 'unknown place', apiCallEnabled = false) => {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
  if (isProduction) {
    const errObj: ErrObj = {
      title: `The error occurs in ${place}`,
      date: new Date(),
      raw: data,
    };
    try {
      errObj.message = get(data, 'message', 'empty message');
      errObj.customMessage = get(data, 'customMessage', 'empty customMessage');
      errObj.userAgent = window && window.navigator.userAgent;
      errObj.url = window && window.location.href;
      errObj.userData = authManager.getCookieAuthData();
      errObj.store = JSON.parse(window.sessionStorage.getItem(`${process.env.REACT_APP_VERSION}_exercises`) || '');
      errObj.subject = JSON.parse(window.sessionStorage.getItem(`${process.env.REACT_APP_VERSION}_subject`) || '');
      if (errObj.store && errObj.store.exercises) {
        errObj.store.exercisesLength = errObj.store.exercises.length;
      }
    } catch (e) {
      errObj.message = 'Undefined error...';
    }
    // TODO remove that function
    if (apiCallEnabled) {
      fetch('https://app.mojamatura.edu.pl/err/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...errObj, errorData: data }),
      });
    }
  } else {
    throw new Error(isString(data) ? data : JSON.stringify(data));
  }
};

export default logError;
