import React, { useCallback } from 'react';

import { Button, CircularProgress, Container } from '@material-ui/core';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import teacherApi from 'api/rest/teacher/teacher';
import { Classroom } from 'api/rest/teacher/teacher.types';
import { ReactComponent as HomeIcon } from 'assets/iconhub/home.svg';
import PATHS from 'constants/paths';
import QUERY from 'constants/queryKeys';
import { useAuthContext } from 'context/AuthContext';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

import TeacherNavigationBarDynamicFilters from './_components/TeacherNavigationBarDynamicFilters/TeacherNavigationBarDynamicFilters';
import TeacherNavigationBarDynamicPath from './_components/TeacherNavigationBarDynamicPath/TeacherNavigationBarDynamicPath';
import useStyles from './TeacherNavigationBar.styles';

type Props = {
  classroomData?: Classroom;
};

const TeacherNavigationBar: React.FC<Props> = ({ classroomData }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { teacher } = useAuthContext();
  const { data: teacherData, isLoading } = useQuery([QUERY.GET_TEACHERS, teacher], teacherApi.getTeacher(teacher));
  const { logEvent } = useUserLogContext();

  const onBackToDashboard = useCallback(() => {
    logEvent('teacher-navigation-back-to-dashboard');
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.innerContainer}>
          {isLoading && !teacherData ? (
            <CircularProgress size={24} />
          ) : (
            <Button
              size='small'
              component={Link}
              onClick={onBackToDashboard}
              startIcon={<HomeIcon className={classes.icon} />}
              to={PATHS.TEACHER_DASHBOARD}
            >
              {teacherData?.school.shortName || teacherData?.school.name}
            </Button>
          )}
          {classroomData ? <TeacherNavigationBarDynamicPath classrooms={teacherData?.classrooms} classroomData={classroomData} /> : null}
        </div>
        <TeacherNavigationBarDynamicFilters />
      </Container>
    </div>
  );
};

export default TeacherNavigationBar;
