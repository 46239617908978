import exercisesInitialStorage from '../exercises/exercisesInitialStorage';

const setCurrentExercise = set => rawCurrentExercise => {
  const currentExercise = { ...rawCurrentExercise };
  const currentExerciseShape = currentExercise.files.reduce(
    (prev, { fileType }) => ({ ...prev, [fileType]: prev[fileType] + 1 }),
    exercisesInitialStorage.currentExerciseShape,
  );
  currentExercise.externalSources = [...currentExercise.externalSources];
  set(() => ({
    mark: currentExercise.mark,
    currentExercise,
    ...(currentExercise.files && { currentExerciseShape }),
  }));
};

export default setCurrentExercise;
