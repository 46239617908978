import React, { useState } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { Dialog, Grid, TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import DELETE_ACCOUNT from '../../../api/graphql/mutations/user/DELETE_ACCOUNT';
import messages from '../../../constants/dictionaries/messages';
import DialogGrid from '../../../containers/DialogGrid/DialogGrid';
import { useAuthContext } from '../../../context/AuthContext';
import { useUserLogContext } from '../../../context/UserLogContext/UserLogContext';
import logError from '../../../utils/logError/logError';
import { DeleteAccountDialogHook, DeleteAccountDialogExampleFunction } from './DeleteAccountDialog.logic';
import useStyles from './DeleteAccountDialog.styles';

type Props = {
  onClose: () => void;
};

const DeleteAccountDialog: React.FC<Props> = ({ onClose }) => {
  const { logEvent } = useUserLogContext();
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  // @ts-ignore
  const { onLogout } = useAuthContext();

  const deleteAccount = async ({ target }: { target: { deleteReason: HTMLInputElement } }) => {
    try {
      await apolloClient.mutate({ mutation: DELETE_ACCOUNT, variables: { message: target.deleteReason.value } });
      logEvent('delete-account');
      onLogout();
    } catch (error) {
      enqueueSnackbar(messages.ERROR.PROCESSING('żądania'), { variant: 'error' });
    }
  };

  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open>
      <DialogGrid cancelClick={onClose} hideCloseButton isForm onSubmit={deleteAccount} title='Usuń konto'>
        <div className={classes.wrapper}>
          <Typography>
            Kontynuując wszystkie twoje dane zostaną trwale usunięte. Utracisz również dostęp do oznaczonych zadań oraz komentarzy.
          </Typography>
          <TextField
            className={classes.textarea}
            minRows={3}
            multiline
            variant='outlined'
            autoFocus
            label='Powód usunięcia'
            name='deleteReason'
          />
        </div>
      </DialogGrid>
    </Dialog>
  );
};

export default DeleteAccountDialog;
