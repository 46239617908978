import { isWithinInterval } from 'date-fns';

import { UserSubscription } from 'api/rest/users/users.types';

const getCurrentUserSubscription = (subscriptions?: UserSubscription[]) => {
  if (!subscriptions) return undefined;
  return subscriptions.reverse().find(({ validFrom, validUntil }) => isWithinInterval(new Date(), { start: validFrom, end: validUntil }));
};

export default getCurrentUserSubscription;
