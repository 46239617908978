import React from 'react';

import { Portal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SubjectIcon from 'components/SubjectIcon/SubjectIcon';
import Button from 'components/ButtonRound/ButtonRound';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
import useSubjectStore from 'storages/subject';
import useLayoutStore from '../../../storages/layout';

const useStyles = makeStyles(theme => ({
  wrapper: {
    top: 0,
    position: 'fixed',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 101,
    backgroundColor: theme.palette.grey[100],
    boxShadow: theme.shadows[1],
    transition: '.25s ease',
    padding: theme.legacySpacing(1, 4),
    transform: ({ visible }) => `translateX(${visible ? '0' : '-100%'})`,
  },
  counter: {
    display: 'grid',
    gridTemplateColumns: ({ showSubjectIcon }) => (showSubjectIcon ? 'repeat(3, auto)' : 'repeat(2, auto)'),
    gridGap: theme.legacySpacing(2),
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '.85rem',
  },
  caption: {
    fontSize: '.85rem',
    color: theme.palette.text.disabled,
  },
  button: {
    fontSize: '.9rem',
  },
}));

function ExercisesFiltersHeaderMobile({ drawerCaption, drawerTitle, showSubjectIcon }) {
  const { isMobileFilterDrawerVisible, closeMobileFilterDrawer } = useLayoutStore();

  const { subject } = useSubjectStore();
  const classes = useStyles({ visible: isMobileFilterDrawerVisible, showSubjectIcon });

  return (
    <Portal>
      <div className={classes.wrapper}>
        <div className={classes.counter}>
          {showSubjectIcon && <SubjectIcon iconName={subject?.subjectCode} size='xs' />}
          <Typography className={classes.title} color='primary' noWrap>
            {drawerTitle}
          </Typography>
          <Typography className={classes.caption} tag='span'>
            {drawerCaption ? `(${drawerCaption})` : null}
          </Typography>
        </div>
        <Button className={classes.button} color='primary' onClick={closeMobileFilterDrawer}>
          Gotowe
        </Button>
      </div>
    </Portal>
  );
}

export default ExercisesFiltersHeaderMobile;
