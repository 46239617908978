const QUERY = {
  GET_ALL_NOTIFICATIONS: 'Get all notifications',
  PERIODIC_GET_NEW_NOTIFICATIONS: 'Periodic new notifications check',
  GET_SINGLE_EXERCISE_RESOURCES: 'Get single exercise resources',
  GET_SINGLE_EXERCISE: 'Get single exercise',
  GET_ME: 'Get me',
  GET_CLASSROOM: 'Get classrooms',
  GET_CLASSROOM_EXERCISES: 'Get classrooms marks',
  GET_CLASSROOM_EXERCISES_SUMMARY: 'Get classrooms marks summary',
  GET_SCHOOLS: 'Get schools',
  GET_SINGLE_STUDENT: 'Get student',
  GET_STUDENTS_FOR_CLASS: 'Get students for class',
  GET_TEACHERS: 'Get teachers',
  GET_SINGLE_SECTION: 'get single section',
  GET_SINGLE_SUBSECTION: 'get single subsection',
  GET_SECTIONS_LIST: 'get sections list',
  GET_SUBSECTIONS_LIST: 'get subsections list',
  GET_SUBJECTS_LIST: 'get subjects list',
  GET_SINGLE_SUBJECT: 'get subject',
};

export default QUERY;
