import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { useReactToPrint } from 'react-to-print';

import GET_EXERCISES_FOR_PRINT from 'api/graphql/queries/exercises/GET_EXERCISES_FOR_PRINT';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import html2pdf from 'html2pdf.js';

import { ExerciseToDisplay } from '../../../storages/exercisesToDisplay/_exercisesToDisplay_base';
import { ExerciseFromResponse, ExerciseToPrint } from './PrintExercisesDialog';

type HookParams = { minExtraSpace: number; open: boolean; exercises?: ExerciseToDisplay[] };

const parseExercises = (rawExercises: { exercises: ExerciseFromResponse[] }, uuids: string[]): ExerciseToPrint[] => {
  const unsorted = rawExercises.exercises.map((exercise: ExerciseFromResponse) => ({
    ...exercise,
    files: exercise.files.filter(({ fileType }) => fileType === 't').map(({ croppedPngFile }) => croppedPngFile),
  }));
  return sortBy(unsorted, ({ id }) => uuids.indexOf(id));
};

const usePrintDialogHook = ({ minExtraSpace, open, exercises: exercisesFromProps }: HookParams) => {
  // events loging
  const { logEvent } = useUserLogContext();

  useEffect(() => {
    if (open) logEvent('print-dialog-open');
  }, [open]);

  // exercises fetching
  const { useDataStore } = useFeatureChecker();
  const { exercises } = useDataStore();

  const uuidForQuery = useMemo(() => {
    if (exercisesFromProps) return exercisesFromProps.map(({ globalId }: { globalId: string }) => globalId);
    return exercises.map(({ id }: { id: string }) => id);
  }, [exercises, exercisesFromProps]);

  const [getExercises] = useLazyQuery(GET_EXERCISES_FOR_PRINT);

  const componentRef = useRef(null);

  const [exercisesToPrint, setExercisesToPrint] = useState<null | ExerciseToPrint[]>(null);
  const updateExercisesToPrint = async () => {
    const { data } = await getExercises({ variables: { uuid: uuidForQuery } });
    setExercisesToPrint(parseExercises(data, uuidForQuery));
  };

  const clearExercisesToPrint = () => setExercisesToPrint(null);

  useEffect(() => {
    if (open) updateExercisesToPrint();
    else clearExercisesToPrint();
  }, [open]);

  // forms
  const [extraSpace, setExtraSpace] = useState<number>(minExtraSpace);
  const [onePerPage, setOnePerPage] = useState<boolean>(false);
  const onOnePerPageChange = ({ target }: ChangeEvent<any>) => setOnePerPage(target.checked);
  const onSpaceChange = (e: ChangeEvent<any>, value: number | number[]) => setExtraSpace(value as number);

  // print handling
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => logEvent('print-exercises', { extraSpace, onePerPage, exercisesCount: exercisesToPrint?.length }),
  });
  // const replaceImageUrlToBase64 = async (imgElement: HTMLImageElement) => {
  //   const { base64Data } = await getBase64FromUrl(imgElement.src);
  //   // eslint-disable-next-line no-param-reassign
  //   imgElement.src = base64Data as string;
  // };
  // const handlePdf = useReactToPrint({
  //   onPrintError: (error: string | Error) => logError(error as Error, 'generating PDF'),
  //   content: () => componentRef.current,
  //   removeAfterPrint: true,
  //   print: async printIframe => {
  //     const document = printIframe.contentDocument;
  //     if (document) {
  //       const html = document.getElementsByTagName('html')[0];
  //       await Promise.all(Array.from(html.querySelectorAll('img')).map(replaceImageUrlToBase64));
  //       html2pdf(html, { margin: 15, pagebreak: { mode: ['css', 'legacy'], avoid: '.exerciseBlock' } });
  //     }
  //   },
  // });

  return {
    forms: { extraSpace, onePerPage, onOnePerPageChange, onSpaceChange },
    handlers: { handlePrint },
    exercisesToPrint,
    componentRef,
  };
};

export { usePrintDialogHook };
