import axios from 'axios';

import { Id } from 'types/Id';

import { DefaultApiResponse } from '../_types';
import { parseExercisesForUI } from './exercises.parsers';
import { Exercise, ExerciseDto } from './exercises.types';

const endpoints = {
  exercises: 'exercises/',
};

type GetSingleExerciseQuery = () => Promise<Exercise>;

const exercisesApi = {
  // TODO - exercisesSet type ID
  getSingle:
    (exerciseId: Id): GetSingleExerciseQuery =>
    async () => {
      const response = await axios.get<GetSingleExerciseQuery, DefaultApiResponse<ExerciseDto>>(
        `${endpoints.exercises}exercises/${exerciseId}/`,
      );
      return parseExercisesForUI(response.data);
    },
};

export default exercisesApi;
