import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.grey[100],
  },
  container: {
    margin: theme.spacing(12, 0),
  },
  header: {
    padding: theme.spacing(0, 2),
  },
}));

export default useStyles;
