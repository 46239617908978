import React from 'react';

import { Typography } from '@material-ui/core';

import FullScreen from 'containers/FullScreen/FullScreen';

function Page404() {
  return (
    <FullScreen>
      <Typography color='primary' component='h1' style={{ fontWeight: 900 }} variant='h1'>
        404
      </Typography>
    </FullScreen>
  );
}

export default Page404;
