import React, { useMemo } from 'react';

import clsx from 'clsx';
import { generatePath } from 'react-router-dom';

import { Classroom } from 'api/rest/teacher/teacher.types';
import { ReactComponent as ClassIcon } from 'assets/iconhub/class.svg';
import { ReactComponent as ListIcon } from 'assets/iconhub/list.svg';
import { ReactComponent as UserIcon } from 'assets/iconhub/user.svg';
import ButtonWithPopover from 'components/ButtonWithPopover/ButtonWithPopover';
import type { ButtonWithPopoverElement } from 'components/ButtonWithPopover/ButtonWithPopover';
import { useCheckFunctionality } from 'components/TeacherNavigationBar/TeacherNavigationBar.logic';
import PATHS from 'constants/paths';

import useStyles from './TeacherNavigationBarDynamicPath.styles';

type DetailsViewTeacherNavigationBarProps = {
  classroomData?: Classroom;
  classrooms?: { id: number; name: string; shortName: string }[];
};

const TeacherNavigationBarDynamicPath: React.FC<DetailsViewTeacherNavigationBarProps> = ({ classroomData, classrooms }) => {
  const { isClassroomExercises, isClassroom } = useCheckFunctionality();

  const classes = useStyles();

  const typeElements: ButtonWithPopoverElement[] = useMemo(
    () => [
      {
        label: 'Lista uczniów',
        buttonProps: { disabled: isClassroom, startIcon: <UserIcon className={clsx(classes.icon, isClassroom && classes.iconDisabled)} /> },
        to: generatePath(PATHS.CLASSROOM, { classroomId: classroomData?.id }),
        event: 'teacher-navigation-change-mode',
        eventData: { mode: 'classroom' },
      },
      {
        label: 'Zadania klasy',
        buttonProps: {
          disabled: isClassroomExercises,
          startIcon: <ListIcon className={clsx(classes.icon, isClassroomExercises && classes.iconDisabled)} />,
        },
        to: generatePath(PATHS.CLASSROOM_EXERCISES, { classroomId: classroomData?.id }),
        event: 'teacher-navigation-change-mode',
        eventData: { mode: 'exercises' },
      },
    ],
    [classroomData, isClassroom, isClassroomExercises],
  );

  const classroomElements: ButtonWithPopoverElement[] = useMemo(() => {
    if (!classrooms) return [];
    return classrooms?.map(({ shortName, name, id }) => ({
      label: shortName || name,
      buttonProps: { disabled: id === classroomData?.id },
      to: generatePath(isClassroom ? PATHS.CLASSROOM : PATHS.CLASSROOM_EXERCISES, { classroomId: id }),
      event: 'teacher-navigation-change-class',
      eventData: { classroomId: 'id' },
    }));
  }, [classroomData, classrooms, isClassroom, isClassroomExercises]);

  return (
    <>
      <ButtonWithPopover
        elements={classroomElements}
        id='klasa'
        buttonProps={{ size: 'small', startIcon: <ClassIcon className={classes.icon} /> }}
      >
        <>Klasa: {classroomData?.shortName || classroomData?.name}</>
      </ButtonWithPopover>
      {isClassroom && (
        <ButtonWithPopover
          id='lista_uczniów'
          buttonProps={{ size: 'small', startIcon: <UserIcon className={classes.icon} /> }}
          elements={typeElements}
        >
          Lista uczniów
        </ButtonWithPopover>
      )}
      {isClassroomExercises && (
        <ButtonWithPopover
          elements={typeElements}
          id='zadania_klasy'
          buttonProps={{ size: 'small', startIcon: <ListIcon className={classes.icon} /> }}
        >
          Zadania klasy
        </ButtonWithPopover>
      )}
    </>
  );
};

export default TeacherNavigationBarDynamicPath;
