import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { TextField, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';

import externalResourcesApi from 'api/rest/externalResources/externalResources';
import { ReactComponent as HappyIcon } from 'assets/icons/rate/happy.svg';
import { ReactComponent as NeutralIcon } from 'assets/icons/rate/neutral.svg';
import { ReactComponent as SadIcon } from 'assets/icons/rate/sad.svg';
import ButtonRound from 'components/ButtonRound/ButtonRound';
import MUTATION from 'constants/mutationsKeys';
import queryKeys from 'constants/queryKeys';
import { ContentRate } from 'types/ContentRate';

import useStyles from './RateContent.styles';

type Props = {
  id?: number;
};

const RATES: { [key in ContentRate]: string } = {
  1: 'Źle',
  2: 'Może być',
  3: 'Świetnie',
};

const RateContent: React.FC<Props> = ({ id }) => {
  const { data } = useQuery([queryKeys.GET_SINGLE_EXERCISE_RESOURCES, id], externalResourcesApi.getOne(id), {
    enabled: !!id,
  });
  const mutation = useMutation(MUTATION.UPDATE_RATE, externalResourcesApi.setRating(id));

  const { enqueueSnackbar } = useSnackbar();
  const [ratingState, setRatingState] = useState<{ rating?: ContentRate; fromDB: boolean }>({ rating: undefined, fromDB: false });
  const [text, setText] = useState<string>('');
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (data?.userRating) {
      const { userRating } = data;
      setRatingState({ rating: userRating.rating, fromDB: true });
      setText(userRating.text);
    }
  }, [data]);

  useEffect(() => {
    const { rating, fromDB } = ratingState;
    if (rating && !fromDB) {
      mutation.mutateAsync({ rating, text, exerciseExternalSource: id });
      enqueueSnackbar('Ocena zapisana', { variant: 'success' });
    }
  }, [ratingState]);

  const onTextChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    setText(target.value);
  };

  const onHide = () => setExpanded(false);

  const onSave = () => {
    mutation.mutateAsync({ rating: ratingState.rating, text, exerciseExternalSource: id });
    enqueueSnackbar('Opinia zapisana', { variant: 'success' });
    setExpanded(false);
  };

  const onRateClick = (rating: ContentRate) => () => {
    setRatingState({ rating, fromDB: false });
    if (!expanded) setExpanded(true);
  };

  const placeholder = useMemo(() => {
    switch (ratingState.rating) {
      case 1:
        return 'Napisz proszę co poprawić. Staramy poprawiać jakość naszych nagrań';
      case 2:
        return 'Co możemy poprawić aby dostać najwyższą ocenę? Podziel się z nami opinią';
      case 3:
        return 'Napisz nam co myślisz, przekażemy autorowi aby poprawić mu humor';
      default:
        return 'Wybierz coś...';
    }
  }, [ratingState.rating]);

  const classes = useStyles({ selected: !!ratingState.rating });
  return (
    <div className={classes.root}>
      <Typography align='center' variant='h2'>
        Jak oceniasz rozwiązanie?
      </Typography>
      <div className={classes.ratesWrapper}>
        <button onClick={onRateClick(1)} className={clsx(classes.buttonIcon, ratingState.rating === 1 && 'selected')} type='button'>
          <SadIcon />
        </button>
        <button onClick={onRateClick(2)} className={clsx(classes.buttonIcon, ratingState.rating === 2 && 'selected')} type='button'>
          <NeutralIcon />
        </button>
        <button onClick={onRateClick(3)} className={clsx(classes.buttonIcon, ratingState.rating === 3 && 'selected')} type='button'>
          <HappyIcon />
        </button>
      </div>
      <Collapse in={expanded}>
        <Typography align='center' variant='subtitle1' color='primary' className={classes.rateString}>
          {ratingState.rating ? RATES[ratingState.rating] : ''}
        </Typography>
        <TextField placeholder={placeholder} variant='outlined' minRows={3} multiline onChange={onTextChange} value={text} />
        <div className={classes.buttons}>
          <ButtonRound variant='outlined' size='small' onClick={onHide}>
            Ukryj
          </ButtonRound>
          <ButtonRound disabled={!text} variant='contained' color='primary' size='small' onClick={onSave}>
            Wyślij
          </ButtonRound>
        </div>
      </Collapse>
    </div>
  );
};

export default RateContent;
