import React from 'react';

import { Dialog, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import image from 'assets/decorative_pics/error_payment.png';
import mmEmail from 'constants/dictionaries/contactData';
import { USER_SETTINGS_DIALOGS } from 'constants/paths';
import DialogGrid from 'containers/DialogGrid/DialogGrid';
import useTrimUrl from 'services/common/useTrimUrl/useTrimUrl';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '20px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  image: {
    maxWidth: 'calc(100% - 2.5rem)',
  },
});

function PaymentFailedDialog({ onClose }) {
  const downXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const trimUrl = useTrimUrl();

  const closeWithTrim = () => {
    trimUrl(USER_SETTINGS_DIALOGS.FAILURE);
    onClose();
  };

  const classes = useStyles();
  return (
    <Dialog onClose={closeWithTrim} open>
      <DialogGrid cancelClick={onClose} hideButtons hideCancelButton okClick={closeWithTrim} title='Ups! Coś poszło nie tak...'>
        <Grid className={classes.wrapper} container spacing={10}>
          <Grid item>
            Wystąpił błąd i środki nie zostały pobrane. Prosimy spróbuj ponownie. Jeśli problem będzie się ponawiał skontaktuj się z nami:
            {downXs ? <br /> : ' '}
            <a href={`mailto:${mmEmail}`}>{mmEmail}</a>
          </Grid>
          <Grid item>
            <img alt='' className={classes.image} src={image} />
          </Grid>
        </Grid>
      </DialogGrid>
    </Dialog>
  );
}

export default PaymentFailedDialog;
