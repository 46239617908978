import React from 'react';

import PageLoader from 'components/PageLoader/PageLoader';

import { usePrivateRouteEvents } from './PrivateRouteEvents.logic';

type Props = {
  children: React.ReactElement;
};

const PrivateRouteEvents: React.FC<Props> = ({ children }) => {
  const { isLoading } = usePrivateRouteEvents();

  if (isLoading) return <PageLoader />;

  return children;
};

export default PrivateRouteEvents;
