import React from 'react';

import { PaginatedData } from 'api/rest/_types';
import { ClassroomExercisesMarks, StudentMark } from 'api/rest/teacher/teacher.types';
import useOpenExerciseDrawer from 'components/ExerciseDetailsDrawer/_hooks/useOpenExerciseDrawer';
import ExercisesTable, { ExercisesTableProps } from 'components/ExercisesTable/ExercisesTable';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

import { classroomExercisesHeaders, ClassroomExercisesTableHeaderValues, dataMapping } from './ClassroomExercisesTable.logic';

type Props = {
  data?: PaginatedData<ClassroomExercisesMarks>;
  isLoading: boolean;
  tableProps: Omit<
    ExercisesTableProps<StudentMark, ClassroomExercisesTableHeaderValues>,
    'data' | 'tableId' | 'onRowClick' | 'count' | 'dataMapping' | 'headers'
  >;
};

const ClassroomExercisesTable: React.FC<Props> = ({ data, tableProps, isLoading }) => {
  const { openExerciseDrawer } = useOpenExerciseDrawer();
  const { logEvent } = useUserLogContext();

  const onRowClick = ({ exerciseUuid }: ClassroomExercisesMarks) => {
    logEvent('class-exercises-drawer-expand', { exerciseId: exerciseUuid });
    openExerciseDrawer(exerciseUuid);
  };

  return (
    <ExercisesTable
      fallbackMessage='Uczniowie tej klasy nie oznaczyli zadań z podanymi filtrami.'
      isLoading={isLoading}
      headers={classroomExercisesHeaders}
      count={data?.count}
      dataMapping={dataMapping}
      rowsPerPage={15}
      onRowClick={onRowClick}
      data={data?.results}
      tableId='ClassroomExercisesTable'
      {...tableProps}
    />
  );
};

export default ClassroomExercisesTable;
