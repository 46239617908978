import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    alignContent: 'flex-start',
    gridGap: theme.spacing(4),
    position: 'relative',
    minHeight: '140px',
    paddingBottom: theme.spacing(6),
  },
}));

export default useStyles;
