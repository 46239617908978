import React, { useMemo } from 'react';

import { Dialog } from '@material-ui/core';
import { useQuery } from 'react-query';

import users from 'api/rest/users/users';
import { USER_SETTINGS_DIALOGS } from 'constants/paths';
import QUERY from 'constants/queryKeys';
import DialogGrid from 'containers/DialogGrid/DialogGrid';
import getCurrentUserSubscription from 'services/common/getCurrentUserSubscription/getCurrentUserSubscription';
import useTrimUrl from 'services/common/useTrimUrl/useTrimUrl';

import SubscriptionInfo from './_components/SubscriptionInfo/SubscriptionInfo';
import SubscriptionPurchase from './_components/SubscriptionPurchase/SubscriptionPurchase';

type Props = {
  onClose: () => void;
};

const SubscriptionDialog: React.FC<Props> = ({ onClose }) => {
  const { data: meData, isLoading } = useQuery(QUERY.GET_ME, users.getMe());

  const trimUrl = useTrimUrl();

  const currentSubscription = useMemo(() => getCurrentUserSubscription(meData?.subscriptions), [meData?.subscriptions]);

  const closeWithTrim = () => {
    trimUrl([USER_SETTINGS_DIALOGS.SUCCESS, USER_SETTINGS_DIALOGS.SUBSCRIBE]);
    onClose();
  };

  return (
    <Dialog onClose={closeWithTrim} open>
      <DialogGrid cancelClick={closeWithTrim} hideButtons loading={isLoading} title='Zarządzanie kontem'>
        {currentSubscription ? (
          <SubscriptionInfo subscriptionType={currentSubscription.accessTypeName} validDate={currentSubscription.validUntil} />
        ) : (
          <SubscriptionPurchase />
        )}
      </DialogGrid>
    </Dialog>
  );
};

export default SubscriptionDialog;
