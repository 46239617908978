import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { Id } from '../types/Id';
import useSubjectStore from './subject';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

interface LayoutStore {
  isHeaderVisible: boolean;
  setIsHeaderVisible: (newState: boolean) => void;
  isMenuVisible: boolean;
  toggleIsMenuVisible: () => void;
  isNotificationsVisible: boolean;
  toggleIsNotificationsVisible: () => void;
  expandedNotifications: Id[];
  getExpandedNotifications: () => Id[];
  addExpandedNotifications: (notificationId: Id) => void;
  removeExpandedNotifications: (notificationId: Id) => void;
  setMenuIndicator: (newState: string) => void;
  resetMenuIndicator: () => void;
  menuIndicator?: string;
  isMobileFilterDrawerVisible: boolean;
  openMobileFilterDrawer: () => void;
  closeMobileFilterDrawer: () => void;
}

const layoutStore = persist<LayoutStore>(
  (set, get) => {
    const { subject } = useSubjectStore.getState();
    return {
      isHeaderVisible: !!subject,
      setIsHeaderVisible: (newState: boolean) =>
        set(() => {
          if (newState) return { isHeaderVisible: newState };
          return { isHeaderVisible: newState, isMenuVisible: false };
        }),
      isMenuVisible: false,
      toggleIsMenuVisible: () =>
        set(store => {
          const isMenuVisible = !store.isMenuVisible;
          const menuIndicator = isMenuVisible ? store.menuIndicator : undefined;
          return { isMenuVisible, menuIndicator };
        }),

      isNotificationsVisible: false,
      toggleIsNotificationsVisible: () => set(store => ({ isNotificationsVisible: !store.isNotificationsVisible })),
      expandedNotifications: [],
      getExpandedNotifications: () => get().expandedNotifications,
      addExpandedNotifications: notificationId =>
        set(store => {
          if (store.expandedNotifications.includes(notificationId)) return store;
          return { expandedNotifications: [...store.expandedNotifications, notificationId] };
        }),
      removeExpandedNotifications: notificationIdToRemove =>
        set(store => ({
          expandedNotifications: store.expandedNotifications.filter(notificationId => notificationId !== notificationIdToRemove),
        })),

      menuIndicator: undefined,
      setMenuIndicator: menuIndicator => set({ menuIndicator }),
      resetMenuIndicator: () => set({ menuIndicator: undefined }),

      isMobileFilterDrawerVisible: false,
      openMobileFilterDrawer: () => set({ isMobileFilterDrawerVisible: true }),
      closeMobileFilterDrawer: () => set({ isMobileFilterDrawerVisible: false }),
    };
  },
  {
    name: `${process.env.REACT_APP_VERSION}_layout`,
    getStorage: () => sessionStorage,
  },
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useLayoutStore = create(devtools(layoutStore, { name: 'LAYOUT STORE', enabled: !isProduction }));

export default useLayoutStore;
