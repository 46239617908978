import React, { useEffect } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import PageTitle from 'components/PageTitle/PageTitle';
import PATHS from 'constants/paths';
import FullScreen from 'containers/FullScreen/FullScreen';
import Container from 'containers/Container/Container';
import { useExerciseDrawContext } from 'context/ExerciseDrawContext';
import { useGlobalDialogContext } from 'context/GlobalDialogContext';
import TablesProvider from 'context/TablesContext';
import useSubjectStore from 'storages/subject';
import DataPersist, { DATA_PERSIST_KEY } from 'utils/dataPersist/dataPersist';

import { useQuery } from 'react-query';
import QUERY from 'constants/queryKeys';
import usersApi from 'api/rest/users/users';
import { useAuthContext } from 'context/AuthContext';
import ExerciseDesktopView from './ExerciseDesktopView';
import ExerciseMobileView from './ExerciseMobileView';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});

const ExercisesContent = () => {
  const { subject } = useSubjectStore();
  const { addToGlobalDialogQueue, GLOBAL_DIALOG } = useGlobalDialogContext();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(QUERY.GET_ME, usersApi.getMe());
  const { isSchoolAccount } = useAuthContext();

  const {
    drawState: { drawMode },
  } = useExerciseDrawContext();

  useEffect(() => {
    if (!isLoading && data) {
      const askForSubStore = new DataPersist(DATA_PERSIST_KEY.ASK_FOR_SUBSCRIPTION, 'localStorage');
      const askForSub = askForSubStore.get();

      if (!askForSub?.displayed && !data.hasPremiumAccess && !isSchoolAccount) {
        addToGlobalDialogQueue({ type: GLOBAL_DIALOG.PURCHASE_PROMPT });
        askForSubStore.set({ displayed: true });
      }
    }
  }, []);

  const classes = useStyles();
  const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  if (!subject) navigate(PATHS.SUBJECT_SELECT, { from: PATHS.EXERCISES });
  return (
    <FullScreen>
      <PageTitle>{`Zadania | ${subject?.name || 'Przedmioty'}`}</PageTitle>
      <TablesProvider>
        <Container className={classes.container} width='narrow'>
          {downSm ? <ExerciseMobileView /> : <ExerciseDesktopView drawMode={drawMode} />}
        </Container>
      </TablesProvider>
    </FullScreen>
  );
};

export default ExercisesContent;
