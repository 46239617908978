import { findKey } from 'lodash';

import { SupportedSubjects } from 'types/Subject';

import { Id } from '../../../types/Id';

type nameFormat = 'full' | 'short';

const _subjectsCodes: Record<SupportedSubjects, number> = {
  CH: 1,
  M: 2,
  MR: 3,
  B: 4,
  FI: 5,
  GE: 6,
  HI: 7,
  JA: 8,
  PP: 11,
};

const _subjectsNames: Record<SupportedSubjects, string> = {
  GE: 'Geografia',
  FI: 'Fizyka',
  CH: 'Chemia',
  MR: 'Matematyka rozszerzona',
  JA: 'Język Angielski',
  HI: 'Historia',
  M: 'Matematyka podstawowa',
  B: 'Biologia',
  PP: 'Język polski podstawowy',
};

const _subjectsShortNames: Record<SupportedSubjects, string> = {
  GE: 'GE',
  FI: 'FI',
  CH: 'CH',
  MR: 'MR',
  JA: 'JA',
  HI: 'HI',
  M: 'MP',
  B: 'BI',
  PP: 'PP',
};

const parseSubjectCodeToSubjectNumber = (subjectCode: SupportedSubjects): number => _subjectsCodes[subjectCode];

const parseSubjectNumberToSubjectCode = (subjectNumber: number | Id): SupportedSubjects =>
  <SupportedSubjects>findKey(_subjectsCodes, v => v === subjectNumber);

const resolveSubjectNameFromSubjectCode = (subjectString: SupportedSubjects, format: nameFormat): string => {
  switch (format) {
    case 'short': {
      return _subjectsShortNames[subjectString];
    }
    case 'full':
    default: {
      return _subjectsNames[subjectString];
    }
  }
};

const resolveSubjectNameFromSubjectNumber = (subjectNumber: number, format: nameFormat): string =>
  resolveSubjectNameFromSubjectCode(parseSubjectNumberToSubjectCode(subjectNumber), format);

export {
  parseSubjectCodeToSubjectNumber,
  parseSubjectNumberToSubjectCode,
  resolveSubjectNameFromSubjectCode,
  resolveSubjectNameFromSubjectNumber,
};
