import { useEffect } from 'react';

import { useQuery } from 'react-query';
import { useOutletContext } from 'react-router';

import teacherApi from 'api/rest/teacher/teacher';
import QUERY from 'constants/queryKeys';
import { useAuthContext } from 'context/AuthContext';
import { TeacherLayoutOutletContext } from 'root/TeacherLayout/TeacherLayout';

const useTeacherDashboardPage = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { teacher } = useAuthContext();
  const { data: teacherData, isLoading } = useQuery([QUERY.GET_TEACHERS, teacher], teacherApi.getTeacher(teacher));
  const { setClassroomData } = useOutletContext() as TeacherLayoutOutletContext;
  useEffect(() => {
    setClassroomData();
  }, []);

  return { teacherData, isLoading };
};

export { useTeacherDashboardPage };
