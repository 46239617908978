import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import exercisesInitialStorage from './exercisesInitialStorage';
import setCurrentExercise from '../_actions/setCurrentExercise';
import setCurriculum from '../_actions/setCurriculum';
import setCursor from '../_actions/setCursor';
import getExercises, { parseVariables } from '../_helpers/getExercises';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

const store = persist(
  (set, get) => ({
    ...exercisesInitialStorage,
    fetched: false,

    // ACTIONS
    setExercisesAfterSubjectSelect: async subject => {
      const variables = parseVariables(get(), {
        ...exercisesInitialStorage,
        subject,
      });
      const exercises = await getExercises(variables);
      set({ ...exercisesInitialStorage, ...exercises });
    },
    setSection: async section => {
      set(state => ({
        ...exercisesInitialStorage,
        orderBy: state.orderBy,
        userMarks: state.userMarks,
        section,
      }));
      const variables = parseVariables(get(), { section });
      const exercises = await getExercises(variables);
      set(exercises);
    },
    setSubsection: async subsection => {
      set(state => ({
        ...exercisesInitialStorage,
        orderBy: state.orderBy,
        userMarks: state.userMarks,
        section: state.section,
        subsection,
      }));
      const variables = parseVariables(get(), { subsection });
      const exercises = await getExercises(variables);
      set(exercises);
    },
    setSectionAndSubsection: async ({ section, subsection }) => {
      set({
        ...exercisesInitialStorage,
        section: section || null,
        subsection: subsection || null,
      });
      const variables = parseVariables(get(), { subsection, section });
      const exercises = await getExercises(variables);
      set(exercises);
    },
    setFilters: async filters => {
      set(state => ({
        ...exercisesInitialStorage,
        section: state.section,
        subsection: state.subsection,
        orderBy: state.orderBy,
        curriculum: state.curriculum,
        userMarks: state.userMarks,
        filters,
      }));
      const variables = parseVariables(get(), { filters });
      const exercises = await getExercises(variables);
      set(exercises);
    },
    setUserMarks: async userMarks => {
      set(state => ({
        ...exercisesInitialStorage,
        section: state.section,
        subsection: state.subsection,
        orderBy: state.orderBy,
        curriculum: state.curriculum,
        userMarks,
      }));
      const variables = parseVariables(get(), { userMarks });
      const exercises = await getExercises(variables);
      set(exercises);
    },
    setOrder: async orderBy => {
      set(state => ({
        ...exercisesInitialStorage,
        section: state.section,
        subsection: state.subsection,
        filters: state.filters,
        curriculum: state.curriculum,
        orderBy,
      }));
      const variables = parseVariables(get(), { orderBy });
      const exercises = await getExercises(variables);
      set(exercises);
    },
    reload: async () => {
      const state = get();
      const variables = parseVariables(state);
      set({ exercises: null, currentExercise: null });
      const { exercises } = await getExercises(variables);
      const currentExercise = exercises[state.exerciseCursor];
      set({ exercises, currentExercise });
    },
    setExerciseMark: mark => {
      set({ mark });
    },
    reset: () => set({ ...exercisesInitialStorage, fetched: false }),
    setFileType: fileType => set({ fileType }),
    setTotalCount: subjectExercisesCount => set({ subjectExercisesCount }),
    setCurriculum: setCurriculum(set, get),
    setExerciseCursor: setCursor(set, get),
    setCurrentExercise: setCurrentExercise(set),
  }),
  {
    name: `${process.env.REACT_APP_VERSION}_exercises`,
    getStorage: () => sessionStorage,
  },
);

const useExercisesStore = create(devtools(store, { name: 'EXERCISES', enabled: !isProduction }));

export default useExercisesStore;
