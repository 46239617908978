import React from 'react';

import { ApolloProvider } from '@apollo/react-hooks';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { setDefaultOptions } from 'date-fns';
import locale from 'date-fns/locale/pl';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import client from 'api/graphql/config/client';
import axiosConfig from 'api/rest/axiosConfig';
import queryClient from 'api/rest/queryClient';
import initFirebase from 'config/firebaseConfig';
import ErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';
import AppProviders from 'context/AppProviders';
import App from 'root/App/App';
import MuiGlobalStyles from 'styles/MuiGlobalStyles';
import theme from 'styles/themes/theme';

import initSentry from './config/sentryConfig';

import 'simplebar/dist/simplebar.min.css';
import 'styles/reset.css';
import 'styles/print.css';
import './serviceWorker';

initSentry();

axiosConfig();

initFirebase();

setDefaultOptions({ locale });

const GlobalCss = withStyles(MuiGlobalStyles)(() => null);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalCss />
        <QueryClientProvider client={queryClient}>
          <AppProviders>
            <App />
          </AppProviders>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </ApolloProvider>
  </ErrorBoundary>,
);
