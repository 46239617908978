import parseExerciseFilesForUI from '../_commonParsers/parseExerciseFilesForUI';
import { Exercise, ExerciseDto } from './exercises.types';

type ParseExercisesForUI = (input: ExerciseDto) => Exercise;

const parseExercisesForUI: ParseExercisesForUI = raw => ({
  uuid: raw.uuid,
  subject: raw.subject,
  section: raw.section,
  subsection: raw.subsection,
  tags: raw.tags,
  level: raw.level,
  date: new Date(raw.date),
  source: raw.source,
  files: parseExerciseFilesForUI(raw.files),
  importFileIdentifier: raw.import_file_identifier,
  index: raw.index,
  externalSources: raw.external_sources.map(source => ({
    id: source.id,
    category: source.category,
    createdAt: new Date(source.created_at),
    description: source.description,
    index: source.index,
    isMain: source.is_main,
    isSectionRelated: source.is_section_related,
    updatedAt: new Date(source.updated_at),
    url: source.url,
    userRating: source.user_rating,
  })),
  audio: raw.audio,
  video: raw.video,
  globalId: raw.global_id,
  userMark: raw.user_mark?.mark || null,
});

export { parseExercisesForUI };
