import { isNil } from 'lodash';
import { persist } from 'zustand/middleware';

import { ExerciseFiles, ExerciseFileTypes, ExerciseResourceCategory, ExerciseUserMark } from 'api/rest/exercises/exercises.types';
import isProduction from 'services/common/isProduction';
import { DataFetchingState } from 'types/DataFetchingState';
import { Id } from 'types/Id';
import { UrlString } from 'types/UrlString';
import { Uuid } from 'types/Uuid';

import { GraphQLId } from '../../types/GraphQLId';

export type ExerciseExternalResource = {
  id: Id;
  category: ExerciseResourceCategory;
  isMain: boolean;
  url: string;
  description: string;
};

export type ExerciseToDisplay = { uuid: Uuid; globalId: GraphQLId };
export type ExerciseToDisplayDetails = {
  uuid: Uuid;
  index: number;
  subject: { id: Id; name: string };
  section: { id: Id; name: string };
  subsection?: { id: Id; name: string };
  externalSources: ExerciseExternalResource[];
  files: ExerciseFiles;
  audioSource?: UrlString;
  globalId: string;
  importFileIdentifier: string;
  userMark: ExerciseUserMark | null;
};

type ExerciseToDisplayStorage = {
  cursor: number;
  setCursor: (newCursor: number) => void;
  increaseCursor: () => void;
  decreaseCursor: () => void;
  setCursorByUuid: (uuid: Uuid) => void;
  selectedFileType: ExerciseFileTypes;
  setSelectedType: (newFileType: ExerciseFileTypes) => void;

  exercises: ExerciseToDisplay[];
  currentExercise: ExerciseToDisplayDetails | null;
  setCurrentExercise: (currentExercise: ExerciseToDisplayDetails) => void;
  setExercises: (newExercises: ExerciseToDisplay[]) => void;
  onError: () => void;

  dataState: DataFetchingState;
  isLast: boolean;
  isFirst: boolean;
};

const initialState = {
  cursor: 0,
  exercises: [],
  currentExercise: null,
  dataState: 'IDLE' as DataFetchingState,
  isLast: false,
  isFirst: true,
  selectedFileType: ExerciseFileTypes.question,
};
// TODO unify it later (use for every functionality)

const exercisesToDisplayStorage = (storageName: string) =>
  persist<ExerciseToDisplayStorage>(
    (set, get) => ({
      ...initialState,

      setSelectedType: newFileType => {
        set({ selectedFileType: newFileType });
      },
      setExercises: newExercises =>
        set(prev => ({
          exercises: newExercises,
          isLast: prev.cursor === newExercises.length - 1,
          isFirst: prev.cursor === 0,
        })),
      setCursor: async newCursor => {
        const { exercises } = get();
        const exercisesLength = exercises.length;
        if (newCursor === 0 && exercisesLength === 0) {
          set({
            cursor: newCursor,
            dataState: 'EMPTY',
            currentExercise: null,
            selectedFileType: ExerciseFileTypes.question,
          });
          return;
        }
        if (newCursor < 0 || exercisesLength < newCursor) {
          if (!isProduction) throw Error('Error in exercisesToDisplayStorage, cursor is out of range!');
          return;
        }
        set({
          cursor: newCursor,
          isLast: newCursor === exercisesLength - 1,
          isFirst: newCursor === 0,
          dataState: 'FETCHING',
          selectedFileType: ExerciseFileTypes.question,
        });
      },
      setCurrentExercise: currentExercise => set({ currentExercise, dataState: 'SUCCESS' }),
      increaseCursor: () => {
        const { cursor, setCursor } = get();
        setCursor(cursor + 1);
      },
      decreaseCursor: () => {
        const { cursor, setCursor } = get();
        setCursor(cursor - 1);
      },
      setCursorByUuid: newCursorUuid => {
        const { exercises, setCursor, cursor } = get();
        if (!exercises.length) {
          if (!isProduction) throw Error('Error in exercisesToDisplayStorage, cursor cannot be set for empty set!');
          return;
        }
        const newCursor = exercises.findIndex(({ uuid }) => uuid === newCursorUuid);
        if (isNil(newCursor)) {
          if (!isProduction) throw Error('Error in exercisesToDisplayStorage, cannot find matching exercise!');
        }
        if (newCursor === cursor) return;

        setCursor(newCursor);
      },
      onError: () => set({ dataState: 'ERROR' }),
      reset: () => set(initialState),
    }),
    { name: storageName, getStorage: () => localStorage },
  );

export default exercisesToDisplayStorage;
