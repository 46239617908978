import React, { useState } from 'react';

import { ReactComponent as FacebookIcon } from 'assets/icons/general/facebook.svg';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { generatePath, useNavigate } from 'react-router-dom';

import FacebookLoginWrapper from 'components/FacebookLoginWrapper/FacebookLoginWrapper';
import GeneralError from 'components/GeneralError/GeneralError';
import PATHS from 'constants/paths';
import useFacebookAuth from 'services/common/useFacebookAuth/useFacebookAuth';
import AuthGrid from 'uniqueComponents/Auth/AuthGrid/AuthGrid';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  buttonsWrapper: {
    paddingBottom: '0 !important',
  },
  buttonsContainer: {
    width: theme.containers.formS,
    display: 'grid',
    gridGap: theme.spacing(4),
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  checkboxLabel: {
    '& p': {
      fontSize: '.65rem',
    },
  },
  checkboxLabelRequired: {
    color: theme.palette.error.main,
  },
}));

const initialState = {
  term1: false,
  term2: false,
  newsletter: false,
};

// TODO refactor it + export logic form auth components
const Register = () => {
  const [state, updateState] = useState(initialState);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const facebookAuth = useFacebookAuth();

  const onCheck = ({ target: { name, checked } }) => {
    updateState(prev => ({ ...prev, [name]: checked }));
  };

  const validate = () => {
    const newErrors = [];
    if (!state.term1 || !state.term2) {
      newErrors.push('Zaznacz wymagane zgody.');
    }
    setError(newErrors.join('\n'));
    return !newErrors.length;
  };

  const onButtonClick = (name, callback) => {
    if (validate()) {
      // eslint-disable-next-line default-case
      switch (name) {
        case 'facebook': {
          callback();
          break;
        }
        case 'google': {
          // TODO: google action
          break;
        }
        case 'email': {
          navigate(PATHS.REGISTER_BY_MAIL, state);
          break;
        }
      }
    }
  };

  const classes = useStyles();

  return (
    <AuthGrid title='Utwórz konto!'>
      <Grid alignItems='center' container spacing={8}>
        <Grid className={classes.buttonsWrapper} container item justify='center' xs={12}>
          <div className={classes.buttonsContainer}>
            <Button onClick={() => onButtonClick('email')} size='large' variant='contained'>
              Użyj adresu email
            </Button>
            <FacebookLoginWrapper
              callback={facebookAuth}
              disableMobileRedirect
              redirectUri='https://app.mojamatura.edu.pl/'
              render={({ onClick }) => (
                <Button
                  onClick={() => onButtonClick('facebook', onClick)}
                  size='large'
                  startIcon={<FacebookIcon width={18} />}
                  variant='contained'
                >
                  Użyj konta Facebook
                </Button>
              )}
            />
          </div>
        </Grid>
        <Grid container item justify='center' xs={12}>
          <GeneralError>{error}</GeneralError>
        </Grid>
        <Grid align='center' component={Typography} item xs={12}>
          Posiadasz juz konto?{' '}
          <Link className={classes.link} to={PATHS.LOGIN}>
            Zaloguj&nbsp;się!
          </Link>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              key='term1'
              className={classes.checkboxLabel}
              control={<Checkbox checked={state.term1.value} color='primary' name='term1' onChange={onCheck} />}
              label={
                <p>
                  <span className={classes.checkboxLabelRequired}>*&nbsp;</span>
                  Oświadczam, że zapoznałem(-am) się z&nbsp;
                  <a href={`/${generatePath(PATHS.DOCUMENTS, { documentType: 'terms' })}`} rel='noreferrer noopener' target='_blank'>
                    regulaminem
                  </a>{' '}
                  i{' '}
                  <a
                    href={`/${generatePath(PATHS.DOCUMENTS, { documentType: 'privacy_policy' })}`}
                    rel='noreferrer noopener'
                    target='_blank'
                  >
                    polityką prywatności
                  </a>{' '}
                  serwisu oraz je akceptuję.
                </p>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              key='term2'
              className={classes.checkboxLabel}
              control={<Checkbox checked={state.term2.value} color='primary' name='term2' onChange={onCheck} />}
              label={
                <p>
                  <span className={classes.checkboxLabelRequired}>*&nbsp;</span>
                  Oświadczam, że wyrażam zgodę na przetwarzanie wyżej podanych danych na zasadach ujętych w&nbsp;
                  <a href={`/${generatePath(PATHS.DOCUMENTS, { documentType: 'terms' })}`} rel='noreferrer noopener' target='_blank'>
                    regulaminie
                  </a>{' '}
                  i{' '}
                  <a
                    href={`/${generatePath(PATHS.DOCUMENTS, { documentType: 'privacy_policy' })}`}
                    rel='noreferrer noopener'
                    target='_blank'
                  >
                    polityce prywatności
                  </a>{' '}
                  serwisu.
                </p>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              key='newsletter'
              className={classes.checkboxLabel}
              control={<Checkbox checked={state.newsletter.value} color='primary' name='newsletter' onChange={onCheck} />}
              label={<p>Chciałbym(-ałabym) otrzymywać informacje o nowościach oraz promocjach drogą mailową.</p>}
            />
          </Grid>
        </Grid>
      </Grid>
    </AuthGrid>
  );
};

export default Register;
