import { Option } from 'components/_inputs/_types/Option';

import { SubjectDto, Subject, SubjectDetailsDto, SubjectDetails } from './subjects.types';

type ParseSubjectsListForUI = (input: SubjectDto[]) => Subject[];
type ParseSubjectsListForDropdown = (input: SubjectDto[], addSelectAllOption: boolean) => Option[];
type ParseSubjectForUI = (input: SubjectDetailsDto) => SubjectDetails;

const parseSubjectsListForUI: ParseSubjectsListForUI = raw => raw;
const parseSubjectForUI: ParseSubjectForUI = raw => raw;

const parseSubjectsListForDropdown: ParseSubjectsListForDropdown = (raw, addSelectAllOption) => {
  if (!raw) return [];

  const base = raw.map(({ id, name }) => ({ value: id, label: name }));

  if (!addSelectAllOption) return base;
  return [{ value: '', label: 'Wszystkie' }, ...base];
};

export { parseSubjectsListForUI, parseSubjectForUI, parseSubjectsListForDropdown };
