import axios from 'axios';

import authManager from 'utils/authManager/authManager';

const axiosConfig = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  const cookiesAuthData = authManager.getCookieAuthData();
  if (cookiesAuthData?.token) axios.defaults.headers.common.Authorization = `JWT ${cookiesAuthData.token}`;
  return axios;
};

const initWarehouseAxiosInstance = () =>
  axios.create({
    baseURL: process.env.REACT_APP_WAREHOUSE_API_URL,
  });

export { initWarehouseAxiosInstance };

export default axiosConfig;
