import React from 'react';

import { isNil } from 'lodash';

import type { Me } from 'api/rest/users/users.types';
import CommercialUserDataForm from 'components/CommercialUserDataForm/CommercialUserDataForm';
import PaperWithHeader from 'components/PaperWithHeader/PaperWithHeader';
import SchoolUserDataForm from 'components/SchoolUserDataForm/SchoolUserDataForm';

import useStyles from './UserDataForm.styles';

type Props = {
  meData: Me;
};

const UserDataForm: React.FC<Props> = ({ meData }) => {
  const classes = useStyles();
  return (
    <PaperWithHeader header='Ustawienia konta' contentClassName={classes.root}>
      {meData?.isSchoolAccount ? (
        <SchoolUserDataForm defaultValues={{ newsletter: meData.newsletter, replyEmail: meData.replyEmail }} />
      ) : (
        <CommercialUserDataForm
          defaultValues={{
            newsletter: meData.newsletter,
            accountType: meData.accountType,
            ageRange: isNil(meData.ageRange) ? undefined : meData.ageRange,
            classroom: isNil(meData.classroom) ? undefined : meData.classroom,
          }}
        />
      )}
    </PaperWithHeader>
  );
};

export default UserDataForm;
