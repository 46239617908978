import axios from 'axios';

import createUrlWithParams from 'services/common/createUrlWithParams/createUrlWithParams';
import type { Id } from 'types/Id';

import { parseAgreementDataForBE, parseAgreementsForFE, parseAgreementsParams } from './agreements.parsers';
import type { AgreementsApi, AgreementDto } from './agreements.types';

const endpoint = 'agreements/agreements/';

const agreementsApi: AgreementsApi = {
  getAll: {
    generateQueryKey: params => ['GET ALL AGREEMENTS', params?.hasConsent ? 'has consent' : 'hasnt consent'],
    request: params => async () => {
      const { data } = await axios.get<AgreementDto[]>(createUrlWithParams(endpoint, parseAgreementsParams(params)));
      return parseAgreementsForFE(data);
    },
    requestBaseSettings: {},
  },
  // TODO - exercisesSet test it!!!!!!
  set: {
    generateQueryKey: params => ['SET AGREEMENT', params?.id as Id],
    request: () => data => {
      if (!data) throw Error('Missing id'); // TODO - exercisesSet check it
      return axios.post<unknown>(endpoint, parseAgreementDataForBE(data));
    },
    requestBaseSettings: {},
  },
};

export default agreementsApi;
