import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  loader: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  staticLoader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(8, 0),
  },
}));

export default useStyles;
