import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import GET_CURRICULUMS from 'api/graphql/queries/exercises/GET_CURRICULUMS';
import useExercisesStore from 'storages/exercises/exercises';
import useSubjectStore from 'storages/subject';
import safeJSONParser from 'utils/safeJSONParser/safeJSONParser';

import FiltersBlock from 'uniqueComponents/Exercises/ExercisesFilters/FiltersBlock/FiltersBlock';
import GenericFiltersList from 'uniqueComponents/Exercises/ExercisesFilters/GenericFiltersList/GenericFiltersList';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

function Curriculum() {
  const { logEvent } = useUserLogContext();
  const { subject } = useSubjectStore();
  const { setCurriculum, section, subsection, curriculum } = useExercisesStore();
  const [getCurriculums, { data, loading }] = useLazyQuery(GET_CURRICULUMS);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    if (!loading && data) {
      const choices = safeJSONParser(data.curriculumChoices);
      const newState = [];
      Object.entries(choices).forEach(([key, value]) => {
        if (value.count > 0) {
          newState.push({ name: value.label, id: key, exercisesCount: value.count });
        }
      });
      setFilters(newState);
    }
  }, [data, loading]);

  useEffect(() => {
    const variables = {
      section: section === 'all' ? null : section,
      subsection: subsection === 'all' ? null : subsection,
      subject: subject.id,
    };
    getCurriculums({ variables });
  }, [subject, section, subsection]);

  const [isAllSelected, setIsAllSelected] = useState(false);

  const onSelectAll = () => {
    logEvent('select-filter-click', { filter: 'Podstawa programowa', selectAll: true, value: isAllSelected, feature: 'exercises' });
    const newCurriculum = isAllSelected ? [] : filters.map(({ id }) => id);
    setCurriculum(newCurriculum);
  };

  useEffect(() => {
    setIsAllSelected(curriculum.length === filters.length);
  }, [curriculum, filters]);

  return (
    <FiltersBlock heading='Podstawa programowa' isAllSelected={isAllSelected} isSelectable onSelectAll={onSelectAll}>
      <GenericFiltersList filterName='curriculum' filters={filters} heading='Podstawa programowa' setFilters={setCurriculum} />
    </FiltersBlock>
  );
}

export default Curriculum;
