import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    minHeight: '100vh',
    minWidth: '100vw',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

export default useStyles;
