import React, { createContext, useState, useContext, Suspense, lazy, useMemo } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { drop, isNil, sortBy, uniqBy } from 'lodash';

import LoadingDialog from 'components/_dialogs/LoadingDialog/LoadingDialog';
import type { AdditionalProps as MissingAgreementDialogAdditionalProps } from 'components/_dialogs/MissingAgreementDialog/MissingAgreementDialog';
import isProduction from 'services/common/isProduction';
import { Id } from 'types/Id';
import ensureIsArray from 'utils/ensureIsArray/ensureIsArray';

const HelpSectionInfoDialog = lazy(() => import('components/_dialogs/HelpSectionInfoDialog/HelpSectionInfoDialog'));
const CreateNewExercisesSetDialog = lazy(() => import('components/_dialogs/CreateNewExercisesSetDialog/CreateNewExercisesSetDialog'));
const ImportantMessageDialog = lazy(() => import('components/_dialogs/ImportantNotificationDialog/ImportantNotificationDialog'));
const ExternalSourcesDialog = lazy(() => import('components/_dialogs/ExternalSourcesDialog/ExternalSourcesDialog'));
const PurchasePromptDialog = lazy(() => import('components/_dialogs/PurchasePromptDialog/PurchasePromptDialog'));
const TutorialDialog = lazy(() => import('components/_dialogs/TutorialDialog/TutorialDialog'));
const TutorialMobileDialog = lazy(() => import('components/_dialogs/TutorialMobileDialog/TutorialMobileDialog'));
const VerifyAccountDialog = lazy(() => import('components/_dialogs/VerifyAccountDialog/VerifyAccountDialog'));
const MarkExerciseDialog = lazy(() => import('components/_dialogs/MarkExerciseDialog/MarkExerciseDialog'));
const MissingAgreementDialog = lazy(() => import('components/_dialogs/MissingAgreementDialog/MissingAgreementDialog'));
const UserInfoDialog = lazy(() => import('components/_dialogs/UserInfoDialog/UserInfoDialog'));
const ChangePasswordDialog = lazy(() => import('components/_dialogs/ChangePasswordDialog/ChangePasswordDialog'));

export const GLOBAL_DIALOG = {
  PURCHASE_PROMPT: 'PurchasePromptDialog',
  TUTORIAL: 'TutorialDialog',
  EXTERNAL_RESOURCES: 'ExternalResourcesDialog',
  VERIFY_ACCOUNT: 'VerifyAccountDialog',
  MARK_EXERCISE: 'MarkExerciseDialog',
  USER_INFO: 'UserInfoDialog',
  CHANGE_PASSWORD: 'ChangePassword',
  IMPORTANT_NOTIFICATION: 'ImportantNotification',
  HELP_SECTION_INFO: 'HelpSectionInfo',
  MISSING_AGREEMENT: 'MissingAgreement',
  CREATE_NEW_EXERCISES_SET: 'Create new exercises set',
} as const;

type GlobalDialogsTypeKeys = keyof typeof GLOBAL_DIALOG;
type GlobalDialogsTypeValues = typeof GLOBAL_DIALOG[GlobalDialogsTypeKeys];

type Props = {
  children: React.ReactNode;
};

type Dialog = {
  type: GlobalDialogsTypeValues;
  props?: {
    promise?: { resolve: (value: any) => any; reject: (value: any) => any };
    [key: string]: any;
  };
  priority?: number; // 0 - highest, 10 - lowest
};

type GlobalDialogsContextType = {
  closeGlobalDialog: () => void;
  addToGlobalDialogQueue: (dialogs: Dialog | Dialog[]) => void;
  GLOBAL_DIALOG: typeof GLOBAL_DIALOG; // TODO remove it, use exported enum
  dialogQueue: Dialog[];
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const GlobalDialogsContext = createContext<GlobalDialogsContextType>(null!);

const hasSpecialProps = <T,>(props: any, propsToCheck: any[]): props is { isOpen: boolean; close: () => void } & T => {
  if (propsToCheck.every(singlePropToCheck => !!props[singlePropToCheck])) return props;
  if (!isProduction)
    throw Error(
      `Global dialog open error, these props are missing: ${propsToCheck
        .filter(singlePropToCheck => !props[singlePropToCheck])
        .join(', ')}`,
    );
  return false;
};

const GlobalDialogProvider: React.FC<Props> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [dialogQueue, setDialogQueue] = useState<Dialog[]>([]);

  const onClose = () => {
    setDialogQueue(prevDialogQueue => drop(prevDialogQueue));
  };

  const addToGlobalDialogQueue: (dialogs: Dialog | Dialog[]) => void = dialogs => {
    const dialogArray = ensureIsArray<Dialog>(dialogs).map(dialog => {
      if (isNil(dialog.priority)) return { ...dialog, priority: 5 };
      return dialog;
    });

    setDialogQueue(prevDialogQueue => sortBy(uniqBy([...prevDialogQueue, ...dialogArray], 'type'), 'priority'));
  };

  const renderDialog = (dialog: Dialog) => {
    if (!dialog) return null;
    const props = {
      onClose,
      open: true,
      ...dialog.props,
    };
    const onInvalidProps = () => {
      if (!isProduction) {
        throw Error(`Missing props in global dialog! Dialog type: ${dialog.type}`);
      }
      onClose();
      return null;
    };
    switch (dialog.type) {
      case GLOBAL_DIALOG.PURCHASE_PROMPT:
        return <PurchasePromptDialog {...props} />;
      case GLOBAL_DIALOG.TUTORIAL:
        return downSm ? <TutorialMobileDialog {...props} /> : <TutorialDialog {...props} />;
      case GLOBAL_DIALOG.EXTERNAL_RESOURCES: {
        const allPropsProvided = hasSpecialProps<{ externalSources: any }>(props, ['externalSources']);
        return allPropsProvided ? <ExternalSourcesDialog {...props} /> : onInvalidProps();
      }
      case GLOBAL_DIALOG.VERIFY_ACCOUNT: {
        const allPropsProvided = hasSpecialProps<{ email: string }>(props, ['email']);
        return allPropsProvided ? <VerifyAccountDialog {...props} /> : onInvalidProps();
      }
      case GLOBAL_DIALOG.MARK_EXERCISE: {
        const allPropsProvided = hasSpecialProps<{ exerciseToMark: string }>(props, ['exerciseToMark']);
        return allPropsProvided ? <MarkExerciseDialog {...props} /> : onInvalidProps();
      }
      case GLOBAL_DIALOG.USER_INFO:
        return <UserInfoDialog {...props} />;
      case GLOBAL_DIALOG.CHANGE_PASSWORD:
        return <ChangePasswordDialog {...props} />;
      case GLOBAL_DIALOG.IMPORTANT_NOTIFICATION: {
        const allPropsProvided = hasSpecialProps<{ notificationId: Id }>(props, ['notificationId']);
        return allPropsProvided ? <ImportantMessageDialog {...props} /> : onInvalidProps();
      }
      case GLOBAL_DIALOG.HELP_SECTION_INFO:
        return <HelpSectionInfoDialog {...props} />;
      case GLOBAL_DIALOG.CREATE_NEW_EXERCISES_SET: {
        const propsCheck = hasSpecialProps<{ existingSets: string[] }>(props, ['existingSets']);
        if (propsCheck) return <CreateNewExercisesSetDialog {...props} />;
        onClose();
        return null;
      }
      case GLOBAL_DIALOG.MISSING_AGREEMENT: {
        const allPropsProvided = hasSpecialProps<MissingAgreementDialogAdditionalProps>(props, []);
        return allPropsProvided ? <MissingAgreementDialog {...props} /> : onInvalidProps();
      }
      default:
        return null;
    }
  };

  const value = useMemo(
    () => ({
      closeGlobalDialog: onClose,
      addToGlobalDialogQueue,
      GLOBAL_DIALOG,
      dialogQueue,
    }),
    [onClose, addToGlobalDialogQueue, GLOBAL_DIALOG],
  );

  return (
    <GlobalDialogsContext.Provider value={value}>
      {children}
      {dialogQueue.length ? <Suspense fallback={<LoadingDialog />}>{renderDialog(dialogQueue[0])}</Suspense> : null}
    </GlobalDialogsContext.Provider>
  );
};

export const useGlobalDialogContext = () => useContext(GlobalDialogsContext);

export default GlobalDialogProvider;
