import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { useLocation } from 'react-router-dom';

import GET_MARKS from 'api/graphql/queries/exercises/GET_MARKS';
import useExercisesStore from 'storages/exercises/exercises';
import useSubjectStore from 'storages/subject';
import safeJSONParser from 'utils/safeJSONParser/safeJSONParser';

import PATHS from 'constants/paths';

const FiltersCountContext = createContext(null);

const { Provider } = FiltersCountContext;

const FiltersCountProvider = props => {
  const location = useLocation();
  const { subject } = useSubjectStore();
  const { section, subsection, curriculum } = useExercisesStore();
  const [filters, setFilters] = useState([]);
  const [getMarks, { data: newMarks, loading }] = useLazyQuery(GET_MARKS, { fetchPolicy: 'network-only' });

  const getMarksCount = useCallback(() => {
    if (subject) {
      const variables = {
        subject: subject.id,
        section: section === 'all' ? null : section,
        subsection: subsection === 'all' ? null : subsection,
        curriculum,
      };
      getMarks({ variables });
    }
  }, [subject, section, subsection, curriculum]);

  useEffect(() => {
    if (!loading && newMarks) {
      const choices = safeJSONParser(newMarks.markChoices);
      const newState = [];
      Object.entries(choices).forEach(([key, value]) => {
        newState.push({ name: value.label, id: key, exercisesCount: value.count });
      });
      setFilters(prev => ({ ...prev, userMarks: newState }));
    }
  }, [newMarks, loading]);

  useEffect(() => {
    if (PATHS.EXERCISES === location.pathname) getMarksCount();
  }, [subject, section, subsection, curriculum]);

  return <Provider value={{ filters, updaters: { getMarksCount } }} {...props} />;
};

export const useFiltersCountContext = () => useContext(FiltersCountContext);

export default FiltersCountProvider;
