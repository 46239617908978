import gql from 'graphql-tag';

const SOCIAL_AUTH = gql`
  mutation SocialAuth($accessToken: String!, $provider: String!) {
    socialAuth(accessToken: $accessToken, provider: $provider) {
      token
    }
  }
`;

export default SOCIAL_AUTH;
