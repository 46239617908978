import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { useMediaQuery } from '@material-ui/core';
import { isBoolean, isNil } from 'lodash';
import LZString from 'lz-string';

import drawingToolsConsts from 'constants/dictionaries/drawingTools';
import encoding from 'constants/dictionaries/encoding';
import messages from 'constants/dictionaries/messages';
import ADD_EXERCISE_DRAW from 'api/graphql/mutations/exercises/ADD_EXERCISE_DRAW';
import useSafeMutation from 'services/common/useSafeMutation/useSafeMutation';
import themeObj from 'styles/themes/theme';

import useLayoutStore from 'storages/layout';
import useExerciseDataSourceResolver from '../services/common/useExerciseDataSourceResolver/useExerciseDataSourceResolver';

const defaultCanvasSettings = {
  loadTimeOffset: 2,
  lazyRadius: 0,
  brushRadius: 2,
  brushColor: themeObj.palette.primary.main,
  catenaryColor: themeObj.palette.primary.main,
  hideGrid: true,
  immediateLoading: false,
  hideInterface: true,
};

const highlighterSettings = {
  hideInterface: false,
  brushColor: 'rgba(242, 176, 56, .45)',
  catenaryColor: 'rgba(242, 176, 56, .45)',
  brushRadius: 9,
};

const canvasSettingReducer = (state, { fieldToUpdate, value, valueObject }) => {
  if (valueObject) {
    return {
      ...state,
      ...valueObject,
    };
  }
  // TODO: pt1 mutate object instead of creating new object
  const fieldsToUpdate = Array.isArray(fieldToUpdate) ? fieldToUpdate : [fieldToUpdate];
  const stateCopy = { ...state };
  fieldsToUpdate.forEach(key => {
    stateCopy[key] = value;
  });
  return stateCopy;
};

const ExerciseDrawContext = createContext(null);

const { Provider } = ExerciseDrawContext;

const ExerciseDrawProvider = ({ children, dataSource = undefined }) => {
  // TODO: pt2 change it to class
  const { setIsHeaderVisible } = useLayoutStore();
  const { currentExercise, exercises, allExercisesCompleted } = useExerciseDataSourceResolver(dataSource)();
  const shouldUseCommonStore = useMemo(() => dataSource === 'common_store', []);

  const [addDrawMutation] = useMutation(ADD_EXERCISE_DRAW);
  const safeMutation = useSafeMutation();

  const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [drawMode, setDrawMode] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [unsaved, setUnsaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const [tool, setTool] = useState(drawingToolsConsts.PEN);
  const [penSettings, setPenSettings] = useState({});
  const [canvasRefs, setCanvasRefs] = useState([]);
  const [history, setHistory] = useState([]);

  const [canvasSettings, setCanvasSettings] = useReducer(canvasSettingReducer, defaultCanvasSettings);

  useEffect(() => {
    setDisabled(allExercisesCompleted || !exercises?.length);
  }, [exercises, allExercisesCompleted]);

  useEffect(() => {
    if (downSm) setDrawMode(false);
  }, [downSm]);

  useEffect(() => {
    if (unsaved) {
      window.onbeforeunload = e => {
        const uniqE = e || window.event;
        // For IE and Firefox prior to version 4
        if (uniqE) {
          uniqE.returnValue = 'Niezapisane zmiany';
        }
        // For Safari
        return 'Niezapisane zmiany';
      };
    } else {
      window.onbeforeunload = undefined;
    }
  }, [unsaved]);

  const setPen = useCallback(() => {
    if (tool === drawingToolsConsts.HIGHLIGHTER) {
      setCanvasSettings({ valueObject: penSettings });
    }
    setTool(drawingToolsConsts.PEN);
  }, [tool, penSettings]);

  const toggleDrawMode = useCallback(
    newValue => {
      setDrawMode(prev => (isBoolean(newValue) ? newValue : !prev));
    },
    [drawMode],
  );

  useEffect(() => {
    if (!isNil(drawMode)) {
      setIsHeaderVisible(!drawMode);
      if (!drawMode) {
        setUnsaved(false);
      }
    }
    return () => setIsHeaderVisible(true);
  }, [drawMode]);

  const setHighlighter = useCallback(() => {
    const penSettingsToSave = {};
    Object.keys(highlighterSettings).forEach(key => {
      penSettingsToSave[key] = canvasSettings[key];
    });
    setTool(drawingToolsConsts.HIGHLIGHTER);
    setPenSettings(penSettingsToSave);
    setCanvasSettings({ valueObject: highlighterSettings });
  }, [highlighterSettings, penSettings, canvasSettings]);

  const changeBrushRadius = useCallback(value => {
    setCanvasSettings({ fieldToUpdate: 'brushRadius', value });
    setCanvasSettings({ fieldToUpdate: 'hideInterface', value: value <= 2 });
  }, []);

  const saveCanvas = useCallback(async () => {
    setSaving(true);
    const dataToCompress = JSON.stringify(canvasRefs.map(({ current }) => current.getSaveData()));
    const drawingData = LZString.compressToEncodedURIComponent(dataToCompress);
    const input = {
      exerciseId: shouldUseCommonStore ? currentExercise?.globalId : currentExercise?.id,
      drawingData,
      drawingEncodingType: encoding.URI,
    };
    await safeMutation(() => addDrawMutation({ variables: { input } }), messages.ERROR.SAVING('rysunku'), 'Zapisano nowy rysunek');
    setUnsaved(false);
    setSaving(false);
  }, [canvasRefs, currentExercise]);

  const undo = useCallback(() => {
    if (history.length) {
      setUnsaved(true);
      setHistory(prevHistory => {
        const prevStep = prevHistory.pop();
        const ref = canvasRefs[prevStep];
        if (ref) ref.current.undo();
        return prevHistory;
      });
    }
  }, [history, canvasRefs]);

  const clear = useCallback(
    index => {
      const ref = canvasRefs[index];
      if (ref) {
        ref.current.clear();
        setUnsaved(true);
      }
    },
    [canvasRefs],
  );

  const updateCanvasHistory = useCallback(
    index => {
      setUnsaved(true);
      setHistory(prevHistory => [...prevHistory, index]);
    },
    [history],
  );

  const updateCanvasRefs = useCallback(
    ({ index, ref, remove }) => {
      setCanvasRefs(prevRefs => {
        const newRefs = prevRefs;
        if (remove) {
          newRefs.splice(index, 1);
        } else {
          newRefs[index] = ref;
        }
        return newRefs;
      });
    },
    [canvasRefs],
  );

  const drawState = useMemo(
    () => ({
      drawMode,
      disabled,
      tool,
      canvasSettings,
      unsaved,
      saving,
    }),
    [drawMode, disabled, tool, canvasSettings, unsaved, saving],
  );

  const drawUpdaters = useMemo(
    () => ({
      toggleDrawMode,
      setPen,
      setHighlighter,
      setUnsaved,
      changeColor: value => setCanvasSettings({ fieldToUpdate: ['brushColor', 'catenaryColor'], value }),
      changeBrushRadius,
      saveCanvas,
      updateCanvasHistory,
      updateCanvasRefs,
      undo,
      clear,
    }),
    [
      setDrawMode,
      setDisabled,
      setPen,
      setHighlighter,
      setUnsaved,
      setCanvasSettings,
      saveCanvas,
      updateCanvasHistory,
      updateCanvasRefs,
      undo,
      clear,
    ],
  );

  return <Provider value={{ drawState, drawUpdaters }}>{children}</Provider>;
};

export const useExerciseDrawContext = () => useContext(ExerciseDrawContext);

export default ExerciseDrawProvider;
