import { isWithinInterval } from 'date-fns';
import { isNil, omitBy } from 'lodash';

import type {
  LoginByEmail,
  LoginByEmailDto,
  LoginByIdentifier,
  LoginByIdentifierDto,
  LoginResult,
  LoginResultDto,
  Me,
  MeDto,
  PermanentPasswordDto,
  PermanentPassword,
  User,
  UserDto,
} from './users.types';

type ParseMeForUI = (input: MeDto) => Me;
type ParseLoginByEmailForBE = (input: LoginByEmail) => LoginByEmailDto;
type ParseLoginByIdentifierForBE = (input: LoginByIdentifier) => LoginByIdentifierDto;
type ParseLoginResultForUI = (input: LoginResultDto) => LoginResult;
type ParsePermanentPasswordForBE = (input: PermanentPassword) => PermanentPasswordDto;
type ParseUserForUpdateBE = (input: Partial<User>) => Partial<UserDto>;
type ParsePartialMeForUI = (input: Partial<UserDto>) => Partial<User>;

const parseMeForUI: ParseMeForUI = data => {
  const {
    account_type,
    age_range,
    feature_config,
    has_full_access,
    is_verified,
    show_tutorial,
    has_password_set,
    warehouse_token,
    teacher,
    student,
    user_identifier,
    reply_email,
    assigned_class,
    assigned_school,
    data_processing_objection_sent_at,
    data_processing_objection_reason,
    config,
    ...rest
  } = data;

  const subscriptions = data.subscriptions
    ? data.subscriptions.map(subscription => ({
        validFrom: new Date(subscription.valid_from),
        validUntil: new Date(subscription.valid_until),
        accessTypeName: subscription.access_type_name,
      }))
    : [];

  const hasPremiumAccess = () => {
    if (has_full_access) return true;
    return (
      !!subscriptions?.length &&
      subscriptions.some(subscription => isWithinInterval(new Date(), { start: subscription.validFrom, end: subscription.validUntil }))
    );
  };

  return {
    ...rest,
    accountType: account_type,
    ageRange: age_range,
    featureConfig: feature_config,
    hasFullAccess: has_full_access,
    hasPremiumAccess: hasPremiumAccess(),
    hasPasswordSet: has_password_set,
    isVerified: is_verified,
    showTutorial: show_tutorial,
    subscriptions,
    student,
    teacher,
    isStudent: !isNil(student),
    isTeacher: !isNil(teacher),
    isSchoolAccount: !isNil(student) || !isNil(teacher),
    warehouseToken: warehouse_token,
    userIdentifier: user_identifier,
    replyEmail: reply_email,
    dataProcessingObjectionReason: data_processing_objection_reason,
    dataProcessingObjectionSentAt: data_processing_objection_sent_at ? new Date(data_processing_objection_sent_at) : null,
    config: {
      useSecondaryEmail: config.use_secondary_email,
    },
    assignedClass: assigned_class
      ? {
          id: assigned_class.id,
          name: assigned_class.name,
          letter: assigned_class.letter,
          level: assigned_class.level,
        }
      : null,
    assignedSchool: assigned_school
      ? {
          address: assigned_school.address,
          city: assigned_school.city,
          district: assigned_school.district,
          id: assigned_school.id,
          name: assigned_school.name,
          shortName: assigned_school.short_name,
        }
      : null,
  };
};

const parseLoginByEmailForBE: ParseLoginByEmailForBE = data => data;
const parseLoginByIdentifierForBE: ParseLoginByIdentifierForBE = data => {
  const { loginIdentifier, ...rest } = data;
  return {
    ...rest,
    login_identifier: loginIdentifier,
  };
};
const parseLoginResultForUI: ParseLoginResultForUI = data => data;

const parsePermanentPasswordForBE: ParsePermanentPasswordForBE = data => ({
  password1: data.password1,
  password2: data.password2,
});

const parseMeForUpdateBE: ParseUserForUpdateBE = formData => {
  const notNullableValues = omitBy(
    {
      account_type: formData.accountType,
      age_range: formData.ageRange,
      classroom: formData.classroom,
      newsletter: formData.newsletter,
      origin: formData.origin,
      show_tutorial: formData.showTutorial,
      username: formData.username,
    },
    e => isNil(e) || e === '',
  );

  const nullableValues = {
    data_processing_objection_reason: formData.dataProcessingObjectionReason,
    reply_email: formData.replyEmail,
  };

  return { ...notNullableValues, ...nullableValues };
};

const parsePartialMeForUI: ParsePartialMeForUI = rawData => {
  const notNullableValues = omitBy(
    {
      accountType: rawData.account_type,
      ageRange: rawData.age_range,
      classroom: rawData.classroom,
      newsletter: rawData.newsletter,
      origin: rawData.origin,
      showTutorial: rawData.show_tutorial,
      username: rawData.username,
    },
    e => isNil(e) || e === '',
  );

  const nullableValues = {
    dataProcessingObjectionReason: rawData.data_processing_objection_reason || null,
    dataProcessingObjectionSentAt: rawData.data_processing_objection_sent_at ? new Date(rawData.data_processing_objection_sent_at) : null,
    replyEmail: rawData.reply_email,
  };

  return { ...notNullableValues, ...nullableValues };
};

export {
  parseMeForUI,
  parseLoginByEmailForBE,
  parseLoginResultForUI,
  parseLoginByIdentifierForBE,
  parsePermanentPasswordForBE,
  parseMeForUpdateBE,
  parsePartialMeForUI,
};
