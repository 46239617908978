import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 102,
  },
  popover: {
    '& .MuiPopover-paper': {
      padding: '1rem',
      display: 'grid',
      gridGap: '.75rem',
      width: '100vw',
      maxWidth: '100vw',
      justifyContent: 'center',
    },
  },
  popoverHelpers: {
    '& .MuiPopover-paper': {
      justifyContent: 'stretch',
    },
  },
  popoverIconsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: '1rem',
  },
  popoverButton: {
    background: theme.palette.common.white,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  narrowButton: {
    justifySelf: 'center',
  },
  iconLarge: {
    height: '34px',
    width: '34px',
  },
}));

export default useStyles;
