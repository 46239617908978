import gql from 'graphql-tag';

const SEND_FEEDBACK = gql`
  mutation SendFeedback($message: String!) {
    sendFeedback(message: $message) {
      sent
    }
  }
`;

export default SEND_FEEDBACK;
