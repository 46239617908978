import React, { useEffect, useMemo } from 'react';

import { Dialog } from '@material-ui/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import users from '../../../api/rest/users/users';
import { SchoolUserDataFormInput } from '../../../api/rest/users/users.types';
import MUTATION from '../../../constants/mutationsKeys';
import QUERY from '../../../constants/queryKeys';
import DialogGrid from '../../../containers/DialogGrid/DialogGrid';
import useUpdateMeMutation from '../../../hooks/apiCalls/useUpdateMeMutation/useUpdateMeMutation';
import FormCheckboxInput from '../../_forms/FormCheckboxInput/FormCheckboxInput';
import FormTextInput from '../../_forms/FormTextInput/FormTextInput';
import FormWrapper from '../../FormWrapper/FormWrapper';

type DataProcessingObjectionForm = {
  hasObjection: boolean;
  reason: string | null;
};

type Props = {
  onClose: () => void;
  initialValues: DataProcessingObjectionForm;
};

const DataProcessingObjectionDialog: React.FC<Props> = ({ onClose, initialValues }) => {
  const { handleSubmit, control, watch, formState } = useForm<DataProcessingObjectionForm>({
    defaultValues: { ...initialValues, reason: initialValues.reason || '' },
  });
  const hasObjectionValue = watch('hasObjection');

  const { updateMeMutation } = useUpdateMeMutation({ onSuccess: onClose });

  const onSubmit: SubmitHandler<DataProcessingObjectionForm> = data => {
    updateMeMutation.mutate({ dataProcessingObjectionReason: data.hasObjection ? data.reason : null });
  };

  return (
    <Dialog onClose={onClose} open>
      <DialogGrid
        cancelClick={onClose}
        disabled={!formState.isDirty}
        hideCloseButton
        isForm
        okLabel='Zapisz'
        onSubmit={handleSubmit(onSubmit)}
        sending={updateMeMutation.isLoading}
        title='Przetwarzanie danych osobowych'
      >
        <FormWrapper>
          <FormCheckboxInput label='Wyrażam sprzeciw wobec przetwarzania moich danych osobowych' control={control} name='hasObjection' />
          <FormTextInput
            inputProps={{ disabled: !hasObjectionValue, multiline: true, minRows: 3 }}
            label='Komentarz'
            control={control}
            name='reason'
          />
        </FormWrapper>
      </DialogGrid>
    </Dialog>
  );
};

export default DataProcessingObjectionDialog;
