import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

// TODO to remove
const ButtonRound = withStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    padding: ({ padding }) => (padding ? theme.legacySpacing(...padding) : theme.legacySpacing(1, 5)),
    minWidth: '20px',
  },
}))(Button);

export default ButtonRound;
