import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  infoBox: {
    display: 'grid',
    gap: theme.spacing(6),
    // @ts-ignore
    maxWidth: theme.container.form.m,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    height: '100%',
  },
  headerFallback: {
    justifyContent: 'center',
  },
}));

export default useStyles;
