import { isNil } from 'lodash';
import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import { FileType } from 'types/FileType';

import setCurrentExercise from '../_actions/setCurrentExercise';
import setCursor from '../_actions/setCursor';
import getExercisesByIndexes, { parseVariables } from '../_helpers/getExercisesByIndexes';
import { CursorDirection } from '../_types/CursorDirection';
import useSubjectStore from '../subject';
import { ChosenExercisesStore } from './chosenExercises.types';
import chosenExercisesInitialStorage from './chosenExercisesInitialStorage';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

type ExerciseIdentity = { index: number; id: string };

const chosenExercisesStore = persist<ChosenExercisesStore>(
  (set, get) => ({
    ...chosenExercisesInitialStorage,
    fetched: false,

    // ACTIONS
    addExercise: async ({ index, id }: ExerciseIdentity) => {
      if (!index || !id) return;
      const { subject } = useSubjectStore.getState();
      if (!subject) return;
      const { chosenExercises, subjectCursors } = get();
      const chosenExercisesForCurrentSubject = chosenExercises[subject.subjectCode];
      if (!chosenExercisesForCurrentSubject) return;
      const newSubjectCursors = { ...(subjectCursors || {}) };
      if (isNil(subjectCursors[subject.subjectCode])) {
        newSubjectCursors[subject.subjectCode] = 0;
      }
      set({
        chosenExercises: { ...chosenExercises, [subject.subjectCode]: [...chosenExercisesForCurrentSubject, { index, id }] },
        subjectCursors: newSubjectCursors,
      });
    },
    toggleExercise: ({ index, id }: ExerciseIdentity) => {
      const { subject } = useSubjectStore.getState();
      const { chosenExercises, exerciseCursor, subjectCursors } = get();
      if (!subject) return;
      const chosenExercisesForCurrentSubject = chosenExercises[subject.subjectCode];
      if (!chosenExercisesForCurrentSubject) return;
      let newExercisesList = [...chosenExercisesForCurrentSubject];
      let newCursor = exerciseCursor;

      if (chosenExercisesForCurrentSubject.some(exercise => exercise.id === id)) {
        newExercisesList = newExercisesList.filter(exercise => exercise.id !== id);
        const exerciseIndexToRemove = chosenExercisesForCurrentSubject.findIndex(exercise => exercise.id === id);
        if (exerciseCursor !== 0 && exerciseIndexToRemove <= exerciseCursor) {
          newCursor -= 1;
        }
      } else {
        newExercisesList = [...newExercisesList, { index, id }];
      }
      set({
        chosenExercises: { ...chosenExercises, [subject.subjectCode]: newExercisesList },
        exerciseCursor: newCursor,
        subjectCursors: { ...(subjectCursors || {}), [subject.subjectCode]: newCursor },
      });
    },
    setExerciseCursor: async (direction: CursorDirection) => {
      const { subject } = useSubjectStore.getState();
      if (!subject) return;
      const { subjectCursors } = get();
      const { exerciseCursor } = await setCursor(set, get)(direction);
      set({ subjectCursors: { ...(subjectCursors || {}), [subject.subjectCode]: exerciseCursor } });
    },
    setCurrentExercise: setCurrentExercise(set),
    setFileType: (fileType: FileType) => set({ fileType }),
    setTotalCount: (subjectExercisesCount: number) => set({ subjectExercisesCount }),
    fetchInitialExercises: async () => {
      const state = get();
      const variables = parseVariables(state);
      if (!variables) return null;
      const exercisesData = await getExercisesByIndexes(variables);
      set(exercisesData);
      // setCurrentExercise(set);
      return exercisesData;
    },
    getChosenExercisesForCurrentSubject: () => {
      const { subject } = useSubjectStore.getState();
      const { chosenExercises } = get();
      if (!subject) return [];
      if (!chosenExercises[subject?.subjectCode]) chosenExercises[subject.subjectCode] = [];
      return chosenExercises[subject.subjectCode];
    },
    setExerciseMark: (mark: string) => {
      set({ mark });
    },
    reset: () => set({ ...chosenExercisesInitialStorage, fetched: false }),
  }),
  {
    name: 'chosenExercises',
    getStorage: () => localStorage,
    version: 1,
    migrate: (persistedState: any, version) => {
      if (version === 0) {
        // eslint-disable-next-line no-param-reassign
        persistedState.subjectCursors = {};
        Object.keys(persistedState.chosenExercises).forEach(subject => {
          // eslint-disable-next-line no-param-reassign
          persistedState.subjectCursors[subject] = 0;
        });
      }

      return persistedState;
    },
  },
);

// TODO - exercisesSet migrate

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useChosenExercisesStore = create(devtools(chosenExercisesStore, { name: 'CHOSEN_EXERCISES', enabled: !isProduction }));

export default useChosenExercisesStore;
