import gql from 'graphql-tag';

const GET_EXERCISES_LIST = gql`
  query getExercisesList(
    $subject: ID!
    $section: ID
    $subsection: ID
    $filters: [ID]
    $orderBy: [String]
    $curriculum: [String]
    $userMarks: [String]
    $limit: Int
    $offset: Int
  ) {
    exercises(
      section: $section
      subject: $subject
      subsection: $subsection
      tags: $filters
      orderBy: $orderBy
      curriculum: $curriculum
      marks: $userMarks
      limit: $limit
      offset: $offset
    ) {
      id
    }
    exercisesCount(
      section: $section
      subject: $subject
      subsection: $subsection
      tags: $filters
      curriculum: $curriculum
      marks: $userMarks
    )
  }
`;

export default GET_EXERCISES_LIST;
