import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      background: theme.palette.grey[300],
    },
  },
  wrapper: {
    maxWidth: '210mm',
    margin: `${theme.spacing(8)}px auto`,
    background: theme.palette.common.white,

    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  header: {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    position: 'sticky',
    top: 0,
    opacity: 0.9,
  },
  tabWithIcon: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto auto',
  },

  icon: {
    fontSize: '1rem',
    marginBottom: '.125rem',
  },
  main: {
    background: theme.palette.common.white,
    width: '100%',
    padding: theme.spacing(16, 16, 20),
    boxShadow: theme.shadows[3],

    [theme.breakpoints.down('xs')]: {
      margin: 0,
      padding: theme.spacing(8, 6),
      boxShadow: 'none',
    },
  },
}));

export default useStyles;
