import gql from 'graphql-tag';

const GET_EXERCISES_FOR_PRINT = gql`
  query getExercisesForPrint($uuid: [String]) {
    exercises(limit: 50, uuid: $uuid) {
      id
      index
      files {
        croppedPngFile
        fileType
      }
      section {
        name
      }
      subsection {
        name
      }
    }
  }
`;

export default GET_EXERCISES_FOR_PRINT;
