import React from 'react';

import { Chip, ChipProps } from '@material-ui/core';

import useStyles from './ChipWithNumber.styles';

type Props = {
  value: number | string;
  customBackground?: string;
} & ChipProps;

const ChipWithNumber: React.FC<Props> = ({ value, label, customBackground, ...rest }) => {
  const classes = useStyles({ customBackground });
  return (
    <Chip
      color='primary'
      classes={{ root: classes.root }}
      label={
        <span className={classes.label}>
          {value} {label}
        </span>
      }
      {...rest}
    />
  );
};

export default ChipWithNumber;
