import React from 'react';

import clsx from 'clsx';

import ChipWithNumber from 'components/ChipWithNumber/ChipWithNumber';

import useStyles from './ExercisesStats.styles';

type Props = {
  stats: {
    label: string;
    value: number | string;
    background?: string;
  }[];
  className?: string;
};

const ExercisesStats: React.FC<Props> = ({ stats, className }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      {stats.map(({ value, label, background }) => (
        <ChipWithNumber key={`${value}-${label}`} customBackground={background} value={value} label={label} />
      ))}
    </div>
  );
};

export default ExercisesStats;
