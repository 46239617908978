import { isNil } from 'lodash';

type PrepareParamsFromArray = (paramsArray: { array: (string | number | boolean | undefined)[]; paramKey: string }) => string;

const prepareParamsFromArray: PrepareParamsFromArray = ({ array, paramKey }) => `&${paramKey}=${array.join(`&${paramKey}=`)}`;

export type ParamValue = string | number | boolean | undefined;
export type Params = { [key: string]: ParamValue };
type CreateUrlWithParams = (
  url: string,
  params?: Params,
  paramsArray?: { array: ParamValue[]; paramKey: string },
  removeEmptyValues?: boolean,
) => string;

const createUrlWithParams: CreateUrlWithParams = (url, params, paramsArray, removeEmptyValues = true) => {
  if (!url) throw new Error('Url is required');
  if (!params) return url;
  if (removeEmptyValues) {
    Object.entries(params).forEach(([key, value]) => {
      // eslint-disable-next-line no-param-reassign
      if (isNil(value) || value === '') delete params[key];
    });
  }
  const urlParams = new URLSearchParams(params as unknown as URLSearchParams).toString();

  if (!paramsArray) return `${url}?${urlParams}`;
  return `${url}?${urlParams}${prepareParamsFromArray(paramsArray)}`;
};

export default createUrlWithParams;
