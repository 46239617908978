import { makeStyles } from '@material-ui/core/styles';

import CONTAINERS from 'styles/constants/containers';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: CONTAINERS.FORM_L,
    padding: theme.spacing(2, 0),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  textarea: {
    width: '100%',
  },
}));

export default useStyles;
