import feedbackConsts from 'constants/dictionaries/feedbacks';

const exercisesInitialStorage = {
  section: null,
  subsection: null,
  exercises: null,
  exerciseCursor: 0,
  currentExerciseShape: { t: 0, o: 0, p: 0, a: 0 },
  currentExercise: null,
  fileType: 't', // 't' OR 'o' OR 'p'
  filters: [],
  exercisesCount: null,
  subjectExercisesCount: null,
  orderBy: '-date',
  curriculum: [],
  userMarks: [feedbackConsts.UNRESOLVED.id],
  mark: null,
  allExercisesCompleted: false,
};

export default exercisesInitialStorage;
