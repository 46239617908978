import React from 'react';

import { ButtonBase, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Link } from 'react-router-dom';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'block',
  },
  card: {
    position: 'relative',
    padding: theme.spacing(8),
    height: '100%',
    width: '100%',
    boxShadow: theme.shadows[3],
    cursor: ({ clickable }) => (clickable ? 'pointer' : 'auto'),

    transition: theme.transitions.create(['box-shadow'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),

    '&:hover': {
      boxShadow: theme.shadows[5],
    },

    '@media(max-width: 740px)': {
      padding: theme.spacing(7),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(7, 5),
    },
    '& h2': {
      padding: theme.spacing(6, 0),
      color: ({ disabled }) => (disabled ? theme.palette.text.disabled : theme.palette.primary.main),
      fontSize: '1.75rem',
      fontWeight: 700,
      textAlign: 'center',
      '@media(max-width: 740px)': {
        fontSize: '1.35rem',
        padding: theme.spacing(5, 0),
      },
    },
  },
  additionalText: {
    position: 'absolute',
    width: '100%',
    bottom: theme.spacing(4),
    left: 0,
    textAlign: 'center',
    fontSize: '.8rem',
    color: theme.palette.text.disabled,
  },
  title: {
    position: 'absolute',
    top: theme.spacing(6),
    left: theme.spacing(6),
    textAlign: 'center',
    fontSize: '.875rem',
    fontWeight: 700,
    color: theme.palette.text.disabled,
    '@media(max-width: 740px)': {
      top: theme.spacing(4),
      left: theme.spacing(4),
    },
  },
  link: {
    height: '100%',
    width: '100%',
  },
}));

const DashboardCard = ({ children, additionalText, title, to, className, disabled, ariaLabel, clickable }) => {
  const { logEvent } = useUserLogContext();
  const onClick = () => {
    logEvent('dashboard-click', { button: ariaLabel });
  };
  const classes = useStyles({ disabled, clickable });
  return (
    <ButtonBase aria-label={ariaLabel} className={clsx(classes.button, className)} disabled={disabled} disableRipple={!clickable}>
      <Link className={classes.link} onClick={onClick} to={to}>
        <Paper className={classes.card} square>
          <Typography className={classes.title}>{title}</Typography>
          {children}
          <Typography className={classes.additionalText}>{additionalText}</Typography>
        </Paper>
      </Link>
    </ButtonBase>
  );
};

DashboardCard.defaultProps = {
  to: null,
  className: '',
  disabled: false,
  additionalText: '',
  title: '',
  clickable: true,
};

export default DashboardCard;
