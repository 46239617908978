type TrimStringConfig = {
  showSuffix?: boolean;
  fallback?: string;
  suffix?: string;
};
type TrimString = (count: number, string?: string, config?: TrimStringConfig) => string;

const baseConfig: TrimStringConfig = {
  showSuffix: true,
};

const trimString: TrimString = (count, string, config = baseConfig) => {
  const { showSuffix, suffix = '...', fallback } = { ...baseConfig, ...config };
  if (!string) return fallback || '';
  const { length } = string;
  if (count >= length) return string;
  if (!showSuffix) return string.substring(0, count);
  return length < count + suffix.length ? string : `${string.substring(0, count).trim()}${suffix}`;
};

export default trimString;
