import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import useLayoutStore from 'storages/layout';
import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

const useStyles = makeStyles(theme => ({
  container: {
    transition: `400ms`,
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: ({ justify }) => justify,
    alignItems: 'center',
    paddingTop: ({ headerFree }) => !headerFree && LAYOUT_SIZES.HEADER.md,
    paddingBottom: ({ footerFree }) => !footerFree && LAYOUT_SIZES.FOOTER.md,
    [theme.breakpoints.down('sm')]: {
      paddingTop: ({ headerFree }) => !headerFree && LAYOUT_SIZES.HEADER.sm,
      paddingBottom: ({ footerFree }) => !footerFree && LAYOUT_SIZES.FOOTER.sm,
    },
  },
}));

function FullScreen({ children, justify, headerFree, footerFree, className }) {
  const { isHeaderVisible } = useLayoutStore();
  // TODO: change layout to MUI way
  const { container } = useStyles({
    headerFree: headerFree || !isHeaderVisible,
    justify,
    footerFree,
  });
  return <div className={clsx(container, className)}>{children}</div>;
}

FullScreen.defaultProps = {
  justify: 'center',
  headerFree: false,
  footerFree: false,
  className: null,
};

export default FullScreen;
