import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7, 0),
    display: 'grid',
    gridGap: theme.spacing(4),
    // @ts-ignore
    gridTemplateColumns: `${theme.container.form.m} auto`,
    gridTemplateRows: '95px auto',
    transition: '.3s ease',
    [theme.breakpoints.down('md')]: {
      // @ts-ignore
      gridTemplateColumns: `${theme.container.form.s} auto`,
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      flexGrow: 1,
    },
  },
}));

export default useStyles;
