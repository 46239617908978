import React from 'react';

import { Button, CircularProgress, Paper, Typography } from '@material-ui/core';
import { generatePath, Link } from 'react-router-dom';

import { ReactComponent as ListIcon } from 'assets/iconhub/list.svg';
import { ReactComponent as UserIcon } from 'assets/iconhub/user.svg';
import ChipWithNumber from 'components/ChipWithNumber/ChipWithNumber';
import PageTitle from 'components/PageTitle/PageTitle';
import PATHS from 'constants/paths';
import type { UserEventKey } from 'context/UserLogContext/UserEventKey';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

import { useTeacherDashboardPage } from './TeacherDashboardPage.logic';
import useStyles from './TeacherDashboardPage.styles';

const TeacherDashboardPage: React.FC = () => {
  const { teacherData, isLoading } = useTeacherDashboardPage();
  const { logEvent } = useUserLogContext();

  const logButtonClick = (classroomId: number, event: UserEventKey) => {
    logEvent(event, { classroomId });
  };

  const classes = useStyles();
  return (
    <>
      <PageTitle>Panel nauczyciela</PageTitle>
      <div className={classes.root}>
        {isLoading || !teacherData ? (
          <CircularProgress />
        ) : (
          teacherData.classrooms.map(({ name, id, registeredCount, studentsCount, shortName }) => (
            <Paper key={id} className={classes.classroomTile}>
              <div className={classes.title}>
                <Typography variant='h3'>Klasa: {shortName || name} </Typography>
                <ChipWithNumber size='small' color='default' value={`${registeredCount} z ${studentsCount}`} label='Zarejestrowanych' />
              </div>
              <div className={classes.buttonsContainer}>
                <Button
                  size='large'
                  startIcon={<UserIcon className={classes.icon} />}
                  variant='contained'
                  color='primary'
                  component={Link}
                  to={generatePath(PATHS.CLASSROOM, { classroomId: id })}
                  onClick={() => logButtonClick(id, 'class-list-students-button-click')}
                >
                  Lista uczniów
                </Button>
                <Button
                  size='large'
                  startIcon={<ListIcon className={classes.icon} />}
                  variant='contained'
                  color='primary'
                  component={Link}
                  to={generatePath(PATHS.CLASSROOM_EXERCISES, { classroomId: id })}
                  onClick={() => logButtonClick(id, 'class-list-exercises-button-click')}
                >
                  Zadania klasy
                </Button>
              </div>
            </Paper>
          ))
        )}
      </div>
    </>
  );
};

export default TeacherDashboardPage;
