import React, { MouseEventHandler } from 'react';

import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';

import { ReactComponent as CloseIcon } from 'assets/icons/general/close.svg';

import { Option } from '../_types/Option';
import useStyles from './DateInput.styles.js';

type Props = DatePickerProps & {
  error?: string | boolean;
  options?: Option[];
};

const TextFieldComponent: React.FC<TextFieldProps & { onClear: MouseEventHandler<HTMLButtonElement> }> = ({
  InputProps,
  onClear,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <TextField
      {...rest}
      classes={{ root: classes.root }}
      size='small'
      InputProps={{
        ...InputProps,
        endAdornment: rest.value ? (
          <InputAdornment position='end'>
            <IconButton onClick={onClear}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};

const DateInput: React.FC<Props> = ({ error, ...rest }) => {
  const onClear: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    rest.onChange(null);
  };
  /* eslint-disable react/no-unstable-nested-components */
  return (
    <DatePicker
      TextFieldComponent={props => <TextFieldComponent onClear={onClear} {...props} />}
      disableToolbar
      variant='inline'
      inputVariant='outlined'
      {...rest}
    />
  );
};

export default DateInput;
