import { useNavigate, useLocation } from 'react-router-dom';

import ensureIsArray from 'utils/ensureIsArray/ensureIsArray';

const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams: { [key: string]: string | string[] } = {};
  Array.from(new URLSearchParams(location.search).entries()).forEach(([key, value]) => {
    if (!Object.prototype.hasOwnProperty.call(queryParams, key)) {
      queryParams[key] = value;
    } else {
      queryParams[key] = [...ensureIsArray(queryParams[key]), value];
    }
  });

  const deleteParam = (name: string) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.delete(name);
    const paramsString = urlParams.toString();
    navigate(`${location.pathname}?${paramsString}`);
  };

  const addParam = (name: string, value: string) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set(name, value);
    const paramsString = urlParams.toString();
    navigate(`${location.pathname}?${paramsString}`);
  };

  return { queryParams, deleteParam, addParam };
};

export default useQueryParams;
