import React, { FC } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import { Close as IconClose } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';

import AuthProvider from 'context/AuthContext';
import ConfirmationModalProvider from 'context/ConfirmationModalContext';
import UserDataProvider from 'context/UserDataContext';
import UserLogProvider from 'context/UserLogContext/UserLogContext';
import useSourceDigest from 'services/common/useSourceDigest/useSourceDigest';

import SnackbarActionsContainer from '../components/SnackbarActionsContainer/SnackbarActionsContainer';

type Props = { children: React.ReactNode };

const AppProviders: React.FC<Props> = ({ children }) => {
  useSourceDigest();
  const snackBarConfig = {
    maxSnack: 3,
    style: {
      maxWidth: 'calc(100vw - 10px)',
    },
    anchorOrigin: {
      vertical: 'bottom' as 'top' | 'bottom',
      horizontal: 'left' as 'center' | 'left' | 'right',
    },
  };

  return (
    <SnackbarProvider {...snackBarConfig}>
      <AuthProvider>
        <UserLogProvider>
          <ConfirmationModalProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <UserDataProvider>{children}</UserDataProvider>
            </MuiPickersUtilsProvider>
          </ConfirmationModalProvider>
        </UserLogProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
};

export default AppProviders;
