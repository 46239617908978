const ERROR = {
  SAVING: (dataName = 'danych') => `Wystąpił problem z zapisaniem ${dataName}.`,
  SENDING: (dataName = 'danych') => `Wystąpił problem z wysłaniem ${dataName}.`,
  FETCHING: (dataName = 'danych') => `Wystąpił problem z pobraniem ${dataName}.`,
  PROCESSING: (dataName = 'danych') => `Wystąpił problem z przetworzeniem ${dataName}.`,
  LOGIN: () => 'Wystąpił problem z logowaniem.',
  GENERAL: () => 'Wystąpił problem.',
};

const SUCCESS = {
  SAVING: (dataName = 'dane') => `Zapisano ${dataName}.`,
  SENDING: (dataName = 'dane') => `Wysłano ${dataName}.`,
};

export default {
  ERROR,
  SUCCESS,
};
