import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import RESET_PASSWORD from 'api/graphql/mutations/user/RESET_PASSWORD';
import FormTextInput from 'components/_forms/FormTextInput/FormTextInput';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import LoaderButton from 'components/LoaderButton/LoaderButton';
import messages from 'constants/dictionaries/messages';
import PATHS from 'constants/paths';
import useQueryParams from 'services/common/useQueryParams/useQueryParams';
import validatePassword from 'services/common/validatePassword/validatePassword';
import CONTAINERS from 'styles/constants/containers';
import AuthGrid from 'uniqueComponents/Auth/AuthGrid/AuthGrid';
import logError from 'utils/logError/logError';

type SetNewPasswordFormInput = {
  password: string;
  repeatPassword: string;
};

const PasswordResetPage = () => {
  const { token, uidb } = useParams();
  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (formData: SetNewPasswordFormInput) => {
    setIsLoading(true);
    const variables = {
      token,
      uidb,
      password: formData.password,
    };
    try {
      const { data } = await resetPasswordMutation({ variables });
      if (data.confirmResetPassword.ok) {
        enqueueSnackbar(messages.SUCCESS.SAVING('hasło'), { variant: 'success' });
        navigate((queryParams?.redirect_url as string) || PATHS.LOGIN, { replace: true });
      } else {
        enqueueSnackbar(messages.ERROR.SAVING('nowego hasła'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(messages.ERROR.SAVING('nowego hasła'), { variant: 'error' });
      logError(error as Error, 'setting new password');
    } finally {
      setIsLoading(false);
    }
  };

  const { control, handleSubmit, watch } = useForm<SetNewPasswordFormInput>();
  const passwordValue = watch('password');

  return (
    <AuthGrid title='Ustaw nowe hasło'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper size={CONTAINERS.FORM_S}>
          <FormTextInput
            control={control}
            inputProps={{ type: 'password' }}
            label='Hasło'
            name='password'
            required
            rules={{
              validate: {
                match: validatePassword,
              },
            }}
          />
          <FormTextInput
            control={control}
            inputProps={{ type: 'password' }}
            label='Powtórz hasło'
            name='repeatPassword'
            required
            rules={{
              validate: {
                match: (value: string) => (value === passwordValue ? true : 'Hasła nie pasują do siebie!'),
              },
            }}
          />
          <LoaderButton isLoading={isLoading}>
            <Button variant='contained' color='primary' size='large' type='submit'>
              Wyślij
            </Button>
          </LoaderButton>
        </FormWrapper>
      </form>
    </AuthGrid>
  );
};

export default PasswordResetPage;
