import { useEffect } from 'react';

import { useQuery } from 'react-query';
import { useOutletContext } from 'react-router';
import { useParams } from 'react-router-dom';

import teacherApi from 'api/rest/teacher/teacher';
import QUERY from 'constants/queryKeys';
import { TeacherLayoutOutletContext } from 'root/TeacherLayout/TeacherLayout';
import resolvePolishNumeralsFactory from 'services/common/resolvePolishNumeralsFactory/resolvePolishNumeralsFactory';
import useClassroomFiltersStore from 'storages/teacher/classroomFiltersStorage';

const resolveChipLabel = resolvePolishNumeralsFactory('Oznaczone zadanie', 'Oznaczone zadania', 'Oznaczonych zadań');

const useClassPage = () => {
  const { subject, since, until } = useClassroomFiltersStore();
  const { setClassroomData } = useOutletContext() as TeacherLayoutOutletContext;

  const { classroomId } = useParams() as { classroomId: string };
  const { data: classroomData, isLoading: isClassroomDataLoading } = useQuery(
    [QUERY.GET_CLASSROOM, classroomId],
    teacherApi.getClassroom(classroomId),
  );
  const { data: studentsData, isLoading: isStudentsDataLoading } = useQuery(
    [QUERY.GET_STUDENTS_FOR_CLASS, classroomId, subject, since, until],
    teacherApi.getStudents(classroomId, { subject, since, until }),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (classroomData) setClassroomData(classroomData);
  }, [classroomData]);

  return { classroomData, studentsData, isLoading: isClassroomDataLoading || isStudentsDataLoading };
};

export { useClassPage, resolveChipLabel };
