import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { CircularProgress, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as MrSquareMessageIcon } from 'assets/icons/general/mr_square_message.svg';
import PageTitle from 'components/PageTitle/PageTitle';
import SubjectIcon from 'components/SubjectIcon/SubjectIcon';
import PATHS from 'constants/paths';
import FullScreen from 'containers/FullScreen/FullScreen';
import GET_SUBJECTS from 'api/graphql/queries/subjects/GET_SUBJECTS';
import useDidUpdateEffect from 'services/common/useDidUpdateEffect/useDidUpdateEffect';
import useExercisesStore from 'storages/exercises/exercises';
import useSheetsStore from 'storages/sheets/sheets';
import useSubjectStore from 'storages/subject';
import useLayoutStore from 'storages/layout';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      background: theme.palette.grey[100],
    },
  },
  wrapper: {
    flexGrow: 1,
    width: '100%',
    display: 'grid',
    justifyItems: 'center',
    gridTemplateRows: '40% 60%',
    maxWidth: '1200px',
    padding: '0 2rem',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '68px',
    [theme.breakpoints.down('sm')]: {
      margin: '40px 0',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      maxWidth: '180px',
    },
  },
  iconsWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(125px, 1fr))`,
    gridGap: '3rem',
    justifyItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(85px, 1fr))`,

      gridGap: '2rem',
    },
  },
  button: {
    background: 'none',
    border: 'none',
    width: '155px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 0 20px 0',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      cursor: 'auto',
    },
  },
  caption: {
    marginTop: '15px',
    userSelect: 'none',
    textAlign: 'center',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  mrIcon: {
    marginBottom: '30px',
    marginRight: '-50px',
    [theme.breakpoints.down('sm')]: {
      height: '30px',
      marginBottom: '10px',
      marginRight: '-20px',
    },
  },
}));

const SubjectSelect = () => {
  const { logEvent } = useUserLogContext();
  const { data } = useQuery(GET_SUBJECTS);
  const { from } = useLocation();
  const { setIsHeaderVisible } = useLayoutStore();
  const { subject, setSubject } = useSubjectStore();
  const sheetsStore = useSheetsStore();
  const exercisesStore = useExercisesStore();
  const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    setIsHeaderVisible(false);
    return () => setIsHeaderVisible(true);
  }, []);

  useDidUpdateEffect(() => {
    const path = from || PATHS.ROOT;
    setRedirect(path);
  }, [subject]);

  const onSubjectSelect = newSubject => {
    logEvent('select-subject-click', { selectedSubject: newSubject.name });
    const sameSubject = newSubject.id === subject?.id;
    if (sameSubject) {
      setRedirect(PATHS.ROOT);
    } else {
      setSubject(newSubject);
      sheetsStore.reset();
      exercisesStore.setExercisesAfterSubjectSelect(newSubject);
    }
  };

  const classes = useStyles();
  if (redirect) navigate(redirect);
  return (
    <FullScreen>
      <PageTitle>Wybierz przedmiot</PageTitle>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <MrSquareMessageIcon className={classes.mrIcon} />
          <Typography align='center' className={classes.title} variant='h1'>
            Wybierz przedmiot
          </Typography>
        </div>
        <div className={classes.iconsWrapper}>
          {data?.subjects ? (
            data.subjects.map(sub => (
              <button key={sub.id} className={classes.button} disabled={!sub.active} onClick={() => onSubjectSelect(sub)} type='button'>
                <SubjectIcon iconName={sub.subjectCode} muted={!sub.active} size={downSm ? 'm' : 'xl'} />
                <Typography className={classes.caption}>{sub.name}</Typography>
              </button>
            ))
          ) : (
            <CircularProgress size={45} />
          )}
        </div>
      </div>
    </FullScreen>
  );
};

export default SubjectSelect;
