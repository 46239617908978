import React from 'react';

import PageTitle from 'components/PageTitle/PageTitle';
import ExerciseDrawProvider from 'context/ExerciseDrawContext';
import useSubjectStore from 'storages/subject';

import SheetsContent from './SheetsContent';

const Sheets = () => {
  const { subject } = useSubjectStore();
  return (
    <ExerciseDrawProvider>
      <PageTitle>{`Arkusze | ${subject?.name || 'Przedmioty'}`}</PageTitle>
      <SheetsContent />
    </ExerciseDrawProvider>
  );
};

export default Sheets;
