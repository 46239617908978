import { makeStyles } from '@material-ui/core/styles';

import CONTAINERS from 'styles/constants/containers';
import LOGO from 'styles/constants/logo';

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: CONTAINERS.FORM_L,
    width: '100%',
    backgroundColor: theme.palette.grey[100],
  },
  container: {
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(10, 15),
    justifyItems: 'stretch',

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(6, 6),
    },

    '@media(max-width: 330px)': {
      padding: theme.spacing(6, 2),
    },
  },
  logo: {
    width: LOGO.LOGO_S,
    fill: theme.palette.text.primary,
    justifySelf: 'center',
  },
  title: {
    textAlign: 'center',
  },
}));

export default useStyles;
