import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    fontWeight: 700,
    display: 'flex',
  },

  exercisePathButtonWrapper: {
    flexGrow: 1,
  },

  exercisePathButton: {
    paddingLeft: '0',
  },

  label: {
    fontWeight: 700,
    fontSize: '0.75rem',
  },
}));

export default useStyles;
