import { makeStyles } from '@material-ui/core/styles';

const workingAreaHeight = 480;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '185px 680px',
    height: `${workingAreaHeight}px`,
    gap: theme.spacing(1),
  },
  left: {
    overflow: 'scroll',
    height: `${workingAreaHeight}px`,
    paddingRight: theme.spacing(1),
  },
  right: {
    overflow: 'scroll',
    height: `${workingAreaHeight}px`,
    padding: theme.spacing(1),
  },
  emptyPreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preview: {
    maxWidth: '100%',
  },
  exercisesList: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  fallbackText: {
    width: '100%',
  },
  addButton: {
    maxWidth: '100px',
  },
  removeButton: {
    color: theme.palette.custom.red,
  },
  header: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
}));

export default useStyles;
