import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const basePadding = 8;
const padding = {
  md: basePadding * 0.75,
  sm: basePadding * 0.25,
};

const useStyles = makeStyles(theme => ({
  container: {
    margin: '0 auto',
    width: '100%',
    maxWidth: ({ width }) => `calc(${theme.container[width]} + ${theme.legacySpacing(basePadding * 2)})`,
    padding: theme.spacing(0, basePadding),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, padding.md),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, padding.sm),
    },
  },
}));

function Container({ children, width, className }) {
  const classes = useStyles({ width });
  return <div className={clsx(classes.container, className)}>{children}</div>;
}

Container.defaultProps = {
  width: 'main',
};

export default Container;
