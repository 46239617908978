import { makeStyles } from '@material-ui/core/styles';

type StylesProps = {
  hasTitle: boolean;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '320px',
  },
  header: {
    padding: ({ hasTitle }: StylesProps) => (hasTitle ? theme.spacing(3) : theme.spacing(3, 3, 0, 3)),
    marginBottom: ({ hasTitle }: StylesProps) => theme.spacing(hasTitle ? 6 : 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    borderBottom: ({ hasTitle }: StylesProps) => (hasTitle ? '1px solid rgba(0, 0, 0, 0.12)' : 'none'),
    background: theme.palette.grey[100],
  },
  button: {
    color: theme.palette.primary.main,
    justifySelf: 'flex-end',
  },
}));

export default useStyles;
