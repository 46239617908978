import React, { useEffect, useState, useRef } from 'react';

import { useUserDataContext } from 'context/UserDataContext';

type UseIntersectParams = {
  root?: Document | Element | null;
  rootMargin?: string;
  threshold?: number;
};

type UseIntersectReturn = [
  React.Dispatch<React.SetStateAction<null>>,
  { entry: IntersectionObserverEntry | Record<string, never>; observer: React.MutableRefObject<IntersectionObserver | null> },
];

const useIntersect = ({ root = null, rootMargin, threshold = 0 }: UseIntersectParams): UseIntersectReturn => {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);
  // @ts-ignore
  const { intersectionObserverSupport } = useUserDataContext();

  const observer = useRef<IntersectionObserver | null>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (intersectionObserverSupport) {
      if (observer.current) observer.current.disconnect();
      observer.current = new window.IntersectionObserver(([newEntry]) => updateEntry(newEntry), { root, rootMargin, threshold });
      const { current: currentObserver } = observer;
      if (node) currentObserver.observe(node);
      return () => currentObserver.disconnect();
    }
  }, [node, root, rootMargin, threshold]);

  return [setNode, { entry, observer }];
};

export default useIntersect;

/** * Usage example:
 const SectionWithObserver = ({ children, ...rest }) => {
  const [ref, { entry }] = useIntersect({ rootMargin: '-25% 0px -70% 0px' });

  const handleIntersect = e => {
    console.log(e)
  }

  useEffect(() => {
    if (entry) handleIntersect(entry);
  }, [entry, handleIntersect]);

  return (
    <section ref={ref} {...rest}>
      {children}
    </section>
  );
};
 ** */
