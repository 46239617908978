import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ExercisesIcon } from 'assets/icons/general/exercises.svg';
import { ReactComponent as SheetsIcon } from 'assets/icons/general/sheets.svg';
import { ReactComponent as MyProgressIcon } from 'assets/icons/general/my_progress.svg';
import SimpleStatistics from 'components/SimpleStatistics/SimpleStatistics';
import PATHS from 'constants/paths';
import FullScreen from 'containers/FullScreen/FullScreen';
import useChosenExercisesStore from 'storages/chosenExercises/chosenExercises';
import useExercisesStore from 'storages/exercises/exercises';
import useSheetsStore from 'storages/sheets/sheets';
import useSubjectStore from 'storages/subject';
import SubjectSelectionTile from 'uniqueComponents/Dashboard/SubjectSelectionTile/SubjectSelectionTile';
import DashboardCard from 'uniqueComponents/Dashboard/DashboardCard/DashboardCard';
import { useQuery } from 'react-query';
import QUERY from 'constants/queryKeys';
import usersApi from 'api/rest/users/users';
import { useAuthContext } from 'context/AuthContext';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    maxWidth: '950px',
    padding: theme.legacySpacing(0, 6),
    width: '100%',
    gridGap: '50px',
    alignItems: 'stretch',
    paddingTop: '2rem',

    '@media(max-width: 740px)': {
      gridTemplateColumns: '1fr',
      gridGap: '25px',
    },
  },
  rowCell: {
    display: 'grid',
    gridGap: '50px',
    '@media(max-width: 740px)': {
      gridGap: '25px',
    },
  },
  icon: {
    height: '40px',
    minWidth: '30px',
    marginRight: theme.legacySpacing(3),
    '& path, rect, circle': {
      fill: theme.palette.primary.main,
    },
    '@media(max-width: 740px)': {
      height: '30px',
      minWidth: 'unset',
    },
  },
  disabled: {
    '& path, rect, circle': {
      fill: theme.palette.text.disabled,
    },
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chosenExercisesWrapper: {
    position: 'relative',
    paddingTop: '5px',
    paddingRight: '5px',
  },
  chosenExercisesIcon: {
    background: theme.palette.background.default,
    position: 'relative',
    zIndex: 1,
  },
  chosenExercisesIconShadow: {
    top: '0',
    right: '0',
    position: 'absolute',
  },
  chosenExercisesCounterDisabled: {
    background: theme.palette.grey[500],
  },
}));

const DashboardContent = () => {
  const navigate = useNavigate();
  const { subject } = useSubjectStore();
  const sheetsStore = useSheetsStore();
  const exercisesStore = useExercisesStore();
  const chosenExercisesStore = useChosenExercisesStore();
  const { isTeacher } = useAuthContext();

  const { data } = useQuery(QUERY.GET_ME, usersApi.getMe());
  const hasPremiumAccess = useMemo(() => data?.hasPremiumAccess, [data]);

  const getChosenExercisesCount = () => {
    if (!subject) return 0;
    if (!chosenExercisesStore?.chosenExercises[subject.subjectCode]) return 0;
    return chosenExercisesStore?.chosenExercises[subject.subjectCode].length;
  };

  const classes = useStyles({ chosenExercisesCount: getChosenExercisesCount() });
  if (!subject) {
    sheetsStore.reset();
    exercisesStore.reset();
    return navigate(PATHS.SUBJECT_SELECT);
  }
  return (
    <FullScreen>
      <div className={classes.main}>
        <SubjectSelectionTile />
        <SimpleStatistics />
        <div className={classes.rowCell}>
          <DashboardCard ariaLabel='Zadania' to={PATHS.EXERCISES}>
            <div className={classes.contentWrapper}>
              <ExercisesIcon className={classes.icon} />
              <Typography variant='h2'>Zadania</Typography>
            </div>
          </DashboardCard>
          <DashboardCard
            additionalText={!hasPremiumAccess ? 'Dostępne w Planie Premium' : null}
            ariaLabel='Arkusze'
            disabled={!hasPremiumAccess}
            to={PATHS.SHEETS}
          >
            <div className={classes.contentWrapper}>
              <SheetsIcon className={clsx(classes.icon, !hasPremiumAccess && classes.disabled)} />
              <Typography variant='h2'>Arkusze</Typography>
            </div>
          </DashboardCard>
        </div>
        {isTeacher ? (
          <DashboardCard
            additionalText={!hasPremiumAccess && 'Dostępne w Planie Premium'}
            ariaLabel='panel nauczyciela'
            disabled={!hasPremiumAccess}
            to={PATHS.TEACHER_DASHBOARD}
          >
            <div className={classes.contentWrapper}>
              <MyProgressIcon className={clsx(classes.icon, !hasPremiumAccess && classes.disabled)} />
              <Typography variant='h2'>Panel nauczyciela</Typography>
            </div>
          </DashboardCard>
        ) : (
          <DashboardCard additionalText='Jeszcze nad tym pracujemy...' ariaLabel='moje postępy' disabled>
            <div className={classes.contentWrapper}>
              <MyProgressIcon className={clsx(classes.icon, classes.disabled)} />
              <Typography variant='h2'>Moje postępy</Typography>
            </div>
          </DashboardCard>
        )}

        <DashboardCard
          additionalText={!hasPremiumAccess ? 'Dostępne w Planie Premium' : 'Funkcjonalność w fazie tesowej'}
          ariaLabel='Zestawy zadań'
          disabled={!hasPremiumAccess}
          to={PATHS.EXERCISES_SETS}
        >
          <div className={classes.contentWrapper}>
            <div className={classes.chosenExercisesWrapper}>
              <ExercisesIcon className={clsx(classes.icon, classes.chosenExercisesIcon, !hasPremiumAccess && classes.disabled)} />
              <ExercisesIcon className={clsx(classes.icon, classes.chosenExercisesIconShadow, !hasPremiumAccess && classes.disabled)} />
            </div>
            <Typography variant='h2'>Zestawy zadań</Typography>
          </div>
        </DashboardCard>
      </div>
    </FullScreen>
  );
};

export default DashboardContent;
