import React from 'react';

import { useQuery } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';

import usersApi from 'api/rest/users/users';
import PATHS from 'constants/paths';
import QUERY from 'constants/queryKeys';

const usePremiumAccessControl = () => {
  const navigate = useNavigate();
  const { data } = useQuery(QUERY.GET_ME, usersApi.getMe());

  if (data && !data.hasPremiumAccess) navigate(PATHS.ROOT);
};

export default usePremiumAccessControl;
