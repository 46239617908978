import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { uniqueId, isString } from 'lodash';
import LZString from 'lz-string';
import { useSnackbar } from 'notistack';

import { ReactComponent as AddWorkspaceIcon } from 'assets/icons/drawing/add-workspace.svg';
import { ReactComponent as RemoveWorkspaceIcon } from 'assets/icons/drawing/remove-workspace.svg';
import DrawSettings from 'components/DrawSettings/DrawSettings';
import CanvasWorkspace from 'components/CanvasWorkspace/CanvasWorkspace';
import { a4RatioHorizontal } from 'constants/dictionaries/common';
import encoding from 'constants/dictionaries/encoding';
import messages from 'constants/dictionaries/messages';
import { useConfirmationModalContext } from 'context/ConfirmationModalContext';
import { useExerciseDrawContext } from 'context/ExerciseDrawContext';
import GET_EXERCISE_DRAW from 'api/graphql/queries/exercises/GET_EXERCISE_DRAW';
import useDebounce from 'services/common/useDebounce/useDebounce';
import logError from 'utils/logError/logError';
import blockContextMenu from 'utils/blockContextMenu/blockContextMenu';
import ensureIsArray from 'utils/ensureIsArray/ensureIsArray';

import useCommitError from 'services/common/useCommitError/useCommitError';
import useExerciseDataSourceResolver from 'services/common/useExerciseDataSourceResolver/useExerciseDataSourceResolver';
import { ExerciseFileTypes } from 'api/rest/exercises/exercises.types';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
  },
  workspace: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadiusL,
    marginTop: theme.legacySpacing(6),
    background: theme.palette.background.default,
    position: 'relative',
    cursor: ({ hideInterface }) => (hideInterface ? 'crosshair' : 'none'),
    maxWidth: '1920px',
    margin: '0 auto',
  },
  exercise: {
    width: '50%',
    position: 'absolute',
    top: '0',
    padding: '1rem 0 1rem 1rem',
  },
  workspaceButton: {
    position: 'absolute',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    background: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 15,
    padding: '0',
    '&:focus': {
      outline: 'none',
    },
  },
  addWorkspaceButton: {
    height: '32px',
    width: '32px',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 50%)',
  },
  removeWorkspaceButton: {
    height: '28px',
    width: '28px',
    bottom: '50%',
    right: 0,
    transform: 'translate(50%, 50%)',
  },
}));

const ExerciseDraw = ({ dataSource }) => {
  const { currentExercise } = useExerciseDataSourceResolver(dataSource)();
  const shouldUseCommonStore = useMemo(() => dataSource === 'common_store', []);

  const {
    drawState: { canvasSettings },
    drawUpdaters: { clear },
  } = useExerciseDrawContext();
  const { showConfirmationModal } = useConfirmationModalContext();

  const { enqueueSnackbar } = useSnackbar();
  const commitError = useCommitError();

  const { data, error: loadingError } = useQuery(GET_EXERCISE_DRAW, {
    variables: { uuid: shouldUseCommonStore ? currentExercise.globalId : currentExercise?.id },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (loadingError) commitError(loadingError, messages.ERROR.FETCHING('rysunku'), 'ExerciseDraw: load drawing');
  }, [loadingError]);

  const workspaceWrapperRef = useRef();

  const [canvasDimension, setCanvasDimension] = useState({});
  const [workspaces, setWorkspaces] = useState([]);

  // FUNCTIONS
  const updateCanvasDimension = () => {
    if (workspaceWrapperRef.current) {
      const { offsetWidth } = workspaceWrapperRef.current;
      setCanvasDimension({ width: offsetWidth, height: offsetWidth * a4RatioHorizontal });
    }
  };

  // INITIAL EFFECTS
  useEffect(() => updateCanvasDimension, [workspaceWrapperRef.current]);

  useEffect(() => {
    if (!data) return;
    const { userData } = data.getExercise;
    const dataToLoad = userData?.drawingData;
    const drawingEncodingType = userData?.drawingEncodingType;
    const exercises = shouldUseCommonStore
      ? currentExercise.files[ExerciseFileTypes.question]
      : currentExercise.files
          .filter(({ fileType }) => fileType === 't')
          .sort((a, b) => a.fileIndex - b.fileIndex)
          .map(({ croppedPngFile }) => croppedPngFile);

    const initialWorkspaces = [];
    if (dataToLoad && Object.values(encoding).includes(drawingEncodingType)) {
      try {
        let loadedData;
        if (drawingEncodingType === encoding.UTF16) loadedData = LZString.decompressFromUTF16(dataToLoad);
        if (drawingEncodingType === encoding.URI) loadedData = LZString.decompressFromEncodedURIComponent(dataToLoad);
        const workspacesData = JSON.parse(loadedData);
        const workspacesArray = ensureIsArray(workspacesData);
        const initialWorkspacesLength = workspacesArray.length > exercises.length ? workspacesArray.length : exercises.length;
        for (let i = 0; i < initialWorkspacesLength; i += 1) {
          const exercise = exercises[i];
          const workspace = workspacesArray[i];
          initialWorkspaces.push({
            id: uniqueId(),
            imageSrc: exercise,
            initialData: isString(workspace) ? workspace : JSON.stringify(workspace),
          });
        }
      } catch (error) {
        enqueueSnackbar(messages.ERROR.FETCHING('rysunku'), { variant: 'error' });
        logError(error);
      }
    } else {
      const exerciseLength = exercises.length;
      for (let i = 0; i < exerciseLength; i += 1) {
        const exercise = exercises[i];
        initialWorkspaces.push({
          id: uniqueId(),
          imageSrc: exercise,
          initialData: undefined,
        });
      }
    }
    setWorkspaces(initialWorkspaces);
  }, [data]);

  // REDRAW ON RESIZE
  const [debouncedScreenWidth, setDebouncedScreenWidth] = useState(null);
  const debouncedResize = useDebounce(debouncedScreenWidth, 200);
  const onResize = ({ target }) => setDebouncedScreenWidth(target.innerWidth);
  useEffect(updateCanvasDimension, [debouncedResize, workspaces]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  // HANDLERS
  const addWorkspace = () => {
    const newWorkspaces = {
      id: uniqueId(),
      imageSrc: undefined,
      initialData: undefined,
    };
    setWorkspaces(prevWorkspaces => [...prevWorkspaces, newWorkspaces]);
  };

  const removeOrClearWorkspace = async (index, imageProvided) => {
    // TODO: alerts
    if (imageProvided) {
      const decision = await showConfirmationModal({
        title: 'Czy chcesz wyczyścić ten obszar roboczy?',
        body: 'W ten sposób usuniesz wszytskie zmiany z tego obszaru roboczego. Czy chcesz kontynuować?',
      });
      if (decision) clear(index);
    } else {
      const decision = await showConfirmationModal({
        title: 'Czy chcesz usunąć ten obszar roboczy?',
        body: 'W ten sposób usuniesz wszytskie zmiany wraz z tym obszarem roboczym. Czy chcesz kontynuować?',
      });
      if (decision) {
        setWorkspaces(prevWorkspaces => {
          const newWorkspaces = [...prevWorkspaces];
          newWorkspaces.splice(index, 1);
          return newWorkspaces;
        });
      }
    }
  };

  const answers = useMemo(() => {
    if (shouldUseCommonStore) return currentExercise.files[ExerciseFileTypes.answer];
    return currentExercise.files
      .filter(({ fileType }) => fileType === 'o')
      .sort((a, b) => a.fileIndex - b.fileIndex)
      .map(({ croppedPngFile }) => croppedPngFile);
  }, [currentExercise, shouldUseCommonStore]);

  const classes = useStyles({ hideInterface: canvasSettings.hideInterface });

  return (
    <div className={classes.wrapper}>
      <DrawSettings answers={answers} workspaces={workspaces} />
      {workspaces.map(({ imageSrc, id, initialData }, index) => (
        <div
          key={id}
          ref={index === 0 ? workspaceWrapperRef : undefined}
          className={classes.workspace}
          onContextMenuCapture={blockContextMenu}
        >
          <CanvasWorkspace canvasDimension={canvasDimension} index={index} initialData={initialData} />
          {imageSrc && <img alt='' className={classes.exercise} src={imageSrc} />}
          {index === workspaces.length - 1 && (
            <button className={clsx(classes.workspaceButton, classes.addWorkspaceButton)} onClick={addWorkspace} type='button'>
              <AddWorkspaceIcon />
            </button>
          )}
          <button
            className={clsx(classes.workspaceButton, classes.removeWorkspaceButton)}
            onClick={() => removeOrClearWorkspace(index, !!imageSrc)}
            type='button'
          >
            <RemoveWorkspaceIcon />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ExerciseDraw;
