import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  button: {
    zIndex: '201',
    backgroundColor: theme.palette.grey[100],
    borderRadius: '100%',
    border: 'none',
    position: ({ position }) => position,
    top: '1rem',
    height: '2rem',
    width: '2rem',
    float: 'right',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    cursor: 'pointer',
    opacity: 0.65,
    '&:focus': {
      outline: 'none',
    },
  },
}));

function FloatingButton({ children, position, className, ...rest }) {
  const styles = useStyles({ position });
  return (
    <button className={clsx(styles.button, className)} type='button' {...rest}>
      {children}
    </button>
  );
}

FloatingButton.defaultProps = {
  position: 'sticky',
  className: null,
};

export default FloatingButton;
