import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(6, 0),
    gridGap: theme.spacing(8),
    display: 'grid',
  },
  sliderRow: {
    display: 'grid',
    padding: theme.spacing(4, 2, 0),
    gridGap: theme.spacing(4),
    gridTemplateColumns: '1fr 60px',
  },
  sliderLabel: {
    justifySelf: 'end',
  },
  switchRoot: {
    alignItems: 'flex-start',
    margin: 0,
  },
}));

export default useStyles;
