import { SheetsCoreStore } from './sheets.types';

const sheetsInitialStorage: SheetsCoreStore = {
  exercises: null,
  exerciseCursor: 0,
  currentExerciseShape: { t: 0, o: 0, p: 0, a: 0 },
  currentExercise: null,
  fileType: 't', // 't' OR 'o' OR 'p'
  exercisesCount: null,
  curriculum: [],
  sheets: null,
  year: null,
  month: null,
  allExercisesCompleted: false,
  mark: null,
};

export default sheetsInitialStorage;
