import React from 'react';

import ErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';

import useStyles from './LayoutUnauthorized.styles';

type Props = {
  children: React.ReactElement;
};

const LayoutUnauthorized: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <ErrorBoundary>
      <div className={classes.wrapper}>{children}</div>
    </ErrorBoundary>
  );
};

export default LayoutUnauthorized;
