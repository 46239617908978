import React from 'react';

import ExerciseDrawProvider from 'context/ExerciseDrawContext';

import ExercisesContent from './ExercisesContent';

const Exercises = () => (
  <ExerciseDrawProvider>
    <ExercisesContent />
  </ExerciseDrawProvider>
);

export default Exercises;
