import { useCallback, useEffect, useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';

import { useGlobalDialogContext } from 'context/GlobalDialogContext';
import GET_EXERCISE from 'api/graphql/queries/exercises/GET_EXERCISE';
import useUserPreferencesStore from 'storages/userPreferences';

import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import { useAuthContext } from 'context/AuthContext';

const useNavigation = () => {
  const { isStudent } = useAuthContext();
  const { logEvent } = useUserLogContext();
  const { useDataStore, isSheet, feature } = useFeatureChecker();
  const { hideMarking } = useUserPreferencesStore();

  const {
    fileType,
    setFileType,
    currentExerciseShape,
    currentExercise,
    exercisesCount,
    exercises,
    exerciseCursor,
    fetched,
    setExerciseCursor,
    allExercisesCompleted,
    mark,
  } = useDataStore();
  const { addToGlobalDialogQueue, GLOBAL_DIALOG } = useGlobalDialogContext();
  const showModalMark = () =>
    addToGlobalDialogQueue({ type: GLOBAL_DIALOG.MARK_EXERCISE, props: { exerciseToMark: currentExercise?.id, withNext: true } });
  const [getExerciseInfo, { data }] = useLazyQuery(GET_EXERCISE);

  useEffect(() => {
    if (exercises) {
      const uuid = exercises[exerciseCursor]?.id;
      if (uuid) getExerciseInfo({ variables: { uuid } });
    }
  }, [exerciseCursor, exercises]);

  const buttonsState = useMemo(
    () => ({
      previous: exerciseCursor || allExercisesCompleted,
      next: fetched && currentExercise && exerciseCursor !== exercisesCount - 1,
      answer: exerciseCursor !== exercisesCount && !allExercisesCompleted && fetched && currentExercise,
      hint: currentExerciseShape && currentExerciseShape.p > 0 && !allExercisesCompleted,
      question: !!exercises?.length,
    }),
    [exerciseCursor, fileType, exercisesCount, currentExerciseShape, allExercisesCompleted, fetched],
  );

  const currentName = useMemo(() => data?.getExercise?.importFileIdentifier || '', [data?.getExercise?.importFileIdentifier]);
  const eventData = useMemo(() => ({ feature, exercise: currentExercise?.index }), [feature, currentExercise]);

  const previous = () => {
    logEvent('previous-exercise-click', eventData);
    setExerciseCursor('previous');
  };

  const next = () => {
    logEvent('next-exercise-click', eventData);
    setExerciseCursor('next');
  };

  const shouldShowModal = e => {
    if (mark || e?.ctrlKey) return false;
    if (isSheet && hideMarking) return false;
    return true;
  };

  const nextWithMarking = useCallback(
    e => {
      const modal = shouldShowModal(e);
      if (modal) showModalMark();
      if (!isStudent || !modal) next();
      // for isStudent next is handled in MarkExercise component
    },
    [mark, currentExercise?.id, hideMarking],
  );

  const fileTypeChange = useCallback(
    newFileType => () => {
      if (newFileType === 'p') logEvent('hint-click', eventData);
      if (newFileType === 'o') logEvent('answer-click', eventData);
      if (newFileType === 't') logEvent('content-click', eventData);
      setFileType(newFileType);
    },
    [],
  );

  return {
    previous,
    next,
    nextWithMarking,
    buttonsState,
    fileTypeChange,
    currentName,
  };
};

export default useNavigation;
