import React from 'react';

import { InputProps } from '@material-ui/core';
import type { Control, ValidationRule } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import useRulesProp from 'components/_forms/useRulesProp/useRulesProp';
import TextInput from 'components/_inputs/TextInput/TextInput';

type Props = {
  label: string;
  name: string;
  control: Control<any>;
  helperText?: string;
  required?: boolean;
  defaultValue?: string;
  customError?: string;
  rules?: ValidationRule<any>;
  inputProps?: InputProps;
};

const FormTextInput: React.FC<Props> = ({
  control,
  label,
  helperText,
  name,
  defaultValue = '',
  inputProps = {},
  required = false,
  customError,
  rules,
}) => {
  const { rulesProp } = useRulesProp({ required, rules });

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => (
        // @ts-ignore
        <TextInput error={customError || fieldState?.error?.message} helperText={helperText} label={label} {...inputProps} {...field} />
      )}
      rules={rulesProp}
    />
  );
};

export default FormTextInput;
