import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridGap: theme.spacing(8),
    marginTop: theme.spacing(12),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
}));

export default useStyles;
