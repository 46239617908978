import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import PageLoader from 'components/PageLoader/PageLoader';
import PrivateRouteProviders from 'context/PrivateRouteProviders';
import useAuthRouterState from 'root/_hooks/useAuthRouterState';
import LayoutAuthorized from 'root/LayoutAuthorized/LayoutAuthorized';
import PrivateRouteEvents from 'root/PrivateRouteEvents/PrivateRouteEvents';
import useRedirectsStore from 'storages/redirects/useRedirects';
import { AuthRouterState } from 'types/AuthRouterState';

const PrivateRoute: React.FC = () => {
  const { redirectOnLogout } = useRedirectsStore();
  const { state, from } = useAuthRouterState();

  switch (state) {
    case AuthRouterState.LOADING:
      return <PageLoader />;
    case AuthRouterState.UNAUTHORIZED:
      return <Navigate replace state={{ from }} to={redirectOnLogout} />;
    case AuthRouterState.AUTHORIZED:
    default:
      return (
        <PrivateRouteProviders>
          <PrivateRouteEvents>
            <LayoutAuthorized>
              <Outlet />
            </LayoutAuthorized>
          </PrivateRouteEvents>
        </PrivateRouteProviders>
      );
  }
};

export default PrivateRoute;
