import React from 'react';

import { Button } from '@material-ui/core';
import { SubmitHandler, useForm } from 'react-hook-form';

import { SchoolUserDataFormInput } from 'api/rest/users/users.types';
import FormCheckboxInput from 'components/_forms/FormCheckboxInput/FormCheckboxInput';
import FormTextInput from 'components/_forms/FormTextInput/FormTextInput';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import LoaderButton from 'components/LoaderButton/LoaderButton';
import useUpdateMeMutation from 'hooks/apiCalls/useUpdateMeMutation/useUpdateMeMutation';
import useUserConfig from 'hooks/useUserConfig/useUserConfig';
import emailRegex from 'utils/regex/email';

import useStyles from './SchoolUserDataForm.styles';

type Props = {
  defaultValues: SchoolUserDataFormInput;
};

const SchoolUserDataForm: React.FC<Props> = ({ defaultValues }) => {
  const { control, handleSubmit, formState, reset } = useForm<SchoolUserDataFormInput>({
    defaultValues,
  });
  const {
    permissions: [canUseSecondaryEmail],
  } = useUserConfig(['useSecondaryEmail']);

  const { updateMeMutation } = useUpdateMeMutation({ onSuccess: newValues => reset(newValues as SchoolUserDataFormInput) });

  const onSubmit: SubmitHandler<SchoolUserDataFormInput> = input => {
    updateMeMutation.mutate(input);
  };

  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <FormTextInput
          label='Email do odzyskania hasła'
          control={control}
          rules={{ pattern: { value: emailRegex, message: 'To nie jest poprawny e-mail!' } }}
          inputProps={{ type: 'email', disabled: !canUseSecondaryEmail }}
          name='replyEmail'
          helperText='Ze względu na politykę prywatności nie ma możliwości ustawienia adresu email'
        />

        <FormCheckboxInput
          inputProps={{ disabled: !canUseSecondaryEmail }}
          control={control}
          name='newsletter'
          label='Wyrażam zgodę na przesyłanie newslettera'
        />
        <LoaderButton isLoading={updateMeMutation.isLoading} className={classes.saveButton}>
          <Button disabled={!formState.isValid || !formState.isDirty} type='submit' size='small' color='primary' variant='contained'>
            Zapisz
          </Button>
        </LoaderButton>
      </FormWrapper>
    </form>
  );
};

export default SchoolUserDataForm;
