import { makeStyles } from '@material-ui/core';

type StylesProps = {
  disabled?: boolean;
};

// TODO - exercisesSet remove unused styles
const useStyles = makeStyles(theme => ({
  wrapper: {
    // background: ({ active }: StylesProps) => (active ? theme.palette.grey[300] : theme.palette.grey[200]),
    background: theme.palette.grey[200],
    color: ({ disabled }: StylesProps) => (disabled ? theme.palette.grey[400] : theme.palette.primary.main),
    borderRadius: '100px',
    fontSize: '.875rem',
    overflow: 'hidden',
    position: 'relative',
    padding: '0px',
  },
  label: {
    padding: '0 34px 0 14px',
    minHeight: '26px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    // [theme.breakpoints.down('sm')]: {
    // display: ({ minimal }: StylesProps) => (minimal ? 'none' : 'flex'),
    // },
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: '3px',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    width: '26px',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },

    // [theme.breakpoints.down('sm')]: {
    //   position: ({ minimal }: StylesProps) => (minimal ? 'static' : 'absolute'),
    // },
  },
  addNewSetElement: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  addNewSetElementIcon: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
