import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { Dialog, FormControl, Grid, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import preval from 'preval.macro';

import DialogGrid from 'containers/DialogGrid/DialogGrid';
import { useAuthContext } from 'context/AuthContext';
import GET_EXERCISE_NAME from 'api/graphql/queries/exercises/GET_EXERCISE_NAME';
import parseValuesToSelect from 'services/common/parseValuesToSelect/parseValuesToSelect';
import useExercisesStore from 'storages/exercises/exercises';
import useSheetsStore from 'storages/sheets/sheets';
import useSubjectStore from 'storages/subject';

import hasPremiumAccess from 'services/common/hasPremiumAccess/hasPremiumAccess';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';

const useStyles = makeStyles(theme => ({
  button: {
    height: '45px',
    width: '40px',
    border: 'none',
    background: 'url("report-issue-button.svg")',
    transition: '.25s ease-in-out',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '0px',
    right: '0px',
    zIndex: 299,
    '&:hover': { width: '45px' },
    [theme.breakpoints.down('sm')]: {
      bottom: '53px',
      width: '40px',
    },
  },
  centered: {
    width: '100%',
    maxWidth: '480px',
    margin: '0 auto',
  },
  bold: {
    color: theme.palette.primary.main,
  },
}));

const FORM = {
  EMAIL: 'email',
  TYPE: 'type',
  CONTENT: 'content',
};

const TYPES = [
  { value: 'Strona się nie ładuje' },
  { value: 'Błąd w zadaniu' },
  { value: 'Wolne działanie aplikacji' },
  { value: 'Problem z logowaniem' },
  { value: 'Problem z zakupem' },
  { value: 'Inne' },
];

const prepareStoreToMessage = store => {
  const removedUpdaters = Object.entries(store).reduce(
    (prev, [key, value]) => (typeof value === 'function' ? prev : { ...prev, [key]: value }),
    {},
  );

  return JSON.stringify(removedUpdaters);
};

const resolveFileType = type => {
  switch (type) {
    case 't':
      return 'treść zadania';
    case 'o':
      return 'odpowiedź';
    case 'p':
      return 'podpowiedź';
    default:
      return 'bd';
  }
};

const resolveUserAccess = val => {
  switch (val) {
    case true:
      return 'Pełny dostęp';
    case false:
      return 'Darmowy dostęp';
    default:
      return 'Brak informacji';
  }
};

const ReportProblem = () => {
  const { email, username, userIdentifier, ...me } = useAuthContext() || {};
  const exercisesStore = useExercisesStore();
  const sheetsStore = useSheetsStore();
  const { useDataStore } = useFeatureChecker();
  const store = useDataStore();
  const { subject } = useSubjectStore();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const [isOpen, setIsOpen] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [values, setValues] = useState({ [FORM.EMAIL]: userIdentifier || email || username || '', [FORM.TYPE]: '', [FORM.CONTENT]: '' });
  const update = ({ target }, id) => {
    setValues(prev => ({ ...prev, [target.id || id]: target.value }));
  };

  useEffect(() => {
    const isValid = values[FORM.CONTENT] && values[FORM.TYPE];
    setIsLocked(!isValid);
  }, [values]);

  const openModal = () => {
    setValues({ [FORM.EMAIL]: email || '', [FORM.TYPE]: '', [FORM.CONTENT]: '' });
    setIsOpen(true);
    setIsLocked(false);
  };
  const closeModal = () => setIsOpen(false);

  const onSubmit = async () => {
    setIsLocked(true);
    /* eslint-disable */
    const {
      parsedResult: { browser, platform, os },
    } = bowser.getParser(window.navigator.userAgent);
    const { href } = window.location;
    let exerciseName;

    const uuid = store?.currentExercise?.id;

    if (uuid) {
      const { data } = await client.query({ query: GET_EXERCISE_NAME, variables: { uuid } });
      const { section, subsection, importFileIdentifier, index } = data.getExercise;
      console.log(data.getExercise);
      exerciseName = `${subject.name} > ${section.name} > ${subsection.name} > ${importFileIdentifier} [${resolveFileType(
        store?.fileType,
      )}] (${index})`;
    }
    const email = values[FORM.EMAIL];
    const type = values[FORM.TYPE];
    const content = values[FORM.CONTENT];
    const message = `
*email*: ${email}
*identyfikator użytkownika*: ${userIdentifier}
*kategoria*: ${type}
*opis*: ${content}
*url*: ${href}
*środowisko*: ${os.name}, ${platform.type}, ${browser.name} ${browser.version}
*typ dostepu*: ${resolveUserAccess(hasPremiumAccess(email ? me : null))}
*build*: ${preval`module.exports = new Date().toISOString();`}.

${exerciseName ? `*zadanie*: ${exerciseName}` : ''}
`;
    const state = `===============================================================================================
EXERCISES STORE:
===============================================================================================
${JSON.stringify(prepareStoreToMessage(exercisesStore))}
 
=============================================================================================== 
SHEETS STORE:
===============================================================================================
${JSON.stringify(prepareStoreToMessage(sheetsStore))}`;
    /* eslint-enable */
    try {
      await fetch('https://0dd50wb5rl.execute-api.eu-north-1.amazonaws.com/dev/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, state, email, type, content }),
      });
      enqueueSnackbar('Błąd zgłoszony, nasz zespół zajmie się tym najszybciej jak to możliwe', { variant: 'success' });
      closeModal();
    } catch (e) {
      enqueueSnackbar('Wystąpił problem w zgłoszeniu problemu... absurdalne, prawda?', { variant: 'error' });
    }
  };

  useEffect(() => {
    const reportIssueFallback = document.querySelector('#js__report_problem');
    if (reportIssueFallback) reportIssueFallback.remove();
  }, []);

  const classes = useStyles();

  /* eslint-disable */
  return (
    <>
      <button type='button' className={classes.button} onClick={openModal} />
      <Dialog open={isOpen} onClose={closeModal}>
        <DialogGrid
          isForm
          hideCloseButton
          title='O kurczę...'
          okLabel='Wyślij'
          cancelLabel='Zamknij'
          cancelClick={closeModal}
          okClick={onSubmit}
          disabled={isLocked}
        >
          <Grid className={classes.centered} container item xs={12} spacing={4}>
            <Grid className={classes.centered} item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  Ten przycisk służy do zgłaszania blędów, jeśli wydaje Ci się, że coś nie działa jak powinno spróbuj najpierw zamknąć kartę
                  przeglądarki lub aplikację i otworzyć ją ponownie, jeśli to nie pomaga, zgłoś to nam!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Powiedz nam coś więcej o tym co się stało, abyśmy mogli się temu przyjrzeć.</Typography>
              </Grid>
            </Grid>
            {!email && (
              <Grid item xs={12}>
                <FormControl label='Podaj adres email' size='small' variant='outlined'>
                  <TextField
                    value={values[FORM.EMAIL]}
                    variant='outlined'
                    size='small'
                    label='Podaj adres email'
                    type='email'
                    onChange={update}
                    id={FORM.EMAIL}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl size='small' variant='outlined'>
                <InputLabel id='selectLabel'>Wybierz kategorię błędu</InputLabel>
                <Select
                  className={classes.centered}
                  labelId='selectLabel'
                  value={values[FORM.TYPE]}
                  onChange={e => update(e, FORM.TYPE)}
                  id={FORM.TYPE}
                  label='Wybierz kategorię błędu'
                  displayEmpty
                >
                  {parseValuesToSelect({
                    data: TYPES,
                    isMobile: false,
                    dictionary: { label: 'value', value: 'value' },
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl size='small' variant='outlined'>
                <TextField
                  value={values[FORM.CONTENT]}
                  variant='outlined'
                  size='small'
                  label='Opisz błąd'
                  multiline
                  minRows={4}
                  onChange={update}
                  id={FORM.CONTENT}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogGrid>
      </Dialog>
    </>
  );
  /* eslint-enable */
};

export default ReportProblem;
