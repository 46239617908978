import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    gridColumn: '1/3',

    [theme.breakpoints.down('sm')]: {
      gridColumn: 'auto',
    },
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(8),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
  textBlock: {
    paddingBottom: theme.spacing(1),
  },
  textBlockDoubled: {
    paddingBottom: theme.spacing(6),
  },
  noSubscriptionContent: {
    display: 'grid',
    gap: theme.spacing(6),
  },
  image: {
    display: 'block',
    margin: `${theme.spacing(4)}px 0 0 auto`,
    width: '80px',
    height: '100px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  subscribeButton: {
    justifySelf: 'flex-end',
  },
  logoutButton: {
    cursor: 'pointer',
  },
}));

export default useStyles;
