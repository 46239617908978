import React from 'react';

import { InputProps } from '@material-ui/core';
import { Control, Controller, ValidationRule } from 'react-hook-form';

import useRulesProp from 'components/_forms/useRulesProp/useRulesProp';
import { Option } from 'components/_inputs/_types/Option';
import SelectInput from 'components/_inputs/SelectInput/SelectInput';

type Props = {
  label: string;
  name: string;
  control: Control<any>;
  options: Option[];
  required?: boolean;
  rules?: ValidationRule<any>;
  inputProps?: InputProps;
};

const FormSelectInput: React.FC<Props> = ({ control, label, name, inputProps = {}, rules = {}, required = false, options }) => {
  const { rulesProp } = useRulesProp({ required, rules });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        // @ts-ignore
        <SelectInput error={fieldState.error?.message} label={label} options={options} {...inputProps} {...field} />
      )}
      rules={rulesProp}
    />
  );
};

export default FormSelectInput;
