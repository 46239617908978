import logError from 'utils/logError/logError';

export const DATA_PERSIST_KEY = {
  ASK_FOR_SUBSCRIPTION: 'askForSubscription',
  GOING_PREMIUM_INFO: 'goingPremiumInfo',
  USER_DEVICE: 'userDevice',
  IMAGE_RELOADED: 'imageReloaded',
  SHOULD_FLUSH_STORAGES: 'shouldFlushStorages',
};

const getMs = (number, unit) => {
  if (!number || !unit) {
    throw new Error('Pass unit and number');
  }
  switch (unit) {
    case 'seconds':
      return number * 1000;
    case 'minutes':
      return number * 1000 * 60;
    case 'hours':
      return number * 1000 * 60 * 60;
    case 'days':
      return number * 1000 * 60 * 60 * 24;
    case 'weeks':
      return number * 1000 * 60 * 60 * 24 * 7;
    default:
      return number;
  }
};

/**
 * API to communicate with storages
 */
export default class DataPersist {
  /**
   * @param {String} key
   * @param {String} storage
   * @param {boolean} trackTime
   */
  constructor(key, storage, trackTime = false) {
    const possibleStorage = ['sessionStorage', 'localStorage'];
    this.key = key;
    this.storage = storage;
    this.trackTime = trackTime;
    if (!possibleStorage.includes(this.storage)) {
      throw Error(`${this.storage} is not a valid storage!`);
    }
  }

  /**
   *
   * @param {Number?} number
   * @param {seconds| minutes | hours | days | weeks?} unit
   * @returns {any|undefined}
   */
  get = ({ number, unit } = {}) => {
    const { key, storage } = this;
    try {
      const serialized = window[storage].getItem(key);
      if (serialized === null) {
        return undefined;
      }
      const object = JSON.parse(serialized);
      if (this.trackTime) {
        object._stale = new Date().getTime() > object._lastUpdated + getMs(number, unit);
      }
      return object;
    } catch {
      return undefined;
    }
  };

  /**
   * @param {Object} inputData
   */
  set = inputData => {
    const { key, storage, trackTime } = this;
    const data = inputData;
    try {
      if (trackTime) data._lastUpdated = new Date().getTime();
      const serialized = JSON.stringify(data);
      window[storage].setItem(key, serialized);
    } catch (err) {
      logError(err);
    }
  };

  clear = () => {
    const { key, storage } = this;
    window[storage].removeItem(key);
  };
}
