import React from 'react';

import Header from 'components/Header/Header';
import MenuDrawer from 'components/MenuDrawer/MenuDrawer';
import NotificationsDrawer from 'components/NotificationsDrawer/NotificationsDrawer';
import ErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary';
import useLayoutStore from 'storages/layout';

type Props = {
  children: React.ReactNode;
};

const LayoutAuthorized: React.FC<Props> = ({ children }) => {
  const { isMenuVisible, isNotificationsVisible, toggleIsMenuVisible, toggleIsNotificationsVisible } = useLayoutStore();

  return (
    <ErrorBoundary>
      <div>
        <Header />
        {children}
        <NotificationsDrawer isOpen={isNotificationsVisible} onClose={toggleIsNotificationsVisible} />
        <MenuDrawer isOpen={isMenuVisible} onClose={toggleIsMenuVisible} />
      </div>
    </ErrorBoundary>
  );
};

export default LayoutAuthorized;
