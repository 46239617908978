import { HTMLString } from 'types/HTMLString';
import { Id } from 'types/Id';

export const documents = ['privacy_policy', 'terms'];

export type DocumentsType = typeof documents[number];

export type Document = {
  content: HTMLString;
  createdAt: Date | null;
  id: Id | null;
};

export type DocumentsApi = {
  getDocuments: (documentType: DocumentsType) => Promise<Document>;
};

export type DocumentsApiKeys = {
  [key in keyof DocumentsApi]: (documentType: DocumentsType) => string[];
};
