import { makeStyles } from '@material-ui/core/styles';

type StylesProps = {
  isStuck: boolean;
};

const useStyles = makeStyles(theme => ({
  fileTypeSelector: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(2),
  },
  tile: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    flexGrow: 1,
    transformOrigin: 'top right',
    transition: '.3s ease',
    justifyContent: 'space-between',
    transform: ({ isStuck }: StylesProps) => (isStuck ? 'scale(0)' : 'scale(1)'),
    opacity: ({ isStuck }: StylesProps) => (isStuck ? 0.7 : 1),
    '& p': {
      color: theme.palette.grey['800'],
      textAlign: 'center',
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  navigationButtons: {
    display: 'grid',
    gridTemplateColumns: '28px 1fr 28px',
    alignItems: 'center',
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(1),
    },
  },
  button: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: '.85rem',
    },
  },
}));

export default useStyles;
