import { useEffect } from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import usersApi from 'api/rest/users/users';
import PATHS from 'constants/paths';
import QUERY from 'constants/queryKeys';

// TODO replace it with router settings after router refactor
const useProtectedPage = () => {
  const navigate = useNavigate();

  const { data } = useQuery(QUERY.GET_ME, usersApi.getMe(), { refetchOnWindowFocus: false });

  useEffect(() => {
    if (data && !data.hasPremiumAccess) navigate(PATHS.ROOT);
  }, [data]);
};

export default useProtectedPage;
