import React, { useMemo, useState } from 'react';

import { Container } from '@material-ui/core';
import { Outlet } from 'react-router-dom';

import type { Classroom } from 'api/rest/teacher/teacher.types';
import ExerciseDetailsDrawer from 'components/ExerciseDetailsDrawer/ExerciseDetailsDrawer';
import TeacherNavigationBar from 'components/TeacherNavigationBar/TeacherNavigationBar';
import FullScreen from 'containers/FullScreen/FullScreen';

import usePremiumAccessControl from '../../services/common/usePremiumAccessControl/usePremiumAccessControl';
import useStyles from './TeacherLayout.styles';

export type TeacherLayoutOutletContext = {
  setClassroomData: (classroomData?: Classroom) => void;
};

const TeacherLayout: React.FC = () => {
  usePremiumAccessControl();
  const [classroomData, setClassroomData] = useState<Classroom | undefined>();

  const context: TeacherLayoutOutletContext = useMemo(
    () => ({
      setClassroomData,
    }),
    [setClassroomData],
  );

  const classes = useStyles();
  return (
    <FullScreen className={classes.root} justify='flex-start'>
      <TeacherNavigationBar classroomData={classroomData} />
      <Container className={classes.container}>
        <Outlet context={context} />
      </Container>
      <ExerciseDetailsDrawer />
    </FullScreen>
  );
};

export default TeacherLayout;
