import { useMemo } from 'react';

import { isNil } from 'lodash';
import { Location, useLocation } from 'react-router-dom';

import { useAuthContext } from 'context/AuthContext';
import { AuthRouterState } from 'types/AuthRouterState';

type UseAuthRouterState = () => {
  state: AuthRouterState;
  from: Location;
};

const useAuthRouterState: UseAuthRouterState = () => {
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { token } = useAuthContext();

  const state = useMemo(() => {
    if (isNil(token)) return AuthRouterState.LOADING;
    if (token === false) return AuthRouterState.UNAUTHORIZED;
    return AuthRouterState.AUTHORIZED;
  }, [location, token]);

  return { state, from: location };
};

export default useAuthRouterState;
