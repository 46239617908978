import {
  ExternalResource,
  ExternalResourceDto,
  SetRatingDtoInput,
  SetRatingDtoOutput,
  SetRatingInput,
  SetRatingOutput,
} from './externalResources.types';

type ParseExternalResourceForUI = (input: ExternalResourceDto) => ExternalResource;

type ParseRateExternalResourceForBE = (input: SetRatingInput) => SetRatingDtoInput;

type ParseRateExternalResourceForUI = (input: SetRatingDtoOutput) => SetRatingOutput;

const parseExternalResourceForUI: ParseExternalResourceForUI = data => {
  const { created_at, is_main, is_section_related, updated_at, user_rating, ...rest } = data;
  const { created_at: user_rating_created_at, updated_at: user_rating_updated_at, ...userRatingRest } = user_rating || {};
  return {
    ...rest,
    createdAt: created_at,
    isMain: is_main,
    isSectionRelated: is_section_related,
    updatedAt: updated_at,
    userRating: {
      ...userRatingRest,
      createdAt: user_rating_created_at,
      updatedAt: user_rating_updated_at,
    },
  };
};

const parseRateExternalResourceForBE: ParseRateExternalResourceForBE = data => {
  const { exerciseExternalSource, ...rest } = data;
  return {
    ...rest,
    exercise_external_source: exerciseExternalSource,
  };
};

const parseRateExternalResourceForUI: ParseRateExternalResourceForUI = data => {
  const { exercise_external_source, ...rest } = data;
  return {
    ...rest,
    exerciseExternalSource: exercise_external_source,
  };
};

export { parseExternalResourceForUI, parseRateExternalResourceForBE, parseRateExternalResourceForUI };
