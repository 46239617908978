import React, { useEffect, useMemo, useState } from 'react';

import { CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { DataFetchingState } from '../../types/DataFetchingState';
import { UrlString } from '../../types/UrlString';
import ExerciseError from '../../uniqueComponents/Exercises/ExerciseError/ExerciseError';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import ExerciseExternalResources from '../ExerciseExternalResources/ExerciseExternalResources';
import ExerciseManual from '../ExerciseManual/ExerciseManual';
import ExerciseNote from '../ExerciseNote/ExerciseNote';
import ExerciseSuccess from '../ExerciseSuccess/ExerciseSuccess';
import ImageWithLoaderWrapper from '../ImageWithLoaderWrapper/ImageWithLoaderWrapper';
import { ExerciseDisplayHook, ExerciseDisplayExampleFunction } from './ExerciseDisplay.logic';
import useStyles from './ExerciseDisplay.styles';

type Props = {
  notesRef?: any;
  filesToDisplay?: UrlString[];
  fetchingExerciseDataState: DataFetchingState;
  exercisePath?: JSX.Element;
  audioSource?: UrlString;
  manualComponent?: JSX.Element;
  emptyComponent?: JSX.Element;
  currentExerciseId?: string;
};

// TODO simplify on unify
const ExerciseDisplay: React.FC<Props> = ({
  notesRef,
  filesToDisplay,
  fetchingExerciseDataState,
  exercisePath,
  audioSource,
  manualComponent,
  emptyComponent,
  currentExerciseId,
}) => {
  const [zoomed, setZoomed] = useState(false);

  useEffect(() => {
    setZoomed(false);
  }, [filesToDisplay]);

  const onErrorRefresh = async () => {
    // TODO exercises_set refetch
    // const id = exercises[exerciseCursor]?.id;
    // if (reload) reload();
    // const exercise = await queryExercise(id);
    // updateCurrentExercise(exercise);
    // setError(false);
    // @ts-ignore
    window.location.reload(true);
  };

  const isLoading = useMemo(() => fetchingExerciseDataState === 'FETCHING', [fetchingExerciseDataState]);

  const [imageFetching, setImageFetching] = useState(false);

  useEffect(() => {
    if (isLoading) setImageFetching(true);
  }, [isLoading]);

  const onImageFetched = () => {
    setImageFetching(false);
  };

  const classes = useStyles({ isLoading });

  /* TODO exercises_set - fallbacks (all completed, no match) */

  if (isLoading) {
    return (
      <div className={clsx(classes.root, classes.centered)}>
        <div className={classes.loader}>
          <CircularProgress size={60} />
        </div>
      </div>
    );
  }

  if (fetchingExerciseDataState === 'ERROR') {
    return (
      <div className={classes.root}>
        <ExerciseError onClick={onErrorRefresh} />
      </div>
    );
  }

  if (fetchingExerciseDataState === 'EMPTY') {
    return (
      <div className={clsx(classes.root, classes.centered)}>
        {emptyComponent || (
          <Typography variant='h2' align='center'>
            Brak zadań do wyświetlenia.
          </Typography>
        )}
      </div>
    );
  }

  if (fetchingExerciseDataState === 'IDLE') {
    return (
      <div className={clsx(classes.root, classes.centered)}>
        {manualComponent || (
          <Typography variant='h2' align='center'>
            Skorzysaj z panelu po lewej aby wyświetlić zadania.
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={clsx(classes.notVisible, !imageFetching && classes.visible)}>
        {exercisePath || null}
        {audioSource && <AudioPlayer src={audioSource} />}
      </div>
      {/*  @ts-ignore  */}
      <ImageWithLoaderWrapper
        onLoad={onImageFetched}
        loading={imageFetching}
        setZoomed={setZoomed}
        urls={filesToDisplay || []}
        zoomed={zoomed}
      />
      <div className={clsx(classes.notVisible, !imageFetching && classes.visible)}>
        <ExerciseExternalResources dataSource='common_store' />
        <ExerciseNote exerciseId={currentExerciseId} notesRef={notesRef} />
      </div>
    </div>
  );
};

export default ExerciseDisplay;
