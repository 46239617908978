import React, { useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import HelpMovieSection from 'components/HelpMovieSection/HelpMovieSection';
import PageTitle from 'components/PageTitle/PageTitle';
import FullScreen from 'containers/FullScreen/FullScreen';

import { getTutorialVideos } from './HelpPage.logic';
import useStyles from './HelpPage.styles';

const HelpPage: React.FC = () => {
  const [expanded, setExpanded] = useState<string | null>(null);
  const videos = getTutorialVideos();
  const classes = useStyles();

  const generateHandler = (src: string) => () => {
    setExpanded(prevExpanded => (prevExpanded === src ? null : src));
  };

  return (
    <FullScreen justify='flex-start' className={classes.root}>
      <PageTitle>Pomoc</PageTitle>
      <Container className={classes.container}>
        {videos.map(({ src, toc, title }) => (
          <Accordion className={classes.header} key={src} expanded={expanded === src} onChange={generateHandler(src)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${title}-content`} id={`${title}-header`}>
              <Typography variant='h5'>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <HelpMovieSection expanded={expanded === src} toc={toc} videoSrc={src} title={title} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </FullScreen>
  );
};

export default HelpPage;
