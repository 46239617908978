import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    gap: theme.spacing(3),
  },
  filterSm: {
    maxWidth: '140px',
  },
  filterMd: {
    maxWidth: '160px',
  },
  filterLg: {
    maxWidth: '240px',
  },
}));

export default useStyles;
