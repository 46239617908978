import React from 'react';

import { Popover, Button, PopoverProps } from '@material-ui/core';
import type { ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import { Link, LinkProps } from 'react-router-dom';

import { UserEventKey } from 'context/UserLogContext/UserEventKey';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

import useStyles from './ButtonWithPopover.styles';

export type ButtonWithPopoverElement = {
  label: JSX.Element | string;
  action?: () => void;
  to?: string;
  buttonProps?: ButtonProps | LinkProps;
  event?: UserEventKey;
  eventData?: object;
  key?: string | number;
};

export type Props = {
  id?: string;
  buttonProps?: ButtonProps;
  children: JSX.Element | string;
  elements: ButtonWithPopoverElement[];
  dense?: boolean;
  popoverMinWidth?: number;
  anchorOrigin?: PopoverProps['anchorOrigin'];
};

const ButtonWithPopover: React.FC<Props> = ({
  buttonProps,
  children,
  elements,
  id = 'popover',
  dense = false,
  popoverMinWidth,
  anchorOrigin,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { curryLogEvent } = useUserLogContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionWrapper = (action: () => void) => () => {
    action();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const dynamicId = open ? id : undefined;

  const classes = useStyles({ dense, minWidth: popoverMinWidth });
  return (
    <>
      <Button aria-describedby={dynamicId} onClick={handleClick} {...buttonProps}>
        {children}
      </Button>
      <Popover
        id={dynamicId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'left',
          }
        }
      >
        <div className={classes.elements}>
          {elements.map(({ key, action, label, to, buttonProps: elementButtonProps = {}, event, eventData }, index) => {
            if (action) {
              const elementButtonPropsTyped = elementButtonProps as ButtonProps;
              return (
                <Button
                  size={dense ? 'small' : 'medium'}
                  // eslint-disable-next-line react/no-array-index-key
                  key={key || `${index}`}
                  onClick={curryLogEvent(actionWrapper(action), event, eventData)}
                  disableRipple={dense || elementButtonPropsTyped?.disableRipple}
                  {...elementButtonPropsTyped}
                  className={clsx(classes.button, elementButtonPropsTyped.className)}
                >
                  {label}
                </Button>
              );
            }
            if (to) {
              return (
                <Button
                  size={dense ? 'small' : 'medium'}
                  // eslint-disable-next-line react/no-array-index-key
                  key={key || `${to}-${index}`}
                  to={to}
                  onClick={curryLogEvent(handleClose, event, eventData)}
                  component={Link}
                  className={classes.button}
                  {...(elementButtonProps as any)}
                >
                  {label}
                </Button>
              );
            }
            return null;
          })}
        </div>
      </Popover>
    </>
  );
};

export default ButtonWithPopover;
