import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Picture } from 'react-responsive-picture';

import image from 'assets/decorative_pics/exerciseSuccess.png';
import imageMobile from 'assets/decorative_pics/exerciseSuccess_mobile.png';
import image2x from 'assets/decorative_pics/exercsiesSuccess@2x.png';

const useStyles = makeStyles(theme => ({
  picture: {
    width: '100%',
    maxWidth: '100%',
    position: 'sticky',
    top: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      margin: '5vh auto 0 auto',
      maxWidth: '540px',
      display: 'block',
    },
  },
}));

function ExerciseManual() {
  const { picture } = useStyles();
  const { breakpoints } = useTheme();
  return (
    <Picture
      className={picture}
      sources={[
        {
          srcSet: `${imageMobile} 1x, ${imageMobile} 2x`,
          media: `(max-width: ${breakpoints.values.md}px)`,
        },
        {
          srcSet: `${image} 1x, ${image2x} 2x`,
        },
      ]}
    />
  );
}

export default ExerciseManual;
