import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

const initSentry = () => {
  if (isProduction) {
    return Sentry.init({
      dsn: 'https://5a7d5020cbc34f8e89b975eca79a30fc@o4504777243885568.ingest.sentry.io/4504777251553280',
      integrations: [new BrowserTracing()],
    });
  }
  console.warn('Sentry not initialized');
  return null;
};

export default initSentry;
