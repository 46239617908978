import React from 'react';

import PageTitle from 'components/PageTitle/PageTitle';

import DashboardContent from './_components/DashboardContent/DashboardContent';

const DashboardPage = () => (
  <>
    <PageTitle>Moja Matura</PageTitle>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <DashboardContent />
  </>
);

export default DashboardPage;
