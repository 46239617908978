import React from 'react';

import { useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import NextIcon from '@material-ui/icons/SkipNext';
import PreviousIcon from '@material-ui/icons/SkipPrevious';

import { ExerciseFileTypes } from 'api/rest/exercises/exercises.types';
import ButtonRound from 'components/ButtonRound/ButtonRound';
import ExerciseViewTile from 'components/ExerciseViewTile/ExerciseViewTile';
import { Id } from 'types/Id';
import ExercisesName from 'uniqueComponents/Exercises/ExercisesName/ExercisesName';
import ExercisesPagination from 'uniqueComponents/Exercises/ExercisesPagination/ExercisesPagination';

import useStyles from './ExercisesNavigation.styles';

type Props = {
  isStuck?: boolean;
  exerciseName: string;
  actions: {
    fileTypeChange: (newType: ExerciseFileTypes) => () => void;
    onNext: () => void;
    onPrevious: () => void;
  };
  buttonsEnableState: {
    hint: boolean;
    previous: boolean;
    next: boolean;
    answer: boolean;
    question: boolean;
  };
  fileType: ExerciseFileTypes;
  exercisesCursor: number;
  exercisesCount: number;
  isMarked: boolean;
  currentExerciseId?: Id | null;
  dataSource?: 'common_store';
};

const ExercisesNavigation: React.FC<Props> = ({
  isStuck,
  buttonsEnableState,
  actions,
  exerciseName,
  fileType,
  exercisesCursor,
  exercisesCount,
  isMarked,
  currentExerciseId,
  dataSource,
}) => {
  const downMd = useMediaQuery('(max-width: 1080px)');

  const setAnswerType = actions.fileTypeChange(ExerciseFileTypes.answer);
  const setHintType = actions.fileTypeChange(ExerciseFileTypes.hint);
  const setQuestionType = actions.fileTypeChange(ExerciseFileTypes.question);

  const classes = useStyles({ isStuck: !!isStuck });
  return (
    <ExerciseViewTile className={classes.tile}>
      <>
        <div className={classes.top}>
          <div className={classes.navigationButtons}>
            <IconButton size='small' disabled={!buttonsEnableState.previous} onClick={actions.onPrevious}>
              <PreviousIcon fontSize='small' color={buttonsEnableState.previous ? 'primary' : 'disabled'} />
            </IconButton>
            <ExercisesPagination exerciseCursor={exercisesCursor} total={exercisesCount} />
            <IconButton size='small' disabled={!buttonsEnableState.next} onClick={actions.onNext}>
              <NextIcon fontSize='small' color={buttonsEnableState.next ? 'primary' : 'disabled'} />
            </IconButton>
          </div>
          <ExercisesName
            cacheStrategy={dataSource === 'common_store' ? 'rq' : 'apollo'}
            currentExerciseId={currentExerciseId}
            isMarked={isMarked}
            name={exerciseName}
          />
        </div>
        <nav className={classes.fileTypeSelector}>
          <ButtonRound
            size='small'
            variant={buttonsEnableState.question && fileType === ExerciseFileTypes.question ? 'contained' : undefined}
            color='primary'
            className={classes.button}
            onClick={setQuestionType}
            disabled={!buttonsEnableState.question}
          >
            Treść
          </ButtonRound>
          <ButtonRound
            size='small'
            variant={buttonsEnableState.hint && fileType === ExerciseFileTypes.hint ? 'contained' : undefined}
            color='primary'
            className={classes.button}
            disabled={!buttonsEnableState.hint}
            onClick={setHintType}
          >
            {downMd ? 'Podp.' : 'Podpowiedź'}
          </ButtonRound>
          <ButtonRound
            size='small'
            variant={buttonsEnableState.answer && fileType === ExerciseFileTypes.answer ? 'contained' : undefined}
            color='primary'
            className={classes.button}
            disabled={!buttonsEnableState.answer}
            onClick={setAnswerType}
          >
            {downMd ? 'Odp.' : 'Odpowiedź'}
          </ButtonRound>
        </nav>
      </>
    </ExerciseViewTile>
  );
};

ExercisesNavigation.defaultProps = {
  isStuck: false,
};

export default ExercisesNavigation;
