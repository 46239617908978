import React from 'react';

import { format } from 'date-fns';
import { useQuery } from 'react-query';

import teacherApi from 'api/rest/teacher/teacher';
import type { StudentMark } from 'api/rest/teacher/teacher.types';
import type { TableHeader } from 'components/ExercisesTable/ExercisesTable';
import QUERY from 'constants/queryKeys';
import generateQueryKeyFromObject from 'services/common/generateQueryKeyFromObject/generateQueryKeyFromObject';
import useComplexQuery from 'services/common/useComplexQuery/useComplexQuery';
import useClassroomFiltersStore from 'storages/teacher/classroomFiltersStorage';
import { Id } from 'types/Id';
import trimString from 'utils/trimString/trimString';

const HEADER = {
  EXERCISE_NUMBER: 'exercise__index',
  SUBJECT: 'exercise__subject__name',
  SOURCE: 'exercise__section__name,exercise__subsection__name',
  MARK: 'mark',
  DATE: 'date',
} as const;

type StudentsMarksTableHeaderKeys = keyof typeof HEADER;
export type StudentsMarksTableHeaderValues = typeof HEADER[StudentsMarksTableHeaderKeys];

const studentDetailsHeaders: TableHeader<StudentsMarksTableHeaderValues>[] = [
  { label: 'Numer zadania', id: HEADER.EXERCISE_NUMBER, sortable: true },
  { label: 'Przedmiot', id: HEADER.SUBJECT, sortable: true },
  { label: 'Źrodło', id: HEADER.SOURCE, sortable: true },
  { label: 'Oznaczenie', id: HEADER.MARK, sortable: true },
  { label: 'Data oznaczenia', id: HEADER.DATE, sortable: true, initial: true },
];

const resolveMark = (mark: string) => {
  switch (mark) {
    case 'explain':
      return 'Do wyjaśnienia';
    case 'hard':
      return 'Trudne';
    case 'easy':
    default:
      return 'Łatwe';
  }
};

const prepareSource = (str1: string | null, str2: string | null): string => {
  const maxSourceLength = 50;
  const trimmed1 = trimString(maxSourceLength / 2, str1 || '');
  const trimmed1Length = trimmed1.length > maxSourceLength / 2 ? maxSourceLength / 2 : trimmed1.length;
  const trimmed2 = trimString(maxSourceLength - trimmed1Length, str2 || '');
  return [trimmed1, trimmed2].filter(str => !!str).join(' > ');
};

const dataMapping = ({
  exerciseIndex,
  exerciseSubjectName,
  exerciseSectionName,
  exerciseSubsectionName,
  mark,
  createdAt,
}: StudentMark): React.ReactNode[] => [
  exerciseIndex,
  exerciseSubjectName,
  prepareSource(exerciseSectionName, exerciseSubsectionName),
  resolveMark(mark),
  format(createdAt, 'dd.MM.yyyy - HH:mm'),
];

const useGetStudentMarksData = (studentId: Id) => {
  const { subject, until, since } = useClassroomFiltersStore();
  const filters = { subject, until, since };

  const { queryParams, tableProps } = useComplexQuery<StudentsMarksTableHeaderValues>(10, studentDetailsHeaders);

  const { data, isLoading } = useQuery(
    generateQueryKeyFromObject({
      base: QUERY.GET_SINGLE_STUDENT,
      id: studentId,
      ...filters,
      ...queryParams.pagination,
      ...queryParams.order,
    }),
    teacherApi.getStudentMarks(studentId, {
      filters,
      ...queryParams,
    }),
    {
      keepPreviousData: true,
    },
  );
  return { data, isLoading, tableProps };
};

export { dataMapping, studentDetailsHeaders, useGetStudentMarksData };
