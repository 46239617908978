import { makeStyles } from '@material-ui/core';

type StylesProps = {
  dense: boolean;
  minWidth?: number;
};

const useStyles = makeStyles(theme => ({
  elements: {
    display: 'grid',
    padding: ({ dense }: StylesProps) => (dense ? theme.spacing(1, 2) : theme.spacing(1)),
    gap: theme.spacing(1),
    minWidth: ({ minWidth }: StylesProps) => minWidth || 'unset',
  },
  button: {
    padding: theme.spacing(1),
    justifyContent: 'flex-start',
  },
}));

export default useStyles;
