import React from 'react';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './PrintExercisesContent.styles';
import { ExerciseToPrint } from './PrintExercisesDialog';

type Props = {
  exercisesToPrint: ExerciseToPrint[] | null;
  extraSpace: number;
  onePerPage: boolean;
};

type SectionObj = { name: string } | null;
const prepareHeaderText = (section: SectionObj, subsection: SectionObj, index: number) => {
  const result = [];
  if (section?.name) result.push(section.name);
  if (subsection?.name) result.push(subsection.name);
  result.push(`${index}`);
  return result.join(' > ');
};

const PrintExercisesContent = React.forwardRef<HTMLDivElement, Props>(({ exercisesToPrint, extraSpace, onePerPage }, ref) => {
  const classes = useStyles({ extraSpace, onePerPage });
  if (!exercisesToPrint) return null;
  return (
    <div className={classes.printContent}>
      <div ref={ref} className={classes.printWrapper}>
        {exercisesToPrint.map(({ section, subsection, index, files }: ExerciseToPrint) => (
          <article className={clsx(classes.exerciseBlock, 'exerciseBlock')} key={index}>
            <Typography component='header' variant='caption' className={classes.exerciseHeader}>
              {prepareHeaderText(section, subsection, index)}
            </Typography>
            <div className={classes.exerciseImages}>
              {files.map((file: string) => (
                <img className={classes.exerciseImage} src={file} alt={file} key={file} />
              ))}
            </div>
          </article>
        ))}
      </div>
    </div>
  );
});

export default PrintExercisesContent;
