import gql from 'graphql-tag';

const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      paymentUrl
      subscriptionCreated
    }
  }
`;

export default CREATE_TRANSACTION;
