import React from 'react';

import { FormControl, InputBaseComponentProps, InputLabel, OutlinedInput } from '@material-ui/core';

import InputFooter from 'components/_inputs/InputFooter/InputFooter';

type Props = {
  label: string;
  name: string;
  fullWidth?: boolean;
  error?: string;
  multiline?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  helperText?: string;
  number?: boolean;
  value?: string;
  inputProps?: InputBaseComponentProps;
};

type InputProps = InputBaseComponentProps;

const TextInput: React.FC<Props> = props => {
  const getMergedInputProps = (): InputProps => {
    const { name, inputProps } = props;
    return {
      ...inputProps,
      'data-testid': `${name}_input`,
    };
  };

  const { label, multiline, error, helperText, fullWidth = true, readOnly, disabled, number, ...field } = props;

  return (
    <FormControl size='small' disabled={disabled} error={!!error} fullWidth={fullWidth} variant='outlined'>
      <InputLabel disabled={disabled} htmlFor={`text_input_${label}`}>
        {label}
      </InputLabel>
      <OutlinedInput
        disabled={disabled}
        id={`text_input_${label}`}
        label={label}
        multiline={multiline}
        readOnly={readOnly}
        type={number ? 'number' : 'text'}
        {...field}
        inputProps={getMergedInputProps()}
      />
      <InputFooter isError={!!error} text={error || helperText} name={field.name} />
    </FormControl>
  );
};

export default TextInput;
