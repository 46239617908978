import gql from 'graphql-tag';

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        email
        username
        accountType
        isVerified
        showTutorial
      }
      errors {
        field
        messages
      }
    }
  }
`;

export default UPDATE_USER;
