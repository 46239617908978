import { useEffect } from 'react';

import sourcesStorage from 'persistedStorages/sourcesStorage';

const SOURCES: Record<string, string> = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  LINKTREE: 'linktree',
  GOOGLE: 'google',
  ORGANIC: 'organic',
  EMAIL: 'email',
  LANDING: 'landing',
  TIKTOK: 'tiktok',
};

const refererDictionary: Record<string, string> = {
  'https://l.facebook.com/': SOURCES.FACEBOOK,
  'https://l.instagram.com/': SOURCES.INSTAGRAM,
  'https://linktr.ee/': SOURCES.LINKTREE,
  'https://www.google.com/': SOURCES.GOOGLE,
  'https://mojamatura.edu.pl/': SOURCES.LANDING,
};

const getReferrerDictionary: (referrer: string) => string = referrer => refererDictionary[referrer] || SOURCES.ORGANIC;

const useSourceDigest = () => {
  useEffect(() => {
    const oldStorage = sourcesStorage.get() || {};

    const { searchParams } = new URL(window.location.href);
    const referrer = getReferrerDictionary(document.referrer);
    const source = searchParams.get('source');
    const sourceId = searchParams.get('sourceId');

    const newStorage = {
      source: source || oldStorage.source || SOURCES.ORGANIC,
      sourceId: sourceId || oldStorage.sourceId || SOURCES.ORGANIC,
      referrer: referrer || oldStorage.referrer || SOURCES.ORGANIC,
    };

    sourcesStorage.set(newStorage);
  }, []);
};

export default useSourceDigest;
