import { useMatch } from 'react-router';

import PATHS from 'constants/paths';

const useCheckFunctionality = () => {
  const isClassroom = !!useMatch(PATHS.CLASSROOM);
  const isClassroomExercises = !!useMatch(PATHS.CLASSROOM_EXERCISES);

  return { isClassroom, isClassroomExercises };
};

export { useCheckFunctionality };
