import axios from 'axios';

import { DefaultApiResponse } from '../_types';
import { parseNotificationForUI, parseNotificationArrayForUI } from './notifications.parsers';
import { Notification, NotificationDto } from './notifications.types';

const endpoints = {
  notifications: 'notifications/notifications/',
};

type GetAllQuery = () => Promise<Notification[]>;
type GetSingleQuery = () => Promise<Notification>;
type GetAllNewQuery = () => Promise<Notification[]>;
type SetAsReadQuery = () => Promise<null>; // TODO check type

const notificationsApi = {
  getAll: (): GetAllQuery => async () => {
    const response = await axios.get<GetAllQuery, DefaultApiResponse<NotificationDto[]>>(endpoints.notifications);
    return parseNotificationArrayForUI(response.data);
  },
  getSingle:
    (id: string): GetSingleQuery =>
    async () => {
      const response = await axios.get<GetSingleQuery, DefaultApiResponse<NotificationDto>>(`${endpoints.notifications}${id}/`);
      return parseNotificationForUI(response.data);
    },
  getAllNew: (): GetAllNewQuery => async () => {
    const response = await axios.get<GetAllNewQuery, DefaultApiResponse<NotificationDto[]>>(`${endpoints.notifications}new/`);
    return parseNotificationArrayForUI(response.data);
  },
  setAsRead:
    (id: string): SetAsReadQuery =>
    async () => {
      const response = await axios.post<SetAsReadQuery, DefaultApiResponse<null>>(`${endpoints.notifications}${id}/read/`);
      return response.data; // TODO check and parse response
    },
};

export default notificationsApi;
