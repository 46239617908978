import { makeStyles } from '@material-ui/core';

type StylesProps = {
  size?: number;
  spacing?: number;
  fixedSize?: boolean;
};

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: ({ size }: StylesProps) => size || 'auto',
    width: ({ fixedSize, size }: StylesProps) => (fixedSize && size ? size : '100%'),
    margin: '0 auto',
    display: 'grid',
    gridGap: ({ spacing }: StylesProps) => theme.spacing(spacing || 4),
    marginTop: ({ spacing }: StylesProps) => theme.spacing(spacing || 4),
  },
}));

export default useStyles;
