import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  icon: {
    height: '10px',
    fill: theme.palette.primary.main,
  },
  root: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
}));

export default useStyles;
