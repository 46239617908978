import React, { FC, ReactNode } from 'react';

import IconButton from '@material-ui/core/IconButton';
import { Close as IconClose } from '@material-ui/icons';
import { SnackbarKey, useSnackbar } from 'notistack';

import useStyles from './SnackbarActionsContainer.styles';

type Props = {
  snackbarKey: SnackbarKey;
  closable?: boolean;
  children: ReactNode | ReactNode[];
};

const SnackbarActionsContainer: FC<Props> = ({ snackbarKey, closable, children }) => {
  const { closeSnackbar } = useSnackbar();

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {children}
      {closable && (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
          <IconClose className={classes.icon} />
        </IconButton>
      )}
    </div>
  );
};

export default SnackbarActionsContainer;
