import React, { useCallback, useMemo } from 'react';

import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import { isNil } from 'lodash';
import { useQuery } from 'react-query';

import exercisesSetApi from 'api/rest/exercisesSets/exercisesSet';
import { ExerciseInSetDetails, ExerciseInSetDto } from 'api/rest/exercisesSets/exercisesSet.types';
import ButtonWithPopover, { ButtonWithPopoverElement } from 'components/ButtonWithPopover/ButtonWithPopover';
import { useGlobalDialogContext } from 'context/GlobalDialogContext';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

import useStyles from './ChoosenExercisesButton.styles';
import useAddToExerciseSet from './ChosenExercisesButton.logic';

type Props = {
  disabled?: boolean;
  exerciseToAdd: Partial<ExerciseInSetDetails>;
};

const ChosenExercisesButton: React.FC<Props> = ({ exerciseToAdd, disabled }) => {
  // TODO - exercisesSet update events
  const { logEvent } = useUserLogContext();

  const plumbedExerciseToAdd: ExerciseInSetDto = useMemo(
    () => ({
      subject: !isNil(exerciseToAdd?.subject) ? exerciseToAdd.subject : 1,
      index: !isNil(exerciseToAdd?.index) ? exerciseToAdd.index : 1,
      uuid: exerciseToAdd?.uuid || 'uuid',
    }),
    [exerciseToAdd],
  );

  const { GLOBAL_DIALOG, addToGlobalDialogQueue } = useGlobalDialogContext();

  const { data: exercisesSets } = useQuery(
    exercisesSetApi.getAll.generateQueryKey(),
    exercisesSetApi.getAll.request(),
    exercisesSetApi.getAll.requestBaseSettings,
  );

  const { addToExercisesSet } = useAddToExerciseSet();

  const openCreateNewExercisesSetModal = useCallback(() => {
    const existingSets = exercisesSets ? exercisesSets.map(({ name }) => name) : [];
    addToGlobalDialogQueue({ type: GLOBAL_DIALOG.CREATE_NEW_EXERCISES_SET, props: { existingSets, exerciseToAdd: plumbedExerciseToAdd } });
  }, [exerciseToAdd]);

  const styles = useStyles({ disabled });

  const elements = useMemo(() => {
    const result: ButtonWithPopoverElement[] = [];
    if (exercisesSets) {
      exercisesSets.forEach(({ name, id: setId }) => {
        result.push({
          label: (
            <span>
              <span>{name}</span>
            </span>
          ),
          action: addToExercisesSet(setId, plumbedExerciseToAdd),
          key: setId,
        });
      });
    }
    result.push({
      label: (
        <span className={styles.addNewSetElement}>
          <AddCircleOutlinedIcon className={styles.addNewSetElementIcon} fontSize='small' />
          <span>Stwórz nowy zestaw</span>
        </span>
      ),
      action: openCreateNewExercisesSetModal,
      key: 'add new',
    });
    return result;
  }, [exercisesSets, exerciseToAdd]);

  return (
    <ButtonWithPopover
      dense
      elements={elements}
      id={`Dodaj zadanie numer: ${exerciseToAdd?.index}`}
      buttonProps={{ className: styles.wrapper, disabled, size: 'small' }}
      popoverMinWidth={240}
    >
      <>
        <Typography className={styles.label}>{exerciseToAdd?.index}</Typography>
        <AddIcon className={styles.icon} fontSize='small' />
      </>
    </ButtonWithPopover>
  );
};

export default ChosenExercisesButton;
