import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';

import SheetsCurriculum from 'uniqueComponents/Sheets/SheetsCurriculum/SheetsCurriculum';
import SheetsMonth from 'uniqueComponents/Sheets/SheetsMonth/SheetsMonth';
import SheetsYear from 'uniqueComponents/Sheets/SheetsYear/SheetsYear';

const useStyles = makeStyles(theme => ({
  headingWrapper: {
    paddingTop: theme.legacySpacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
  },
  wrapper: {
    display: 'grid',
    gridGap: theme.legacySpacing(8),
    [theme.breakpoints.down('sm')]: {
      gridGap: theme.legacySpacing(4),
    },
  },
}));

function SheetsFilters() {
  const { headingWrapper, heading, wrapper } = useStyles();

  return (
    <section className={wrapper}>
      <div className={headingWrapper}>
        <Typography className={heading} color='primary' component='h3'>
          Filtry
        </Typography>
      </div>
      <SheetsCurriculum />
      <SheetsYear />
      <SheetsMonth />
    </section>
  );
}

export default SheetsFilters;
