import axios from 'axios';

import { DocumentDto } from './documents.dto';
import { documents as possibleDocumentsTypes, DocumentsApi, DocumentsApiKeys } from './documents.types';

const endpoint = 'documents/';

const documentsApi: DocumentsApi = {
  getDocuments: async type => {
    if (!possibleDocumentsTypes.includes(type)) throw Error('Błędny adres');
    const { data } = await axios.get<DocumentDto>(`${endpoint}${type}`);
    return {
      createdAt: data.created_at ? new Date(data.created_at) : null,
      content: data.content,
      id: data.id,
    };
  },
};

export const documentsApiKeys: DocumentsApiKeys = {
  getDocuments: documentType => ['Document content', documentType],
};

export default documentsApi;
