export default string => {
  try {
    return JSON.parse(string);
  } catch {
    const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
    // eslint-disable-next-line no-console
    if (!isProduction) console.error('Unable to parse string: ', string);
    return {};
  }
};
