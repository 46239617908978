import { makeStyles } from '@material-ui/core';

type StylesProps = {
  isLoading: boolean;
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flexGrow: 1,
    // @ts-ignore
    maxWidth: `${theme.container.exerciseImage}px`,
    left: '50%',
    transform: `translateX(-50%)`,
    transition: '.3s',
    minHeight: '70vh',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    transition: 'opacity .25s ease .1s',
    opacity: ({ isLoading }: StylesProps) => (isLoading ? 1 : 0),
    zIndex: -1,
    position: 'absolute',
    left: '50%',
    top: '80px',
    transform: 'translateX(-50%)',
  },
  notVisible: {
    opacity: 0,
    pointerEvents: 'none',
  },
  visible: {
    opacity: 1,
    pointerEvents: 'auto',
    transition: 'opacity .25s ease',
  },
}));

export default useStyles;
