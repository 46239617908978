import React from 'react';

import { Checkbox, FormControl, FormControlLabel, InputBaseComponentProps } from '@material-ui/core';

import InputFooter from 'components/_inputs/InputFooter/InputFooter';

type Props = {
  label: string;
  name: string;
  fullWidth?: boolean;
  error?: string;
  multiline?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  helperText?: string;
  number?: boolean;
  value?: string;
  inputProps?: InputBaseComponentProps;
};

const CheckboxInput: React.FC<Props> = props => {
  const { label, error, disabled, ...field } = props;

  return (
    <FormControl size='small' disabled={disabled} error={!!error}>
      <FormControlLabel label={label} control={<Checkbox color='primary' checked={!!field.value} {...field} />} />
      <InputFooter isError={!!error} text={error} name={field.name} />
    </FormControl>
  );
};

export default CheckboxInput;
