import React, { useRef, useState } from 'react';

import { Button, Dialog, Typography } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';

import usersApi from 'api/rest/users/users';
import { ReactComponent as Arrow } from 'assets/icons/general/arrow-left.svg';
import FormTextInput from 'components/_forms/FormTextInput/FormTextInput';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import GeneralError from 'components/GeneralError/GeneralError';
import LoaderButton from 'components/LoaderButton/LoaderButton';
import SeparatorWithLabel from 'components/SeparatorWithLabel/SeparatorWithLabel';
import MUTATION from 'constants/mutationsKeys';
import PATHS from 'constants/paths';
import { useAuthContext } from 'context/AuthContext';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import CONTAINERS from 'styles/constants/containers';
import AuthGrid from 'uniqueComponents/Auth/AuthGrid/AuthGrid';
import RemindPasswordForm from 'uniqueComponents/Auth/RemindPasswordForm/RemindPasswordForm';
import emailRegex from 'utils/regex/email';

import useStyles from './LoginBySchoolPage.styles';

type LoginBySchoolFormInput = {
  loginIdentifier: string;
  password: string;
};

const LoginBySchoolPage = () => {
  const recaptchaRef = useRef<any>(null);
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

  const { logEvent } = useUserLogContext();

  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState<boolean>(false);
  const closeForgotDialog = () => setForgotPasswordDialogOpen(false);
  const openForgotDialog = () => {
    logEvent('forgotten-password-click');
    setForgotPasswordDialogOpen(true);
  };

  const [generalError, setGeneralError] = useState<string | JSX.Element | null>(null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { onLogin } = useAuthContext();

  const loginMutation = useMutation(MUTATION.LOGIN_BY_IDENTIFIER, usersApi.loginByIdentifier(), {
    onSuccess: result => {
      // on success
      if ('access' in result) {
        logEvent('login-school');
        recaptchaRef.current.reset();
        onLogin(result.access);
      }
      // on incorrect credentials
      if ('message' in result) {
        recaptchaRef.current.reset();
        setGeneralError(result.message);
      }
    },
  });

  const classes = useStyles();

  const onSubmit: SubmitHandler<LoginBySchoolFormInput> = async data => {
    let recaptcha;
    const isEmailAsIdentifier = !!data.loginIdentifier.match(emailRegex);
    if (isEmailAsIdentifier) {
      setGeneralError(
        <div className={classes.customErrorWrapper}>
          <span>Niepoprawny identyfikator użytkownika szkolnego!</span>
          <Typography className={classes.customErrorInfo} variant='subtitle1'>
            Aby zalogować się adresem e-mail kliknij <Link to={PATHS.LOGIN}>tutaj</Link>.
          </Typography>
        </div>,
      );
      return;
    }
    try {
      recaptcha = await recaptchaRef.current.executeAsync();
    } catch (error) {
      setGeneralError('Wystąpił problem z Google recaptcha, zgłoś to nam!');
    }
    loginMutation.mutate({ ...data, recaptcha });
  };

  const { control, handleSubmit, watch } = useForm<LoginBySchoolFormInput>();
  const loginIdentifierValue = watch('loginIdentifier');

  return (
    <AuthGrid title='Zaloguj się do szkoły!'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <FormTextInput required name='loginIdentifier' label='Identyfikator' control={control} />
          <FormTextInput required name='password' label='Hasło' control={control} inputProps={{ type: 'password' }} />
        </FormWrapper>
        <FormWrapper size={CONTAINERS.FORM_S}>
          <LoaderButton isLoading={loginMutation.isLoading}>
            <Button variant='contained' color='primary' size='large' type='submit'>
              Zaloguj się
            </Button>
          </LoaderButton>
          {generalError && <GeneralError>{generalError}</GeneralError>}
          <SeparatorWithLabel>Lub:</SeparatorWithLabel>
          <Button size='large' variant='contained' onClick={openForgotDialog}>
            Przypomnij hasło
          </Button>
          <Button size='large' variant='text' component={Link} to={PATHS.LOGIN}>
            <Arrow className={classes.icon} />
            Powrót do wszystkich opcji
          </Button>
        </FormWrapper>
        <ReCAPTCHA size='invisible' ref={recaptchaRef} sitekey={recaptchaSiteKey} />
      </form>
      <Dialog onClose={closeForgotDialog} open={forgotPasswordDialogOpen}>
        <RemindPasswordForm
          isSchoolAccount
          redirectUrl={PATHS.LOGIN_BY_SCHOOL}
          initialValue={loginIdentifierValue}
          onClose={closeForgotDialog}
        />
      </Dialog>
    </AuthGrid>
  );
};

export default LoginBySchoolPage;
