import React, { useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import ExerciseAside from './_components/ExerciseAside/ExerciseAside';
import ExerciseDraw from './_components/ExerciseDraw/ExerciseDraw';
import ExerciseHeader from './_components/ExerciseHeader/ExerciseHeader';
import ExerciseMain from './_components/ExerciseMain/ExerciseMain';
import ExercisesViewGrid from '../../components/ExercisesViewGrid/ExercisesViewGrid';

const ExerciseDesktopView = ({ drawMode }) => {
  const notesRef = useRef();
  const focusNote = () => notesRef.current.focus();

  return drawMode ? (
    <ExerciseDraw />
  ) : (
    <ExercisesViewGrid
      aside={<ExerciseAside focusNote={focusNote} />}
      header={<ExerciseHeader />}
      main={<ExerciseMain notesRef={notesRef} />}
    />
  );
};

export default ExerciseDesktopView;
