import { useMemo } from 'react';

import GET_ACCOUNT_SETUP_CHOICES from '../../../api/graphql/queries/user/GET_ACCOUNT_SETUP_CHOICES';
import messages from '../../../constants/dictionaries/messages';
import { UserOrigin } from '../../../types/UserOrigin';
import createOptionsFromEnum from '../createOptionsFromEnum/createOptionsFromEnum';
import parseJsonToOptions from '../parseJsonToOptions/parseJsonToOptions';
import useSafeQuery from '../useSafeQuery/useSafeQuery';

const useAccountTypeOptionsQuery = () => {
  const { data, loading } = useSafeQuery(GET_ACCOUNT_SETUP_CHOICES, {}, messages.ERROR.FETCHING('danych'));

  const { accountTypeOptions, ageRangeOptions, classOptions, originOptions } = useMemo(() => {
    if (loading) return {};
    return {
      accountTypeOptions: parseJsonToOptions(data.accountTypeChoices),
      ageRangeOptions: parseJsonToOptions(data.ageRangeChoices),
      classOptions: parseJsonToOptions(data.classChoices),
      originOptions: createOptionsFromEnum(UserOrigin),
    };
  }, [data, loading]);

  return { isLoading: loading, accountTypeOptions, ageRangeOptions, classOptions, originOptions };
};

export default useAccountTypeOptionsQuery;
