import gql from 'graphql-tag';

const GET_SUBJECT_SECTIONS = gql`
  query getSubjectSections($id: ID!) {
    getSubject(id: $id) {
      id
      sections {
        id
        name
        free
        subsections {
          id
          name
        }
      }
    }
    exercisesCount(subject: $id)
  }
`;

export default GET_SUBJECT_SECTIONS;
