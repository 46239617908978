import React, { useMemo, useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { Typography, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';

import REQUEST_RESET_PASSWORD from 'api/graphql/mutations/user/REQUEST_RESET_PASSWORD';
import FormTextInput from 'components/_forms/FormTextInput/FormTextInput';
import messages from 'constants/dictionaries/messages';
import DialogGrid from 'containers/DialogGrid/DialogGrid';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import logError from 'utils/logError/logError';
import emailRegex from 'utils/regex/email';

type Props = {
  onClose: () => void;
  redirectUrl?: string;
  initialValue?: string;
  isSchoolAccount?: boolean;
};

type RemindPasswordFormInput = {
  loginIdentifier: string;
};

const RemindPasswordForm: React.FC<Props> = ({ onClose, initialValue, redirectUrl, isSchoolAccount }) => {
  const { logEvent } = useUserLogContext();
  const [resetPasswordMutation] = useMutation(REQUEST_RESET_PASSWORD);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit } = useForm<RemindPasswordFormInput>({ defaultValues: { loginIdentifier: initialValue || '' } });

  const onSubmit: SubmitHandler<RemindPasswordFormInput> = async ({ loginIdentifier }) => {
    setIsLoading(true);
    logEvent('reset-password');
    try {
      const { data } = await resetPasswordMutation({ variables: { loginIdentifier, redirectUrl } });
      if (data.requestResetPassword.sent) {
        enqueueSnackbar('Email wysłany, sprawdź swoją skrzynkę odbiorczą', { variant: 'success' });
      } else {
        enqueueSnackbar(messages.ERROR.SENDING('maila'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(messages.ERROR.SENDING('maila'), { variant: 'error' });
      logError(error as Error, 'RemindPasswordForm');
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const { validation, header, label } = useMemo(() => {
    if (isSchoolAccount) {
      return {
        validation: { pattern: { value: /.{3,}/i, message: 'Identyfikator musi mieć więcej niż trzy znaki!' } },
        header: 'Podaj identyfikator logowania do konta szkolnego.',
        label: 'Identyfikator',
      };
    }
    return {
      validation: { pattern: { value: emailRegex, message: 'To nie jest poprawny e-mail!' } },
      header: 'Podaj email, na który chcesz dostać link do zresetowania hasła.',
      label: 'Email',
    };
  }, [isSchoolAccount]);

  return (
    <DialogGrid
      cancelClick={onClose}
      disabled={isLoading}
      hideCloseButton
      isForm
      narrow
      onSubmit={handleSubmit(onSubmit)}
      title='Przypomnij hasło'
    >
      <Grid container justifyContent='center' spacing={8}>
        <Grid item>
          <Typography align='center'>{header}</Typography>
        </Grid>
        <Grid container item>
          <FormTextInput control={control} label={label} name='loginIdentifier' required rules={validation} />
        </Grid>
      </Grid>
    </DialogGrid>
  );
};

export default RemindPasswordForm;
