import React, { useMemo } from 'react';

import { Select } from '@material-ui/core';

import FiltersFormControl from 'components/FiltersFormControl/FiltersFormControl';
import { useUserDataContext } from 'context/UserDataContext';
import parseValuesToSelect from 'services/common/parseValuesToSelect/parseValuesToSelect';
import useExercisesStore from 'storages/exercises/exercises';

import FiltersBlock from 'uniqueComponents/Exercises/ExercisesFilters/FiltersBlock/FiltersBlock';
import { useQuery } from 'react-query';
import QUERY from 'constants/queryKeys';
import usersApi from 'api/rest/users/users';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

function Sections({ sections }) {
  const { logEvent } = useUserLogContext();
  const { data } = useQuery(QUERY.GET_ME, usersApi.getMe());

  const { section, subsection, setSection, setSubsection } = useExercisesStore();
  const { isMobile } = useUserDataContext();
  const getSections = useMemo(() => {
    if (sections) {
      const localSections = [...sections];
      if (!localSections.find(({ id }) => id === 'all')) {
        localSections.unshift({ name: 'Wszystko', id: 'all' });
      }
      return parseValuesToSelect({
        data: localSections,
        isMobile,
        dictionary: { label: 'name', value: 'id' },
        placeholder: 'Wybierz dział...',
        disabled: ({ free }) => !free && !data?.hasPremiumAccess,
      });
    }
    return null;
  }, [sections, data?.hasPremiumAccess]);

  const getSubsections = useMemo(() => {
    if (section && section !== 'all' && sections) {
      const { subsections } = sections.find(({ id }) => id === section) || {};
      if (!subsections) return null;
      const localSubsections = [...subsections];
      if (!localSubsections.find(({ id }) => id === 'all')) {
        localSubsections.unshift({ name: 'Wszystko', id: 'all' });
      }
      return localSubsections
        ? parseValuesToSelect({
            data: localSubsections,
            isMobile,
            dictionary: { label: 'name', value: 'id' },
            placeholder: 'Wybierz poddział...',
          })
        : null;
    }
    return null;
  }, [sections, section, data]);

  const onSectionSelect = ({ target: { value } }) => {
    const label = sections.find(({ id }) => id === value)?.name || value;
    logEvent('select-filter-click', { filter: 'Dział', value: label, feature: 'exercises' });
    setSection(value);
  };
  const onSubsectionSelect = ({ target: { value } }) => {
    setSubsection(value);
    try {
      const { subsections } = sections.find(({ id }) => id === section) || {};
      const label = subsections.find(({ id }) => id === value)?.name || value;
      logEvent('select-filter-click', { filter: 'Poddział', value: label, feature: 'exercises' });
    } catch (e) {
      if (process.env.REACT_APP_ENVIRONMENT !== 'production') console.error('log error');
    }
  };
  return (
    <>
      <FiltersBlock heading='Dział'>
        <FiltersFormControl size='small' variant='outlined'>
          <Select displayEmpty native={isMobile} onChange={onSectionSelect} value={section || ''}>
            {getSections}
          </Select>
        </FiltersFormControl>
      </FiltersBlock>
      <FiltersBlock heading='Poddział'>
        <FiltersFormControl size='small' variant='outlined'>
          <Select
            autoWidth
            disabled={!section || section === 'all'}
            displayEmpty
            native={isMobile}
            onChange={onSubsectionSelect}
            value={subsection || ''}
          >
            {getSubsections || []}
          </Select>
        </FiltersFormControl>
      </FiltersBlock>
    </>
  );
}

export default Sections;
