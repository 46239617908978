import React, { RefObject, useEffect, useState } from 'react';

import { Button, Typography } from '@material-ui/core';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ExerciseInSetDetails } from 'api/rest/exercisesSets/exercisesSet.types';
import subjectsApi from 'api/rest/subjects/subjects';
import QUERY from 'constants/queryKeys';

import exercisesSetApi from '../../../../api/rest/exercisesSets/exercisesSet';
import { useUserLogContext } from '../../../../context/UserLogContext/UserLogContext';
import { parseSubjectCodeToSubjectNumber } from '../../../../services/common/subjectParsers/subjectParsers';
import useExercisesSetsStore from '../../../../storages/exercisesSets/exercisesSets';
import useSubjectStore from '../../../../storages/subject';
import CONTAINERS from '../../../../styles/constants/containers';
import FormSelectInput from '../../../_forms/FormSelectInput/FormSelectInput';
import FormTextInput from '../../../_forms/FormTextInput/FormTextInput';
import FormWrapper from '../../../FormWrapper/FormWrapper';
import LoaderButton from '../../../LoaderButton/LoaderButton';
import useStyles from './EditExercisesSetAddNewForm.styles';

type Props = {
  exercisesListRef: RefObject<HTMLDivElement>;
};

type AddNewFormInput = {
  subject: number;
  exerciseIndex: string;
};

const EditExercisesSetAddNewForm: React.FC<Props> = ({ exercisesListRef }) => {
  const { logEvent } = useUserLogContext();

  const { data: subjectOptions } = useQuery(QUERY.GET_SUBJECTS_LIST, subjectsApi.getSubjectsListForDropdown(false), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  });

  const { currentSetId } = useExercisesSetsStore();
  const { subject: globalSubject } = useSubjectStore();

  const [globalError, setGlobalError] = useState<null | string>(null);

  const { control, handleSubmit, formState, watch, setValue } = useForm<AddNewFormInput>({
    defaultValues: {
      subject: globalSubject?.subjectCode && parseSubjectCodeToSubjectNumber(globalSubject.subjectCode),
      exerciseIndex: '',
    },
  });

  const subjectValue = watch('subject');
  const exerciseIndexValue = watch('exerciseIndex');

  useEffect(() => {
    setValue('exerciseIndex', '');
    setGlobalError(null);
  }, [subjectValue]);

  useEffect(() => {
    setGlobalError(null);
  }, [exerciseIndexValue]);

  const appendMutation = useMutation(exercisesSetApi.append.generateQueryKey(), exercisesSetApi.append.request());

  const queryClient = useQueryClient();

  const onSubmit = async ({ exerciseIndex, subject }: AddNewFormInput) => {
    // @ts-ignore
    const result = await appendMutation.mutateAsync({ input: { subject, exerciseIndex: +exerciseIndex }, setId: currentSetId });
    const error = get(result, 'error');
    if (error) {
      setGlobalError(error);
    } else {
      logEvent('exercises-sets-add-exercise-in-dialog');
      await queryClient.invalidateQueries(exercisesSetApi.getDetails.generateQueryKey(currentSetId));
      const exercisesListNode = exercisesListRef.current;
      if (exercisesListNode) exercisesListNode.scrollTo(0, exercisesListNode.scrollHeight + 100);
      setValue('exerciseIndex', '');
    }
  };

  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <FormWrapper fixedSize size={CONTAINERS.FORM_M}>
        <FormSelectInput
          rules={{ required: 'To pole jest wymagane' }}
          label='Przedmiot'
          name='subject'
          control={control}
          options={subjectOptions || []}
        />
        <FormTextInput
          rules={{ required: 'To pole jest wymagane' }}
          inputProps={{ type: 'number' }}
          label='Numer zadania'
          name='exerciseIndex'
          control={control}
        />
        <LoaderButton isLoading={appendMutation.isLoading}>
          <Button variant='contained' color='primary' type='submit' disabled={!formState.isValid || !!globalError}>
            Dodaj
          </Button>
        </LoaderButton>
        <Typography color='error'>{globalError}</Typography>
      </FormWrapper>
    </form>
  );
};

export default EditExercisesSetAddNewForm;
