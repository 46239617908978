import React from 'react';

import ExerciseViewTile from 'components/ExerciseViewTile/ExerciseViewTile';
import { ExerciseDataSources } from 'services/common/useExerciseDataSourceResolver/useExerciseDataSourceResolver';
import ExercisesHelpers from 'uniqueComponents/Exercises/ExercisesHelpers/ExercisesHelpers';

type Props = {
  focusNote: () => void;
  children: React.ReactNode;
  dataSource: ExerciseDataSources;
};

const ExerciseViewAsideWrapper: React.FC<Props> = ({ focusNote, children, dataSource }) => (
  <ExerciseViewTile>
    <ExercisesHelpers focusNote={focusNote} dataSource={dataSource} />
    {children}
  </ExerciseViewTile>
);

export default ExerciseViewAsideWrapper;
