import React, { lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react';

import { Button, makeStyles } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { useGlobalDialogContext } from 'context/GlobalDialogContext';
import parseYoutubeLink from 'services/common/parseYoutubeLink/parseYoutubeLink';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
import Loader from 'components/Loader/Loader';
import RateContent from 'components/RateContent/RateContent';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import useSubjectStore from 'storages/subject';
import useExerciseDataSourceResolver from '../../services/common/useExerciseDataSourceResolver/useExerciseDataSourceResolver';

const ReactPlayer = lazy(() => import('react-player/youtube'));

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.legacySpacing(8),
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: ({ singleColumn }) => (singleColumn ? 'auto' : 'auto auto'),
    gridGap: theme.spacing(6),
    width: ({ singleColumn }) => (singleColumn ? '40%' : '75%'),
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: ({ singleColumn }) => (singleColumn ? '40%' : '85%'),
    },
    [theme.breakpoints.down('xs')]: {
      width: () => '85%',
      gridTemplateColumns: () => 'auto',
    },
  },
  videoWrapper: {
    marginTop: theme.spacing(4),
    position: 'relative',
    minHeight: ({ videoHeight }) => videoHeight,
  },
}));

const orientationChanged = () => {
  const isLandscape = window.orientation > 0;
  const iframe = document.querySelector('#YouTube iframe');
  if (iframe) {
    iframe.setAttribute('allowFullScreen', 'true');
    const requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
    const exitFullScreen =
      document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen;

    if (isLandscape && requestFullScreen) requestFullScreen.call(iframe);
    else if (exitFullScreen && window.fullScreen) exitFullScreen.call(iframe);
  }
};

function ExerciseExternalResources({ dataSource }) {
  const { logEvent, curryLogEvent } = useUserLogContext();
  const { subject } = useSubjectStore();

  const { currentExercise } = useExerciseDataSourceResolver(dataSource)();

  const { addToGlobalDialogQueue, GLOBAL_DIALOG } = useGlobalDialogContext();
  // const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [youtubeVideo, setYoutubeVideo] = useState({ url: null, isOpen: false });
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const onReady = () => setLoading(false);

  const ref = useRef();
  const helpersRef = useRef();
  const videoHeight = youtubeVideo.isOpen ? ref.current.clientWidth * 0.5625 : 0;

  // LOGS
  const playPeriods = useRef([]);
  const duration = useRef(0);

  const setDuration = durationInSeconds => {
    duration.current = durationInSeconds * 1000;
  };
  const logDuration = () => {
    if (!playPeriods.current.length) return;
    const durationPlayed = playPeriods.current
      .map(period => ({ ...period, end: period.end || Date.now() }))
      .reduce((acc, curr) => acc + (curr.end - curr.start), 0);
    logEvent('video-play-duration', { durationPlayed, duration, exercise: currentExercise?.index });
    playPeriods.current = [];
  };
  useEffect(() => {
    window.addEventListener('beforeunload', logDuration);

    return () => {
      logDuration();
      window.removeEventListener('beforeunload', logDuration);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [youtubeVideo?.url]);

  const [main, ...rest] = useMemo(() => {
    if (!currentExercise?.externalSources) return [];
    const { externalSources } = currentExercise;
    const [mainSource] = externalSources.filter(({ isMain }) => isMain);
    if (mainSource) setYoutubeVideo({ url: parseYoutubeLink(mainSource.url), isOpen: false });
    return [mainSource, ...externalSources.filter(({ isMain }) => !isMain)];
  }, [currentExercise?.externalSources]);

  const openExternalResourcesDialog = () => {
    setPlaying(false);
    addToGlobalDialogQueue({ type: GLOBAL_DIALOG.EXTERNAL_RESOURCES, props: { externalSources: rest } });
  };

  const toggleVideo = () => {
    const { url, isOpen } = youtubeVideo;
    logEvent('video-click', { exercise: currentExercise.index, subject: subject.name, open: !isOpen });
    if (isOpen) {
      setYoutubeVideo({ url, isOpen: false });
      setLoading(true);
      logDuration();
    } else {
      setYoutubeVideo({ url, isOpen: true });
      setPlaying(true);
      setTimeout(() => ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' }), 10);
    }
  };

  useEffect(() => {
    window.addEventListener('orientationchange', orientationChanged);
    return () => window.removeEventListener('orientationchange', orientationChanged);
  });

  const onPlay = () => {
    playPeriods.current = [...playPeriods.current, { start: Date.now() }];
    logEvent('video-play', { exercise: currentExercise.index });
  };
  const onPause = () => {
    const playPeriodsCP = [...playPeriods.current];
    playPeriodsCP[playPeriodsCP.length - 1].end = Date.now();
    playPeriods.current = playPeriodsCP;
    logEvent('video-pause', { exercise: currentExercise.index });
  };
  const onEnded = () => {
    const playPeriodsCP = [...playPeriods.current];
    playPeriodsCP[playPeriodsCP.length - 1].end = Date.now();
    playPeriods.current = playPeriodsCP;
    logEvent('video-ended', { exercise: currentExercise.index });
  };

  const shouldShowMain = !!main;
  const shouldShowRest = !!rest.length;
  const classes = useStyles({ videoHeight, singleColumn: !shouldShowMain || !shouldShowRest });
  return (
    <div className={classes.wrapper}>
      <div className={classes.buttons}>
        {shouldShowMain && (
          <Button color='primary' endIcon={!youtubeVideo.isOpen && <PlayArrowIcon />} onClick={toggleVideo} variant='contained'>
            {youtubeVideo.isOpen ? 'Ukryj rozwiązanie' : 'Rozwiązanie wideo'}
          </Button>
        )}
        {shouldShowRest && (
          <Button
            color='primary'
            innerRef={helpersRef}
            onClick={curryLogEvent(openExternalResourcesDialog, 'external-sources-click', { exercise: currentExercise.index })}
            variant='contained'
          >
            Materiały pomocnicze
          </Button>
        )}
      </div>
      <div ref={ref} className={classes.videoWrapper}>
        {youtubeVideo.isOpen && (
          <Suspense fallback={<Loader />}>
            {loading && <Loader />}
            <ReactPlayer
              controls
              fallback={Loader}
              height={videoHeight}
              id='YouTube'
              onDuration={setDuration}
              onEnded={onEnded}
              onPause={onPause}
              onPlay={onPlay}
              onReady={onReady}
              playing={playing}
              url={youtubeVideo.url}
              width='100%'
            />
            <RateContent id={main?.id} />
          </Suspense>
        )}
      </div>
    </div>
  );
}

export default ExerciseExternalResources;
