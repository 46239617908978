import React, { useMemo, useRef } from 'react';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import ExerciseDisplay from 'components/ExerciseDisplay/ExerciseDisplay';
import ExercisePath from 'components/ExercisePath/ExercisePath';
import ExercisesViewGrid from 'components/ExercisesViewGrid/ExercisesViewGrid';
import ExerciseViewAsideWrapper from 'components/ExerciseViewAsideWrapper/ExerciseViewAsideWrapper';
import ExerciseViewHeaderWrapper from 'components/ExerciseViewHeaderWrapper/ExerciseViewHeaderWrapper';
import ExerciseViewTile from 'components/ExerciseViewTile/ExerciseViewTile';
import PrintPanel from 'components/PrintPanel/PrintPanel';
import { useExerciseDrawContext } from 'context/ExerciseDrawContext';
import ExerciseDraw from 'pages/Exercises/_components/ExerciseDraw/ExerciseDraw';
import resolvePolishNumeralsFactory from 'services/common/resolvePolishNumeralsFactory/resolvePolishNumeralsFactory';
import useModernNavigation from 'services/exercises/useModernNavigation/useModernNavigation';
import useExercisesSetsStore from 'storages/exercisesSets/exercisesSets';
import useExercisesToDisplay_sets from 'storages/exercisesToDisplay/useExercisesToDisplay_sets';
import ChosenExercisesFilters from 'uniqueComponents/ChosenExercises/ChosenExercisesFilters/ChosenExercisesFilters';
import ExercisesNavigation from 'uniqueComponents/Exercises/ExercisesNavigation/ExercisesNavigation';

import SubjectIcon from '../../../../components/SubjectIcon/SubjectIcon';
import { parseSubjectNumberToSubjectCode } from '../../../../services/common/subjectParsers/subjectParsers';
import useStyles from './ExercisesSetsDesktop.styles';

const ExercisesSetsDesktop: React.FC = () => {
  const { exercises, selectedFileType, currentExercise, dataState, cursor } = useExercisesToDisplay_sets();
  const { currentSetData } = useExercisesSetsStore();

  const notesRef = useRef<HTMLTextAreaElement>(null);
  const focusNote = () => notesRef.current?.focus();

  const {
    // @ts-ignore
    drawState: { drawMode },
  } = useExerciseDrawContext();

  const classes = useStyles();

  // TODO unify - move to Navigation component
  const { actions: navigationActions, buttonsState, exerciseName } = useModernNavigation();

  const currentExerciseSubjectCode = useMemo(
    () => (currentExercise?.subject?.id ? parseSubjectNumberToSubjectCode(currentExercise.subject.id) : null),
    [currentExercise],
  );

  if (drawMode) return <ExerciseDraw dataSource='common_store' />;

  return (
    <ExercisesViewGrid
      aside={
        <ExerciseViewAsideWrapper dataSource='common_store' focusNote={focusNote}>
          <ChosenExercisesFilters />
        </ExerciseViewAsideWrapper>
      }
      header={
        <ExerciseViewHeaderWrapper>
          <ExerciseViewTile>
            {currentSetData ? (
              <div className={classes.header}>
                {currentExerciseSubjectCode && <SubjectIcon iconName={currentExerciseSubjectCode} size='s' />}
                <Typography variant='h5'>
                  {currentExerciseSubjectCode ? `${currentExerciseSubjectCode} ` : ''}
                  {currentExercise?.index}
                </Typography>
              </div>
            ) : (
              <Typography variant='subtitle1' className={clsx(classes.header, classes.headerFallback)}>
                Nie wybrano zestawu zadań
              </Typography>
            )}
          </ExerciseViewTile>
          <PrintPanel exercisesCount={exercises.length} exercises={exercises} />
          <ExercisesNavigation
            dataSource='common_store'
            currentExerciseId={currentExercise?.globalId}
            exercisesCursor={cursor}
            fileType={selectedFileType}
            actions={navigationActions}
            buttonsEnableState={buttonsState}
            exerciseName={exerciseName}
            exercisesCount={exercises.length}
            isMarked={!!currentExercise?.userMark}
          />
        </ExerciseViewHeaderWrapper>
      }
      main={
        <ExerciseDisplay
          exercisePath={
            <ExercisePath section={currentExercise?.section} subsection={currentExercise?.subsection} subject={currentExercise?.subject} />
          }
          fetchingExerciseDataState={dataState}
          filesToDisplay={currentExercise?.files[selectedFileType]}
          audioSource={currentExercise?.audioSource}
          notesRef={notesRef}
          currentExerciseId={currentExercise?.globalId}
          manualComponent={
            <Typography align='center' variant='h2'>
              Wybierz zestaw zadań, aby kontynuować.
            </Typography>
          }
          emptyComponent={
            <div className={classes.infoBox}>
              <Typography align='center' variant='h2'>
                Ten zestaw zadań jest pusty.
              </Typography>
              <Typography align='center' variant='subtitle1'>
                Aby dodać zadania do zestawu skorzystaj z przycisku dodawania zadań w widoku zadań lub arkuszy.
              </Typography>
            </div>
          }
        />
      }
    />
  );
};

export default ExercisesSetsDesktop;
