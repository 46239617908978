import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { difference } from 'lodash';
import { useNavigate } from 'react-router-dom';

import ImageWithLoaderWrapper from 'components/ImageWithLoaderWrapper/ImageWithLoaderWrapper';
import ExerciseSuccess from 'components/ExerciseSuccess/ExerciseSuccess';
import ExerciseManual from 'components/ExerciseManual/ExerciseManual';
import AddExerciseToChosenButton from 'components/AddExerciseToChosenButton/AddExerciseToChosenButton';
import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import ExerciseExternalResources from 'components/ExerciseExternalResources/ExerciseExternalResources';
import ExerciseNote from 'components/ExerciseNote/ExerciseNote';
import messages from 'constants/dictionaries/messages';
import PATHS from 'constants/paths';
import GET_EXERCISE from 'api/graphql/queries/exercises/GET_EXERCISE';
import useCommitError from 'services/common/useCommitError/useCommitError';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
import useExercisesStore from 'storages/exercises/exercises';
import ExerciseError from 'uniqueComponents/Exercises/ExerciseError/ExerciseError';
import { useQuery } from 'react-query';
import QUERY from 'constants/queryKeys';
import usersApi from 'api/rest/users/users';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    flexGrow: 1,
    maxWidth: `${theme.container.exerciseImage}px`,
    left: '50%',
    transform: `translateX(-50%)`,
    transition: '.3s',
    minHeight: '70vh',
  },
  icon: {
    width: '1rem',
    height: '1rem',
    fill: theme.palette.primary.main,
  },
  fallback: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& h3': {
      fontWeight: 600,
      fontSize: '1.3rem',
      padding: theme.legacySpacing(10, 5),
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
  },
  loader: {
    transition: 'opacity .25s ease .1s',
    opacity: ({ loading }) => (loading ? 1 : 0),
    zIndex: '-1',
    position: 'absolute',
    left: '50%',
    top: '80px',
    transform: 'translateX(-50%)',
  },
  exercisePath: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '0.75rem',
    marginBottom: theme.legacySpacing(2),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  exercisePathPath: {
    flexGrow: '1',
    width: '100%',
  },
}));

const ExerciseMain = ({ setZoomed, zoomed, notesRef }) => {
  const { isChosenExercise, useDataStore } = useFeatureChecker();
  const client = useApolloClient();
  const {
    fileType,
    setCurrentExercise,
    currentExercise,
    exercisesCount,
    exercises,
    exerciseCursor,
    reload,
    allExercisesCompleted,
    filters,
    orderBy,
    fetched,
  } = useDataStore();
  const { setSectionAndSubsection } = useExercisesStore();
  const commitError = useCommitError();
  const navigate = useNavigate();
  const { data } = useQuery(QUERY.GET_ME, usersApi.getMe());
  const premiumUser = useMemo(() => data?.hasPremiumAccess, [data]);

  const [currentExercisesUrls, setCurrentExercisesUrls] = useState(null);
  const [audioSource, setAudioSource] = useState(null);
  const [fallback, setFallback] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const classes = useStyles({ loading });

  const infoService = useCallback(() => {
    switch (true) {
      case !fetched:
        return <ExerciseManual />;
      case exercisesCount === 0:
        return <h3>Nie ma zadań spełniających te kryteria</h3>;
      case exercisesCount === null:
        return (
          <div className={classes.loader}>
            <CircularProgress size={60} />
          </div>
        );
      case allExercisesCompleted:
        return <ExerciseSuccess />;
      default:
        return null;
    }
  }, [fetched, exercisesCount, allExercisesCompleted]);

  useEffect(() => setFallback(infoService()), [allExercisesCompleted, fileType, fetched, exercisesCount, currentExercisesUrls]);

  const queryExercise = async uuid => {
    const res = await client.query({
      query: GET_EXERCISE,
      variables: { uuid },
    });
    return res.data?.getExercise;
  };

  const onLoaded = () => {
    setLoading(false);
  };
  const onLoadStart = () => {
    setLoading(true);
  };

  const onError = () => setError(true);

  const updateCurrentExercise = ({ files, id, userMark, section, subsection, externalSources, audio, index }) => {
    try {
      setCurrentExercise({ files, id, mark: userMark?.mark, section, subsection, externalSources, index });
      const filesToDisplay = files.filter(file => file.fileType === fileType).sort((a, b) => a.fileIndex - b.fileIndex);
      if (filesToDisplay && filesToDisplay.length) {
        const newCurrentExercisesUrls = filesToDisplay.map(({ croppedPngFile }) => croppedPngFile);
        const newImageLoading = !!difference(newCurrentExercisesUrls, currentExercisesUrls).length;
        if (!newImageLoading) onLoaded();
        setCurrentExercisesUrls(newCurrentExercisesUrls);
      } else {
        setCurrentExercisesUrls(null);
        setError(true);
        commitError({ message: 'missing filesToDisplay' }, messages.ERROR.FETCHING('zadania'), 'ExerciseMain: updateCurrentExercise');
      }
      setAudioSource(audio);
    } catch (e) {
      commitError(e, messages.ERROR.FETCHING('zadania'), 'ExerciseMain: updateCurrentExercise (try/catch)');
      setError(true);
    }
  };

  const getExercise = () => {
    if (exercises?.length > 0) {
      setError(false);
      const id = exercises[exerciseCursor]?.id;
      if (id) {
        onLoadStart();
        queryExercise(id)
          .then(updateCurrentExercise)
          .catch(e => {
            setError(true);
            commitError(e, messages.ERROR.FETCHING('zadania'), 'ExerciseMain: useEffect for loading image');
          });
      }
    } else {
      setCurrentExercisesUrls(null);
      setFallback(infoService());
    }
  };

  useEffect(() => {
    getExercise();
  }, [exercises, exerciseCursor, fileType, filters, orderBy]);

  const onExercisePathClick = () => {
    const { section, subsection } = currentExercise;
    if (section) {
      setSectionAndSubsection({ section: section.id, subsection: subsection?.id });
      navigate(PATHS.EXERCISES);
    }
  };

  const onErrorRefresh = async () => {
    const id = exercises[exerciseCursor]?.id;
    if (reload) reload();
    const exercise = await queryExercise(id);
    updateCurrentExercise(exercise);
    setError(false);
    window.location.reload(true);
  };

  const getExercisePath = () => {
    if (!currentExercise) return null;
    const { section, subsection } = currentExercise;
    if (!section) return null;
    if (!subsection)
      return (
        <div className={classes.exercisePath}>
          <div className={classes.exercisePathPath} onClick={onExercisePathClick}>
            {section.name}
          </div>
          <AddExerciseToChosenButton disabled={loading} />
        </div>
      );
    return (
      <div className={classes.exercisePath}>
        <div className={classes.exercisePathPath} onClick={onExercisePathClick}>{`${section.name} > ${subsection.name}`}</div>{' '}
        <AddExerciseToChosenButton disabled={loading} />
      </div>
    );
  };

  return (
    <div className={classes.wrapper}>
      {fallback && <Box className={classes.fallback}>{fallback}</Box>}
      {error && <ExerciseError onClick={onErrorRefresh} />}
      {/* {!audioSource && !isChosenExercise && !isSheet && premiumUser && <AddExerciseToChosenButton disabled={loading} />} */}
      {audioSource && <AudioPlayer src={audioSource} withAddExercise={premiumUser && !isChosenExercise} />}
      {getExercisePath()}
      {currentExercisesUrls && !fallback && !error && (
        <>
          <ImageWithLoaderWrapper
            loading={loading}
            onError={onError}
            onLoad={onLoaded}
            setZoomed={setZoomed}
            urls={currentExercisesUrls}
            zoomed={zoomed}
          />
          {!loading && <ExerciseExternalResources />}
          {!loading && <ExerciseNote exerciseId={currentExercise?.id} notesRef={notesRef} />}
        </>
      )}
      {loading && !error && !fallback && (
        <div className={classes.loader}>
          <CircularProgress size={60} />
        </div>
      )}
    </div>
  );
};

ExerciseMain.defaultProps = {
  setZoomed: null,
  notesRef: null,
  zoomed: false,
};

export default ExerciseMain;
