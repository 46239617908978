import React, { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Button, makeStyles, Typography } from '@material-ui/core';

import GET_FILTER_TAGS from 'api/graphql/queries/exercises/GET_FILTER_TAGS';
import GET_SUBJECT_SECTIONS from 'api/graphql/queries/subjects/GET_SUBJECT_SECTIONS';
import useConditionalQuery from 'services/common/useConditionalQuery/useConditionalQuery';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
import useSubjectStore from 'storages/subject';

import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import Curriculum from './Curriculum/Curriculum';
import DynamicFilters from './DynamicFilters/DynamicFilters';
import Marks from './Marks/Marks';
import Ordering from './Ordering/Ordering';
import Sections from './Sections/Sections';

const useStyles = makeStyles(theme => ({
  headingWrapper: {
    paddingTop: theme.legacySpacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
  },
  wrapper: {
    display: 'grid',
    gridGap: theme.legacySpacing(8),
    [theme.breakpoints.down('sm')]: {
      gridGap: theme.legacySpacing(4),
    },
  },
}));

function ExercisesFilters() {
  const { curryLogEvent } = useUserLogContext();
  const { useDataStore } = useFeatureChecker();
  const { reset, setTotalCount, section, subsection, filters, curriculum, userMarks } = useDataStore();
  const { subject } = useSubjectStore();
  const { headingWrapper, heading, wrapper } = useStyles();
  const [getFilters, { data: filtersData }] = useLazyQuery(GET_FILTER_TAGS, {
    fetchPolicy: 'no-cache',
  });
  const { data: sectionsData } = useConditionalQuery(subject?.id, GET_SUBJECT_SECTIONS, {
    variables: { id: subject?.id },
  });
  useEffect(() => {
    const id = subject?.id;
    if (id) {
      getFilters({
        variables: {
          id,
          section: section === 'all' ? null : section,
          subsection: subsection === 'all' ? null : subsection,
          filters,
          curriculum,
        },
      });
    }
  }, [subject, section, subsection, curriculum, userMarks]);

  useEffect(() => {
    if (sectionsData && setTotalCount) setTotalCount(sectionsData.exercisesCount);
  }, [sectionsData]);

  return (
    <section className={wrapper}>
      <div className={headingWrapper}>
        <Typography className={heading} color='primary' component='h3'>
          Filtry
        </Typography>
        <Button onClick={curryLogEvent(reset, 'clear-filters-click')} size='small'>
          Wyczyść
        </Button>
      </div>
      <Sections sections={sectionsData?.getSubject?.sections} />
      <Curriculum />
      <Ordering />
      <Marks />
      <DynamicFilters tagCategories={filtersData?.getSubject?.tagCategories} />
    </section>
  );
}

export default ExercisesFilters;
