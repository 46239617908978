import { sortBy } from 'lodash';

import client from 'api/graphql/config/client';
import GET_EXERCISES_LIST_BY_INDEXES from 'api/graphql/queries/exercises/GET_EXERCISES_LIST_BY_INDEXES';
import logError from 'utils/logError/logError';

import useSubjectStore from '../subject';

const parseVariables = ({ chosenExercises }) => {
  const { subject } = useSubjectStore.getState();
  const exercisesToFetch = chosenExercises[subject.subjectCode];
  if (!exercisesToFetch) return null;
  return {
    subject: subject.id,
    indexes: exercisesToFetch.map(({ index }) => index),
  };
};

const getExercisesByIndexes = async variables => {
  try {
    const { data } = await client.query({
      query: GET_EXERCISES_LIST_BY_INDEXES,
      fetchPolicy: 'no-cache',
      variables,
    });
    if (data) {
      const exercises = sortBy(data?.exercises, exercise => variables.indexes.findIndex(index => exercise.index === index));
      const [currentExercise] = exercises;
      return {
        allExercisesCompleted: false,
        exercises,
        currentExercise,
        exercisesCount: exercises ? exercises.length : 0,
        fetched: true,
      };
    }
    return {};
  } catch (error) {
    logError(error);
    return {};
  }
};

export { parseVariables };
export default getExercisesByIndexes;
