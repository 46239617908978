import client from 'api/graphql/config/client';
import GET_EXERCISES_LIST from 'api/graphql/queries/exercises/GET_EXERCISES_LIST';
import logError from 'utils/logError/logError';
import asyncDebounce from 'utils/asyncDebounce/asyncDebounce';

import useSubjectStore from '../subject';

const onlyIfUndefined = (value, fallback) => (value === undefined ? fallback : value);

const parseVariables = (base, overrides = {}) => {
  let subjectId = overrides?.subject?.id;
  if (!subjectId) {
    const { subject } = useSubjectStore.getState();
    subjectId = subject.id;
  }
  const { section, userMarks, subsection, orderBy, filters, curriculum } = base;
  const parseArrayLike = value => (value?.length ? value : null);
  const allExercisesCheck = value => (value === 'all' ? null : value);

  return {
    subject: subjectId,
    section: allExercisesCheck(onlyIfUndefined(overrides.section, section)),
    subsection: allExercisesCheck(onlyIfUndefined(overrides.subsection, subsection)),
    userMarks: parseArrayLike(onlyIfUndefined(overrides.userMarks, userMarks)),
    orderBy: onlyIfUndefined(overrides.orderBy, orderBy),
    filters: parseArrayLike(onlyIfUndefined(overrides.filters, filters)),
    curriculum: parseArrayLike(onlyIfUndefined(overrides.curriculum, curriculum)),
    offset: overrides.offset || 0,
  };
};

const getExercises = asyncDebounce(async rawVariables => {
  const variables = { ...rawVariables };
  variables.limit = 50;
  try {
    const { data } = await client.query({
      query: GET_EXERCISES_LIST,
      fetchPolicy: 'no-cache',
      variables,
    });
    if (data) {
      return {
        allExercisesCompleted: false,
        exercises: data.exercises,
        exercisesCount: data.exercisesCount,
        fetched: true,
      };
    }
    return {};
  } catch (error) {
    sessionStorage.removeItem(`${process.env.REACT_APP_VERSION}_exercises`);
    window.location.href = '/';
    logError(error);
    return {};
  }
}, 500);

export { parseVariables };
export default getExercises;
