import { useCallback } from 'react';

import useExerciseDetailsStore from 'storages/exerciseDetails';
import { Id } from 'types/Id';

const useOpenExerciseDrawer = () => {
  const { setCurrentExerciseId, openExerciseDetails, isExerciseDetailsVisible } = useExerciseDetailsStore();
  const openExerciseDrawer = useCallback(
    (exerciseId: Id) => {
      setCurrentExerciseId(exerciseId);
      if (!isExerciseDetailsVisible) openExerciseDetails();
    },
    [setCurrentExerciseId, openExerciseDetails, isExerciseDetailsVisible],
  );

  return { openExerciseDrawer };
};

export default useOpenExerciseDrawer;
