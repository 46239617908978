import React, { useState, useRef } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { Button } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import REGISTER_NEW_USER from 'api/graphql/mutations/user/REGISTER_NEW_USER';
import UPDATE_USER from 'api/graphql/mutations/user/UPDATE_USER';
import { ReactComponent as Arrow } from 'assets/icons/general/arrow-left.svg';
import FormTextInput from 'components/_forms/FormTextInput/FormTextInput';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import GeneralError from 'components/GeneralError/GeneralError';
import LoaderButton from 'components/LoaderButton/LoaderButton';
import PATHS from 'constants/paths';
import { useAuthContext } from 'context/AuthContext';
import prepareSourcesForInput from 'services/common/prepareSourcesForInput/prepareSourcesForInput';
import validatePassword from 'services/common/validatePassword/validatePassword';
import CONTAINERS from 'styles/constants/containers';
import logError from 'utils/logError/logError';
import emailRegex from 'utils/regex/email';

import useStyles from './RegisterForm.styles';

type RegisterFormInput = {
  username: string;
  email: string;
  password: string;
  repeatPassword: string;
};

const RegisterForm = () => {
  const recaptchaRef = useRef<any>();
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
  const [processing, setProcessing] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { onLogin } = useAuthContext();
  const navigate = useNavigate();
  const { state: prevStepState } = useLocation();

  const [registerNewUserMutation] = useMutation(REGISTER_NEW_USER);
  const [updateUserMutation] = useMutation(UPDATE_USER);

  const onSubmit: SubmitHandler<RegisterFormInput> = async data => {
    setProcessing(true);
    let recaptchaResponse;
    try {
      recaptchaResponse = await recaptchaRef.current.executeAsync();
    } catch (error) {
      setGeneralError('Wystąpił problem z połączeniem, zgłoś to nam!');
      recaptchaRef.current.reset();
      setProcessing(false);
      logError(error as Error, 'Register form');
    }
    const input = {
      username: data.username,
      email: data.email,
      password: data.password,
      repeatPassword: data.repeatPassword,
      recaptchaResponse,
      ...prepareSourcesForInput(),
    };
    registerNewUserMutation({ variables: { input } })
      .then(({ data: { registerNewUser } }) => {
        const { token, errors } = registerNewUser;
        if (token) {
          onLogin(token);
          if (prevStepState && prevStepState.newsletter) {
            updateUserMutation({
              variables: {
                input: {
                  newsletter: prevStepState.newsletter,
                },
              },
            });
          }
          navigate(PATHS.ROOT);
        } else if (errors[0]?.messages) {
          recaptchaRef.current.reset();
          setGeneralError(errors[0].messages.join('\n'));
          setProcessing(false);
        } else {
          recaptchaRef.current.reset();
          setGeneralError('Wystąpił jakiś bład, zgłoś to nam!');
          setProcessing(false);
        }
      })
      .catch(() => {
        setGeneralError('Wystąpił problem z połączeniem, zgłoś to nam!');
        setProcessing(false);
      });
  };

  const { control, handleSubmit, watch } = useForm<RegisterFormInput>();
  const passwordValue = watch('password');

  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper size={CONTAINERS.FORM_M}>
        <FormTextInput
          control={control}
          label='Nazwa użytkownika'
          name='username'
          required
          rules={{ pattern: { value: /.{3,}/i, message: 'Nazwa użytkownika musi mieć więcej niż trzy znaki!' } }}
        />
        <FormTextInput
          control={control}
          inputProps={{ type: 'email' }}
          label='Email'
          name='email'
          required
          rules={{ pattern: { value: emailRegex, message: 'To nie jest poprawny e-mail!' } }}
        />
        <FormTextInput
          control={control}
          inputProps={{ type: 'password' }}
          label='Hasło'
          name='password'
          required
          rules={{
            validate: {
              match: validatePassword,
            },
          }}
        />
        <FormTextInput
          control={control}
          inputProps={{ type: 'password' }}
          label='Powtórz hasło'
          name='repeatPassword'
          required
          rules={{
            validate: {
              match: (value: string) => (value === passwordValue ? true : 'Hasła nie pasują do siebie!'),
            },
          }}
        />
      </FormWrapper>
      <FormWrapper size={305}>
        <ReCAPTCHA size='invisible' ref={recaptchaRef} sitekey={recaptchaSiteKey} />
        <LoaderButton isLoading={processing}>
          <Button color='primary' size='large' type='submit' variant='contained'>
            Dalej
          </Button>
        </LoaderButton>
        {generalError && <GeneralError>{generalError}</GeneralError>}
        <Button component={Link} size='large' to={PATHS.REGISTER} variant='text'>
          <Arrow className={classes.icon} />
          Powrót do wszystkich opcji
        </Button>
      </FormWrapper>
    </form>
  );
};

export default RegisterForm;
