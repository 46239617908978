import React from 'react';

import PageTitle from 'components/PageTitle/PageTitle';
import FullScreen from 'containers/FullScreen/FullScreen';
import ExerciseDrawProvider from 'context/ExerciseDrawContext';
import TablesProvider from 'context/TablesContext';
import useProtectedPage from 'services/common/useProtectedPage/useProtectedPage';

import ExercisesSetsContent from './_components/ExercisesSetsContent/ExercisesSetsContent';

const ExercisesSetsPage = () => {
  useProtectedPage();
  return (
    // @ts-ignore
    <ExerciseDrawProvider dataSource='common_store'>
      <PageTitle>Zestawy zadań</PageTitle>
      <FullScreen>
        <TablesProvider dataSource='common_store'>
          <ExercisesSetsContent />
        </TablesProvider>
      </FullScreen>
    </ExerciseDrawProvider>
  );
};

export default ExercisesSetsPage;
