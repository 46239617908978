import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Picture } from 'react-responsive-picture';

import image from 'assets/decorative_pics/error.png';
import image2x from 'assets/decorative_pics/error@2x.png';
import FullScreen from 'containers/FullScreen/FullScreen';
import { Button } from '@material-ui/core';
import axios from 'axios';
import DataPersist, { DATA_PERSIST_KEY } from 'utils/dataPersist/dataPersist';
import authManager from 'utils/authManager/authManager';
import useRedirectsStore from 'storages/redirects/useRedirects';
import logError from 'utils/logError/logError';

const useStyles = makeStyles({
  picture: {
    maxHeight: '500px',
    maxWidth: '80vw',
  },
  wrapper: {
    display: 'flex',
    gap: '3rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

const shouldFlushDataStorage = new DataPersist(DATA_PERSIST_KEY.SHOULD_FLUSH_STORAGES, 'sessionStorage');

const shouldFlushDataStorageValue = shouldFlushDataStorage.get();

function SomethingWentWrong() {
  const { pruneRedirectOnLogin } = useRedirectsStore();

  const flushData = async () => {
    logError({ customMessage: 'User reloaded error page' }, 'On error reload', true);
    // clear cookies
    authManager.removeCookieAuthData();

    // clear sessionStorage
    sessionStorage.clear();

    // clear localStorage
    localStorage.clear();

    // axios headers
    axios.defaults.headers.common.Authorization = undefined;

    // clear query storages?

    // url redirect
    pruneRedirectOnLogin();
  };

  const onClick = () => {
    shouldFlushDataStorage.set(true);
    window.location.reload(true);
  };

  useEffect(() => {
    const onFlush = async () => {
      if (shouldFlushDataStorageValue) {
        shouldFlushDataStorage.set(false);
        await flushData();
        window.location.reload(true);
        window.location = '/';
      }
    };
    if (shouldFlushDataStorageValue) onFlush();
  }, []);

  const { wrapper, picture } = useStyles();
  return (
    <FullScreen footerFree headerFree>
      {}
      <div className={wrapper}>
        <Picture
          className={picture}
          sources={[
            {
              srcSet: `${image} 1x, ${image2x} 2x`,
            },
          ]}
        />
        <Button color='primary' onClick={onClick} size='large' variant='contained'>
          Przeładuj stronę
        </Button>
      </div>
    </FullScreen>
  );
}

export default SomethingWentWrong;
