import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useUserDataContext } from 'context/UserDataContext';

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: ({ popover }) => (popover ? theme.palette.grey[700] : theme.palette.common.white),
    fontSize: '11px',
    alignItems: 'center',
    opacity: ({ disabled }) => disabled && 0.4,
  },
  label: {
    margin: '0',
    lineHeight: ({ isLandscape }) => isLandscape && '14px',
  },
  icon: {
    height: ({ popover }) => (popover ? '28px' : '18px'),
    width: ({ popover }) => (popover ? '28px' : '18px'),
    marginBottom: theme.spacing(1),
  },
}));

function NavigationItem({ children, onClick, icon: Icon, disabled, popover, iconProps, ...rest }) {
  const { isLandscape } = useUserDataContext();
  const classes = useStyles({ disabled, isLandscape, popover });
  return (
    <Button className={classes.button} disabled={disabled} onClick={onClick} {...rest}>
      <div className={classes.wrapper}>
        <Icon className={classes.icon} {...iconProps} />
        <p className={classes.label}>{children}</p>
      </div>
    </Button>
  );
}

NavigationItem.defaultProps = {
  disabled: false,
  popover: false,
  iconProps: {},
};

export default NavigationItem;
