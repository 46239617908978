import React, { useMemo } from 'react';

import { Select } from '@material-ui/core';

import FiltersFormControl from 'components/FiltersFormControl/FiltersFormControl';
import { useUserDataContext } from 'context/UserDataContext';
import parseValuesToSelect from 'services/common/parseValuesToSelect/parseValuesToSelect';
import useSheetsStore from 'storages/sheets/sheets';

import FiltersBlock from 'uniqueComponents/Exercises/ExercisesFilters/FiltersBlock/FiltersBlock';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

function SheetsYear() {
  const { logEvent } = useUserLogContext();
  const { sheets, year, setYear } = useSheetsStore();
  const { isMobile } = useUserDataContext();

  const onChange = ({ target: { value } }) => {
    logEvent('select-filter-click', { filter: 'Arkusze - rok', value, feature: 'sheets' });
    setYear(value);
  };

  const years = useMemo(() => {
    if (!sheets) return [];
    const data = Object.keys(sheets).map(value => ({ value, label: value }));
    return parseValuesToSelect({ data, isMobile, placeholder: 'Wybierz...' });
  }, [sheets]);

  return (
    <FiltersBlock heading='Rok'>
      <FiltersFormControl size='small' variant='outlined'>
        <Select disabled={!sheets} displayEmpty native={isMobile} onChange={onChange} value={year || ''}>
          {years}
        </Select>
      </FiltersFormControl>
    </FiltersBlock>
  );
}

export default SheetsYear;
