import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import DataPersist, { DATA_PERSIST_KEY } from 'utils/dataPersist/dataPersist';
import detectMobile from 'utils/detectMobile/detectMobile';

const UserDataContext = createContext(null);

const { Provider } = UserDataContext;

const isMobileLocal = new DataPersist(DATA_PERSIST_KEY.USER_DEVICE, 'localStorage');

const isMobile = (() => {
  const isMobileLocalData = isMobileLocal.get();
  if (isMobileLocalData !== undefined && process.env.NODE_ENV !== 'development') return isMobileLocalData;
  const result = detectMobile();
  isMobileLocal.set(result);
  return result;
})();

const intersectionObserverSupport = !!window.IntersectionObserver;

const checkLandscape = window => {
  if (!window || !isMobile) return null;
  return window.innerWidth > window.innerHeight;
};

const value = {
  isMobile,
  intersectionObserverSupport,
};

const UserDataProvider = props => {
  const [isLandscape, setIsLandscape] = useState(null);

  const checkLogicOnResize = useCallback(() => {
    setIsLandscape(checkLandscape(window));
  }, [window]);

  useEffect(() => {
    checkLogicOnResize();
    window.addEventListener('resize', checkLogicOnResize);
    return () => window.removeEventListener('resize', checkLogicOnResize);
  }, []);

  return <Provider value={{ isLandscape, ...value }} {...props} />;
};

export const useUserDataContext = () => useContext(UserDataContext);

export default UserDataProvider;
