import React from 'react';

import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

import image from 'assets/decorative_pics/laptop-heart.png';
import mmEmail from 'constants/dictionaries/contactData';

const useStyles = makeStyles(theme => ({
  grid: {
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  planName: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  validUntil: {
    fontWeight: 700,
  },
  image: {
    maxWidth: '415px',
    margin: '20px 0 20px -10%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '375px',
      marginLeft: '-7%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '240px',
      marginLeft: '0',
    },
  },
}));

function SubscriptionInfo({ validDate, subscriptionType }) {
  const downXs = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const classes = useStyles();
  return (
    <Grid className={classes.grid} container spacing={10}>
      <Grid item xs={12}>
        Obecnie korzystasz z:
        {downXs ? <br /> : ' '}
        <span className={classes.planName}>{subscriptionType}</span>
      </Grid>
      <Grid item xs={12}>
        Plan ważny do:
        {downXs ? <br /> : ' '}
        <span className={classes.validUntil}>{format(validDate, 'dd MMMM yyyy', { locale: pl })}</span>
      </Grid>
      <Grid item xs={12}>
        Dziękujemy za zakup Planu Premium! Przedłużenie planu o kolejne dni będzie możliwe po wygaśnięciu bieżącego okresu rozliczeniowego.
        Jeśli masz pytania dotyczące twojego konta zapraszamy do kontaktu po adresem email:
        {downXs ? <br /> : ' '}
        <a href={`mailto:${mmEmail}`}>{mmEmail}</a>
      </Grid>
      <Grid container item justify='center' xs={12}>
        <img alt='' className={classes.image} src={image} />
      </Grid>
    </Grid>
  );
}

export default SubscriptionInfo;
