import React from 'react';

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isNil } from 'lodash';

import useExercisesStore from 'storages/exercises/exercises';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkboxRoot: {
    padding: '4px 9px',
    [theme.breakpoints.down('sm')]: {
      '& .MuiSvgIcon-root': {
        fontSize: '1.25rem',
      },
    },
  },
  formControl: {
    '& .MuiTypography-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
  },
}));

function GenericFiltersList({ filters, filterName, setFilters, radio, changeCallback, heading }) {
  const { curryLogEvent } = useUserLogContext();
  const exercisesStore = useExercisesStore();

  const onChange = ({ target: { value, checked } }) => {
    let newFilter = [value];
    if (!radio) {
      const source = exercisesStore[filterName];
      newFilter = checked ? [...source, value] : source.filter(v => v !== value);
    }
    setFilters(newFilter);
    if (changeCallback) changeCallback({ value, checked });
  };

  const isChecked = id => {
    if (!exercisesStore[filterName]) return false;
    return radio ? id === exercisesStore[filterName] : exercisesStore[filterName].includes(id);
  };
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {filters &&
        filters.map(({ name, id, exercisesCount }) => (
          <div key={id} className={classes.row}>
            <FormControlLabel
              key={id}
              className={classes.formControl}
              control={
                <Checkbox
                  checked={isChecked(id)}
                  classes={{ root: classes.checkboxRoot, icon: classes.checkboxIcon }}
                  color='primary'
                  onChange={curryLogEvent(onChange, 'select-filter-click', { filter: `${heading} - ${name}`, value: !isChecked(id) })}
                  value={id}
                />
              }
              label={name}
            />
            {!isNil(exercisesCount) && <Typography color='textSecondary'>{exercisesCount}</Typography>}
          </div>
        ))}
    </div>
  );
}

GenericFiltersList.defaultProps = {
  radio: false,
  changeCallback: () => {},
  filters: null,
};

export default GenericFiltersList;
