import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import PageLoader from 'components/PageLoader/PageLoader';
import useAuthRouterState from 'root/_hooks/useAuthRouterState';
import LayoutUnauthorized from 'root/LayoutUnauthorized/LayoutUnauthorized';
import useRedirectsStore from 'storages/redirects/useRedirects';
import { AuthRouterState } from 'types/AuthRouterState';

const AuthRoute: React.FC = () => {
  const { state, from } = useAuthRouterState();
  const { redirectOnLogin } = useRedirectsStore();

  switch (state) {
    case AuthRouterState.LOADING:
      return <PageLoader />;
    case AuthRouterState.AUTHORIZED:
      return <Navigate replace state={{ from }} to={redirectOnLogin} />;
    case AuthRouterState.UNAUTHORIZED:
    default:
      return (
        <LayoutUnauthorized>
          <Outlet />
        </LayoutUnauthorized>
      );
  }
};

export default AuthRoute;
