import React, { useEffect } from 'react';

import { Drawer } from '@material-ui/core';
import { useQuery } from 'react-query';

import notificationsApi from 'api/rest/notifications/notifications';
import { Notification } from 'api/rest/notifications/notifications.types';
import DrawerWrapper from 'components/DrawerWrapper/DrawerWrapper';
import Loader from 'components/Loader/Loader';
import NotificationCard from 'components/NotificationCard/NotificationCard';
import QUERY from 'constants/queryKeys';

import useStyles from './NotificationsDrawer.styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const NotificationsDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const { data, refetch } = useQuery(QUERY.GET_ALL_NOTIFICATIONS, notificationsApi.getAll());

  useEffect(() => {
    if (isOpen) refetch();
  }, [isOpen]);

  const classes = useStyles();
  return (
    <Drawer onClose={onClose} open={isOpen} anchor='right'>
      <DrawerWrapper onClose={onClose} title='Powiadomienia'>
        <div className={classes.root}>
          {data ? data?.map((notification: Notification) => <NotificationCard key={notification.id} {...notification} />) : <Loader />}
        </div>
      </DrawerWrapper>
    </Drawer>
  );
};

export default NotificationsDrawer;
