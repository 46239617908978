import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import messages from 'constants/dictionaries/messages';
import { useUserDataContext } from 'context/UserDataContext';
import useDidUpdateEffect from 'services/common/useDidUpdateEffect/useDidUpdateEffect';
import useCommitError from 'services/common/useCommitError/useCommitError';
import logError from 'utils/logError/logError';
import DataPersist, { DATA_PERSIST_KEY } from 'utils/dataPersist/dataPersist';

import ImageWithLoader from '../ImageWithLoader/ImageWithLoader';

const useStyles = makeStyles(theme => ({
  wrapper: {
    zIndex: '1',
    background: ({ loading }) => (loading ? 'none' : theme.palette.common.white),
    transition: '.25s ease .05s',
  },
  image: ({ loading }) => ({
    display: 'block',
    width: '100%',
    margin: loading ? 'none' : '1rem auto',
    visibility: loading ? 'hidden' : 'visible',

    [theme.breakpoints.down('sm')]: {
      margin: loading ? 'none' : '.25rem auto',
    },
  }),
}));

const ANIMATION_TIME = 100;
const mediaUrl = process.env.REACT_APP_MEDIA_URL;

const mapUrls = url => ({
  url: `${mediaUrl}${url}`,
  loaded: false,
});

const reloaded = new DataPersist(DATA_PERSIST_KEY.IMAGE_RELOADED, 'localStorage');

function ImageWithLoaderWrapper({ urls, onError, onLoad, setZoomed, zoomed, loading }) {
  const commitGlobalError = useCommitError();

  // LOAD logic
  const [sources, setSources] = useState(urls.map(mapUrls));
  const updateSources = newSources => {
    const sourcesWithId = newSources.map((source, index) => {
      const id = new URL(source.url).pathname + index;
      return { id, ...source };
    });
    setSources(sourcesWithId);
  };

  const onCommitError = ({ target }) => {
    if (reloaded.get()) {
      onError();
      logError({ message: `Unable to load image from ${target.src}` }, 'image loading');
    } else {
      window.location.reload(true);
      reloaded.set(true);
    }
  };

  const onCommitLoad = ({ target }) => {
    try {
      const indexToUpdate = sources.findIndex(({ url }) => url === target.src);
      const newSources = [...sources];
      newSources[indexToUpdate].loaded = true;
      updateSources(newSources);
      reloaded.set(false);
    } catch (error) {
      commitGlobalError(error, messages.ERROR.FETCHING('zadania'), 'ImageWithLoaderWrapper: onCommitLoad');
    }
  };

  useEffect(() => {
    if (sources.every(({ loaded }) => loaded)) onLoad();
  }, [sources]);
  useDidUpdateEffect(() => {
    updateSources(urls.map(mapUrls));
  }, [urls]);

  // ZOOM logic
  const zoomRef = useRef();
  const { isMobile } = useUserDataContext();
  const onZoom = ({ state }) => {
    if (setZoomed) {
      setTimeout(() => setZoomed(state.scale > 1), ANIMATION_TIME);
    }
  };

  useEffect(() => {
    if (zoomRef.current) zoomRef.current.resetTransform();
  }, [sources, zoomRef]);

  const classes = useStyles({ loading });
  return (
    <div className={classes.wrapper}>
      <TransformWrapper
        ref={zoomRef}
        doubleClick={{
          step: 0.5,
          animationTime: ANIMATION_TIME,
          disabled: !isMobile,
        }}
        limitToBounds
        onZoom={onZoom}
        panning={{ disabled: !zoomed, paddingSize: 3, maxScale: 1.5 }}
        wheel={{ disabled: true }}
      >
        <TransformComponent>
          {sources.map(({ url, id }) => (
            <ImageWithLoader key={id} className={classes.image} commitError={onCommitError} commitLoad={onCommitLoad} src={url} />
          ))}
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
}

ImageWithLoaderWrapper.defaultProps = {
  setZoomed: null,
  zoomed: false,
  loading: false,
  onLoad: () => null,
  onError: () => null,
};

export default ImageWithLoaderWrapper;
