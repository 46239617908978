import gql from 'graphql-tag';

const GET_EXERCISE_DRAW = gql`
  query GetExerciseDraw($uuid: String!) {
    getExercise(uuid: $uuid) {
      __typename
      userData {
        drawingData
        drawingEncodingType
      }
    }
  }
`;

export default GET_EXERCISE_DRAW;
