import gql from 'graphql-tag';

const GET_SUBJECTS = gql`
  query getSubjectsList {
    subjects {
      subjectCode
      name
      id
      active
      pk
    }
  }
`;

export default GET_SUBJECTS;
