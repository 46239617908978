import { isNumber } from 'lodash';

import logError from 'utils/logError/logError';

export default (number, fractionDigits = 2) => {
  const toNumber = +number;
  if (!isNumber(toNumber)) {
    logError({ message: 'passed price is not a number' });
    return undefined;
  }
  const fixedNumber = `${parseFloat(toNumber.toFixed(fractionDigits))}`;
  return `${fixedNumber.replace('.', ',')} PLN`;
};
