import React, { JSXElementConstructor, ReactElement } from 'react';

const LoaderButtonModifyChildren = (
  child: ReactElement<{ className: string; disabled: boolean }, JSXElementConstructor<{ className: string; disabled: boolean }>>,
  extraClassName: string,
  isLoading: boolean,
) => {
  const props = {
    ...child.props,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    className: child?.className ? `${child.className} ${extraClassName}` : extraClassName,
    disabled: child.props.disabled || isLoading,
  };

  return React.cloneElement(child, props);
};

export { LoaderButtonModifyChildren };
