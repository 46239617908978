import React, { useCallback, useEffect } from 'react';

import { Button } from '@material-ui/core';
import { format } from 'date-fns';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';

import exercisesSetApi from 'api/rest/exercisesSets/exercisesSet';
import { ExerciseInSetDto } from 'api/rest/exercisesSets/exercisesSet.types';
import SnackbarActionsContainer from 'components/SnackbarActionsContainer/SnackbarActionsContainer';
import SWMessageTypes from 'constants/dictionaries/SWMessageTypes';
import { useAuthContext } from 'context/AuthContext';
import { useUserDataContext } from 'context/UserDataContext';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import { parseSubjectCodeToSubjectNumber, resolveSubjectNameFromSubjectCode } from 'services/common/subjectParsers/subjectParsers';
import useChosenExercisesStore from 'storages/chosenExercises/chosenExercises';
import { SupportedSubjects } from 'types/Subject';

// // Detects if device is on iOS
// const isIos = () => {
//   const userAgent = window.navigator.userAgent.toLowerCase();
//   return /iphone|ipad|ipod/.test(userAgent);
// };
// // Detects if device is in standalone mode
// const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;
//
// const isPWAInstalled = async () => {
//   if ('getInstalledRelatedApps' in window.navigator) {
//     const relatedApps = await window.navigator.getInstalledRelatedApps();
//     relatedApps.forEach(app => {
//       console.log(app.id, app.platform, app.url);
//     });
//   }
//   return false;
// };

const useApp = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { warehouseToken, token } = useAuthContext() || {};
  const { logEvent } = useUserLogContext();
  const { enqueueSnackbar } = useSnackbar();

  const globalMessageListener = (event: any) => {
    const { type, msg, options } = event.data;
    if (type === SWMessageTypes.GLOBAL) {
      enqueueSnackbar(msg, options);
    }
  };

  const listenerForSWUpdates = async () => {
    const registration = await navigator.serviceWorker.getRegistration();
    if (registration) {
      // @ts-ignore
      registration.addEventListener('updatefound', (event: { target: { installing: ServiceWorker } }) => {
        const newSW = event.target.installing;
        newSW.addEventListener('statechange', () => {
          if (newSW.state === 'installed') {
            const reload = () => {
              newSW.postMessage('SKIP_WAITING');
              window.location.reload();
            };

            enqueueSnackbar('Dostępna jest nowa wersja aplikacji', {
              persist: true,
              action: (snackbarKey: SnackbarKey) => (
                <SnackbarActionsContainer snackbarKey={snackbarKey}>
                  <Button onClick={reload} color='inherit'>
                    ODŚWIEŻ
                  </Button>
                </SnackbarActionsContainer>
              ),
            });
          }
        });
      });
    }
  };

  // @ts-ignore
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      listenerForSWUpdates();

      navigator.serviceWorker.addEventListener('message', globalMessageListener);
      return () => navigator.serviceWorker.removeEventListener('message', globalMessageListener);
    }
  }, []);

  // LOG EVENTS
  const onFocus = useCallback(() => logEvent('enter-tab', { url: window.location.href }), [logEvent]);
  const onBlur = useCallback(() => logEvent('leave-tab', { url: window.location.href }), [logEvent]);
  const onBeforeUnload = useCallback(() => logEvent('close-tab', { url: window.location.href }), [logEvent]);

  useEffect(() => {
    logEvent('open-app');

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [warehouseToken]);

  // TODO - exercisesSet - extract to hoook

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isMobile } = useUserDataContext();
  const createNewExercisesSetMutation = useMutation(
    exercisesSetApi.create.generateQueryKey(),
    exercisesSetApi.create.request(),
    exercisesSetApi.create.requestBaseSettings,
  );
  const queryClient = useQueryClient();

  const { reset: resetChosenExercises } = useChosenExercisesStore();
  const migrateChosenExercises = (setName: string, exercises: ExerciseInSetDto[]) =>
    createNewExercisesSetMutation.mutateAsync({ exercises, name: setName });

  useEffect(() => {
    const isLoggedIn = !!token;
    const migratedKey = 'chosenExercises-migrated';
    const chosenExercisesKey = 'chosenExercises';
    const localExercisesString = window.localStorage.getItem(chosenExercisesKey);
    try {
      if (localExercisesString && !window.localStorage.getItem(migratedKey) && isLoggedIn) {
        (async () => {
          try {
            const localExercises = JSON.parse(localExercisesString);
            await Promise.all(
              Object.entries(localExercises.state.chosenExercises as Record<SupportedSubjects, { index: number; id: string }[]>)
                .map(([subjectCode, exercises]) => {
                  const subject = resolveSubjectNameFromSubjectCode(subjectCode as SupportedSubjects, 'full');
                  if (subject) {
                    return migrateChosenExercises(
                      `${subject}${isMobile ? ' - mobile' : ''} (zestaw zmigrowany - ${format(new Date(), 'dd-MM-yyyy')})`,
                      exercises.map(({ id, index }) => ({
                        uuid: id,
                        index,
                        subject: parseSubjectCodeToSubjectNumber(subjectCode as SupportedSubjects),
                      })),
                    );
                  }
                  return null;
                })
                .filter(call => !!call),
            );
            await queryClient.invalidateQueries(exercisesSetApi.getAll.generateQueryKey());
          } finally {
            resetChosenExercises();
            window.localStorage.removeItem(chosenExercisesKey);
            window.localStorage.setItem(migratedKey, new Date().toString());
          }
        })();
      }
    } catch (e) {
      window.localStorage.removeItem(chosenExercisesKey);
    }
  }, [token]);

  // useEffect(async () => {
  //   if (!isProduction) {
  //     if (isIos() && !isInStandaloneMode()) {
  //       alert('install it');
  //     }
  //   }
  // }, []);
};

export { useApp };
