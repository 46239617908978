import { isFunction } from 'lodash';
import { useSnackbar } from 'notistack';

import messages from 'constants/dictionaries/messages';

import useCommitError from 'services/common/useCommitError/useCommitError';

export default () => {
  const commitError = useCommitError();
  const { enqueueSnackbar } = useSnackbar();

  /**
   *
   * @param {Function} graphQL api call
   * @param {String} errorMsg
   * @param {String} successMessage
   * @returns {Promise<unknown>}
   */
  return (call, errorMsg = messages.ERROR.SAVING(), successMsg = null) =>
    new Promise((resolve, reject) => {
      if (isFunction(call)) {
        try {
          call()
            .then(response => {
              if (response.errors || response.error) {
                const error = response.error ? response.error : {};
                const errors = response.errors ? response.errors : {};
                commitError({ ...error, ...errors }, errorMsg, 'apiCall: graphQL error');
              } else if (successMsg) {
                enqueueSnackbar(successMsg, { variant: 'success' });
              }
              resolve(response);
            })
            .catch(error => {
              commitError(error, errorMsg, 'apiCall');
              reject(error);
            });
        } catch (error) {
          commitError(error, errorMsg, 'apiCall');
          reject(error);
        }
      } else reject(new Error('Passed argument must be a function'));
    });
};
