import { makeStyles } from '@material-ui/core';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

type StylesProps = {
  visible: boolean;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(7, 0),
    display: 'grid',
    gridGap: theme.spacing(6),
    gridTemplateColumns: `${LAYOUT_SIZES.CONTAINER.FORM.md} auto`,
    gridTemplateRows: '95px auto',
    transition: '.3s ease',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: `${LAYOUT_SIZES.CONTAINER.FORM.sm} auto`,
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      flexGrow: 1,
    },
  },
  drawer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    background: theme.palette.common.white,
    zIndex: 101,
    left: 0,
    right: 0,
    padding: `${LAYOUT_SIZES.FOOTER.sm * 1.5}px ${theme.spacing(4)}px`,
    transition: '.25s ease',
    overflow: 'hidden',
    minHeight: '100vh',
    height: ({ visible }: StylesProps) => (visible ? 'auto' : '10vh'),
    transform: ({ visible }: StylesProps) => `translateX(${visible ? '0' : '-100%'})`,
  },
}));

export default useStyles;
