import React, { useCallback, useState } from 'react';

import { Button, Typography } from '@material-ui/core';

import { ReactComponent as PrinterIcon } from 'assets/iconhub/printer.svg';
import ExerciseViewTile from 'components/ExerciseViewTile/ExerciseViewTile';
import { ExerciseToDisplay } from 'storages/exercisesToDisplay/_exercisesToDisplay_base';

import PrintExercisesDialog from '../_dialogs/PrintExercisesDialog/PrintExercisesDialog';
import useStyles from './PrintPanel.styles';

type Props = {
  isStuck?: boolean;
  exercisesCount: number; // TODO remove after exercises source unify
  exercises?: ExerciseToDisplay[];
};

const MAX_TO_PRINT = 50; // to increase it - logic changes are needed

const PrintPanel: React.FC<Props> = ({ isStuck, exercisesCount, exercises }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = useCallback(() => setIsDialogOpen(true), [setIsDialogOpen]);
  const closeDialog = useCallback(() => setIsDialogOpen(false), [setIsDialogOpen]);

  const classes = useStyles({ isStuck: !!isStuck });

  if (!exercisesCount) {
    return (
      <ExerciseViewTile className={classes.tile}>
        <Typography variant='caption'>Wybierz zadania, żeby odblokować możliwość drukowania</Typography>
      </ExerciseViewTile>
    );
  }

  if (exercisesCount > MAX_TO_PRINT) {
    return (
      <ExerciseViewTile className={classes.tile}>
        <Typography variant='caption'>
          Opcja drukowania będzie dostępna jeśli liczba dostępnych, przefiltrowanych zadań będzie nie większa niż {MAX_TO_PRINT}
        </Typography>
      </ExerciseViewTile>
    );
  }

  return (
    <>
      <ExerciseViewTile className={classes.tile}>
        <Button disableRipple color='primary' onClick={openDialog}>
          <PrinterIcon className={classes.icon} />
          Wydrukuj
        </Button>
      </ExerciseViewTile>
      <PrintExercisesDialog exercises={exercises} open={isDialogOpen} onClose={closeDialog} />
    </>
  );
};

export default PrintPanel;
