import { CSSProperties } from 'react';

import { makeStyles } from '@material-ui/core';

const absoluteCenterStyles: object = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paginationBar: {
    width: '100%',
    display: 'block',
    borderBottom: 'none',
  },
  row: {
    cursor: 'pointer',

    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  loaderWrapper: {
    ...absoluteCenterStyles,
  },
  fallbackMessage: {
    ...absoluteCenterStyles,
    textAlign: 'center',
    maxWidth: '360px',
  },
  tableBody: {
    position: 'relative',
  },
}));

export default useStyles;
