import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import logError from 'utils/logError/logError';
import authManager from 'utils/authManager/authManager';

const getToken = () => {
  const token = authManager.getCookieAuthData()?.token;
  return token ? `JWT ${token}` : '';
};

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  fetchOptions: {
    mode: 'cors',
    signal: new AbortController().signal,
  },
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: getToken(),
  },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      logError(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, 'APOLLO CLIENT'),
    );

  if (networkError) logError(`[Network error]: ${networkError}`, 'APOLLO NETWORK');
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authLink.concat(httpLink), errorLink]),
});

export default client;
