import React from 'react';

import { Dialog } from '@material-ui/core';

import UserFeedbackForm from 'components/UserFeedbackForm/UserFeedbackForm';

type Props = {
  onClose: () => void;
};

const UserFeedbackDialog: React.FC<Props> = ({ onClose }) => (
  <Dialog onClose={onClose} open>
    <UserFeedbackForm closeDialog={onClose} />
  </Dialog>
);

export default UserFeedbackDialog;
