import React from 'react';

import { Routes, Route } from 'react-router-dom';

import PATHS, { AUTH_PREFIX, INTERNAL_PREFIX } from 'constants/paths';
import { internalRoutes, authRoutes, NestedRoute as RouteType } from 'constants/routes';
import DashboardPage from 'pages/DashboardPage/DashboardPage';
import DocumentsPage from 'pages/DocumentsPage/DocumentsPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import Page404 from 'pages/Page404/Page404';
import AuthRoute from 'root/AuthRoute/AuthRoute';
import PrivateRoute from 'root/PrivateRoute/PrivateRoute';

const renderer = (routesToRender: RouteType[]) =>
  routesToRender.map(routeConfig => {
    const { path, element: Element, subRoutes } = routeConfig;
    if (subRoutes) {
      const indexRoute = subRoutes.find(({ index }) => index);
      return (
        <Route element={<Element />} key={path} path={path}>
          {indexRoute && <Route element={<indexRoute.element />} index />}
          {renderer(subRoutes)}
        </Route>
      );
    }
    return <Route element={<Element />} key={path} path={path} />;
  });

const Router: React.FC = () => (
  <Routes>
    <Route element={<DocumentsPage />} path={PATHS.DOCUMENTS} />
    <Route element={<AuthRoute />} path={AUTH_PREFIX}>
      <Route element={<LoginPage />} index />
      {authRoutes.map(routeConfig => {
        const { path, element: Element } = routeConfig;
        return <Route element={<Element />} key={path} path={path} />;
      })}
    </Route>
    <Route element={<PrivateRoute />} path={INTERNAL_PREFIX}>
      <Route element={<DashboardPage />} index />
      {renderer(internalRoutes)}
    </Route>
    <Route element={<Page404 />} path='*' />
  </Routes>
);
export default Router;
