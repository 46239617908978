import create from 'zustand';
import { devtools } from 'zustand/middleware';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

interface ClassroomExercisesFiltersStore {
  subject: string | number;
  section: string | number;
  subsection: string | number;
  since: Date | null;
  until: Date | null;
  update: (key: keyof ClassroomExercisesFiltersStore, value: string | Date | null) => void;
}

const classroomExercisesFiltersStore = devtools<ClassroomExercisesFiltersStore>(
  set => ({
    subject: '',
    section: '',
    subsection: '',
    since: null,
    until: null,
    update: (key, value) => {
      const rest: { section?: string; subsection?: string } = {};
      if (key === 'section' || key === 'subject') rest.subsection = '';
      if (key === 'subject') rest.section = '';

      set({ [key]: value, ...rest });
    },
  }),
  { name: 'CLASSROOM EXERCISES FILTERS STORE', enabled: !isProduction },
);

const useClassroomExercisesFiltersStore = create(classroomExercisesFiltersStore);

export default useClassroomExercisesFiltersStore;
