import React from 'react';

import { Paper } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './ExerciseViewTile.styles';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const ExerciseViewTile: React.FC<Props> = ({ children, className }) => {
  const classes = useStyles();
  return (
    <Paper square className={clsx(classes.root, className)}>
      {children}
    </Paper>
  );
};

export default ExerciseViewTile;
