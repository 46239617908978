import gql from 'graphql-tag';

export const queryString = `
  mutation Cha($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      errors {
        messages
        field
      }
    }
  }
`;

const UPDATE_PASSWORD = gql`
  ${queryString}
`;

export default UPDATE_PASSWORD;
