import client from 'api/graphql/config/client';
import GET_EXERCISES_LIST_FOR_SHEETS from 'api/graphql/queries/exercises/GET_EXERCISES_LIST_FOR_SHEETS';
import logError from 'utils/logError/logError';

import useSubjectStore from '../subject';
import useUserPreferencesStore from '../userPreferences';

const parseVariables = base => {
  const { subject } = useSubjectStore.getState();
  const { year, month, curriculum } = base;
  const parseArrayLike = value => (value?.length ? value : null);

  return {
    subject: subject.id,
    orderBy: 'date',
    curriculum: parseArrayLike(curriculum),
    year,
    month,
  };
};

const getExercisesForSheets = async variables => {
  const { setHideMarking } = useUserPreferencesStore.getState();
  setHideMarking(false);
  try {
    const { data } = await client.query({
      query: GET_EXERCISES_LIST_FOR_SHEETS,
      fetchPolicy: 'no-cache',
      variables,
    });
    if (data) {
      const exercises = data?.sheetExercises;
      const [currentExercise] = exercises;
      return {
        allExercisesCompleted: false,
        exercises,
        currentExercise,
        exercisesCount: exercises ? exercises.length : 0,
        exerciseCursor: 0,
        fetched: true,
      };
    }
    return {};
  } catch (error) {
    sessionStorage.removeItem(`${process.env.REACT_APP_VERSION}_sheets`);
    window.location.href = '/';
    logError(error);
    return {};
  }
};

export { parseVariables };
export default getExercisesForSheets;
