import type {
  Agreement,
  AgreementDto,
  GetAgreementsParams,
  GetAgreementsParamsDto,
  SetAgreementsData,
  SetAgreementsDataDto,
} from './agreements.types';

export const parseAgreementsParams = (params?: GetAgreementsParams): GetAgreementsParamsDto => ({
  has_consent: params?.hasConsent,
});

export const parseAgreementsForFE = (raw: AgreementDto[]): Agreement[] =>
  raw.map(rawAgreement => ({
    category: rawAgreement.category,
    content: rawAgreement.content,
    file: rawAgreement.file,
    title: rawAgreement.title,
    id: rawAgreement.id,
    hasConsent: rawAgreement.has_consent,
    validSince: new Date(rawAgreement.valid_since),
  }));

export const parseAgreementDataForBE = (dataFromUI: SetAgreementsData): SetAgreementsDataDto => [{ id: dataFromUI.id }];
