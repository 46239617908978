import React, { ChangeEvent, useEffect, useState } from 'react';

import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { format } from 'date-fns';
import { useIsMutating, useMutation, useQueryClient } from 'react-query';

import notificationsApi from 'api/rest/notifications/notifications';
import { Notification } from 'api/rest/notifications/notifications.types';
import QUERY from 'constants/queryKeys';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import useLayoutStore from 'storages/layout';

import useStyles from './NotificationCard.styles';

type Props = Notification;

const NotificationCard: React.FC<Props> = ({ title, body, isRead, createdAt, id }) => {
  const { logEvent } = useUserLogContext();

  const queryClient = useQueryClient();
  const markAsReadMutation = useMutation(['setAsRead', id], notificationsApi.setAsRead(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY.GET_ALL_NOTIFICATIONS);
      queryClient.invalidateQueries(QUERY.PERIODIC_GET_NEW_NOTIFICATIONS);
    },
  });
  const isMutating = useIsMutating(['setAsRead', id]);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onChange: (e: ChangeEvent<any>, newState: boolean) => void = (e, newState) => {
    setIsExpanded(newState);
  };

  const onExpand = () => {
    logEvent('notifications-detail-click', { title, isRead });
    if (!isRead && !isMutating) markAsReadMutation.mutate();
  };

  const { removeExpandedNotifications, expandedNotifications } = useLayoutStore();

  useEffect(() => {
    if (expandedNotifications.includes(id)) {
      setIsExpanded(true);
      onExpand();
      removeExpandedNotifications(id);
    }
  }, [expandedNotifications]);

  const classes = useStyles({ showUnreadIndicator: !isRead });

  return (
    <Accordion square className={classes.root} expanded={isExpanded} onChange={onChange}>
      <AccordionSummary id={id} onClick={onExpand}>
        <div className={classes.header}>
          <Typography variant='caption'>{format(new Date(createdAt), 'dd MMMM')}</Typography>
          <div className={classes.unreadIndicator} />
          <Typography variant='subtitle1'>{title}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionContentExpanded }} className={classes.content}>
        <Typography variant='body1' dangerouslySetInnerHTML={{ __html: body }} />
      </AccordionDetails>
    </Accordion>
  );
};

export default NotificationCard;
