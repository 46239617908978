import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { FileType } from 'types/FileType';

import setCurrentExercise from '../_actions/setCurrentExercise';
import setCursor from '../_actions/setCursor';
import getExercisesForSheets, { parseVariables } from '../_helpers/getExercisesForSheets';
import getSheetsList from '../_helpers/getSheets';
import { SheetsStore } from './sheets.types';
import sheetsInitialStorage from './sheetsInitialStorage';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

const sheetsStore = persist<SheetsStore>(
  (set, get) => ({
    ...sheetsInitialStorage,
    fetched: false,

    // ACTIONS
    setExerciseCursor: setCursor(set, get),
    setCurrentExercise: setCurrentExercise(set),
    setCurriculum: async (curriculum: string[]) => {
      set({ curriculum });
      const sheets = await getSheetsList(curriculum);
      set({ ...sheetsInitialStorage, curriculum, sheets, fetched: false });
    },
    setYear: async (year: string) => {
      let { month } = sheetsInitialStorage;
      set(state => {
        if (state.sheets && year) {
          const months = state.sheets[year];
          if (months.length === 1) {
            [month] = months;
          }
        }
        return {
          ...sheetsInitialStorage,
          month,
          sheets: state.sheets,
          curriculum: state.curriculum,
          year,
        };
      });
      if (month) {
        const state = get();
        const variables = parseVariables(state);
        const exercisesData = await getExercisesForSheets(variables);
        set(exercisesData);
      } else {
        set({ fetched: false });
      }
    },
    setMonth: async (month: number) => {
      set({ month });
      const state = get();
      const variables = parseVariables(state);
      const exercisesData = await getExercisesForSheets(variables);
      set(exercisesData);
    },
    setFileType: (fileType?: FileType) => set({ fileType }),
    setExerciseMark: (mark: string) => {
      set({ mark });
    },
    reset: () => set({ ...sheetsInitialStorage, fetched: false }),
  }),
  {
    name: `${process.env.REACT_APP_VERSION}_sheets`,
    getStorage: () => sessionStorage,
  },
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useSheetsStore = create(devtools(sheetsStore, { name: 'SHEETS', enabled: !isProduction }));

export default useSheetsStore;
