import { format } from 'date-fns';
import { isNil, omitBy } from 'lodash';

import { ClassroomExercisesTableHeaderValues } from 'components/ClassroomExercisesTable/ClassroomExercisesTable.logic';
import { StudentsMarksTableHeaderValues } from 'components/StudentDetails/StudentDetails.logic';
import { OrderDirectionValues } from 'types/OrderDirectionValues';

import paginatedResponseParser from '../_commonParsers/paginatedResponseParser';
import { ComplexQueryParams, PaginatedData, PaginatedDataDto, PaginationParamsDto } from '../_types';
import {
  ClassroomDto,
  Classroom,
  SchoolDto,
  TeacherDto,
  School,
  Teacher,
  ClassroomExercisesMarksDto,
  ClassroomExercisesMarks,
  Student,
  StudentDto,
  StudentMarksQueryFiltersDto,
  ClassroomMarksQueryParamsDto,
  StudentMarkDto,
  StudentMark,
  StudentsMarksFilters,
  StudentMarksQueryFilters,
  ClassroomMarksFiltersKeys,
  ClassroomExercisesMarksSummary,
  ClassroomExercisesMarksSummaryDto,
  ClassroomMarksFilters,
} from './teacher.types';

type ParseClassroomForUI = (input: ClassroomDto) => Classroom;
type ParseSchoolForUI = (input: SchoolDto) => School;
type ParseStudentsForUI = (input: StudentDto[]) => Student[];
type ParseTeacherForUI = (input: TeacherDto) => Teacher;
type ParseClassroomExercisesMarksForUI = (input: PaginatedDataDto<ClassroomExercisesMarksDto>) => PaginatedData<ClassroomExercisesMarks>;
type ParseStudentMarksParamsForBE = (
  input?: ComplexQueryParams<StudentsMarksFilters, StudentsMarksTableHeaderValues>,
) => PaginationParamsDto & StudentMarksQueryFiltersDto;
type ParseClassroomMarksParamsForBE = (input?: ClassroomMarksFilters) => Partial<ClassroomMarksQueryParamsDto>;
type ParsePaginatedClassroomMarksParamsForBE = (
  input?: ComplexQueryParams<ClassroomMarksFiltersKeys, ClassroomExercisesTableHeaderValues>,
) => PaginationParamsDto & ClassroomMarksQueryParamsDto;
type ParseStudentMarksForUI = (input: PaginatedDataDto<StudentMarkDto>) => PaginatedData<StudentMark>;
type ParseAllStudentsMarksParamsForBE = (input?: StudentMarksQueryFilters) => StudentMarksQueryFiltersDto;
type ParseClassroomExercisesMarksSummaryForUI = (input: ClassroomExercisesMarksSummaryDto) => ClassroomExercisesMarksSummary;

const parseClassroomForUI: ParseClassroomForUI = ({ registered_count, short_name, ...rest }) => ({
  ...rest,
  registeredCount: registered_count,
  shortName: short_name,
});

const parseSchoolForUI: ParseSchoolForUI = ({ short_name, ...rest }) => ({
  ...rest,
  shortName: short_name,
});

const parseStudentMarksForUI: ParseStudentMarksForUI = paginatedData => {
  const resultsParser = (results: StudentMarkDto[]): StudentMark[] =>
    results.map(mark => ({
      createdAt: new Date(mark.created_at),
      updatedAt: new Date(mark.updated_at),
      exerciseIndex: mark.exercise_index,
      exerciseIdentifier: mark.exercise_identifier,
      exerciseUuid: mark.exercise_uuid,
      exerciseSectionName: mark.exercise_section_name,
      exerciseSubjectName: mark.exercise_subject_name,
      exerciseSubsectionName: mark.exercise_subsection_name,
      mark: mark.mark,
      id: mark.id,
    }));
  return paginatedResponseParser<StudentMarkDto, StudentMark>(paginatedData, resultsParser);
};

const parseStudentsForUI: ParseStudentsForUI = raw =>
  raw.map(mark => ({
    index: mark.index,
    id: mark.id,
    identifier: mark.identifier,
    marksCount: mark.marks_count,
  }));

const parseTeacherForUI: ParseTeacherForUI = raw => ({
  id: raw.id,
  school: {
    id: raw.school.id,
    name: raw.school.name,
    shortName: raw.school.short_name,
    city: raw.school.city,
    district: raw.school.district,
    address: raw.school.address,
  },
  classrooms: raw.classrooms.map(({ registered_count, students_count, id, name, short_name }) => ({
    name,
    id,
    shortName: short_name,
    registeredCount: registered_count,
    studentsCount: students_count,
  })),
});

const parseClassroomExercisesMarksForUI: ParseClassroomExercisesMarksForUI = paginatedData => {
  const resultsParser = (results: ClassroomExercisesMarksDto[]): ClassroomExercisesMarks[] =>
    results.map(mark => ({
      easyMarks: mark.easy_marks,
      explainMarks: mark.explain_marks,
      hardMarks: mark.hard_marks,
      uuid: mark.uuid,
      index: mark.index,
      sectionName: mark.section_name,
      subjectName: mark.subject_name,
      subsectionName: mark.subsection_name,
      exerciseUuid: mark.uuid,
    }));
  return paginatedResponseParser<ClassroomExercisesMarksDto, ClassroomExercisesMarks>(paginatedData, resultsParser);
};

const getOrderValue = ({ key, direction }: { key?: string; direction?: OrderDirectionValues }): string | null => {
  if (!key) return null;
  return direction === 'asc' ? key : `-${key}`;
};

const parseStudentMarksParamsForBE: ParseStudentMarksParamsForBE = params => {
  if (!params) return {};
  const { filters, pagination, order } = params;

  const limit = pagination.perPage || 20;
  const page = pagination.page || 0;

  return omitBy(
    {
      subject: filters?.subject,
      since: filters?.since ? format(filters?.since as Date, 'yyyy-MM-dd') : null,
      until: filters?.until ? format(filters?.until as Date, 'yyyy-MM-dd') : null,
      limit,
      offset: limit * page,
      order: order && getOrderValue(order),
    },
    isNil,
  );
};

const parseAllStudentsMarksParamsForBE: ParseAllStudentsMarksParamsForBE = filters => {
  if (!filters) return {};
  return omitBy(
    {
      subject: filters?.subject,
      since: filters?.since ? format(filters?.since as Date, 'yyyy-MM-dd') : null,
      until: filters?.until ? format(filters?.until as Date, 'yyyy-MM-dd') : null,
    },
    isNil,
  );
};

const parseClassroomMarksParamsForBE: ParseClassroomMarksParamsForBE = params =>
  omitBy(
    {
      subject: params?.subject,
      section: params?.section,
      subsection: params?.subsection,
      since: params?.since ? format(params?.since as Date, 'yyyy-MM-dd') : null,
      until: params?.until ? format(params?.until as Date, 'yyyy-MM-dd') : null,
    },
    isNil,
  );

const parsePaginatedClassroomMarksParamsForBE: ParsePaginatedClassroomMarksParamsForBE = params => {
  if (!params) return {};
  const { filters, pagination, order } = params;

  const limit = pagination.perPage || 20;
  const page = pagination.page || 0;

  return omitBy(
    {
      subject: filters?.subject,
      section: filters?.section,
      subsection: filters?.subsection,
      since: filters?.since ? format(filters?.since as Date, 'yyyy-MM-dd') : null,
      until: filters?.until ? format(filters?.until as Date, 'yyyy-MM-dd') : null,
      limit,
      offset: limit * page,
      order: order && getOrderValue(order),
    },
    isNil,
  );
};

const parseClassroomExercisesMarksSummaryForUI: ParseClassroomExercisesMarksSummaryForUI = raw => raw;

export {
  parsePaginatedClassroomMarksParamsForBE,
  parseStudentMarksParamsForBE,
  parseClassroomForUI,
  parseSchoolForUI,
  parseTeacherForUI,
  parseClassroomExercisesMarksForUI,
  parseStudentsForUI,
  parseStudentMarksForUI,
  parseAllStudentsMarksParamsForBE,
  parseClassroomExercisesMarksSummaryForUI,
  parseClassroomMarksParamsForBE,
};
