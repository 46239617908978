import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(4, 2, 6, 2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  exerciseRow: {
    border: `thin solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  rowData: {
    display: 'flex',
    gap: theme.spacing(5),
    padding: theme.spacing(2, 0),
  },
}));

export default useStyles;
