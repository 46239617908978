import React, { useEffect, useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { isNil } from 'lodash';

import SubjectIcon from 'components/SubjectIcon/SubjectIcon';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
import useSubjectStore from 'storages/subject';
import ExerciseViewTile from 'components/ExerciseViewTile/ExerciseViewTile';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    width: theme.container.form.m,
    marginRight: theme.spacing(8),
    transition: 'height .3s ease, font-size .3s ease, padding .3s ease',
    padding: ({ sticked }) => (sticked ? '0' : '1rem'),
    height: ({ sticked }) => (sticked ? '50px' : '95px'),
    '&>*': {
      fontSize: ({ sticked }) => (sticked ? '.8rem' : '1rem'),
      margin: '0 .25em',
    },
    [theme.breakpoints.down('md')]: {
      width: theme.container.form.s,
    },
  },
  subject: {
    fontWeight: 600,
    fontSize: '1rem',
  },
  counter: {
    color: theme.palette.text.disabled,
  },
}));

const getCount = count => {
  if (isNil(count)) return '';
  return `(${count})`;
};

const ExercisesResultCounter = ({ isStuck }) => {
  const { useDataStore } = useFeatureChecker();
  const classes = useStyles({ sticked: isStuck });
  const { subject } = useSubjectStore();
  const { exercisesCount, section, subjectExercisesCount } = useDataStore();
  const [count, setCount] = useState(section ? exercisesCount : subjectExercisesCount);
  useEffect(() => {
    if (exercisesCount) setCount(exercisesCount);
  }, [exercisesCount]);

  return (
    <ExerciseViewTile className={classes.wrapper}>
      <SubjectIcon iconName={subject?.subjectCode} size={isStuck ? 's' : 'm'} />
      <Typography className={classes.subject} color='primary' fontSize='m' noWrap tag='h1'>
        {subject?.name}
      </Typography>
      <Typography className={classes.counter} tag='span'>
        {getCount(count)}
      </Typography>
    </ExerciseViewTile>
  );
};

ExercisesResultCounter.defaultProps = {
  isStuck: false,
};

export default ExercisesResultCounter;
