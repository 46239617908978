import React, { useMemo } from 'react';

import { Button, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

import { UserSubscription } from 'api/rest/users/users.types';
import { ReactComponent as PurchasePrompt } from 'assets/icons/yellow_student_guy.svg';
import mmEmail from 'constants/dictionaries/contactData';
import PATHS, { AUTH_PATHS } from 'constants/paths';
import { useAuthContext } from 'context/AuthContext';
import getCurrentUserSubscription from 'services/common/getCurrentUserSubscription/getCurrentUserSubscription';
import useRedirectsStore from 'storages/redirects/useRedirects';

import PaperWithHeader from '../PaperWithHeader/PaperWithHeader';
import useStyles from './CurrentSubscriptionInfo.styles';

type Props = {
  subscriptions: UserSubscription[];
  openSubscribeDialog: () => void;
  hasFullAccess: boolean;
  isSchoolAccount: boolean;
};

// TODO historia subskrypcji
const CurrentSubscriptionInfo: React.FC<Props> = ({ subscriptions, openSubscribeDialog, hasFullAccess, isSchoolAccount }) => {
  // @ts-ignore
  const { onLogout } = useAuthContext();
  const currentSubscription = useMemo(() => getCurrentUserSubscription(subscriptions), [subscriptions]);
  const { setRedirectOnLogout } = useRedirectsStore();

  const onLogoutClick = () => {
    setRedirectOnLogout(PATHS.REGISTER);
    onLogout();
  };

  const classes = useStyles();

  if (currentSubscription) {
    return (
      <PaperWithHeader className={classes.root} header='Plan Premium'>
        <div className={classes.content}>
          <div>
            <Typography variant='h6' className={classes.textBlock}>
              Obecnie korzystasz z planu: <b>{currentSubscription.accessTypeName}</b>
            </Typography>
            <Typography variant='h6' className={classes.textBlockDoubled}>
              Plan ważny do: <b>{format(currentSubscription.validUntil, 'dd MMMM yyyy', { locale: pl })}</b>
            </Typography>
            <Typography variant='body2' className={classes.textBlock}>
              Przedłużenie planu o kolejne dni będzie możliwe po wygaśnięciu bieżącego okresu rozliczeniowego. Jeśli masz pytania dotyczące
              twojego konta zapraszamy do kontaktu po adresem email:
              <a href={`mailto:${mmEmail}`}>{` ${mmEmail}`}</a>
            </Typography>
          </div>
          <PurchasePrompt className={classes.image} />
        </div>
      </PaperWithHeader>
    );
  }

  if (hasFullAccess)
    return (
      <PaperWithHeader className={classes.root} header='Pełen dostęp'>
        <Typography>Aktualnie posiadasz przydzielony pełen dostęp do aplikacji.</Typography>
      </PaperWithHeader>
    );

  return (
    <PaperWithHeader className={classes.root} header='Brak aktywnej subskrypcji'>
      {isSchoolAccount ? (
        <>
          <Typography variant='subtitle1'>Twoja szkoła nie wykupiła dostępu do aplikacji.</Typography>
          <Typography variant='subtitle2'>
            Jeśli chcesz wykupić subskrypcje samodzielnie{' '}
            <Typography className={classes.logoutButton} onClick={onLogoutClick} component='span' color='primary'>
              wyloguj się
            </Typography>
            , a następnie załóż nowe konto.
          </Typography>
        </>
      ) : (
        <div className={classes.noSubscriptionContent}>
          <Typography variant='subtitle1'>Wykup plan premium, aby uzyskać pełen dostęp do wszystkich funkcjonalności aplikacji!</Typography>
          <Button className={classes.subscribeButton} size='small' variant='contained' color='primary' onClick={openSubscribeDialog}>
            Subskrybuj
          </Button>
        </div>
      )}
    </PaperWithHeader>
  );
};

export default CurrentSubscriptionInfo;
