import { useMemo } from 'react';

import { ValidationRule } from 'react-hook-form';

const useRulesProp = ({ required, rules }: { required: boolean; rules?: ValidationRule<any> }) => {
  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: required, message: 'Pole wymagane' }, ...rules }),
    [rules, required],
  );

  return { rulesProp };
};

export default useRulesProp;
