import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Picture } from 'react-responsive-picture';

import image from 'assets/decorative_pics/manual.png';
import image2x from 'assets/decorative_pics/manual@2x.png';
import imageSheets from 'assets/decorative_pics/manual_sheets.png';
import imageSheets2x from 'assets/decorative_pics/manual_sheets@2x.png';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';

const useStyles = makeStyles(theme => ({
  picture: {
    width: '100%',
    maxWidth: '450px',
    position: 'sticky',
    top: 0,

    [theme.breakpoints.down('sm')]: {
      position: 'static',
      margin: '0 auto',
      maxWidth: '360px',
      display: 'block',
    },
  },
}));

function ExerciseManual() {
  const { isSheet } = useFeatureChecker();
  const { picture } = useStyles();
  return (
    <Picture
      className={picture}
      sources={[
        {
          srcSet: `${isSheet ? imageSheets : image} 1x, ${isSheet ? imageSheets2x : image2x} 2x`,
        },
      ]}
    />
  );
}

export default ExerciseManual;
