import { isNumber } from 'lodash';

import getExercises, { parseVariables } from '../_helpers/getExercises';
import useSubjectStore from '../subject';

const PREFETCH = 4;

const backgroundUpdateExercises = async (set, get, fetchedExercisesLength) => {
  const state = get();
  const { subject } = useSubjectStore.getState();
  const variables = parseVariables(state, { subject, offset: fetchedExercisesLength });
  const { exercises } = await getExercises(variables);
  const prevExercises = state.exercises || [];
  const newExercises = exercises || [];
  set({ exercises: [...prevExercises, ...newExercises] });
};

const setCursor = (set, get) => async direction => {
  const getData = async () => {
    const { exerciseCursor, exercisesCount, exercises } = get();
    const lastExercise = exercisesCount - 1;
    const allExercisesCompleted = exerciseCursor >= lastExercise;
    const fetchedExercisesLength = exercises?.length;

    if (direction === 'next' && exercises && exercisesCount > lastExercise) {
      const newExerciseCursor = exerciseCursor + 1;
      if (newExerciseCursor > fetchedExercisesLength - PREFETCH && exercisesCount !== fetchedExercisesLength) {
        // that never happen in sheets and chosen exercises
        backgroundUpdateExercises(set, get, fetchedExercisesLength);
      }
      return { exerciseCursor: newExerciseCursor, allExercisesCompleted };
    }
    if (direction === 'previous' && exerciseCursor > 0) {
      return { exerciseCursor: exerciseCursor - 1, allExercisesCompleted: false };
    }
    if (isNumber(direction)) {
      return { exerciseCursor: direction, allExercisesCompleted: false };
    }
    return exerciseCursor;
  };
  const data = await getData();
  set({
    fileType: 't',
    ...data,
  });
  return data;
};

export default setCursor;
