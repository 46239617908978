import React from 'react';

import { Portal } from '@material-ui/core';

import useLayoutStore from '../../storages/layout';
import ExercisesFiltersHeaderMobile from '../../uniqueComponents/Exercises/ExercisesFiltersHeaderMobile/ExercisesFiltersHeaderMobile';
import useStyles from './ExercisesMobileViewGrid.styles';

type Props = {
  header: React.ReactNode;
  main: React.ReactNode;
  navigation: React.ReactNode;
  drawerContent: React.ReactNode;
  drawerTitle: string;
  drawerCaption: string;
  showSubjectIcon?: boolean;
};

const ExercisesMobileViewGrid: React.FC<Props> = ({
  header,
  main,
  navigation,
  drawerContent,
  drawerCaption,
  drawerTitle,
  showSubjectIcon,
}) => {
  const { isMobileFilterDrawerVisible } = useLayoutStore();

  const classes = useStyles({ visible: isMobileFilterDrawerVisible });
  return (
    <div className={classes.wrapper}>
      <div>
        {header}
        {main}
      </div>
      {navigation}
      <Portal>
        <div className={classes.drawer}>
          <ExercisesFiltersHeaderMobile drawerCaption={drawerCaption} drawerTitle={drawerTitle} showSubjectIcon={showSubjectIcon} />
          {drawerContent}
        </div>
      </Portal>
    </div>
  );
};

export default ExercisesMobileViewGrid;
