import { useEffect } from 'react';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const useBodyLock = (state = true, targetSelector = 'html') =>
  useEffect(() => {
    const target = document.querySelector(targetSelector);
    if (state) disableBodyScroll(target);
    else enableBodyScroll(target);
    return () => enableBodyScroll(target);
  }, [state]);

export default useBodyLock;
