import client from 'api/graphql/config/client';
import GET_SHEETS_LIST from 'api/graphql/queries/exercises/GET_SHEETS_LIST';
import safeJSONParser from 'utils/safeJSONParser/safeJSONParser';
import logError from 'utils/logError/logError';

import useSubjectStore from '../subject';

const getSheetsList = async curriculum => {
  const { subject } = useSubjectStore.getState();

  try {
    const { data } = await client.query({
      query: GET_SHEETS_LIST,
      variables: { subject: subject.id, curriculum: [curriculum] },
    });
    return safeJSONParser(data.sheets);
  } catch (error) {
    logError(error);
    return {};
  }
};

export default getSheetsList;
