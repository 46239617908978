import gql from 'graphql-tag';

const GET_EXERCISE_NAME = gql`
  query GetExerciseName($uuid: String!) {
    getExercise(uuid: $uuid) {
      importFileIdentifier
      index
      section {
        name
      }
      subsection {
        name
      }
    }
  }
`;

export default GET_EXERCISE_NAME;
