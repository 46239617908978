import React from 'react';

import useStyles from './FormWrapper.styles';

type Props = {
  size?: number;
  spacing?: number;
  children: React.ReactNode;
  fixedSize?: boolean;
};

const FormWrapper: React.FC<Props> = ({ size, children, spacing, fixedSize }) => {
  const classes = useStyles({ size, spacing, fixedSize });
  return <div className={classes.root}>{children}</div>;
};

export default FormWrapper;
