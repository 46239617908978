import axios, { AxiosError } from 'axios';
import PubSub from 'pubsub-js';

import { AppEvent } from 'types/AppEvent';

import { DefaultApiResponse } from '../_types';
import {
  parseLoginByEmailForBE,
  parseLoginByIdentifierForBE,
  parseLoginResultForUI,
  parseMeForUI,
  parsePermanentPasswordForBE,
  parseMeForUpdateBE,
  parsePartialMeForUI,
} from './users.parsers';
import { LoginByEmail, LoginByIdentifier, LoginResult, LoginResultDto, Me, MeDto, PermanentPassword, User, UserDto } from './users.types';

const endpoints = {
  me: 'users/me/',
  updateMe: 'users/update_me/',
  loginByEmail: 'users/token/obtain2',
  loginByIdentifier: 'users/token/obtain/identifier',
  permanentPassword: 'users/set_permanent_password/',
};

type LoginErrorMessage = { message: string };

type GetMeQuery = () => Promise<Me | null>;

type LoginByEmailMutation = (data: LoginByEmail) => Promise<LoginResult | LoginErrorMessage>;

type LoginByIdentifierMutation = (data: LoginByIdentifier) => Promise<LoginResult | LoginErrorMessage>;

type SetPermanentPasswordMutation = (data: PermanentPassword) => Promise<unknown>;

type UpdateMe = (data: Partial<User>) => Promise<unknown>;

const loginResponseHandler = (error: unknown): LoginErrorMessage => {
  const result = { message: 'Wystąpił nieznany błąd' };
  if (axios.isAxiosError(error) && error.response?.status === 401) {
    const messageFromApi = error.response.data.detail;
    result.message = messageFromApi || 'Taki użytkownik nie istnieje lub hasło jest niepoprawne';
  }
  return result;
};

const usersApi = {
  getMe: (): GetMeQuery => async () => {
    try {
      const response = await axios.get<GetMeQuery, DefaultApiResponse<MeDto>>(endpoints.me);
      return parseMeForUI(response.data);
    } catch (error: unknown) {
      const { response } = error as AxiosError;
      if (response?.status === 403) PubSub.publish(AppEvent.OUTDATED_TOKEN);
      return null;
    }
  },
  loginByEmail: (): LoginByEmailMutation => async data => {
    try {
      const response = await axios.post<LoginByEmailMutation, DefaultApiResponse<LoginResultDto>>(
        endpoints.loginByEmail,
        parseLoginByEmailForBE(data),
      );
      return parseLoginResultForUI(response.data);
    } catch (error) {
      return loginResponseHandler(error);
    }
  },
  loginByIdentifier: (): LoginByIdentifierMutation => async data => {
    try {
      const response = await axios.post<LoginByIdentifierMutation, DefaultApiResponse<LoginResultDto>>(
        endpoints.loginByIdentifier,
        parseLoginByIdentifierForBE(data),
      );
      return parseLoginResultForUI(response.data);
    } catch (error) {
      return loginResponseHandler(error);
    }
  },
  setPermanentPassword: (): SetPermanentPasswordMutation => async formData => {
    const data = parsePermanentPasswordForBE(formData);
    return axios.post(endpoints.permanentPassword, data);
  },
  updateMe: (): UpdateMe => async formData => {
    const data = parseMeForUpdateBE(formData);
    const response = await axios.patch<Partial<UserDto>>(endpoints.updateMe, data);
    return parsePartialMeForUI(response.data);
  },
};

export default usersApi;
