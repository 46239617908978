import React from 'react';

import { makeStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';

import { ReactComponent as JA } from 'assets/icons/subjects/ang-p.svg';
import { ReactComponent as AR } from 'assets/icons/subjects/ang-r.svg';
import { ReactComponent as BI } from 'assets/icons/subjects/biologia.svg';
import { ReactComponent as CH } from 'assets/icons/subjects/chemia.svg';
import { ReactComponent as FI } from 'assets/icons/subjects/fizyka.svg';
import { ReactComponent as GE } from 'assets/icons/subjects/geografia.svg';
import { ReactComponent as HI } from 'assets/icons/subjects/historia.svg';
import { ReactComponent as MP } from 'assets/icons/subjects/mat-p.svg';
import { ReactComponent as MR } from 'assets/icons/subjects/mat-r.svg';
import { ReactComponent as PP } from 'assets/icons/subjects/polski-p.svg';

const icons = {
  CH,
  BI,
  FI,
  GE,
  MP,
  MR,
  M: MP,
  B: BI,
  HI,
  JA,
  AR,
  PP,
};

const padding = size => {
  switch (size) {
    case 'xl':
      return 3;
    case 'l':
      return 2.75;
    case 'm':
      return 1;
    case 's':
      return 0.25;
    case 'xs':
      return 0;
    default:
      return 2.5;
  }
};

const colorSolver = (color, palette) => {
  switch (color) {
    case 'primary':
      return palette.primary.main;
    case 'yellow':
      return palette.custom.orange;
    default:
      return palette.primary.main;
  }
};

const yellowIcons = ['JA', 'AR'];

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: ({ muted, color }) => (muted ? theme.palette.grey['500'] : colorSolver(color, theme.palette)),
    borderRadius: ({ round }) => (round ? '100%' : theme.shape.borderRadiusXL),
    boxShadow: theme.customShadows.medium,
    width: ({ size }) => theme.elements.icon[size],
    height: ({ size }) => theme.elements.icon[size],
    padding: ({ size }) => theme.spacing(padding(size)),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.3s ease',
    flexShrink: '0',
  },
  icon: {
    height: '100%',
    width: '100%',
  },
}));

function SubjectIcon({ iconName, size, muted, round, clickable, className }) {
  const color = yellowIcons.includes(iconName) ? 'yellow' : 'primary';
  const classes = useStyles({ size, muted, round, color });
  const Icon = icons[iconName];
  if (!Icon) return null;
  if (clickable) {
    return (
      <ButtonBase className={clsx(classes.wrapper, className)} component='div'>
        <Icon className={classes.icon} />
      </ButtonBase>
    );
  }
  return (
    <div className={clsx(classes.wrapper, className)}>
      <Icon className={classes.icon} />
    </div>
  );
}

SubjectIcon.defaultProps = {
  size: 'm',
  round: true,
  clickable: false,
  muted: false,
  className: null,
};

export default SubjectIcon;
