import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import ReportProblem from 'root/ReportProblem/ReportProblem';
import Router from 'root/Router/Router';

import { useApp } from './App.logic';

const App: React.FC = () => {
  useApp();
  return (
    <BrowserRouter>
      <ReportProblem />
      <Router />
    </BrowserRouter>
  );
};
export default App;
