import gql from 'graphql-tag';

const DELETE_ACCOUNT = gql`
  mutation DeleteUser($message: String) {
    deleteUser(message: $message) {
      deleted
    }
  }
`;

export default DELETE_ACCOUNT;
