import React from 'react';

import { Typography } from '@material-ui/core';

import { Me } from 'api/rest/users/users.types';
import PaperWithHeader from 'components/PaperWithHeader/PaperWithHeader';

import useStyles from './UserInfoSection.styles';

type Props = {
  meData: Me;
};

const UserInfoSection: React.FC<Props> = ({ meData }) => {
  const classes = useStyles();

  return (
    <PaperWithHeader header='Dane konta' contentClassName={classes.root}>
      {meData.assignedSchool && (
        <Typography variant='h6'>
          Szkoła: <b>{meData.assignedSchool.name}</b>
        </Typography>
      )}
      {meData.assignedClass && (
        <Typography variant='h6'>
          Klasa: <b>{meData.assignedClass.name}</b>
        </Typography>
      )}
      <Typography variant='h6'>
        Login: <b>{meData.email}</b>
      </Typography>
    </PaperWithHeader>
  );
};

export default UserInfoSection;
