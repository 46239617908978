import { makeStyles } from '@material-ui/core';

type StylesProps = {
  extraSpace: number;
  onePerPage: boolean;
};

const useStyles = makeStyles(theme => ({
  '@global': {
    '@media print': {
      body: {
        background: 'none',
      },
    },
  },
  printContent: {
    display: 'none',
  },
  printWrapper: {
    borderLeft: `thin solid ${theme.palette.primary.main}`,
    borderRight: `thin solid ${theme.palette.primary.main}`,
    borderBottom: `thin solid ${theme.palette.primary.main}`,
    background: theme.palette.common.white,
  },
  exerciseBlock: {
    display: 'grid',
    // gridGap: theme.spacing(1),
    justifyItems: 'center',
    breakInside: 'avoid',
    pageBreakInside: 'avoid',
    marginBottom: ({ extraSpace }: StylesProps) => `calc(${extraSpace || 0}cm)`,
    pageBreakAfter: ({ onePerPage }: StylesProps) => (onePerPage ? 'always' : 'auto'),
    breakAfter: ({ onePerPage }: StylesProps) => (onePerPage ? 'always' : 'auto'),
  },
  exerciseHeader: {
    borderBottom: `thin solid ${theme.palette.primary.main}`,
    borderTop: `thin solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: theme.spacing(1, 4),
    width: '100%',
    fontSize: '0.5em',
    fontWeight: 600,
    breakAfter: 'avoid',
    breakPageAfter: 'avoid',
  },
  exerciseImages: {
    marginTop: theme.spacing(3),
    maxWidth: '130mm',
    breakInside: 'auto',
    pageBreakInside: 'auto',
  },
  exerciseImage: {
    maxWidth: '100%',
  },
}));

export default useStyles;
