import React from 'react';

import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SubjectIcon from 'components/SubjectIcon/SubjectIcon';
import PATHS from 'constants/paths';
import useSubjectStore from 'storages/subject';

import DashboardCard from 'uniqueComponents/Dashboard/DashboardCard/DashboardCard';

const useStyles = makeStyles(theme => ({
  wrapper: {
    gridColumn: '1/3',
    '@media(max-width: 740px)': {
      gridColumn: 'initial',
    },
  },
  icon: {
    position: 'absolute',
    top: theme.legacySpacing(6),
    right: theme.legacySpacing(6),
    '@media(max-width: 740px)': {
      top: theme.legacySpacing(3),
      right: theme.legacySpacing(3),
    },
  },
}));

function SubjectSelectionTile() {
  const { subject } = useSubjectStore();
  const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const classes = useStyles();
  return (
    <DashboardCard
      additionalText='Kliknij, aby zmienić'
      ariaLabel='Zmiana przedmiotu'
      className={classes.wrapper}
      title='Uczysz się:'
      to={PATHS.SUBJECT_SELECT}
    >
      <Typography component='h2'>{subject.name}</Typography>
      <SubjectIcon className={classes.icon} iconName={subject.subjectCode} size={downSm ? 's' : 'm'} />
    </DashboardCard>
  );
}

export default SubjectSelectionTile;
