import React, { useEffect, useState } from 'react';

import { Container } from '@material-ui/core';
import { isNil } from 'lodash';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import users from 'api/rest/users/users';
import DataProcessingObjectionDialog from 'components/_dialogs/DataProcessingObjectionDialog/DataProcessingObjectionDialog';
import DeleteAccountDialog from 'components/_dialogs/DeleteAccountDialog/DeleteAccountDialog';
import PaymentFailedDialog from 'components/_dialogs/PaymentFailedDialog/PaymentFailedDialog';
import PaymentSuccessDialog from 'components/_dialogs/PaymentSuccessDialog/PaymentSuccessDialog';
import SetNewPasswordDialog from 'components/_dialogs/SetNewPasswordDialog/SetNewPasswordDialog';
import SubscriptionDialog from 'components/_dialogs/SubscriptionDialog/SubscriptionDialog';
import UserFeedbackDialog from 'components/_dialogs/UserFeedbackDialog/UserFeedbackDialog';
import CurrentSubscriptionInfo from 'components/CurrentSubscriptionInfo/CurrentSubscriptionInfo';
import Loader from 'components/Loader/Loader';
import PageTitle from 'components/PageTitle/PageTitle';
import UserDataForm from 'components/UserDataForm/UserDataForm';
import UserDataProcessingObjectionInfo from 'components/UserDataProcessingObjectionInfo/UserDataProcessingObjectionInfo';
import UserInfoSection from 'components/UserInfoSection/UserInfoSection';
import UserSettingsOptions from 'components/UserSettingsOptions/UserSettingsOptions';
import QUERY from 'constants/queryKeys';
import FullScreen from 'containers/FullScreen/FullScreen';

import useStyles from './UserSettingsPage.styles';

type UserSettingsDialogs =
  | 'payment-success'
  | 'payment-failure'
  | 'delete-account'
  | 'set-new-password'
  | 'user-feedback'
  | 'subscribe'
  | 'data-processing-objection';

const UserSettingsPage: React.FC = () => {
  const { dialogToOpen } = useParams() as { dialogToOpen: UserSettingsDialogs };

  const [dialog, setDialog] = useState<UserSettingsDialogs | null>(dialogToOpen || null);
  const closeDialog = () => setDialog(null);
  const openDialogFactory = (dialogName: UserSettingsDialogs) => () => setDialog(dialogName);

  useEffect(() => {
    if (dialogToOpen) setDialog(dialogToOpen);
  }, [dialogToOpen]);

  const { data: meData } = useQuery(QUERY.GET_ME, users.getMe());

  const classes = useStyles();

  if (!meData) {
    return <Loader staticDisplay />;
  }

  return (
    <FullScreen>
      <PageTitle>Ustawienia użytkownika</PageTitle>
      <Container maxWidth='md' className={classes.root}>
        <UserDataProcessingObjectionInfo reason={meData.dataProcessingObjectionReason} date={meData.dataProcessingObjectionSentAt} />
        <CurrentSubscriptionInfo
          hasFullAccess={meData.hasFullAccess}
          openSubscribeDialog={openDialogFactory('subscribe')}
          subscriptions={meData.subscriptions}
          isSchoolAccount={meData.isSchoolAccount}
        />
        <UserInfoSection meData={meData} />
        <UserDataForm meData={meData} />
        <UserSettingsOptions
          isSchoolAccount={meData.isSchoolAccount}
          openDeleteDialog={openDialogFactory('delete-account')}
          openFeedbackDialog={openDialogFactory('user-feedback')}
          openSetNewPasswordDialog={openDialogFactory('set-new-password')}
          openDataProcessingObjectionDialog={openDialogFactory('data-processing-objection')}
        />
      </Container>
      {dialog === 'subscribe' && <SubscriptionDialog onClose={closeDialog} />}
      {dialog === 'payment-failure' && <PaymentFailedDialog onClose={closeDialog} />}
      {dialog === 'payment-success' && <PaymentSuccessDialog onClose={closeDialog} />}
      {dialog === 'delete-account' && <DeleteAccountDialog onClose={closeDialog} />}
      {dialog === 'set-new-password' && <SetNewPasswordDialog onClose={closeDialog} />}
      {dialog === 'user-feedback' && <UserFeedbackDialog onClose={closeDialog} />}
      {dialog === 'data-processing-objection' && (
        <DataProcessingObjectionDialog
          initialValues={{
            hasObjection: !isNil(meData?.dataProcessingObjectionReason),
            reason: meData?.dataProcessingObjectionReason || null,
          }}
          onClose={closeDialog}
        />
      )}
    </FullScreen>
  );
};

export default UserSettingsPage;
