import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

const initFirebase = () => {
  if (!isProduction) {
    // Import the functions you need from the SDKs you need
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: 'AIzaSyC_QHvyA1APlLfrOWrMGGT_DMcxefJPMpU',
      authDomain: 'moja-matura.firebaseapp.com',
      projectId: 'moja-matura',
      storageBucket: 'moja-matura.appspot.com',
      messagingSenderId: '106112428826',
      appId: '1:106112428826:web:b2e18b7c9b5f953b06daa3',
      measurementId: 'G-5WM9VVKCPB',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }
};

export default initFirebase;
