import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useSpring, animated, config } from 'react-spring';

import AddExerciseToChosenButton from 'components/AddExerciseToChosenButton/AddExerciseToChosenButton';

const getThumbStyles = theme => ({
  border: `thin solid ${theme.palette.grey['300']}`,
  height: '35px',
  width: '8px',
  borderRadius: '0',
  background: '#ffffff',
  cursor: 'pointer',
});

const getTrackStyles = theme => ({
  width: '100%',
  height: '6px',
  cursor: 'pointer',
  background: theme.palette.primary.main,
  borderRadius: '0',
  border: 'none',
});

const useStyles = makeStyles(theme => ({
  audio: {
    display: 'none',
    visibility: 'hidden',
    position: 'absolute',
    left: '-1000000000000px',
  },
  playerWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr 75px',
    gridGap: '.5rem',
    paddingBottom: '1rem',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
    },

    '& input[type=range]:focus': {
      outline: 'none',
    },
    '& input[type="range"]': {
      '-webkit-appearance': 'none',
      background: 'transparent',
      width: '100%',
    },
    '& input[type="range"]::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
      marginTop: '-15px' /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */,
      ...getThumbStyles(theme),
    },
    '& input[type=range]::-moz-range-thumb': getThumbStyles(theme),
    '& input[type=range]::-ms-thumb': getThumbStyles(theme),

    '& input[type=range]::-ms-track': {
      ...getTrackStyles(theme),
      background: 'transparent',
      borderColor: 'transparent',
      color: 'transparent',
    },
    '& input[type=range]::-webkit-slider-runnable-track': getTrackStyles(theme),
    '& input[type=range]:focus::-webkit-slider-runnable-track': getTrackStyles(theme),
    '& input[type=range]::-moz-range-track': getTrackStyles(theme),
    '& input[type=range]::-ms-fill-lower': getTrackStyles(theme),
    '& input[type=range]:focus::-ms-fill-lower': getTrackStyles(theme),
    '& input[type=range]::-ms-fill-upper': getTrackStyles(theme),
    '& input[type=range]:focus::-ms-fill-upper': getTrackStyles(theme),
  },
  timeWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    justifyItems: 'flex-end',
    gridGap: '3px',
    fontSize: '0.875rem',
    color: theme.palette.text.hint,
  },
  playButton: {
    background: theme.palette.background.paper,
    border: `thin solid ${theme.palette.grey['300']}`,
    width: '40px',
    padding: '0 12px',
    height: '35px',
    fontSize: 0,
    color: 'transparent',
    cursor: 'pointer',

    '& svg': {
      height: '18px',
      fill: theme.palette.primary.main,
    },
  },
}));

const calculateTime = secs => {
  if (!secs && secs !== 0) return `- : --`;
  const minutes = Math.floor(secs / 60);
  const seconds = Math.floor(secs % 60);
  const normalizedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${minutes}:${normalizedSeconds}`;
};

function AudioPlayer({ src }) {
  const audio = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSecond, setCurrentSecond] = useState(0);
  const [duration, setDuration] = useState(null);

  const onMetadataLoad = () => setDuration(audio.current.duration);
  const onTimeUpdate = () => setCurrentSecond(Math.floor(audio.current.currentTime));
  const onAudioEnds = () => setIsPlaying(false);

  useEffect(() => {
    const { current } = audio;
    current.addEventListener('loadedmetadata', onMetadataLoad);
    current.addEventListener('timeupdate', onTimeUpdate);
    current.addEventListener('ended', onAudioEnds);
    return () => {
      if (current) current.removeEventListener('loadedmetadata', onMetadataLoad);
      if (current) current.removeEventListener('timeupdate', onTimeUpdate);
      if (current) current.removeEventListener('ended', onAudioEnds);
    };
  }, []);

  const togglePlaying = () => setIsPlaying(prev => !prev);

  useEffect(() => {
    if (isPlaying) audio.current.play();
    else audio.current.pause();
  }, [isPlaying]);

  useEffect(() => {
    setIsPlaying(false);
    setCurrentSecond(0);
  }, [src]);

  const onSlide = ({ target }) => {
    setCurrentSecond(target.value);
    audio.current.currentTime = target.value;
  };
  const { points } = useSpring({ config: config.default, points: isPlaying ? 1 : 0 });

  const classes = useStyles();
  return (
    <>
      <audio ref={audio} className={classes.audio} controls preload='auto' src={src}>
        Twoja przeglądarka nie wspiera plilów audio.
      </audio>
      <div className={classes.playerWrapper}>
        <button className={classes.playButton} onClick={togglePlaying} type='button'>
          <svg viewBox='0 0 100 100'>
            <animated.polygon
              points={points.to({
                range: [0, 1],
                output: ['0,0 100,50 100,50 0,100', '10,0 30,0 30,100 10,100'],
              })}
            />
            <animated.polygon
              points={points.to({
                range: [0, 1],
                output: ['0,20 0,20 0,80 0,80', '50,0 70,0 70,100 50,100'],
              })}
            />
          </svg>
          Odtwórz/wstrzymaj
        </button>
        <input max={duration} min={0} onChange={onSlide} step={1} type='range' value={currentSecond} />
        <div className={classes.timeWrapper}>
          <span>{calculateTime(currentSecond)}</span>
          <span>/</span>
          <span>{calculateTime(duration)}</span>
        </div>
      </div>
    </>
  );
}

export default AudioPlayer;
