import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 400px',
    width: '100%',
    gridGap: theme.spacing(8),
    padding: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 0),
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
  videoWrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '640px',
    justifySelf: 'center',
  },
  description: {
    [theme.breakpoints.down('md')]: {
      justifySelf: 'center',
    },
  },
  list: {
    listStylePosition: 'outside',
    paddingLeft: theme.spacing(4),
  },
  listEl: {
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
