import { useMemo } from 'react';

import { useQuery } from 'react-query';

import usersApi from 'api/rest/users/users';
import { UserConfigKeys } from 'api/rest/users/users.types';
import QUERY from 'constants/queryKeys';

const useUserConfig = (permissionsToCheck: UserConfigKeys[]) => {
  const { data, refetch } = useQuery(QUERY.GET_ME, usersApi.getMe());

  const permissions = useMemo(
    () =>
      permissionsToCheck.map(permission => {
        if (!data) return null;
        return data.config[permission];
      }),
    [data],
  );

  return { permissions, refetchPermissions: refetch };
};

export default useUserConfig;
