import React from 'react';

import { CircularProgress, Dialog, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  dialog: {},
});

function LoadingDialog() {
  const downXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const classes = useStyles();
  return (
    <Dialog className={classes.dialog} fullScreen={downXs} open={!false}>
      <CircularProgress />
    </Dialog>
  );
}

export default LoadingDialog;
