import React, { useMemo } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { Select } from '@material-ui/core';

import FiltersFormControl from 'components/FiltersFormControl/FiltersFormControl';
import { useUserDataContext } from 'context/UserDataContext';
import GET_CURRICULUMS from 'api/graphql/queries/exercises/GET_CURRICULUMS';
import parseValuesToSelect from 'services/common/parseValuesToSelect/parseValuesToSelect';
import useSheetsStore from 'storages/sheets/sheets';
import useSubjectStore from 'storages/subject';
import FiltersBlock from 'uniqueComponents/Exercises/ExercisesFilters/FiltersBlock/FiltersBlock';
import safeJSONParser from 'utils/safeJSONParser/safeJSONParser';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

function SheetsCurriculum() {
  const { logEvent } = useUserLogContext();

  const { subject } = useSubjectStore();
  const { curriculum, setCurriculum } = useSheetsStore();
  const { isMobile } = useUserDataContext();

  const { data } = useQuery(GET_CURRICULUMS, { variables: { subject: subject?.id, clientContext: 'SHEETS' } });

  const curriculums = useMemo(() => {
    if (!data) return [];
    const choices = safeJSONParser(data?.curriculumChoices);
    const valuesArray = Object.entries(choices).map(([value, { label }]) => ({ value, label }));
    return parseValuesToSelect({ data: valuesArray, isMobile, placeholder: 'Wybierz...' });
  }, [data]);

  const onChange = ({ target: { value } }) => {
    logEvent('select-filter-click', { filter: 'Arkusze - podstawa programowa', value, feature: 'sheets' });
    setCurriculum(value);
  };

  return (
    <FiltersBlock heading='Podstawa programowa'>
      <FiltersFormControl size='small' variant='outlined'>
        <Select displayEmpty native={isMobile} onChange={onChange} value={curriculum || ''}>
          {curriculums}
        </Select>
      </FiltersFormControl>
    </FiltersBlock>
  );
}

export default SheetsCurriculum;
