import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Chip, CircularProgress, Dialog, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import exercisesSetApi from 'api/rest/exercisesSets/exercisesSet';
import DialogGrid from 'containers/DialogGrid/DialogGrid';
import { resolveSubjectNameFromSubjectNumber } from 'services/common/subjectParsers/subjectParsers';
import useExercisesSetsStore from 'storages/exercisesSets/exercisesSets';
import { Uuid } from 'types/Uuid';

import { useUserLogContext } from '../../context/UserLogContext/UserLogContext';
import ButtonWithPopover, { ButtonWithPopoverElement } from '../ButtonWithPopover/ButtonWithPopover';
import EditExercisesSetAddNewForm from './_components/EditExercisesSetAddNewForm/EditExercisesSetAddNewForm';
import EditExercisesSetExerciseRow from './_components/EditExercisesSetExerciseRow/EditExercisesSetExerciseRow';
import useStyles from './EditExercisesSetDialog.styles';

type Props = {
  onClose: () => void;
};

const EditExercisesSetDialog: React.FC<Props> = ({ onClose }) => {
  const { logEvent } = useUserLogContext();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const exercisesListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    logEvent('exercises-sets-open-edit-dialog');
    return () => logEvent('exercises-sets-close-edit-dialog');
  }, []);

  const { dataState, refreshExercisesInSelectedSet, currentSetId, setCurrentSetId } = useExercisesSetsStore();

  const queryKey = useMemo(() => exercisesSetApi.getDetails.generateQueryKey(currentSetId), [currentSetId]);

  const { data: currentSetData } = useQuery(queryKey, exercisesSetApi.getDetails.request(currentSetId), { enabled: !!currentSetId });

  const updateMutation = useMutation(exercisesSetApi.update.generateQueryKey(), exercisesSetApi.update.request(), {
    ...exercisesSetApi.update.requestBaseSettings,
    onMutate: async params => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousExercises = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, params?.input);

      // Return a context object with the snapshotted value
      return { previousExercises };
    },
    onError: (err, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousExercises);
    },
    // // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const onExerciseRemove = (uuidToRemove: Uuid) => {
    if (!currentSetData) return;
    const newExercises = currentSetData.exercises.filter(({ uuid }) => uuid !== uuidToRemove);
    updateMutation.mutate({ setId: currentSetId, input: { ...currentSetData, exercises: newExercises } });
    logEvent('exercises-sets-remove-exercise-in-dialog');
  };

  const onExerciseMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!currentSetData) return;
      const newExercises = [...currentSetData.exercises];
      const elementToMove = newExercises[dragIndex];
      newExercises.splice(dragIndex, 1);
      newExercises.splice(hoverIndex, 0, elementToMove);
      updateMutation.mutate({ setId: currentSetId, input: { ...currentSetData, exercises: newExercises } });
      logEvent('exercises-sets-move-exercise-in-set');
    },
    [currentSetData, currentSetId],
  );

  const [selectedExerciseUuid, setSelectedExerciseUuid] = useState<Uuid | null | 'add_exercise'>(
    currentSetData?.exercises?.length ? currentSetData.exercises[0].uuid : null,
  );
  const selectedExercise = useMemo(() => {
    if (selectedExerciseUuid === 'add_exercise') return null;
    return currentSetData?.exercises.find(({ uuid }) => uuid === selectedExerciseUuid || null);
  }, [currentSetData, selectedExerciseUuid]);

  const removeMutation = useMutation(exercisesSetApi.remove.generateQueryKey({ id: currentSetId }), exercisesSetApi.remove.request());

  const onRemoveSet = async () => {
    const confirmed = window.confirm('Usunięcie zestawu zadań jest nieodwracalne, czy chcesz kontynuować?');
    if (confirmed) {
      await removeMutation.mutateAsync({ id: currentSetId });
      await queryClient.invalidateQueries(exercisesSetApi.getAll.generateQueryKey());
      setCurrentSetId(null);
      logEvent('exercises-sets-remove-exercise-set');
      enqueueSnackbar('Zestaw zadań został usunięty!', { variant: 'success' });
      onClose();
    }
  };

  const setExerciseAdding = () => setSelectedExerciseUuid('add_exercise');

  const classes = useStyles();

  const renderButtons = useCallback(() => {
    if (dataState === 'FETCHING') return <CircularProgress size={30} />;
    if (!currentSetData || dataState === 'ERROR') return 'Wystąpił problem z pobraniem danych...';

    return (
      <div className={classes.exercisesList}>
        {currentSetData.exercises.map(({ index, uuid, subject }, indexInArray) => (
          <EditExercisesSetExerciseRow
            index={indexInArray}
            id={uuid}
            key={uuid}
            onRemove={() => onExerciseRemove(uuid)}
            color={uuid === selectedExerciseUuid ? 'primary' : 'default'}
            label={`${resolveSubjectNameFromSubjectNumber(subject, 'short')} ${index}`}
            onClick={() => setSelectedExerciseUuid(uuid)}
            onMove={onExerciseMove}
          />
        ))}
        <Chip
          onClick={setExerciseAdding}
          color={selectedExerciseUuid === 'add_exercise' ? 'primary' : 'default'}
          className={classes.addButton}
          variant='outlined'
          label={<Typography>Dodaj +</Typography>}
        />
      </div>
    );
  }, [dataState, currentSetData, onExerciseMove, selectedExerciseUuid]);

  const renderPreview = useCallback(() => {
    if (selectedExerciseUuid === 'add_exercise') return <EditExercisesSetAddNewForm exercisesListRef={exercisesListRef} />;
    if (selectedExercise) return selectedExercise.files.t.map(file => <img className={classes.preview} alt='' src={file} />);

    return (
      <Typography variant='caption' align='center' className={classes.fallbackText}>
        {currentSetData?.exercises?.length ? 'Kliknij zadanie, aby zobaczyć podgląd' : 'Ten zestaw nie zawiera żadnych zadań'}
      </Typography>
    );
  }, [selectedExerciseUuid, selectedExercise, currentSetData?.exercises]);

  const popoverElements: ButtonWithPopoverElement[] = useMemo(
    () => [
      {
        label: 'Dodaj nowe zadanie',
        action: setExerciseAdding,
      },
      {
        label: 'Usuń zestaw zadań',
        action: onRemoveSet,
        buttonProps: { className: classes.removeButton },
      },
    ],
    [],
  );

  const closeAndRefresh = () => {
    refreshExercisesInSelectedSet();
    onClose();
  };

  return (
    <Dialog maxWidth='lg' open onClose={closeAndRefresh} disableBackdropClick>
      <DialogGrid
        hideCloseButton
        okLabel='OK'
        title={`Edytuj zestaw zadań ${currentSetData ? ` - ${currentSetData.name}` : ''}`}
        okClick={closeAndRefresh}
        hideCancelButton
        customTopButton={
          <ButtonWithPopover dense elements={popoverElements} id='settings' buttonProps={{ size: 'small', color: 'primary' }}>
            <MenuIcon />
          </ButtonWithPopover>
        }
      >
        <div className={classes.container}>
          <div className={classes.left} ref={exercisesListRef}>
            <DndProvider backend={HTML5Backend}>{renderButtons()}</DndProvider>
          </div>
          <div className={clsx(classes.right, !selectedExercise && classes.emptyPreview)}>{renderPreview()}</div>
        </div>
      </DialogGrid>
    </Dialog>
  );
};

export default EditExercisesSetDialog;
