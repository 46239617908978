import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Popover, Slider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';

import { ReactComponent as HighligtherIcon } from 'assets/icons/drawing/highlighter.svg';
import { ReactComponent as PaletteIcon } from 'assets/icons/drawing/palette.svg';
import { ReactComponent as PenSizeIcon } from 'assets/icons/drawing/pen-size.svg';
import { ReactComponent as PenIcon } from 'assets/icons/general/edit.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/general/floppy-disk.svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/general/question-mark.svg';
import { ReactComponent as TablesIcon } from 'assets/icons/general/tables.svg';
import { ReactComponent as UndoIcon } from 'assets/icons/general/undo.svg';
import drawingToolsConsts from 'constants/dictionaries/drawingTools';
import { useConfirmationModalContext } from 'context/ConfirmationModalContext';
import { useExerciseDrawContext } from 'context/ExerciseDrawContext';
import { useTablesContext } from 'context/TablesContext';
import useDebounce from 'services/common/useDebounce/useDebounce';
import useKeyboardListener from 'services/common/useKeyboardListener/useKeyboardListener';

const separatorStyles = ({ grey }) => ({
  content: '""',
  position: 'absolute',
  display: 'block',
  height: '70%',
  width: '1px',
  top: '15%',
  background: grey[400],
});

const useStyles = makeStyles(theme => ({
  settingsBar: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    top: 0,
    position: 'sticky',
    background: theme.palette.common.white,
    zIndex: 20,
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto',
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 12px',
  },
  iconWithText: {
    fontSize: '10px',
    '&>p': {
      marginTop: '3px',
    },
  },
  colorInput: {
    visibility: 'hidden',
    webkitAppearance: 'none',
    border: 'none',
    width: 0,
    height: 0,
    margin: 0,
    padding: 0,
  },
  paletteIcon: {
    '& .inner-color': {
      fill: ({ color }) => color,
    },
  },
  sliderWrapper: {
    padding: theme.legacySpacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '245px',
    height: '45px',
  },
  icon: {
    height: '18px',
    width: '18px',
  },
  appendSeparator: {
    position: 'relative',
    '&::after': {
      ...separatorStyles(theme.palette),
      right: '-10px',
    },
  },
  prependSeparator: {
    position: 'relative',
    '&::before': {
      ...separatorStyles(theme.palette),
      left: '-10px',
    },
  },
  disabledIcon: {
    '& *': {
      fill: `${theme.palette.grey[400]} !important`,
    },
  },
}));

function DrawSettings({ answers }) {
  const {
    drawUpdaters: { toggleDrawMode, changeColor, setPen, setHighlighter, changeBrushRadius, saveCanvas, undo },
    drawState: {
      tool,
      unsaved,
      saving,
      canvasSettings: { brushColor, brushRadius },
    },
  } = useExerciseDrawContext();
  const { showConfirmationModal } = useConfirmationModalContext();
  const { openTables, openCustomImages, ready: tablesReady } = useTablesContext();

  // GENERAL
  const backHandler = useCallback(async () => {
    if (unsaved) {
      const confirmation = await showConfirmationModal({
        title: 'Niezapisane zmiany',
        body: 'Utracisz wszystkie niezapisane zmiany, na pewno chcesz wrócić?',
      });
      if (confirmation) toggleDrawMode();
    } else {
      toggleDrawMode();
    }
  }, [unsaved]);

  // COLORS handlers
  const inputColorRef = useRef();
  const focusColorInput = useCallback(() => inputColorRef.current.click(), [inputColorRef]);
  const [debouncedColorVal, setDebouncedColorVal] = useState(brushColor);
  const debouncedColor = useDebounce(debouncedColorVal, 250);
  const onColorChange = ({ target }) => setDebouncedColorVal(target.value);
  useEffect(() => changeColor(debouncedColor), [debouncedColor]);

  // SHOW ANSWER
  const showAnswer = useCallback(() => {
    openCustomImages(answers);
  }, [answers]);

  // RADIUS handlers
  const [radiusAnchorElement, setRadiusAnchorElement] = useState(null);
  const radiusOpen = useCallback(({ currentTarget }) => setRadiusAnchorElement(currentTarget), []);
  const radiusClose = useCallback(() => setRadiusAnchorElement(null), []);
  const onBrushRadiusChange = useCallback((e, value) => changeBrushRadius(value), []);

  const open = Boolean(radiusAnchorElement);
  const radiusPopoverId = useMemo(() => (open ? 'simple-popover' : undefined), [open]);

  useKeyboardListener({
    keys: 'z',
    ctrlKey: true,
    callback: undo,
  });

  const classes = useStyles({ color: brushColor, unsaved });
  return (
    <Box boxShadow={3} className={classes.settingsBar}>
      <div className={classes.wrapper}>
        <div className={classes.section}>
          <div className={clsx(classes.buttonGroup, classes.appendSeparator)}>
            <IconButton onClick={showAnswer} title='Odpowiedź'>
              <div className={classes.iconWithText}>
                <QuestionMarkIcon className={classes.icon} />
                <p>Odpowiedź</p>
              </div>
            </IconButton>
            <IconButton disabled={!tablesReady} onClick={openTables} title='Tablice'>
              <div className={classes.iconWithText}>
                <TablesIcon className={classes.icon} disabled={!tablesReady} />
                <p>Tablice</p>
              </div>
            </IconButton>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.buttonGroup}>
            <IconButton onClick={undo} title='Cofnij'>
              <UndoIcon className={classes.icon} />
            </IconButton>
          </div>
          <div className={classes.buttonGroup}>
            <IconButton
              className={classes.button}
              disabled={tool === drawingToolsConsts.HIGHLIGHTER}
              onClick={focusColorInput}
              title='Zmiana koloru'
            >
              <PaletteIcon className={clsx(classes.icon, classes.paletteIcon)} />
              <input ref={inputColorRef} className={classes.colorInput} onChange={onColorChange} type='color' value={brushColor} />
            </IconButton>
            <IconButton
              aria-describedby={radiusPopoverId}
              disabled={tool === drawingToolsConsts.HIGHLIGHTER}
              onClick={radiusOpen}
              title='Zmiana grubości'
            >
              <PenSizeIcon className={classes.icon} />
            </IconButton>
          </div>
          <div className={classes.buttonGroup}>
            <Popover
              anchorEl={radiusAnchorElement}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              className={classes.radiusPopover}
              id={radiusPopoverId}
              onClose={radiusClose}
              open={open}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={classes.sliderWrapper}>
                <Slider marks max={10} min={1} onChangeCommitted={onBrushRadiusChange} step={1} value={brushRadius} />
              </div>
            </Popover>
            <IconButton onClick={setPen} title='Pióro'>
              <PenIcon className={classes.icon} />
            </IconButton>
            <IconButton disabled={tool === drawingToolsConsts.HIGHLIGHTER} onClick={setHighlighter} title='Zakreślacz'>
              <HighligtherIcon className={classes.icon} />
            </IconButton>
          </div>
        </div>
        <div className={classes.section}>
          <div className={clsx(classes.buttonGroup, classes.prependSeparator)}>
            <IconButton disabled={!unsaved || saving} onClick={saveCanvas} title='Zapisz'>
              <SaveIcon className={clsx(classes.icon, (!unsaved || saving) && classes.disabledIcon)} />
            </IconButton>
            <IconButton onClick={backHandler} title='Powrót'>
              <ClearIcon className={classes.icon} />
            </IconButton>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default DrawSettings;
