import { useLocation } from 'react-router-dom';

import FEATURES from 'constants/dictionaries/features';
import PATHS from 'constants/paths';
import useChosenExercisesStore from 'storages/chosenExercises/chosenExercises';
import { ChosenExercisesStore } from 'storages/chosenExercises/chosenExercises.types';
import useExercisesStore from 'storages/exercises/exercises';
import useSheetsStore from 'storages/sheets/sheets';
import { SheetsStore } from 'storages/sheets/sheets.types';

// todo problem
type UseDataStore = () => SheetsStore | ChosenExercisesStore | any;

type FeaturesKeys = keyof typeof FEATURES;
type FeaturesValues = typeof FEATURES[FeaturesKeys];

type FeatureState = {
  feature: FeaturesValues | null;
  useDataStore: UseDataStore;
  isExercise: boolean;
  isSheet: boolean;
  isChosenExercise: boolean;
};

const getStore = (activeFeature?: FeaturesValues): UseDataStore => {
  switch (activeFeature) {
    case FEATURES.SHEETS:
      return useSheetsStore;
    case FEATURES.EXERCISES_SETS:
      return useChosenExercisesStore;
    case FEATURES.EXERCISES:
    default: {
      return useExercisesStore;
    }
  }
};

const getState = (activeFeature?: FeaturesValues): FeatureState => ({
  feature: activeFeature || null,
  useDataStore: getStore(activeFeature),
  isExercise: activeFeature === FEATURES.EXERCISES,
  isSheet: activeFeature === FEATURES.SHEETS,
  isChosenExercise: activeFeature === FEATURES.EXERCISES_SETS,
});

const useFeatureChecker = (): FeatureState => {
  const { pathname } = useLocation();
  switch (pathname) {
    case PATHS.SHEETS:
      return getState(FEATURES.SHEETS);
    case PATHS.EXERCISES:
      return getState(FEATURES.EXERCISES);
    case PATHS.EXERCISES_SETS:
      return getState(FEATURES.EXERCISES_SETS);
    default:
      return getState();
  }
};

export default useFeatureChecker;
