import PubSub from 'pubsub-js';
import { QueryClient } from 'react-query';

import { AppEvent } from 'types/AppEvent';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: error => {
        PubSub.publish(AppEvent.API_MUTATION_ERROR, { error });
      },
    },
    queries: {
      onError: error => {
        PubSub.publish(AppEvent.API_FETCH_ERROR, { error });
      },
    },
  },
});

export default queryClient;
