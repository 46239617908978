import { alpha, makeStyles } from '@material-ui/core/styles';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

type StyleParams = {
  showIndicator: boolean;
};

const useStyles = makeStyles(theme => ({
  '@keyframes pulse': {
    '0%': { boxShadow: `0 0 0 0 ${alpha(theme.palette.custom.red, 0.9)}` },
    '100%': { boxShadow: `0 0 0 7px ${alpha(theme.palette.custom.red, 0)}` },
  },
  '@keyframes ringBell': {
    '0%': { transform: 'translate(-50%, -50%) rotate(0deg)' },
    '1%': { transform: 'translate(-50%, -50%) rotate(10deg)' },
    '5%': { transform: 'translate(-50%, -50%) rotate(-10deg)' },
    '10%': { transform: 'translate(-50%, -50%) rotate(10deg)' },
    '15%': { transform: 'translate(-50%, -50%) rotate(-10deg)' },
    '20%': { transform: 'translate(-50%, -50%) rotate(0)' },
    '100%': { transform: 'translate(-50%, -50%) rotate(0)' },
  },
  container: {
    position: 'relative',

    height: `calc(${LAYOUT_SIZES.HEADER.md}px - ${theme.spacing(9)}px)`,
    width: `calc(${LAYOUT_SIZES.HEADER.md}px - ${theme.spacing(9)}px)`,

    border: 'none',
    background: 'none',
    marginLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  indicator: {
    position: 'absolute',
    top: '5%',
    right: '12%',
    height: '30%',
    width: '30%',
    borderRadius: '100px',
    zIndex: 1,
    background: theme.palette.custom.red,
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    transform: ({ showIndicator }: StyleParams) => `scale(${showIndicator ? 1 : 0})`,
    animation: `$pulse`,
    animationDuration: '1800ms',
    animationIterationCount: 'infinite',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: `calc(${LAYOUT_SIZES.HEADER.md}px - ${theme.spacing(9)}px)`,
    width: `calc(${LAYOUT_SIZES.HEADER.md}px - ${theme.spacing(9)}px)`,
    transformOrigin: 'top center',
    animationDuration: '6s',
    animationIterationCount: 'infinite',
    animationPlayState: ({ showIndicator }: StyleParams) => (showIndicator ? 'running' : 'paused'),
    animationFillMode: 'forwards',
    animation: `$ringBell`,
  },
}));

export default useStyles;
