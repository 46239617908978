import { makeStyles } from '@material-ui/core/styles';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

type StyleParams = {
  isHeaderVisible: boolean;
};

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    left: '0',
    top: '0',
    zIndex: 100,
    width: '100vw',
    padding: theme.spacing(2, 3),
    height: LAYOUT_SIZES.HEADER.md,
    boxShadow: theme.shadows[2],
    transform: ({ isHeaderVisible }: StyleParams) => `translateY(${isHeaderVisible ? 0 : '-105%'})`,
    transition: `transform 400ms`,

    [theme.breakpoints.down('sm')]: {
      height: LAYOUT_SIZES.HEADER.sm,
    },
  },
  content: {
    maxWidth: LAYOUT_SIZES.CONTAINER.MAIN,
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 6),
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: '250px auto 250px',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
      gridTemplateColumns: '100px auto 100px',
    },

    '@media(max-width: 400px)': {
      gridTemplateColumns: 'auto 1fr',
    },
  },
  gridCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoLink: {
    height: `calc(${LAYOUT_SIZES.HEADER.md}px - ${theme.spacing(7)}px)`,
    alignSelf: 'center',

    [theme.breakpoints.down('sm')]: {
      height: `calc(${LAYOUT_SIZES.HEADER.sm}px - ${theme.spacing(6)}px)`,
    },

    '@media(max-width: 400px)': {
      display: 'none',
    },
  },
  logo: {
    height: '100%',
    marginBottom: 0,
  },
  location: {
    color: theme.palette.common.white,
    userSelect: 'none',
    fontWeight: 500,

    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
  },
  purchaseButton: {
    height: `calc(${LAYOUT_SIZES.HEADER.md}px - ${theme.spacing(9)}px)`,
    marginRight: theme.spacing(5),
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuButtonContainer: {
    border: 'none',
    background: 'none',
    marginLeft: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
    },
  },
  menuButton: {
    height: `calc(${LAYOUT_SIZES.HEADER.md}px - ${theme.spacing(8)}px)`,
    width: `calc(${LAYOUT_SIZES.HEADER.md}px - ${theme.spacing(8)}px)`,
  },
  contentGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'center',
    height: `calc(${LAYOUT_SIZES.HEADER.md}px - ${theme.spacing(12)}px)`,
  },
}));

export default useStyles;
