import { useEffect } from 'react';

import { useQuery } from 'react-query';
import { useOutletContext } from 'react-router';
import { useParams } from 'react-router-dom';

import teacherApi from 'api/rest/teacher/teacher';
import { ClassroomMarksFilters } from 'api/rest/teacher/teacher.types';
import {
  classroomExercisesHeaders,
  ClassroomExercisesTableHeaderValues,
} from 'components/ClassroomExercisesTable/ClassroomExercisesTable.logic';
import QUERY from 'constants/queryKeys';
import { TeacherLayoutOutletContext } from 'root/TeacherLayout/TeacherLayout';
import generateQueryKeyFromObject from 'services/common/generateQueryKeyFromObject/generateQueryKeyFromObject';
import useComplexQuery from 'services/common/useComplexQuery/useComplexQuery';
import useClassroomExercisesFiltersStore from 'storages/teacher/classroomExercisesFiltersStorage';
import { Id } from 'types/Id';

const _useGetFilters = (): ClassroomMarksFilters => {
  const { subject, section, subsection, since, until } = useClassroomExercisesFiltersStore();
  return { subject, until, since, section, subsection };
};

const useExercisesMarksSummaryData = (id: Id) => {
  const filters = _useGetFilters();

  const { data, isLoading } = useQuery(
    generateQueryKeyFromObject({
      base: QUERY.GET_CLASSROOM_EXERCISES_SUMMARY,
      id,
      ...filters,
    }),
    teacherApi.getClassroomExercisesMarksSummary(id, filters),
  );
  return { data, isLoading };
};

const useClassroomExercisesData = (id: Id) => {
  const filters = _useGetFilters();
  const { queryParams, tableProps } = useComplexQuery<ClassroomExercisesTableHeaderValues>(10, classroomExercisesHeaders);

  const { data, isLoading } = useQuery(
    generateQueryKeyFromObject({
      base: QUERY.GET_CLASSROOM_EXERCISES,
      id,
      ...filters,
      ...queryParams.pagination,
      ...queryParams.order,
    }),
    teacherApi.getClassroomExercisesMarks(id, {
      filters,
      ...queryParams,
    }),
    {
      keepPreviousData: true,
    },
  );
  return { data, isLoading, tableProps };
};

const useNavigationUpdate = () => {
  const { setClassroomData } = useOutletContext() as TeacherLayoutOutletContext;

  const { classroomId } = useParams() as { classroomId: string };
  const { data } = useQuery([QUERY.GET_CLASSROOM, classroomId], teacherApi.getClassroom(classroomId), { cacheTime: 1000 * 60 * 10 });

  useEffect(() => {
    if (data) setClassroomData(data);
  }, [data]);
};

export { useExercisesMarksSummaryData, useClassroomExercisesData, useNavigationUpdate };
