import { makeStyles } from '@material-ui/core';

type StyleParams = {
  isStuck: boolean;
};

const useStyles = makeStyles(theme => ({
  tile: {
    backgroundColor: '#ffffff',
    flexGrow: 1,
    flexShrink: 1,
    transformOrigin: 'top left',
    transition: '.3s ease',
    color: theme.palette.grey['400'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    transform: ({ isStuck }: StyleParams) => (isStuck ? 'scale(0)' : 'scale(1)'),
    opacity: ({ isStuck }: StyleParams) => (isStuck ? 0.7 : 1),
  },
  icon: {
    height: '32px',
    width: '32px',
    borderRadius: '100%',
    padding: theme.spacing(1.75),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
