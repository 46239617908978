import React from 'react';

import { Dialog, Typography } from '@material-ui/core';

import FormWrapper from 'components/FormWrapper/FormWrapper';
import DialogGrid from 'containers/DialogGrid/DialogGrid';
import validatePassword from 'services/common/validatePassword/validatePassword';

import FormTextInput from '../../_forms/FormTextInput/FormTextInput';
import { useSetNewPasswordDialog } from './SetNewPasswordDialog.logic';

type Props = {
  onClose: () => void;
};
const SetNewPasswordDialog: React.FC<Props> = ({ onClose }) => {
  const { disabled, isLoading, onSubmit, control, newPasswordValue } = useSetNewPasswordDialog({ onClose });

  return (
    <Dialog maxWidth='xs' open onClose={onClose}>
      <DialogGrid
        disabled={disabled}
        hideCloseButton
        isForm
        sending={isLoading}
        okLabel='Zapisz'
        title='Zmień hasło'
        onSubmit={onSubmit}
        cancelClick={onClose}
      >
        <FormWrapper>
          <Typography>
            Hasło musi mieć co najmniej osiem znaków i nie może składać się z samych cyfr <br />
          </Typography>
          <FormTextInput required name='oldPassword' label='Obecne hasło' control={control} inputProps={{ type: 'password' }} />
          <FormTextInput
            required
            name='newPassword'
            label='Nowe hasło'
            control={control}
            inputProps={{ type: 'password' }}
            rules={{
              validate: {
                match: validatePassword,
              },
            }}
          />
          <FormTextInput
            required
            name='repeatNewPassword'
            label='Powtórz hasło'
            control={control}
            inputProps={{ type: 'password' }}
            rules={{
              validate: {
                match: (value: string) => (value === newPasswordValue ? true : 'Hasła nie pasują do siebie!'),
              },
            }}
          />
        </FormWrapper>
      </DialogGrid>
    </Dialog>
  );
};

export default SetNewPasswordDialog;
