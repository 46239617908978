import React from 'react';

import { Typography } from '@material-ui/core';

import { PaginatedData } from 'api/rest/_types';
import { StudentMark } from 'api/rest/teacher/teacher.types';
import useOpenExerciseDrawer from 'components/ExerciseDetailsDrawer/_hooks/useOpenExerciseDrawer';
import ExercisesTable, { ExercisesTableProps } from 'components/ExercisesTable/ExercisesTable';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

import { StudentsMarksTableHeaderValues } from '../../StudentDetails.logic';


type MarksTableProps = {
  data?: PaginatedData<StudentMark>;
  isLoading: boolean;
  tableProps: Omit<ExercisesTableProps<StudentMark, StudentsMarksTableHeaderValues>, 'data' | 'tableId' | 'onRowClick' | 'count'>;
};

const MarksTable: React.FC<MarksTableProps> = ({ data, tableProps, isLoading }) => {
  const { logEvent } = useUserLogContext();
  const { openExerciseDrawer } = useOpenExerciseDrawer();
  const onRowClick = ({ exerciseUuid }: StudentMark) => {
    logEvent('class-students-exercise-drawer-expand', { exerciseUuid });
    openExerciseDrawer(exerciseUuid);
  };
  // if (!data.count) return <Typography variant='subtitle1'></Typography>;
  const ExerciseTableComponent = ExercisesTable<StudentMark, StudentsMarksTableHeaderValues>;
  return (
    <ExerciseTableComponent
      {...tableProps}
      tableId='SingleStudentTable'
      onRowClick={onRowClick}
      data={data?.results}
      count={data?.count}
      fallbackMessage='Uczeń nie oznaczył żadnych zadań.'
      isLoading={isLoading}
    />
  );
};

export default MarksTable