import axios from 'axios';

import { DefaultApiResponse } from '../_types';
import { parseSectionsListForUI, parseSectionForUI } from './sections.parsers';
import { Section, SectionDto } from './sections.types';

const endpoints = {
  sections: 'exercises/sections/',
};

type GetSectionsList = () => Promise<Section[]>;
type GetSectionQuery = () => Promise<Section>;

const sectionsApi = {
  getSectionsList:
    (subjectId: string): GetSectionsList =>
    async () => {
      const response = await axios.get<GetSectionsList, DefaultApiResponse<SectionDto[]>>(`${endpoints.sections}${subjectId}/`);
      return parseSectionsListForUI(response.data);
    },
  getSection:
    (sectionId: number): GetSectionQuery =>
    async () => {
      const response = await axios.get<GetSectionQuery, DefaultApiResponse<SectionDto>>(`${endpoints.sections}${sectionId}/`);
      return parseSectionForUI(response.data);
    },
};

export default sectionsApi;
