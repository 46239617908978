import React from 'react';

import AuthGrid from 'uniqueComponents/Auth/AuthGrid/AuthGrid';

import RegisterForm from './_components/RegisterForm/RegisterForm';

const RegisterByMail = () => (
  <AuthGrid title='Utwórz dane logowania:'>
    <RegisterForm />
  </AuthGrid>
);

export default RegisterByMail;
