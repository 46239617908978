import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import exercisesSetApi from 'api/rest/exercisesSets/exercisesSet';
import { ExercisesSetDetails } from 'api/rest/exercisesSets/exercisesSet.types';
import queryClient from 'api/rest/queryClient';
import isProduction from 'services/common/isProduction';
import { DataFetchingState } from 'types/DataFetchingState';
import { Id } from 'types/Id';

import { useSetsStorage } from '../exercisesToDisplay/useExercisesToDisplay_sets';

type ValueFields = {
  currentSetId: Id | '';
  currentSetData: ExercisesSetDetails | null;
  dataState: DataFetchingState;
};

type Actions = {
  setCurrentSetId: (newSetId: Id | null) => void;
  refreshExercisesInSelectedSet: () => void;
  reset: () => void;
};

type ExercisesSetStorage = ValueFields & Actions;

const initial: ValueFields = {
  currentSetData: null,
  currentSetId: '',
  dataState: 'IDLE',
};

const exercisesSetStore = persist<ExercisesSetStorage>(
  (set, get) => ({
    ...initial,
    reset: () => {
      set(initial);
    },
    setCurrentSetId: async newSetId => {
      const { setExercises, setCursor } = useSetsStorage.getState();

      if (!newSetId) {
        set(initial);
        setExercises([]);
        setCursor(0);
        return;
      }
      set({ currentSetId: newSetId, dataState: 'FETCHING' });
      try {
        const currentSetData = await queryClient.fetchQuery(
          exercisesSetApi.getDetails.generateQueryKey(newSetId),
          exercisesSetApi.getDetails.request(newSetId),
        );
        set({ currentSetData, dataState: 'SUCCESS' });
        setExercises(currentSetData.exercises.map(({ uuid, globalId }) => ({ uuid, globalId })));
        setCursor(0);
      } catch (error) {
        set({ currentSetData: null, dataState: 'ERROR' });
      }
    },
    refreshExercisesInSelectedSet: async () => {
      const { currentSetId } = get();
      try {
        const { setExercises } = useSetsStorage.getState();
        const currentSetData = await queryClient.fetchQuery(
          exercisesSetApi.getDetails.generateQueryKey(currentSetId),
          exercisesSetApi.getDetails.request(currentSetId),
        );
        setExercises(currentSetData.exercises.map(({ uuid, globalId }) => ({ uuid, globalId })));
        set({ currentSetData, dataState: 'SUCCESS' });
      } catch (error) {
        set({ currentSetData: null, dataState: 'ERROR' });
      }
    },
  }),
  {
    name: 'exercisesSets_generalData',
    getStorage: () => localStorage,
  },
);

// @ts-ignore
const useExercisesSetsStore = create(devtools(exercisesSetStore, { name: 'EXERCISES_SETS', enabled: !isProduction }));

export default useExercisesSetsStore;
