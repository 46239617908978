import React from 'react';

import DateInput from 'components/_inputs/DateInput/DateInput';
import SelectInput from 'components/_inputs/SelectInput/SelectInput';
import { useCheckFunctionality } from 'components/TeacherNavigationBar/TeacherNavigationBar.logic';
import useClassroomExercisesFiltersStore from 'storages/teacher/classroomExercisesFiltersStorage';
import useClassroomFiltersStore from 'storages/teacher/classroomFiltersStorage';

import { useClassroomExercisesFiltersLogic, useFilterCommonLogic } from './TeacherNavigationBarDynamicFilters.logic';
import useStyles from './TeacherNavigationBarDynamicFilters.styles';

const ClassroomFilers = () => {
  const { update, subject, since, until } = useClassroomFiltersStore();

  const { onSubjectChange, onDateSinceChange, onDateUntilChange, subjectOptions } = useFilterCommonLogic(update);

  const classes = useStyles();
  return (
    <>
      <SelectInput value={subject} onChange={onSubjectChange} className={classes.filterMd} label='Przedmiot' options={subjectOptions} />
      <DateInput
        maxDate={until}
        className={classes.filterSm}
        format='dd.MM.yyyy'
        disableFuture
        autoOk
        label='Od'
        onChange={onDateSinceChange}
        value={since}
      />
      <DateInput
        minDate={since}
        className={classes.filterSm}
        format='dd.MM.yyyy'
        disableFuture
        autoOk
        label='Do'
        onChange={onDateUntilChange}
        value={until}
      />
    </>
  );
};

const ClassroomExercisesFilers = () => {
  const { update, subject, section, subsection, since, until } = useClassroomExercisesFiltersStore();

  const { onSubjectChange, onDateSinceChange, onDateUntilChange, subjectOptions } = useFilterCommonLogic(update);
  const { onSectionChange, onSubsectionChange, isSectionDisabled, isSubsectionDisabled, sectionOptions, subsectionOptions } =
    useClassroomExercisesFiltersLogic();

  const classes = useStyles();
  return (
    <>
      <SelectInput value={subject} onChange={onSubjectChange} className={classes.filterMd} label='Przedmiot' options={subjectOptions} />
      <SelectInput
        value={section}
        onChange={onSectionChange}
        disabled={isSectionDisabled}
        className={classes.filterLg}
        label='Dział'
        options={sectionOptions}
      />
      <SelectInput
        disabled={isSubsectionDisabled}
        value={subsection}
        onChange={onSubsectionChange}
        className={classes.filterLg}
        label='Poddział'
        options={subsectionOptions}
      />
      <DateInput
        maxDate={until}
        className={classes.filterSm}
        format='dd.MM.yyyy'
        disableFuture
        autoOk
        label='Od'
        onChange={onDateSinceChange}
        value={since}
      />
      <DateInput
        minDate={since}
        className={classes.filterSm}
        format='dd.MM.yyyy'
        disableFuture
        autoOk
        label='Do'
        onChange={onDateUntilChange}
        value={until}
      />
    </>
  );
};

const TeacherNavigationBarDynamicFilters: React.FC = () => {
  const { isClassroomExercises, isClassroom } = useCheckFunctionality();

  const classes = useStyles();
  if (!isClassroom && !isClassroomExercises) return null;
  return (
    <div className={classes.root}>
      {isClassroom && <ClassroomFilers />}
      {isClassroomExercises && <ClassroomExercisesFilers />}
    </div>
  );
};

export default TeacherNavigationBarDynamicFilters;
