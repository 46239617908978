import axios, { AxiosError } from 'axios';

import type { Id } from 'types/Id';

import {
  parseAppendExercisesSetForBE,
  parseCreateExercisesSetForBE,
  parseExercisesSetDetailsForFE,
  parseExercisesSetForFE,
  parseUpdateExercisesSetForBE,
} from './exercisesSet.parsers';
import type {
  ExercisesSetApi,
  ExercisesSetDetailsDto,
  ExercisesSetDto,
  UpdateExercisesSetDataDto,
  CreateExercisesSetDataDto,
  AppendExercisesSetDataDto,
} from './exercisesSet.types';

const endpoint = 'exercises/sets/';

const exercisesSetApi: ExercisesSetApi = {
  getAll: {
    generateQueryKey: () => 'GET ALL EXERCISES SETS',
    request: () => async () => {
      const { data } = await axios.get<ExercisesSetDto[]>(endpoint);
      return parseExercisesSetForFE(data);
    },
    requestBaseSettings: {
      staleTime: 1000 * 60 * 3,
    },
  },
  getDetails: {
    generateQueryKey: setId => ['GET EXERCISES SET DETAILS', setId as Id],
    request: setId => async () => {
      if (!setId) throw Error('Missing setId');
      const { data } = await axios.get<ExercisesSetDetailsDto>(`${endpoint}${setId}/`);
      return parseExercisesSetDetailsForFE(data);
    },
    requestBaseSettings: {},
  },
  create: {
    generateQueryKey: params => ['CREATE EXERCISES SET', params?.name as string],
    request: () => async input => {
      if (!input) throw Error('Missing input data');
      const { data } = await axios.post<ExercisesSetDetailsDto>(endpoint, parseCreateExercisesSetForBE(input));
      return { id: data.id, exercises: data.exercises, name: data.name };
    },
    requestBaseSettings: {},
  },
  update: {
    generateQueryKey: () => 'UPDATE EXERCISES SET',
    request: () => data => {
      if (!data) throw Error('Missing input data');
      return axios.put<UpdateExercisesSetDataDto>(`${endpoint}${data.setId}/`, parseUpdateExercisesSetForBE(data.input));
    },
    requestBaseSettings: {},
  },
  append: {
    generateQueryKey: () => 'APPEND TO EXERCISES SET',
    request: () => async data => {
      if (!data) throw Error('Missing input data');
      try {
        return await axios.post<AppendExercisesSetDataDto>(`${endpoint}${data.setId}/append/`, parseAppendExercisesSetForBE(data.input));
      } catch (e: unknown) {
        const error = e as AxiosError;
        return { error: error.response?.data._error[0] };
      }
    },
    requestBaseSettings: {},
  },
  remove: {
    generateQueryKey: params => ['REMOVE EXERCISES SET', params?.id as Id],
    request: () => params => {
      if (!params?.id) throw Error('Missing id');
      return axios.delete<CreateExercisesSetDataDto>(`${endpoint}${params.id}/`);
    },
    requestBaseSettings: {},
  },
};

export default exercisesSetApi;
