import React from 'react';

import { InputProps } from '@material-ui/core';
import type { Control, ValidationRule } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import useRulesProp from 'components/_forms/useRulesProp/useRulesProp';
import CheckboxInput from 'components/_inputs/CheckboxInput/CheckboxInput';

type Props = {
  label: string;
  name: string;
  control: Control<any>;
  required?: boolean;
  customError?: string;
  rules?: ValidationRule<any>;
  inputProps?: InputProps;
};

const FormCheckboxInput: React.FC<Props> = ({ control, label, name, inputProps = {}, required = false, customError, rules }) => {
  const { rulesProp } = useRulesProp({ required, rules });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        // @ts-ignore
        <CheckboxInput error={customError || fieldState?.error?.message} label={label} {...inputProps} {...field} />
      )}
      rules={rulesProp}
    />
  );
};

export default FormCheckboxInput;
