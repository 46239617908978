import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

type StylesProps = {
  iconWidth?: number;
};

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MenuTooltip_premium': {
      background: theme.palette.primary.main,
    },
    '.MenuTooltipArrow_premium': {
      color: theme.palette.primary.main,
    },
  },
  root: {
    display: 'grid',
    gridTemplateColumns: '20px 1fr',
    alignItems: 'center',
    gridGap: theme.spacing(4),
    padding: theme.spacing(3, 5),
    color: theme.palette.primary.main,

    '&.active': {
      background: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),

      '& path, rect, circle': {
        fill: theme.palette.common.white,
        '&.inverted': {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
  icon: {
    width: ({ iconWidth }: StylesProps) => `${iconWidth || 20}px`,
    maxHeight: '30px',
    '& path, rect, circle': {
      fill: theme.palette.primary.main,
      '&.inverted': {
        fill: theme.palette.common.white,
      },
    },
  },
  disabled: {
    color: theme.palette.text.disabled,
    cursor: 'default',
    '& path, rect, circle': {
      fill: theme.palette.text.disabled,
    },
  },
  wrapper: {
    position: 'relative',
  },
  '@keyframes move': {
    '0%': { transform: 'translateY(0%)' },
    '70%': { transform: 'translateY(35%)' },
    '100%': { transform: 'translateY(0%)' },
  },
  pointer: {
    position: 'absolute',
    top: '85%',
    left: '20%',
    animationDuration: '1.25s',
    animationIterationCount: 'infinite',
    animation: `$move`,
  },
}));

export default useStyles;
