import React, { useState } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import messages from 'constants/dictionaries/messages';
import DialogGrid from 'containers/DialogGrid/DialogGrid';
import SEND_FEEDBACK from 'api/graphql/mutations/notifications/SEND_FEEDBACK';
import logError from 'utils/logError/logError';
import useStyles from './UserFeedbackForm.styles';

const UserFeedbackForm = ({ closeDialog }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const client = useApolloClient();

  const onChange = ({ target }) => {
    const newDisabled = target.value.length < 3;
    if (newDisabled !== disabled) setDisabled(newDisabled);
  };

  const onSubmit = async e => {
    e.preventDefault();
    const { value } = e.target.message;
    setSending(true);
    try {
      const { errors } = await client.mutate({
        mutation: SEND_FEEDBACK,
        variables: { message: value },
      });
      if (errors?.length) {
        enqueueSnackbar(messages.ERROR.SENDING('wiadomości'), { variant: 'error' });
        logError(errors);
      } else {
        enqueueSnackbar('Dziękujemy za opinie', { variant: 'success' });
        closeDialog();
      }
    } catch (error) {
      enqueueSnackbar(messages.ERROR.SENDING('wiadomości'), { variant: 'error' });
      logError(error);
    } finally {
      setSending(false);
    }
  };
  const classes = useStyles();
  return (
    <DialogGrid
      cancelClick={closeDialog}
      disabled={disabled}
      hideCloseButton
      isForm
      okLabel='Wyślij'
      onSubmit={onSubmit}
      sending={sending}
      title='Twoja opinia'
    >
      <div className={classes.wrapper}>
        <TextField
          autoFocus
          className={classes.textarea}
          minRows={6}
          multiline
          name='message'
          onChange={onChange}
          placeholder='Powiedz Nam co myślisz o portalu Moja Matura...'
          variant='outlined'
        />
      </div>
    </DialogGrid>
  );
};

export default UserFeedbackForm;
