import gql from 'graphql-tag';

const VERIFY_PROMOCODE = gql`
  mutation VerifyPromoCode($input: VerifyPromoCodeInput!) {
    verifyPromoCode(input: $input) {
      ok
      isPaymentRequired
      accessType {
        id
        duration
        name
        price
        priceNetto
      }
    }
  }
`;

export default VERIFY_PROMOCODE;
