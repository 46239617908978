import React from 'react';

import { Typography, useMediaQuery, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import { useLocation, Link, matchPath } from 'react-router-dom';

import usersApi from 'api/rest/users/users';
import { ReactComponent as MenuIcon } from 'assets/iconhub/menu.svg';
import { ReactComponent as Logo } from 'assets/logo/full_white.svg';
import { ReactComponent as Mark } from 'assets/logo/mark_white.svg';
import PATHS, { USER_SETTINGS_DIALOGS } from 'constants/paths';
import QUERY from 'constants/queryKeys';
import { internalRoutes } from 'constants/routes';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import useLayoutStore from 'storages/layout';

import NotificationBell from './_components/NotificationBell/NotificationBell';
import useStyles from './Header.styles';

const getLocationName = (pathName: string): string => {
  const match = internalRoutes.find(({ path }) => matchPath(path, pathName));
  return match ? match.name : '';
};

const Header = (): React.ReactElement => {
  const { logEvent } = useUserLogContext();
  const theme = useTheme();
  const location = useLocation();
  const upSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { data } = useQuery(QUERY.GET_ME, usersApi.getMe());
  const { isHeaderVisible, toggleIsMenuVisible, toggleIsNotificationsVisible } = useLayoutStore();

  const onBuyClick = () => {
    logEvent('buy-click');
  };

  const styles = useStyles({ isHeaderVisible });
  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <Link className={styles.logoLink} to={PATHS.ROOT}>
          {upSm ? <Mark className={styles.logo} /> : <Logo className={styles.logo} />}
        </Link>
        <div className={styles.gridCenter}>
          <Typography className={styles.location}>{getLocationName(location.pathname)}</Typography>
        </div>
        <div className={styles.contentGroup}>
          {data && !data.hasPremiumAccess && !data.isSchoolAccount && (
            <Button
              onClick={onBuyClick}
              className={styles.purchaseButton}
              size='small'
              variant='outlined'
              component={Link}
              to={`${PATHS.USER_SETTINGS}/${USER_SETTINGS_DIALOGS.SUBSCRIBE}`}
            >
              Kup
            </Button>
          )}
          <NotificationBell onClick={toggleIsNotificationsVisible} />
          <button onClick={toggleIsMenuVisible} type='button' className={styles.menuButtonContainer}>
            <MenuIcon className={styles.menuButton} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
