import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: theme.palette.grey[200],
    padding: theme.spacing(2, 0),
    boxShadow: theme.shadows[3],
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  icon: {
    height: '28px',
    width: '28px',
    '& *': {
      stroke: `${theme.palette.primary.main} !important`,
    },
  },
}));

export default useStyles;
