const LAYOUT_SIZES = {
  HEADER: {
    md: 64,
    sm: 50,
  },
  TEACHER_HEADER: {
    md: 54,
    sm: 34,
  },
  FOOTER: {
    md: 65,
    sm: 53,
  },
  CONTAINER: {
    MAIN: 1640,
    FORM: {
      md: 400,
      sm: 340,
    },
  },
};

export default LAYOUT_SIZES;
