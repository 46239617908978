import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  empty: {
    marginTop: 0,
  },
  helperText: {
    fontSize: '0.75rem',
  },
}));

export default useStyles;
