import React, { MouseEventHandler } from 'react';

import { Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Pointer } from 'assets/icons/general/pointer.svg';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import useLayoutStore from 'storages/layout';

import useStyles from './MenuLink.styles';

export type DisabledReason = 'under_construction' | 'premium';

export type MenuLinkType = {
  label: string;
  to: string;
  icon: React.FunctionComponent<{ className?: string }>;
  disabled?: DisabledReason;
  iconWidth?: number;
  showIndicator?: boolean;
};

type Props = MenuLinkType;

const resolveTooltip = (disabledReason?: DisabledReason): { title: string; tooltipClassName?: string; arrowClassName?: string } => {
  switch (disabledReason) {
    case 'premium':
      return { title: 'Dostępne w Premium', tooltipClassName: 'MenuTooltip_premium', arrowClassName: 'MenuTooltipArrow_premium' };
    case 'under_construction':
      return {
        title: 'Pracujemy nad tym',
        tooltipClassName: 'MenuTooltip_underConstruction',
        arrowClassName: 'MenuTooltipArrow_underConstruction',
      };
    default:
      return { title: '' };
  }
};

const MenuLink: React.FC<Props> = ({ to, label, icon: Icon, disabled, iconWidth, showIndicator }) => {
  const { logEvent } = useUserLogContext();
  const { toggleIsMenuVisible } = useLayoutStore();
  const onLinkClick: MouseEventHandler<HTMLAnchorElement> = e => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    logEvent('hamburger-click', { button: label });
    toggleIsMenuVisible();
  };

  const tooltip = resolveTooltip(disabled);
  const classes = useStyles({ iconWidth });
  return (
    <Tooltip placement='left' title={tooltip.title} arrow classes={{ tooltip: tooltip.tooltipClassName, arrow: tooltip.arrowClassName }}>
      <div className={classes.wrapper}>
        <NavLink end onClick={onLinkClick} to={to} className={clsx(classes.root, disabled && classes.disabled)}>
          <Icon className={classes.icon} />
          <Typography>{label}</Typography>
        </NavLink>
        {showIndicator && <Pointer className={classes.pointer} />}
      </div>
    </Tooltip>
  );
};

export default MenuLink;
