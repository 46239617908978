import { useEffect } from 'react';

import { useQuery } from 'react-query';

import agreementsApi from 'api/rest/agreements/agreements';
import usersApi from 'api/rest/users/users';
import QUERY from 'constants/queryKeys';
import { useGlobalDialogContext } from 'context/GlobalDialogContext';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

const usePrivateRouteEvents = () => {
  const { logEvent } = useUserLogContext();

  const { data: userData, isLoading: userIsLoading } = useQuery(QUERY.GET_ME, usersApi.getMe());
  const { data: agreementsData, isLoading: agreementsDataLoading } = useQuery(
    agreementsApi.getAll.generateQueryKey({ hasConsent: false }),
    agreementsApi.getAll.request({ hasConsent: false }),
    agreementsApi.getAll.requestBaseSettings,
  );
  const { addToGlobalDialogQueue, GLOBAL_DIALOG } = useGlobalDialogContext();

  useEffect(() => {
    if (!userIsLoading && !agreementsDataLoading && userData) {
      const { showTutorial, isVerified, email, accountType, origin, isSchoolAccount, hasPasswordSet } = userData;
      const dialogsToOpen = [];

      if (!hasPasswordSet) dialogsToOpen.push({ type: GLOBAL_DIALOG.CHANGE_PASSWORD, props: { email }, priority: 1 });

      if (!isVerified) dialogsToOpen.push({ type: GLOBAL_DIALOG.VERIFY_ACCOUNT, props: { email }, priority: 1 });

      if ((!accountType || !origin) && !isSchoolAccount) dialogsToOpen.push({ type: GLOBAL_DIALOG.USER_INFO });

      if (showTutorial) {
        logEvent('tutorial-open', { initial: true });
        dialogsToOpen.push({ type: isSchoolAccount ? GLOBAL_DIALOG.HELP_SECTION_INFO : GLOBAL_DIALOG.TUTORIAL, priority: 7 });
      }

      if (agreementsData?.length) {
        agreementsData.forEach(({ title, content, file, id, validSince }) => {
          if (validSince < new Date()) {
            dialogsToOpen.push({
              type: GLOBAL_DIALOG.MISSING_AGREEMENT,
              priority: 2,
              props: {
                title,
                content,
                id,
                file,
              },
            });
          }
        });
      }

      if (dialogsToOpen.length) addToGlobalDialogQueue(dialogsToOpen);
    }
  }, [userData, userIsLoading, agreementsData]);

  return { isLoading: userIsLoading };
};

export { usePrivateRouteEvents };
