import React, { useEffect, useRef, useCallback } from 'react';

import CanvasDraw from 'react-canvas-draw';

import { useExerciseDrawContext } from 'context/ExerciseDrawContext';

function CanvasWorkspace({ canvasDimension, initialData, index }) {
  const workspaceRef = useRef();
  const {
    drawState: { canvasSettings },
    drawUpdaters: { updateCanvasHistory, updateCanvasRefs },
  } = useExerciseDrawContext();

  useEffect(() => {
    if (workspaceRef.current) {
      updateCanvasRefs({ index, ref: workspaceRef });
    }
    return () => updateCanvasRefs({ index, remove: true });
  }, [workspaceRef.current]);

  const onChange = useCallback(() => {
    updateCanvasHistory(index);
  }, [index]);

  if (!canvasDimension.width) return null;

  return (
    <CanvasDraw
      ref={workspaceRef}
      backgroundColor='transparent'
      canvasHeight={canvasDimension.height}
      canvasWidth={canvasDimension.width}
      onChange={onChange}
      saveData={initialData}
      {...canvasSettings}
    />
  );
}

CanvasWorkspace.defaultProps = {
  initialData: null,
};

export default CanvasWorkspace;
