import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';

import authManager from 'utils/authManager/authManager';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { AppEvent } from 'types/AppEvent';
import useSubscription from 'services/common/useSubscription/useSubscription';
import users from 'api/rest/users/users';
import QUERY from 'constants/queryKeys';
import useExercisesSetsStore from 'storages/exercisesSets/exercisesSets';
import useSheetsStore from 'storages/sheets/sheets';
import useExercisesStore from 'storages/exercises/exercises';
import PATHS, { AUTH_PREFIX } from 'constants/paths';
import useRedirectsStore from 'storages/redirects/useRedirects';
import { useApolloClient } from '@apollo/react-hooks';
// eslint-disable-next-line camelcase
import useExercisesToDisplay_sets from '../storages/exercisesToDisplay/useExercisesToDisplay_sets';

const AuthContext = createContext(null);

const { Provider } = AuthContext;

const localData = authManager.getCookieAuthData();
const initialAuthData = {
  token: null,
};

export const authServices = {
  onLogin: token => {
    axios.defaults.headers.common.Authorization = `JWT ${token}`;
    authManager.setCookieAuthData({ token });
  },
  onLogout: () => {
    axios.defaults.headers.common.Authorization = undefined;
    authManager.removeCookieAuthData();
    sessionStorage.clear();
  },
};

function AuthProvider(props) {
  const { setRedirectOnLogin, setRedirectOnLogout, pruneRedirectOnLogin } = useRedirectsStore();
  const [authData, setAuthData] = useState(localData || initialAuthData);
  const [token, setToken] = useState(localData?.token || initialAuthData.token);

  const sheetsStore = useSheetsStore();
  const exercisesStore = useExercisesStore();
  const exercisesSetsStore = useExercisesSetsStore();
  const exercisesToDisplaySets = useExercisesToDisplay_sets();
  const queryClient = useQueryClient();
  const apolloClient = useApolloClient();

  const { refetch } = useQuery(QUERY.GET_ME, users.getMe(), { enabled: false });
  const updateAuthStorage = useCallback(
    newData => {
      setAuthData(prev => {
        const concatData = { ...prev, ...newData };
        authManager.setCookieAuthData(concatData);
        setRedirectOnLogout(concatData.isSchoolAccount ? PATHS.LOGIN_BY_SCHOOL : PATHS.LOGIN);
        return concatData;
      });
    },
    [authData, token],
  );

  const onLogout = useCallback(async () => {
    authServices.onLogout();
    setToken(initialAuthData.token);
    setAuthData({ token: false });

    apolloClient.clearStore();
    queryClient.clear();

    sheetsStore.reset();
    exercisesStore.reset();
    exercisesSetsStore.reset();
    exercisesToDisplaySets.reset();

    pruneRedirectOnLogin();
  }, []);

  const onLogin = useCallback((newToken, additionalData) => {
    authServices.onLogin(newToken);
    if (additionalData) setAuthData(additionalData);
    setToken(newToken);
  }, []);

  useEffect(() => {
    if (!token && !window.location.pathname.includes(AUTH_PREFIX)) setRedirectOnLogin(window.location.pathname);
    if (token) {
      (async () => {
        const { data } = await refetch();
        if (data) {
          updateAuthStorage({ token, ...data });
        } else {
          onLogout();
        }
      })();
    }
  }, [token]);

  useEffect(() => {
    const cookiesAuthData = authManager.getCookieAuthData();
    if (cookiesAuthData) {
      setAuthData(cookiesAuthData);
      axios.defaults.headers.common.Authorization = `JWT ${cookiesAuthData.token}`;
    } else setAuthData({ token: false });
  }, []);

  useSubscription(AppEvent.OUTDATED_TOKEN, onLogout);

  const authDataValue = useMemo(
    () => ({
      ...authData,
      updateAuthStorage,
      onLogin,
      onLogout,
    }),
    [authData, updateAuthStorage, onLogin, onLogout],
  );

  return <Provider value={authDataValue} {...props} />;
}

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
