import gql from 'graphql-tag';

const GET_ACCESS_TYPES = gql`
  query getAccessTypes {
    accessTypes {
      id
      duration
      name
      price
      priceNetto
    }
  }
`;

export default GET_ACCESS_TYPES;
