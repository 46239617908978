import React from 'react';

import { Typography } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    padding: theme.legacySpacing(1),
    cursor: ({ disabled }) => !disabled && 'pointer',
    opacity: ({ disabled }) => disabled && '.4',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
    '&:after': {
      position: 'absolute',
      display: ({ badge }) => (badge ? 'block' : 'none'),
      right: '12px',
      top: '0',
      content: '"!"',
      paddingTop: '3px',
      color: 'white',
      fontWeight: '900',
      fontSize: '8px',
      height: '14px',
      width: '14px',
      background: theme.palette.primary.main,
      borderRadius: '100px',
    },
  },
  icon: {
    height: '1.75rem',
    width: '1.25rem',
    paddingBottom: '.5rem',
  },
  label: {
    fontSize: '.75rem',
  },
}));

function IconWithText({ icon: Icon, label, onClick, disabled, badge, iconClassName, iconProps }) {
  const classes = useStyles({ disabled, badge });
  return (
    <ButtonBase className={classes.button} disabled={disabled} onClick={onClick}>
      <Icon className={clsx(classes.icon, iconClassName)} {...iconProps} />
      <Typography className={classes.label}>{label}</Typography>
    </ButtonBase>
  );
}

IconWithText.defaultProps = {
  disabled: false,
  badge: false,
  iconClassName: null,
  iconProps: {},
};

export default IconWithText;
