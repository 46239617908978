import { makeStyles } from '@material-ui/core';

type StylesProps = {
  customBackground?: string;
};

const useStyles = makeStyles(theme => {
  const getCustomBackgroundStyles = (customBackground: string) => ({
    backgroundColor: customBackground,
    color: theme.palette.getContrastText(customBackground),
  });
  return {
    label: {
      display: 'inline-block',
      paddingTop: theme.spacing(0.5),
    },
    root: ({ customBackground }: StylesProps) => ({
      ...(!!customBackground && getCustomBackgroundStyles(customBackground)),
    }),
  };
});

export default useStyles;
