import React from 'react';

import { Theme, useMediaQuery } from '@material-ui/core';
import { useQuery } from 'react-query';
import { Location, Navigate } from 'react-router-dom';

import usersApi from 'api/rest/users/users';
import PageLoader from 'components/PageLoader/PageLoader';
import PATHS from 'constants/paths';
import QUERY from 'constants/queryKeys';
import Container from 'containers/Container/Container';
import FullScreen from 'containers/FullScreen/FullScreen';
import { useExerciseDrawContext } from 'context/ExerciseDrawContext';
import TablesProvider from 'context/TablesContext';
import ExerciseDesktopView from 'pages/Exercises/ExerciseDesktopView';
import ExerciseMobileView from 'pages/Exercises/ExerciseMobileView';
import useSubjectStore from 'storages/subject';

import useStyles from './PremiumExercisesPageChecker.styles';

type Props = {
  from: Location;
};

const PremiumExercisesPageChecker: React.FC<Props> = ({ from }) => {
  const { subject } = useSubjectStore();
  const { data, isLoading } = useQuery(QUERY.GET_ME, usersApi.getMe());

  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    drawState: { drawMode },
  } = useExerciseDrawContext();

  const classes = useStyles();
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  if (!subject) return <Navigate state={{ from }} to={PATHS.SUBJECT_SELECT} />;
  if (isLoading) return <PageLoader />;
  if (data && !data.hasPremiumAccess) return <Navigate state={{ from }} to={PATHS.ROOT} />;
  return (
    <FullScreen>
      <TablesProvider dataSource='change it'>
        <Container className={classes.container} width='narrow'>
          {downSm ? <ExerciseMobileView /> : <ExerciseDesktopView drawMode={drawMode} />}
        </Container>
      </TablesProvider>
    </FullScreen>
  );
};

export default PremiumExercisesPageChecker;
