import { makeStyles } from '@material-ui/core';

type StylesProps = {
  minimal?: boolean;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: ({ minimal }: StylesProps) => (minimal ? '0' : '.5rem'),
    flexShrink: 1,
  },

  button: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default useStyles;
