import React, { useEffect } from 'react';

import { CircularProgress } from '@material-ui/core';

import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

import MarksTable from './_components/MarksTable/MarksTable';
import { dataMapping, studentDetailsHeaders, useGetStudentMarksData } from './StudentDetails.logic';
import useStyles from './StudentDetails.styles';

const StudentDetails: React.FC<{ id: number }> = ({ id }) => {
  const { logEvent } = useUserLogContext();

  const { data, isLoading, tableProps } = useGetStudentMarksData(id);

  useEffect(() => {
    logEvent('class-students-results-expand', { studentId: id });
    return () => logEvent('class-students-results-collapse', { studentId: id });
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MarksTable
        data={data}
        isLoading={isLoading}
        tableProps={{
          headers: studentDetailsHeaders,
          dataMapping,
          ...tableProps,
        }}
      />
    </div>
  );
};

export default StudentDetails;
