export enum UserOrigin {
  TWITTER = 'Twitter',
  TIKTOK = 'TikTok',
  INSTAGRAM = 'Instagram',
  FACEBOOK = 'Facebook',
  YOUTUBE = 'YouTube',
  GOOGLE = 'Google',
  GOOGLE_PLAY = 'Sklep Play',
  FRIENDS = 'Od znajomych lub rodziny',
  SCHOOL = 'Ze szkoły',
  OTHER = 'Inne',
}
