import React, { useMemo } from 'react';

import { useFiltersCountContext } from 'context/FiltersCountContext';
import useExercisesStore from 'storages/exercises/exercises';

import FiltersBlock from 'uniqueComponents/Exercises/ExercisesFilters/FiltersBlock/FiltersBlock';
import GenericFiltersList from 'uniqueComponents/Exercises/ExercisesFilters/GenericFiltersList/GenericFiltersList';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

function Marks() {
  const { logEvent } = useUserLogContext();
  const { filters } = useFiltersCountContext();
  const { setUserMarks, userMarks } = useExercisesStore();

  const isAllSelected = useMemo(() => userMarks?.length === filters?.userMarks?.length, [userMarks, filters.userMarks]);

  const onSelectAll = () => {
    logEvent('select-filter-click', { filter: 'Moje zadania', selectAll: true, value: isAllSelected, feature: 'exercises' });

    const newUserMarks = filters?.userMarks?.map(({ id }) => id);
    if (isAllSelected || !newUserMarks) {
      setUserMarks([]);
      return;
    }

    setUserMarks(newUserMarks);
  };

  return (
    <FiltersBlock heading='Moje zadania' isAllSelected={isAllSelected} isSelectable onSelectAll={onSelectAll}>
      <GenericFiltersList
        filterName='userMarks'
        filters={filters.userMarks}
        heading='Moje zadania'
        setFilters={setUserMarks}
        title='userMarks'
      />
    </FiltersBlock>
  );
}

export default Marks;
