import gql from 'graphql-tag';

const GET_ACCOUNT_SETUP_CHOICES = gql`
  query getAccountSetupChoices {
    accountTypeChoices
    ageRangeChoices
    classChoices
  }
`;

export default GET_ACCOUNT_SETUP_CHOICES;
