import { Notification, NotificationDto } from './notifications.types';

type ParseNotificationForUI = (input: NotificationDto) => Notification;

type ParseNotificationArrayForUI = (input: NotificationDto[]) => Notification[];

const parseNotificationForUI: ParseNotificationForUI = (data: NotificationDto) => {
  const { created_at, is_read, ...rest } = data;
  return {
    ...rest,
    createdAt: created_at,
    isRead: is_read,
  };
};

const parseNotificationArrayForUI: ParseNotificationArrayForUI = dataArr => dataArr.map(data => parseNotificationForUI(data));

export { parseNotificationForUI, parseNotificationArrayForUI };
