import React from 'react';

import { Divider, Drawer, Typography } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useQuery } from 'react-query';

import usersApi from 'api/rest/users/users';
import { ReactComponent as PlanetIcon } from 'assets/icons/externalResources/wiki.svg';
import { ReactComponent as AvatarIcon } from 'assets/icons/general/avatar.svg';
import { ReactComponent as ChosenExercisesIcon } from 'assets/icons/general/chosen_exercises.svg';
import { ReactComponent as ExercisesIcon } from 'assets/icons/general/exercises.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/general/home.svg';
import { ReactComponent as BoardIcon } from 'assets/icons/general/my_progress.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/general/question-mark.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/general/settings.svg';
import { ReactComponent as SheetsIcon } from 'assets/icons/general/sheets.svg';
import DrawerWrapper from 'components/DrawerWrapper/DrawerWrapper';
import MenuLink, { DisabledReason, MenuLinkType } from 'components/MenuLink/MenuLink';
import PATHS from 'constants/paths';
import QUERY from 'constants/queryKeys';
import { useAuthContext } from 'context/AuthContext';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import useLayoutStore from 'storages/layout';
import trimString from 'utils/trimString/trimString';

import useStyles from './MenuDrawer.styles';

const getMenuLinks = ({
  isPremiumUser,
  isTeacher,
}: {
  isPremiumUser?: boolean;
  isTeacher: boolean;
}): (MenuLinkType & {
  showDivider?: boolean;
})[] => [
  ...(isTeacher
    ? [
        {
          label: 'Panel nauczyciela',
          to: PATHS.TEACHER_DASHBOARD,
          icon: BoardIcon,
          showDivider: true,
          disabled: isPremiumUser ? undefined : ('premium' as DisabledReason),
        },
      ]
    : []),
  { label: 'Wybierz przedmiot', to: PATHS.SUBJECT_SELECT, icon: PlanetIcon },
  { label: 'Menu główne', to: PATHS.ROOT, icon: HomeIcon },
  { label: 'Zadania', to: PATHS.EXERCISES, icon: ExercisesIcon },
  { label: 'Arkusze', to: PATHS.SHEETS, icon: SheetsIcon, disabled: isPremiumUser ? undefined : 'premium' },
  {
    label: 'Zestawy zadań',
    to: PATHS.EXERCISES_SETS,
    icon: ChosenExercisesIcon,
    disabled: isPremiumUser ? undefined : 'premium',
    showDivider: true,
    iconWidth: 26,
  },
  {
    label: 'Ustawienia',
    to: PATHS.USER_SETTINGS,
    icon: SettingsIcon,
  },

  {
    label: 'Pomoc',
    to: PATHS.HELP,
    icon: HelpIcon,
  },
];

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const MenuDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const { logEvent } = useUserLogContext();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { username, onLogout, isTeacher } = useAuthContext();
  const { toggleIsMenuVisible, menuIndicator } = useLayoutStore();
  const { data } = useQuery(QUERY.GET_ME, usersApi.getMe());

  const logout = () => {
    logEvent('log-out');
    toggleIsMenuVisible();
    onLogout();
  };

  const classes = useStyles();
  return (
    <Drawer open={isOpen} onClose={onClose} anchor='right'>
      <DrawerWrapper onClose={onClose}>
        <div className={classes.header}>
          <AvatarIcon className={classes.avatarIcon} />
          <div>
            <Typography variant='subtitle1'>{trimString(22, username)}</Typography>
            <ButtonBase disableRipple onClick={logout}>
              <Typography variant='subtitle2'>Wyloguj się</Typography>
            </ButtonBase>
          </div>
        </div>
        <Divider className={classes.divider} />
        {getMenuLinks({ isPremiumUser: data?.hasPremiumAccess, isTeacher }).map(menuLinkProp => {
          const { showDivider, ...props } = menuLinkProp;
          return (
            <span key={menuLinkProp.to}>
              <MenuLink showIndicator={menuIndicator === props.to} key={menuLinkProp.to} {...props} />
              {showDivider && <Divider className={classes.divider} />}
            </span>
          );
        })}
      </DrawerWrapper>
    </Drawer>
  );
};

export default MenuDrawer;
