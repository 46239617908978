import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';

import users from 'api/rest/users/users';
import { User } from 'api/rest/users/users.types';
import MUTATION from 'constants/mutationsKeys';
import QUERY from 'constants/queryKeys';

type HookParams = {
  onSuccess?: (values: Partial<User>) => void;
};

const useUpdateMeMutation = (params?: HookParams) => {
  const { onSuccess } = params || {};
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateMeMutation = useMutation(MUTATION.UPDATE_ME, users.updateMe(), {
    onSuccess: async newValues => {
      await queryClient.invalidateQueries(QUERY.GET_ME);
      enqueueSnackbar('Dane pomyślnie zapisane', { variant: 'success' });
      if (onSuccess) onSuccess(newValues as Partial<User>);
    },
  });

  return { updateMeMutation };
};

export default useUpdateMeMutation;
