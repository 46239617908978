import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';

import { ExerciseFileTypes } from 'api/rest/exercises/exercises.types';
import ExerciseDisplay from 'components/ExerciseDisplay/ExerciseDisplay';
import ExercisePath from 'components/ExercisePath/ExercisePath';
import ExercisesMobileViewGrid from 'components/ExercisesMobileViewGrid/ExercisesMobileViewGrid';
import NavigationMobile from 'components/NavigationMobile/NavigationMobile';
import { parseSubjectNumberToSubjectCode } from 'services/common/subjectParsers/subjectParsers';
import useModernNavigation from 'services/exercises/useModernNavigation/useModernNavigation';
import useExercisesSetsStore from 'storages/exercisesSets/exercisesSets';
import useExercisesToDisplay_sets from 'storages/exercisesToDisplay/useExercisesToDisplay_sets';
import ChosenExercisesFilters from 'uniqueComponents/ChosenExercises/ChosenExercisesFilters/ChosenExercisesFilters';

import ExerciseHeaderMobile from '../../../Exercises/_components/ExerciseHeaderMobile/ExerciseHeaderMobile';
import useStyles from './ExercisesSetsMobile.styles';

const ExercisesSetsMobile: React.FC = () => {
  const { exercises, dataState, selectedFileType, currentExercise, cursor } = useExercisesToDisplay_sets();
  const { currentSetData } = useExercisesSetsStore();

  const exercisesCount = useMemo(() => `${exercises.length || 0}`, [exercises]);
  const { actions: navigationActions, buttonsState, exerciseName } = useModernNavigation();

  const classes = useStyles();
  return (
    <ExercisesMobileViewGrid
      header={
        <ExerciseHeaderMobile
          // @ts-ignore
          subjectCode={parseSubjectNumberToSubjectCode(currentExercise?.subject.id)}
          isMarked={!!currentExercise?.userMark}
          currentExerciseId={currentExercise?.globalId}
          exercisesCount={exercisesCount}
          exerciseName={exerciseName}
          exerciseNumber={cursor + 1}
          cacheStrategy='rq'
        />
      }
      main={
        <ExerciseDisplay
          exercisePath={
            <ExercisePath section={currentExercise?.section} subsection={currentExercise?.subsection} subject={currentExercise?.subject} />
          }
          fetchingExerciseDataState={dataState}
          filesToDisplay={currentExercise?.files[selectedFileType]}
          audioSource={currentExercise?.audioSource}
          currentExerciseId={currentExercise?.globalId}
          manualComponent={
            <Typography align='center' variant='h2'>
              Wybierz zestaw zadań, aby kontynuować.
            </Typography>
          }
          emptyComponent={
            <div className={classes.infoBox}>
              <Typography align='center' variant='h2'>
                Ten zestaw zadań jest pusty.
              </Typography>
              <Typography align='center' variant='subtitle1'>
                Aby dodać zadania do zestawu skorzystaj z przycisku dodawania zadań w widoku zadań lub arkuszy.
              </Typography>
            </div>
          }
        />
      }
      drawerContent={<ChosenExercisesFilters />}
      drawerCaption={exercisesCount}
      drawerTitle={currentSetData?.name || 'Wczytywanie...'}
      navigation={
        <NavigationMobile
          hasAttachments={!!currentExercise?.files[ExerciseFileTypes.attachment].length}
          hasExercises={!!exercisesCount}
          fileType={selectedFileType}
          actions={navigationActions}
          buttonsEnableState={buttonsState}
          // @ts-ignore
          subjectCode={parseSubjectNumberToSubjectCode(currentExercise?.subject.id)}
        />
      }
    />
  );
};

export default ExercisesSetsMobile;
