import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';

import { Portal } from '@material-ui/core';
import { isArray } from 'lodash';

import biolchem from 'assets/tables/biolchem.png';
import Tables from 'components/Tables/Tables';
import useSubjectStore from 'storages/subject';

import useUserPreferencesStore from 'storages/userPreferences';
import useExerciseDataSourceResolver from 'services/common/useExerciseDataSourceResolver/useExerciseDataSourceResolver';
// eslint-disable-next-line camelcase
import useExercisesToDisplay_sets from '../storages/exercisesToDisplay/useExercisesToDisplay_sets';
import { parseSubjectNumberToSubjectCode } from '../services/common/subjectParsers/subjectParsers';

const importAll = files => {
  const images = {};
  files.keys().map(item => {
    images[item.replace('./', '')] = files(item);
  });
  return [...new Set(Object.values(images))];
};

const math = importAll(require.context('assets/tables/matematyka', false, /\.(png|jpe?g|svg)$/, 'sync'));

const mathNew = importAll(require.context('assets/tables/new/matematyka', false, /\.(png|jpe?g|svg)$/, 'sync'));

const biolchemNew = importAll(require.context('assets/tables/new/biolchem', false, /\.(png|jpe?g|svg)$/, 'sync'));

const TablesContext = createContext(null);

const { Provider } = TablesContext;

const imagePathsLib = {
  CH: [biolchem],
  B: [biolchem],
  FI: [biolchem],
  M: math,
  MR: math,
};
const imagePathsLibNew = {
  CH: biolchemNew,
  B: biolchemNew,
  FI: biolchemNew,
  M: mathNew,
  MR: mathNew,
};

const TablesProvider = ({ children, dataSource }) => {
  const { showNewTables } = useUserPreferencesStore();
  const { currentExercise } = useExerciseDataSourceResolver(dataSource)();
  const { subject } = useSubjectStore();
  const { currentExercise: currentExerciseForSets } = useExercisesToDisplay_sets();

  const subjectCode = useMemo(() => {
    if (dataSource === 'common_store') return parseSubjectNumberToSubjectCode(currentExerciseForSets?.subject.id);
    return subject?.subjectCode;
  }, [subject, currentExerciseForSets, dataSource]);

  const [ready, setReady] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [imagePath, setImagePath] = useState(null);
  const [customContent, setCustomContent] = useState(null);
  const [fromCustomContent, setFromCustomContent] = useState(false);

  useEffect(() => {
    if (subjectCode === 'GE' && currentExercise) {
      if (dataSource === 'common_store') {
        const { files } = currentExercise;
        const attachment = files?.a;
        if (attachment) {
          setImagePath(files.a);
        }
      } else {
        const { files } = currentExercise;
        if (isArray(files)) {
          const attachment = files.find(({ fileType }) => fileType === 'a');
          if (attachment) {
            setImagePath([attachment.croppedPngFile]);
          }
        }
      }
    } else {
      setImagePath(showNewTables ? imagePathsLibNew[subjectCode] : imagePathsLib[subjectCode]);
    }
  }, [subjectCode, currentExercise, showNewTables]);

  useEffect(() => {
    // open custom images array; - answer in drawing mode
    if (customContent) {
      setIsOpen(true);
      setFromCustomContent(true);
    }
  }, [customContent]);

  const closeTables = useCallback(() => setIsOpen(false), []);
  const openTables = useCallback(() => {
    setFromCustomContent(false);
    setIsOpen(true);
  }, []);
  const onLoad = useCallback(() => setReady(true), []);

  const value = {
    openTables,
    openCustomImages: setCustomContent,
    ready,
  };

  return (
    <Provider value={value}>
      <Portal>
        <Tables
          close={closeTables}
          customContent={customContent}
          fromCustomContent={fromCustomContent}
          fullWidth={['CH', 'B', 'FI'].includes(subjectCode)}
          imagePath={imagePath}
          isOpen={isOpen}
          onLoad={onLoad}
          subjectCode={subjectCode}
        />
      </Portal>
      {children}
    </Provider>
  );
};

export const useTablesContext = () => useContext(TablesContext);

export default TablesProvider;
