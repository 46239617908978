import React, { createContext, useContext, useCallback, useState, useEffect, useRef } from 'react';

import { initWarehouseAxiosInstance } from 'api/rest/axiosConfig';
import { useAuthContext } from 'context/AuthContext';
import useSubjectStore from 'storages/subject';
import detectMobile from 'utils/detectMobile/detectMobile';

import { UserEventKey } from './UserEventKey';

type Props = {
  children: React.ReactNode;
};

type CurriedFn = (...params: any[]) => any;

type UserLogContextType = {
  logEvent: (event: UserEventKey, eventData?: object) => void;
  curryLogEvent: (fn: CurriedFn, event?: UserEventKey, eventData?: object) => CurriedFn;
};

type EventEnv = 'staging-web' | 'staging-mobile' | 'staging-app' | 'production-web' | 'production-mobile' | 'production-app';

type Event = {
  event: UserEventKey;
  event_data?: object;
  token: string;
  env: EventEnv;
  wasAuthorized: boolean;
};

type HistoryEvent = Omit<Event, 'token' | 'wasAuthorized'>;

type LastEvent = HistoryEvent & { timestamp: number };

const env: EventEnv = (() => {
  const base = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'production' : 'staging';
  if (window.matchMedia('(display-mode: standalone)').matches) return `${base}-app`;
  if (detectMobile()) return `${base}-mobile`;
  return `${base}-web`;
})();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const UserLogContext = createContext<UserLogContextType>(null!);
const { Provider } = UserLogContext;

const DEBOUNCE_THRESHOLD = 1000;

const abortCollecting = (isSchoolAccount: boolean, token?: string) => token === null && !isSchoolAccount;

const shouldThrottle = (currentEvent: HistoryEvent, lastEvent?: LastEvent): boolean => {
  if (!lastEvent) return false;
  if (currentEvent.event !== lastEvent.event) return false;
  return Date.now() < lastEvent.timestamp + DEBOUNCE_THRESHOLD;
};

const warehouseAxiosInstance = initWarehouseAxiosInstance();

const sendWithBeacon = (data: Event) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'develop') {
    console.group(`EVENT LOG - ${data.event}`);
    console.log(`Env: ${data.env}`);
    console.log('Event data: ', data.event_data);
    console.groupEnd();
    return;
  }

  if (!navigator.sendBeacon) {
    warehouseAxiosInstance.post('/e', data);
  } else {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    navigator.sendBeacon(`${process.env.REACT_APP_WAREHOUSE_API_URL}/efd`, formData);
  }
};

const UserLogProvider: React.FC<Props> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { warehouseToken: token, isSchoolAccount } = useAuthContext() || {};
  const [history, setHistory] = useState<HistoryEvent[]>([]);
  const lastEvent = useRef<LastEvent | undefined>(undefined);
  const { subject } = useSubjectStore();

  const logEvent = useCallback(
    (event: UserEventKey, eventData = {}) => {
      if (abortCollecting(isSchoolAccount, token)) return;
      const eventInput: HistoryEvent = { event, env };
      eventInput.event_data = { ...eventData, subject: subject?.name };

      if (shouldThrottle(eventInput, lastEvent.current)) return;
      lastEvent.current = { ...eventInput, timestamp: Date.now() };

      if (token) {
        sendWithBeacon({ ...eventInput, token, wasAuthorized: true });
      } else setHistory(prevState => [...prevState, eventInput as HistoryEvent]);
    },
    [token, env, history, setHistory, subject, isSchoolAccount],
  );

  const curryLogEvent =
    (fn: CurriedFn, event?: UserEventKey, eventData = {}) =>
    (...args: any[]) => {
      if (event) logEvent(event, eventData);
      fn(...args);
    };

  const applyHistory = useCallback(() => {
    if (abortCollecting(isSchoolAccount, token)) {
      setHistory([]);
      return;
    }
    history.forEach((event, index) => {
      setTimeout(() => {
        sendWithBeacon({ ...event, token, wasAuthorized: false });
      }, (1 + index) * 500);
    });
    setTimeout(() => setHistory([]), 1000);
  }, [token, history, setHistory, isSchoolAccount]);

  useEffect(() => {
    if (token && history.length) applyHistory();
  }, [token]);

  const value = {
    logEvent,
    curryLogEvent,
  };

  return <Provider value={value}>{children}</Provider>;
};

export const useUserLogContext = () => useContext(UserLogContext);

export default UserLogProvider;
