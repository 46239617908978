import { makeStyles } from '@material-ui/core';

type StylesProps = {
  isStuck: boolean;
  intersectionObserverSupport: boolean;
  childrenLength: number;
};

const useStyles = makeStyles(theme => ({
  root: ({ childrenLength, isStuck, intersectionObserverSupport }: StylesProps) => ({
    gridColumn: '1/3',
    display: 'grid',
    // @ts-ignore
    gridTemplateColumns: `${theme.container.form.m} repeat(${childrenLength - 1}, 1fr)`,
    [theme.breakpoints.down('md')]: {
      // @ts-ignore
      gridTemplateColumns: `${theme.container.form.s} repeat(${childrenLength - 1}, 1fr)`,
    },
    gridGap: theme.spacing(4),
    position: intersectionObserverSupport ? 'sticky' : 'unset',
    top: '5px',
    zIndex: 200,
    transition: '.3s ease',
    height: isStuck ? '50px' : '95px',
    marginBottom: isStuck ? '45px' : '0px',
    [theme.breakpoints.down('md')]: {
      // @ts-ignore
      gridTemplateColumns: `${theme.container.form.s} 1fr 1fr`,
    },
  }),
}));

export default useStyles;
