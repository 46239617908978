import React from 'react';

import useStyles from './SeparatorWithLabel.styles';

type Props = {
  children: React.ReactNode;
};

const SeparatorWithLabel: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <div className={classes.separator}>
      <span />
      <p>Lub:</p>
      <span />
    </div>
  );
};

export default SeparatorWithLabel;
