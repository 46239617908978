import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(0, 2),
    maxWidth: '420px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  stats: {
    paddingBottom: theme.spacing(4),
  },
}));

export default useStyles;
