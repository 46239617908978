import React from 'react';

import { MenuItem } from '@material-ui/core';
import { isFunction } from 'lodash';

const parseValuesToSelect = ({ data, isMobile = false, dictionary, placeholder, disabled }) => {
  const value = dictionary?.value ? dictionary.value : 'value';
  const label = dictionary?.label ? dictionary.label : 'label';
  const items = data.map(e => {
    const optionValue = e[value];
    let optionLabel = e[label];
    let finalDisabled = disabled;
    if (isFunction(disabled)) finalDisabled = disabled(e);
    if (isFunction(label)) optionLabel = label(e);
    return isMobile ? (
      <option key={optionValue} disabled={finalDisabled} value={optionValue}>
        {optionLabel}
      </option>
    ) : (
      <MenuItem key={optionValue} disabled={finalDisabled} value={optionValue}>
        {optionLabel}
      </MenuItem>
    );
  });
  if (placeholder) {
    const placeholderOption = isMobile ? (
      <option key={placeholder} disabled value=''>
        {placeholder}
      </option>
    ) : (
      <MenuItem key={placeholder} disabled value=''>
        {placeholder}
      </MenuItem>
    );
    items.unshift(placeholderOption);
  }
  return items;
};

export default parseValuesToSelect;
