import gql from 'graphql-tag';

const GET_EXERCISES_LIST_BY_INDEXES = gql`
  query getExercisesListByIndexes($limit: Int, $offset: Int, $indexes: [Int], $subject: ID!) {
    exercises(limit: $limit, offset: $offset, indexes: $indexes, subject: $subject) {
      id
      importFileIdentifier
      index
    }
  }
`;

export default GET_EXERCISES_LIST_BY_INDEXES;
