import React from 'react';

import { useMediaQuery, Paper } from '@material-ui/core';

import { ReactComponent as Logo } from 'assets/logo/full.svg';

import useStyles from './AuthGrid.styles';

type Props = {
  title: string;
  children: React.ReactNode;
};

const AuthGrid: React.FC<Props> = ({ title, children }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const upMd = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={upMd ? 1 : 0}>
      <div className={classes.container}>
        <Logo className={classes.logo} />
        <h1 className={classes.title}>{title}</h1>
        {children}
      </div>
    </Paper>
  );
};

export default AuthGrid;
