import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import { useUserDataContext } from 'context/UserDataContext';
import useIntersect from 'services/common/useIntersect/useIntersect';

import useStyles from './ExerciseViewHeaderWrapper.styles';

type ChildProps = { isStuck: boolean };

type Props = {
  children: React.FunctionComponentElement<ChildProps> | React.FunctionComponentElement<ChildProps>[];
};

const ExerciseViewHeaderWrapper: React.FC<Props> = ({ children }) => {
  const [isStuck, setIsStuck] = useState(true);

  // @ts-ignore
  const { intersectionObserverSupport } = useUserDataContext();

  let ref;
  let entry: unknown;
  const intersect = useIntersect({ rootMargin: '0px 0px -98% 0px' });
  if (intersectionObserverSupport) {
    [ref, { entry }] = intersect;
  }

  const handleIntersect = ({ isIntersecting }: { isIntersecting: boolean }) => {
    setIsStuck(isIntersecting);
  };

  useEffect(() => {
    if (entry) {
      // @ts-ignore
      handleIntersect(entry);
    }
  }, [entry, handleIntersect]);

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement<ChildProps>(child, { isStuck });
    }
    return child;
  });

  const classes = useStyles({ isStuck, intersectionObserverSupport, childrenLength: childrenWithProps.length });
  return (
    // @ts-ignore
    <div ref={ref} className={clsx(classes.root)}>
      {childrenWithProps}
    </div>
  );
};

export default ExerciseViewHeaderWrapper;
