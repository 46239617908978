import { useEffect } from 'react';

import ensureIsArray from 'utils/ensureIsArray/ensureIsArray';

/**
 *
 * @param {Array|Object} listeners
 */
const useKeyboardListener = listeners =>
  useEffect(() => {
    const effect = ({ key, ctrlKey }) => {
      const lowerCaseKey = key.toLowerCase();
      const listenersArr = ensureIsArray(listeners);
      const firedListener = listenersArr.find(listener => {
        const arr = ensureIsArray(listener.keys);
        return listener.ctrlKey ? arr.includes(lowerCaseKey) && ctrlKey : arr.includes(lowerCaseKey);
      });
      if (firedListener) {
        firedListener.callback();
      }
    };
    const thisEffect = effect.bind(this);

    window.addEventListener('keyup', thisEffect);
    return () => window.removeEventListener('keyup', thisEffect);
  }, [listeners]);

export default useKeyboardListener;
