import React, { useEffect, useRef, useState, Suspense } from 'react';

import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import ReactPlayer from 'react-player';

import Loader from 'components/Loader/Loader';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

import useStyles from './HelpMovieSection.styles';

type Props = {
  videoSrc: string;
  title: string;
  toc: string[];
  expanded: boolean;
};

const orientationChanged = () => {
  const isLandscape = window.orientation > 0;
  const iframe = document.querySelector('#YouTube iframe');
  if (iframe) {
    iframe.setAttribute('allowFullScreen', 'true');
    // @ts-ignore
    const requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
    const exitFullScreen =
      // @ts-ignore
      document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen;

    if (isLandscape && requestFullScreen) requestFullScreen.call(iframe);
    // @ts-ignore
    else if (exitFullScreen && window.fullScreen) exitFullScreen.call(iframe);
  }
};

const HelpMovieSection: React.FC<Props> = ({ toc, videoSrc, title, expanded }) => {
  // const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const onReady = () => setLoading(false);

  const ref = useRef(null);
  const videoHeight = get(ref, 'current.clientWidth', 0) * 0.5625;

  useEffect(() => {
    window.addEventListener('orientationchange', orientationChanged);
    return () => window.removeEventListener('orientationchange', orientationChanged);
  });

  // LOGS
  const { logEvent } = useUserLogContext();
  const playPeriods = useRef<{ start: number; end: number }[]>([]);
  const duration = useRef(0);
  const onPlay = () => {
    playPeriods.current = [...playPeriods.current, { start: Date.now(), end: Date.now() }];
    logEvent('help-video-play', { title });
  };
  const onPause = () => {
    const playPeriodsCP = [...playPeriods.current];
    if (playPeriodsCP[playPeriodsCP.length - 1]) playPeriodsCP[playPeriodsCP.length - 1].end = Date.now();
    playPeriods.current = playPeriodsCP;
    logEvent('help-video-pause', { title });
  };
  const setDuration = (durationInSeconds: number) => {
    duration.current = durationInSeconds * 1000;
  };
  const logDuration = () => {
    if (!playPeriods.current.length) return;
    const durationPlayed = playPeriods.current.reduce((acc, curr) => acc + (curr.end - curr.start), 0);
    logEvent('help-video-play-duration', { durationPlayed, duration, title });
  };
  useEffect(() => {
    window.addEventListener('beforeunload', logDuration);

    return () => {
      logDuration();
      window.removeEventListener('beforeunload', logDuration);
    };
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div ref={ref} className={classes.videoWrapper}>
        {expanded && (
          <Suspense fallback={<Loader />}>
            {loading && <Loader />}
            <ReactPlayer
              stopOnUnmount
              controls
              fallback={Loader as unknown as React.ReactElement}
              id='YouTube'
              onDuration={setDuration}
              onPause={onPause}
              playing={expanded}
              height={videoHeight}
              onPlay={onPlay}
              onReady={onReady}
              url={videoSrc}
              width='100%'
            />
          </Suspense>
        )}
      </div>
      <div className={classes.description}>
        <Typography variant='h4'>Z tego filmu dowiesz się: </Typography>
        <ul className={classes.list}>
          {toc.map(el => (
            <Typography className={classes.listEl} component='li' variant='body1' key={el}>
              {el}
            </Typography>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HelpMovieSection;
