import axios from 'axios';

import { DefaultApiResponse } from '../_types';
import { parseExternalResourceForUI, parseRateExternalResourceForBE, parseRateExternalResourceForUI } from './externalResources.parsers';
import { ExternalResource, ExternalResourceDto, SetRatingDtoOutput, SetRatingInput, SetRatingOutput } from './externalResources.types';

const endpoints = {
  exerciseExternalSource: 'exercises/exercise-external-sources/',
};

type GetOneQuery = () => Promise<ExternalResource>;

type SetRatingMutation = (data: SetRatingInput) => Promise<SetRatingOutput>;

const externalResourcesApi = {
  getOne:
    (id?: number): GetOneQuery =>
    async () => {
      if (!id) throw Error('Missing ID in externalResourcesApi.getOne');
      const response = await axios.get<GetOneQuery, DefaultApiResponse<ExternalResourceDto>>(`${endpoints.exerciseExternalSource}${id}/`);
      return parseExternalResourceForUI(response.data);
    },
  setRating:
    (id?: number): SetRatingMutation =>
    async data => {
      if (!id) throw Error('Missing ID in externalResourcesApi.setRating');
      if (!data.exerciseExternalSource) throw Error('Missing exercise_external_source in externalResourcesApi.setRating');
      if (!data.rating) throw Error('Missing rating in externalResourcesApi.setRating');
      const response = await axios.post<SetRatingMutation, DefaultApiResponse<SetRatingDtoOutput>>(
        `${endpoints.exerciseExternalSource}${id}/rating/`,
        parseRateExternalResourceForBE(data),
      );
      return parseRateExternalResourceForUI(response.data);
    },
};

export default externalResourcesApi;
