import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';

import { ExerciseFileTypes } from 'api/rest/exercises/exercises.types';
import { ReactComponent as DrawIcon } from 'assets/icons/general/edit.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/general/file.svg';
import { ReactComponent as TablesIcon } from 'assets/icons/general/tables.svg';
import IconWithText from 'components/IconWithText/IconWithText';
import { useExerciseDrawContext } from 'context/ExerciseDrawContext';
import { useTablesContext } from 'context/TablesContext';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import useExerciseDataSourceResolver, {
  ExerciseDataSources,
} from 'services/common/useExerciseDataSourceResolver/useExerciseDataSourceResolver';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
import useSubjectStore from 'storages/subject';

import { parseSubjectNumberToSubjectCode } from '../../../services/common/subjectParsers/subjectParsers';
import useStyles from './ExercisesHelpers.styles';

type TablesButtonProps = {
  logData: { feature: string | null; exercise: any };
  hasAttachment: boolean;
  subjectCode: string | null;
};

const TablesButton: React.FC<TablesButtonProps> = ({ logData, hasAttachment, subjectCode: subjectCodeFromProps }) => {
  const { curryLogEvent } = useUserLogContext();

  const { subject } = useSubjectStore();

  // TABLES SERVICES
  // @ts-ignore
  const { openTables } = useTablesContext();

  const subjectCode = useMemo(() => subjectCodeFromProps || subject?.subjectCode, [subject, subjectCodeFromProps]);

  const shouldShowAttachments = useMemo(() => subjectCode === 'GE', [subjectCode]);

  const shouldShowTables = useMemo(() => subjectCode && ['CH', 'B', 'FI', 'M', 'MR'].includes(subjectCode), [subjectCode]);

  return shouldShowAttachments ? (
    <IconWithText disabled={!hasAttachment} icon={TablesIcon} label='Pomoce' onClick={curryLogEvent(openTables, 'tables-click', logData)} />
  ) : (
    <IconWithText
      disabled={!shouldShowTables}
      icon={TablesIcon}
      label='Tablice'
      onClick={curryLogEvent(openTables, 'tables-click', logData)}
    />
  );
};

type Props = {
  focusNote: () => void;
  dataSource: ExerciseDataSources;
};

const ExercisesHelpers: React.FC<Props> = ({ focusNote, dataSource }) => {
  const { curryLogEvent } = useUserLogContext();
  const { feature } = useFeatureChecker();
  const { currentExercise, exercises } = useExerciseDataSourceResolver(dataSource)();
  const hasAttachment = useMemo(() => {
    if (!currentExercise?.files) return false;
    if (dataSource === 'common_store') {
      return !!currentExercise?.files[ExerciseFileTypes.attachment].length;
    }
    // @ts-ignore
    return !!currentExercise?.files.some(({ fileType }) => fileType === 'a');
  }, [currentExercise]);

  const shouldBeDisabled = useMemo(() => {
    if (dataSource === 'common_store') return !currentExercise;
    return !exercises?.length;
  }, [currentExercise, exercises]);

  // DRAW SERVICES
  const {
    // @ts-ignore
    drawUpdaters: { toggleDrawMode },
  } = useExerciseDrawContext();

  const classes = useStyles();

  const logData = useMemo(() => ({ feature, exercise: currentExercise?.index }), [currentExercise]);
  return (
    <section className={classes.wrapper}>
      <Typography className={classes.heading} color='primary' component='h3'>
        Pomoce
      </Typography>
      <div className={classes.iconsWrapper}>
        <TablesButton
          subjectCode={
            dataSource === 'common_store' && currentExercise ? parseSubjectNumberToSubjectCode(currentExercise.subject.id) : null
          }
          hasAttachment={hasAttachment}
          logData={logData}
        />
        <IconWithText
          disabled={shouldBeDisabled}
          icon={NoteIcon}
          label='Notatka'
          onClick={curryLogEvent(focusNote, 'note-click', logData)}
        />
        <IconWithText
          disabled={shouldBeDisabled}
          icon={DrawIcon}
          label='Rysuj'
          onClick={curryLogEvent(toggleDrawMode, 'draw-click', logData)}
        />
      </div>
    </section>
  );
};

export default ExercisesHelpers;
