import React from 'react';

import { useQuery } from 'react-query';

import notificationsApi from 'api/rest/notifications/notifications';
import type { Notification } from 'api/rest/notifications/notifications.types';
import { ReactComponent as NotificationIcon } from 'assets/iconhub/notification.svg';
import QUERY from 'constants/queryKeys';
import { useGlobalDialogContext } from 'context/GlobalDialogContext';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

import useStyles from './NotificationBell.styles';

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const NotificationBell: React.FC<Props> = ({ onClick }) => {
  const { curryLogEvent } = useUserLogContext();
  const { GLOBAL_DIALOG, addToGlobalDialogQueue, dialogQueue } = useGlobalDialogContext();

  const openNotificationDialogInImportant = (newNotifications: Notification[]) => {
    // TODO handle multiple messages
    const importantNotification = newNotifications.find(({ important, id, isRead }) => important && !isRead);
    if (importantNotification) {
      if (!dialogQueue.length) {
        addToGlobalDialogQueue({
          type: GLOBAL_DIALOG.IMPORTANT_NOTIFICATION,
          props: { notificationId: importantNotification.id },
        });
      }
    }
  };

  const { data: unreadNotifications } = useQuery(QUERY.PERIODIC_GET_NEW_NOTIFICATIONS, notificationsApi.getAllNew(), {
    refetchInterval: 1000 * 60 * 3, // 3 min
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
    onSuccess: openNotificationDialogInImportant,
  });

  const styles = useStyles({ showIndicator: !!unreadNotifications?.length });
  return (
    <button
      type='button'
      className={styles.container}
      onClick={curryLogEvent(onClick, 'notifications-click', { unreadCount: unreadNotifications?.length })}
    >
      <span className={styles.indicator} />
      <NotificationIcon className={styles.icon} />
    </button>
  );
};

export default NotificationBell;
