import React from 'react';

import FEATURES from 'constants/dictionaries/features';
import useFeatureChecker from 'services/common/useFeatureChecker/useFeatureChecker';
import ExercisesFilters from 'uniqueComponents/Exercises/ExercisesFilters/ExercisesFilters';
import SheetsFilters from 'uniqueComponents/Sheets/SheetsFilters/SheetsFilters';
import ExerciseViewAsideWrapper from 'components/ExerciseViewAsideWrapper/ExerciseViewAsideWrapper';

const getFilters = feature => {
  switch (feature) {
    case FEATURES.EXERCISES:
      return <ExercisesFilters />;
    case FEATURES.SHEETS:
      return <SheetsFilters />;
    default:
      return null;
  }
};

// TODO legacy component
const ExerciseAside = ({ focusNote }) => {
  const { feature } = useFeatureChecker();
  return (
    <ExerciseViewAsideWrapper dataSource='change it' focusNote={focusNote}>
      {getFilters(feature)}
    </ExerciseViewAsideWrapper>
  );
};

export default ExerciseAside;
