import { ChangeEvent, useMemo } from 'react';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useQuery } from 'react-query';

import subjectsApi from 'api/rest/subjects/subjects';
import { Option } from 'components/_inputs/_types/Option';
import QUERY from 'constants/queryKeys';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import useClassroomExercisesFiltersStore from 'storages/teacher/classroomExercisesFiltersStorage';

type OnSubjectChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
type OnDateSinceChange = (date: MaterialUiPickersDate) => void;
type OnDateUntilChange = (date: MaterialUiPickersDate) => void;

type UseFilterCommonLogic = (update: (key: any, value: string | Date) => void) => {
  onSubjectChange: OnSubjectChange;
  onDateSinceChange: OnDateSinceChange;
  onDateUntilChange: OnDateUntilChange;
  subjectOptions: Option[];
};

const parseDataToDropdown = (data?: { id: string | number; name: string }[]): Option[] => {
  if (!data) return [];

  return [{ value: '', label: 'Wszystkie' }, ...data.map(({ id, name }) => ({ value: id, label: name }))];
};

const useFilterCommonLogic: UseFilterCommonLogic = update => {
  const { logEvent } = useUserLogContext();
  const onSubjectChange: OnSubjectChange = ({ target }) => {
    logEvent('class-students-filter-subject', { value: target.value });
    update('subject', target.value as string);
  };

  const onDateSinceChange: OnDateSinceChange = date => {
    logEvent('class-students-filter-since', { value: date });
    update('since', date as Date);
  };

  const onDateUntilChange: OnDateUntilChange = date => {
    logEvent('class-students-filter-until', { value: date });
    update('until', date as Date);
  };

  const { data: subjectOptions } = useQuery(QUERY.GET_SUBJECTS_LIST, subjectsApi.getSubjectsListForDropdown(true), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  });

  return { onDateSinceChange, onDateUntilChange, onSubjectChange, subjectOptions: subjectOptions || [] };
};

const useClassroomExercisesFiltersLogic = () => {
  const { update, subject, section } = useClassroomExercisesFiltersStore();
  const { logEvent } = useUserLogContext();

  const onSectionChange: OnSubjectChange = ({ target }) => {
    logEvent('class-students-filter-section', { value: target.value });
    update('section', target.value as string);
  };

  const onSubsectionChange: OnSubjectChange = ({ target }) => {
    logEvent('class-students-filter-subsection', { value: target.value });
    update('subsection', target.value as string);
  };

  const isSectionDisabled = useMemo(() => !subject, [subject]);

  const isSubsectionDisabled = useMemo(() => !section, [section]);

  const { data } = useQuery([QUERY.GET_SINGLE_SUBJECT, subject], subjectsApi.getSubject(subject), {
    enabled: !!subject,
    staleTime: 1000 * 60 * 60, // 1h
  });

  const subsectionOptions = useMemo(() => {
    if (!data || !section) return [];
    const { subsections } = data.sections.find(({ id }) => id.toString() === section.toString()) || {};
    return parseDataToDropdown(subsections);
  }, [data, section]);

  return {
    onSectionChange,
    onSubsectionChange,
    isSectionDisabled,
    isSubsectionDisabled,
    sectionOptions: parseDataToDropdown(data?.sections),
    subsectionOptions,
  };
};

export { useFilterCommonLogic, useClassroomExercisesFiltersLogic };
