import gql from 'graphql-tag';

const GET_EXERCISE = gql`
  query GetExercise($uuid: String!) {
    getExercise(uuid: $uuid) {
      __typename
      id
      date
      level
      source
      importFileIdentifier
      audio
      index
      section {
        name
        id
      }
      subsection {
        name
        id
      }
      externalSources {
        __typename
        id
        category
        description
        url
        isMain
      }
      userMark {
        __typename
        id
        mark
      }
      tags {
        id
        name
      }
      files {
        croppedPngFile
        fileType
        fileIndex
      }
      userData {
        note
      }
    }
  }
`;

export default GET_EXERCISE;
