// TODO refactor
import React from 'react';

import { Grid, CircularProgress, useMediaQuery, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  wrapper: {
    // minHeight: '30vh', Crashing on safari....
    // height: '100%',
    minHeight: '320px',
    minWidth: '280px',
    backgroundColor: theme.palette.grey[100],
    maxWidth: ({ narrow }) => narrow && '500px',
    padding: theme.legacySpacing(5, 10),
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: theme.legacySpacing(4, 6, 6, 6),
    },
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.spacing(4),
    margin: theme.spacing(2, 0),
    borderBottom: `thin solid ${theme.palette.grey[400]}`,
    [theme.breakpoints.down('xs')]: {
      margin: theme.legacySpacing(1, 0),
    },
  },
  title: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      textAlign: 'center',
      flexGrow: 1,
    },
  },
  closeButtonDesktop: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },
  buttonsGroup: {
    gap: theme.spacing(2),
    display: 'flex',
  },
  buttonsBase: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  buttonsExtra: {},
  buttonWrapper: {
    position: 'relative',
    '& button': {
      minHeight: '35px',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -9,
    marginLeft: -9,
  },
  closeButtonXS: {
    paddingBottom: '1rem',
  },
}));

function DialogGrid({
  children,
  title,
  cancelClick,
  okClick,
  sending,
  disabled,
  cancelLabel,
  hideCancelButton,
  isForm,
  onSubmit,
  okLabel,
  okProps,
  loading,
  hideButtons,
  narrow,
  hideCloseButton,
  hideOkButton,
  cancelDisabled,
  closeButtonLabel,
  closeClick,
  extraButtons,
  customTopButton,
}) {
  const defaultPreventedSubmit = e => {
    e.preventDefault();
    onSubmit(e);
  };
  const downXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const Wrapper = isForm ? 'form' : 'div';
  const classes = useStyles({ narrow });
  return (
    <Wrapper className={classes.wrapper} onSubmit={onSubmit && defaultPreventedSubmit}>
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <div className={classes.titleBar}>
            <Typography className={classes.title} variant='h3'>
              {title}
            </Typography>
            {!hideCloseButton && (
              <Button className={classes.closeButtonDesktop} onClick={closeClick || cancelClick} size='small' variant='outlined'>
                {closeButtonLabel}
              </Button>
            )}
            {customTopButton && customTopButton}
          </div>
          <div>{children}</div>
          {!hideButtons && (
            <div className={classes.buttons}>
              <div className={clsx(classes.buttonsGroup, classes.buttonsExtra)}>
                {extraButtons &&
                  extraButtons.map(({ label, ...buttonProps }) => (
                    <div key={label} className={classes.buttonWrapper}>
                      <Button size='small' variant='outlined' {...buttonProps}>
                        {label}
                      </Button>
                    </div>
                  ))}
              </div>
              <div className={clsx(classes.buttonsGroup, classes.buttonsBase)}>
                {!hideCancelButton && (
                  <div className={classes.buttonWrapper}>
                    <Button disabled={cancelDisabled} onClick={cancelClick} size='small' variant='outlined'>
                      {cancelLabel}
                    </Button>
                  </div>
                )}
                {!hideOkButton && (
                  <div className={classes.buttonWrapper}>
                    <Button
                      color='primary'
                      disabled={sending || disabled}
                      onClick={okClick}
                      size='small'
                      type={isForm ? 'submit' : 'button'}
                      variant='contained'
                      {...okProps}
                    >
                      {okLabel}
                    </Button>
                    {sending && <CircularProgress className={classes.buttonProgress} size={18} />}
                  </div>
                )}
              </div>
            </div>
          )}
          {!hideCloseButton && downXs && (
            <Grid className={classes.closeButtonXS} container item justify='center' xs={12}>
              <Button onClick={closeClick || cancelClick} size='small' variant='outlined'>
                {closeButtonLabel}
              </Button>
            </Grid>
          )}
        </>
      )}
    </Wrapper>
  );
}

DialogGrid.defaultProps = {
  cancelClick: null,
  closeClick: null,
  okClick: () => {},
  onSubmit: () => {},
  sending: false,
  disabled: false,
  hideCancelButton: false,
  isForm: false,
  cancelLabel: 'Anuluj',
  okLabel: 'OK',
  loading: false,
  hideButtons: false,
  hideCloseButton: false,
  children: null,
  title: '',
  okProps: {},
  narrow: false,
  hideOkButton: false,
  cancelDisabled: false,
  closeButtonLabel: 'Zamknij',
  extraButtons: undefined,
  customTopButton: undefined,
};

export default DialogGrid;
