import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Picture } from 'react-responsive-picture';

import image from 'assets/decorative_pics/error.png';
import image2x from 'assets/decorative_pics/error@2x.png';
import ButtonRound from 'components/ButtonRound/ButtonRound';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  picture: {
    maxHeight: '480px',
    maxWidth: '100%',
    margin: '2rem 0',
  },
});

function ExerciseError({ onClick }) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Picture
        className={classes.picture}
        sources={[
          {
            srcSet: `${image} 1x, ${image2x} 2x`,
          },
        ]}
      />
      <ButtonRound color='primary' onClick={onClick} size='large' variant='contained'>
        Załaduj ponownie
      </ButtonRound>
    </div>
  );
}

export default ExerciseError;
