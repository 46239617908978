import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridGap: '1.5rem',
    paddingBottom: '1.5rem',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold as number,
  },
  iconsWrapper: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'repeat(4, 1fr)',
    fill: theme.palette.primary.main,
  },
  resourcesIcon: {
    marginTop: '-.25rem',
    marginRight: '-.25rem',
    height: '2.25rem !important',
    width: '2rem !important',
  },
}));

export default useStyles;
