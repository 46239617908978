import React, { useCallback, useEffect, useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { useLazyQuery } from '@apollo/client';
import { makeStyles, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import messages from 'constants/dictionaries/messages';
import ADD_EXERCISE_NOTE from 'api/graphql/mutations/exercises/ADD_EXERCISE_NOTE';
import GET_EXERCISE_NOTE from 'api/graphql/queries/exercises/GET_EXERCISE_NOTE';
import useDebounce from 'services/common/useDebounce/useDebounce';
import useDidUpdateEffect from 'services/common/useDidUpdateEffect/useDidUpdateEffect';
import logError from 'utils/logError/logError';

const useStyles = makeStyles(theme => ({
  input: {
    paddingTop: theme.legacySpacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.legacySpacing(4),
    },
  },
}));

const NOTE_LIMIT = 3000;

function ExerciseNote({ notesRef, exerciseId }) {
  const { enqueueSnackbar } = useSnackbar();
  const [addNoteMutation] = useMutation(ADD_EXERCISE_NOTE);
  const [value, setValue] = useState('');

  const [debouncedValueToSave, setDebouncedValueToSave] = useState(null);
  const debouncedValue = useDebounce(debouncedValueToSave, 350);

  const [load, { data }] = useLazyQuery(GET_EXERCISE_NOTE, {
    fetchPolicy: 'no-cache',
  });

  const save = async () => {
    try {
      const input = {
        exerciseId,
        note: value,
      };
      await addNoteMutation({ variables: { input } });
    } catch (error) {
      enqueueSnackbar(messages.ERROR.SAVING('notatki'), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    const note = data?.getExercise.userData?.note;
    if (note) setValue(note);
  }, [data]);

  useEffect(() => {
    if (exerciseId) load({ variables: { uuid: exerciseId } });
  }, [exerciseId]);

  const onChange = useCallback(({ target }) => {
    if (target.value.length > NOTE_LIMIT) {
      enqueueSnackbar(`Przekroczony limit ${NOTE_LIMIT} znaków notatki`, { variant: 'warning', preventDuplicate: true });
      return;
    }
    setValue(target.value);
    setDebouncedValueToSave(target.value);
  }, []);

  useDidUpdateEffect(() => {
    save();
  }, [debouncedValue]);

  const classes = useStyles();
  return (
    <div className={classes.input}>
      <TextField inputRef={notesRef} label='Twoja notatka' multiline name='note' onChange={onChange} value={value} variant='outlined' />
    </div>
  );
}

export default ExerciseNote;
