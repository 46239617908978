const MUTATION = {
  UPDATE_RATE: 'Update or add new rate',
  LOGIN_BY_EMAIL: 'Login by email',
  LOGIN_BY_IDENTIFIER: 'Login by identifier',
  SET_PERMANENT_PASSWORD: 'Set perm pass',
  SET_NEW_PASSWORD: 'Set new pass',
  UPDATE_ME: 'Update me',
};

export default MUTATION;
