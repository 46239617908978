import React from 'react';

import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { isNil } from 'lodash';

import PaperWithHeader from 'components/PaperWithHeader/PaperWithHeader';

import useStyles from './UserDataProcessingObjectionInfo.styles';

type Props = {
  date: Date | null;
  reason: string | null;
};

const UserDataProcessingObjectionInfo: React.FC<Props> = ({ date, reason }) => {
  const classes = useStyles();
  if (isNil(reason) || !date) return null;
  return (
    <PaperWithHeader className={classes.root} header='Przetwarzanie danych osobowych'>
      <div className={classes.captionWithIcon}>
        <WarningIcon fontSize='small' color='primary' />
        <Typography variant='subtitle1'>Użytkownik wyraził sprzeciw wobec przetwarzania danych osobowych.</Typography>
      </div>
      <Typography variant='subtitle2'>Data odnotowania: {format(date, 'dd MMMM yyyy', { locale: pl })}</Typography>
    </PaperWithHeader>
  );
};

export default UserDataProcessingObjectionInfo;
