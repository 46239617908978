import React from 'react';

import { ExercisesViewGridHook, ExercisesViewGridExampleFunction } from './ExercisesViewGrid.logic';
import useStyles from './ExercisesViewGrid.styles';

type Props = { header: React.ReactNode; aside: React.ReactNode; main: React.ReactNode };

const ExercisesViewGrid: React.FC<Props> = ({ header, aside, main }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {header}
      {aside}
      {main}
    </div>
  );
};

export default ExercisesViewGrid;
