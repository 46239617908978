import React from 'react';

import { Divider, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './PaperWithHeader.styles';

type Props = {
  header: string;
  children: React.ReactNode;
  contentClassName?: string;
  className?: string;
};

const PaperWithHeader: React.FC<Props> = ({ header, children, contentClassName, className }) => {
  const classes = useStyles();
  return (
    <Paper square className={clsx(className)}>
      <Typography className={classes.header} variant='h3'>
        {header}
      </Typography>
      <Divider />
      <div className={clsx(classes.content, contentClassName)}>{children}</div>
    </Paper>
  );
};

export default PaperWithHeader;
