import React from 'react';

import { CircularProgress, Typography, AccordionSummary, Accordion, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ChipWithNumber from 'components/ChipWithNumber/ChipWithNumber';
import ExercisesStats from 'components/ExercisesStats/ExercisesStats';
import PageTitle from 'components/PageTitle/PageTitle';
import StudentDetails from 'components/StudentDetails/StudentDetails';

import { useClassPage, resolveChipLabel } from './ClassroomPage.logic';
import useStyles from './ClassroomPage.styles';

const ClassroomPage: React.FC = () => {
  const { classroomData, studentsData, isLoading } = useClassPage();

  const classes = useStyles();
  return !classroomData || !studentsData || isLoading ? (
    <CircularProgress size={45} />
  ) : (
    <>
      <PageTitle>{`Klasa ${classroomData?.shortName || classroomData?.name}`}</PageTitle>
      <ExercisesStats
        className={classes.stats}
        stats={[
          {
            value: `${classroomData.registeredCount || 0} z ${studentsData.length}`,
            label: 'Zarejestrowanych',
          },
        ]}
      />
      <div>
        {studentsData.map(({ id, identifier, index, marksCount }) => (
          <Accordion key={id} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${id}-content`} id={`${id}-header`}>
              <div className={classes.header}>
                <Typography variant='subtitle1'>
                  {index}. <b>{identifier}</b>
                </Typography>
                <ChipWithNumber size='small' color='primary' label={resolveChipLabel(marksCount)} value={marksCount} />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <StudentDetails id={id} />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default ClassroomPage;
