import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(0, 8, 2),
    display: 'grid',
    gridTemplateColumns: '40px auto',
    gridGap: theme.spacing(4),
    alignItems: 'center',
  },
  avatarIcon: {
    maxWidth: '100%',
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
}));

export default useStyles;
