import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { Id } from 'types/Id';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

interface ExerciseDetailsStore {
  isExerciseDetailsVisible: boolean;
  // TODO - exercisesSet type ID
  currentExerciseId: Id | null;
  setCurrentExerciseId: (newId: Id) => void;

  openExerciseDetails: () => void;
  closeExerciseDetails: () => void;
}

const exerciseDetailsStore = persist<ExerciseDetailsStore>(
  set => ({
    currentExerciseId: null,
    isExerciseDetailsVisible: false,

    setCurrentExerciseId: newId => set({ currentExerciseId: newId }),
    openExerciseDetails: () => set({ isExerciseDetailsVisible: true }),
    closeExerciseDetails: () => set({ isExerciseDetailsVisible: false }),
  }),
  {
    name: `${process.env.REACT_APP_VERSION}_exercise_details`,
    getStorage: () => sessionStorage,
  },
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useExerciseDetailsStore = create(devtools(exerciseDetailsStore, { name: 'EXERCISE DETAILS STORE', enabled: !isProduction }));

export default useExerciseDetailsStore;
