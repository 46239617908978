import React, { createContext, useState, useContext, useRef } from 'react';

import { Dialog } from '@material-ui/core';

import DialogGrid from 'containers/DialogGrid/DialogGrid';

const ConfirmationModalContext = createContext({});

const { Provider } = ConfirmationModalContext;

function ConfirmationModalProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [confirmationOptions, setConfirmationOptions] = useState({});

  const awaitingConfirmation = useRef();

  const closeModal = () => {
    setOpen(false);
    setConfirmationOptions({});
  };

  const showConfirmationModal = options => {
    setOpen(true);
    setConfirmationOptions(options);
    return new Promise(resolve => {
      awaitingConfirmation.current = { resolve };
    });
  };

  const onCancel = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(false);
    }

    closeModal();
  };

  const onConfirm = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(true);
    }

    closeModal();
  };

  return (
    <Provider value={{ showConfirmationModal }}>
      {children}
      <Dialog open={open}>
        <DialogGrid
          cancelClick={onCancel}
          cancelLabel='Nie'
          hideCloseButton
          okClick={onConfirm}
          okLabel='Tak'
          title={confirmationOptions.title}
        >
          {confirmationOptions.body}
        </DialogGrid>
      </Dialog>
    </Provider>
  );
}

export const useConfirmationModalContext = () => useContext(ConfirmationModalContext);

export default ConfirmationModalProvider;
