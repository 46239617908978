import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  headingWrapper: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    paddingTop: theme.legacySpacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold as number,
  },
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      gridGap: theme.spacing(4),
    },
  },
  selectWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  buttonsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    gridGap: '.5rem',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, auto)',
    },
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'center',
      gridTemplateColumns: 'repeat(4, auto)',
    },
    '@media(max-width: 360px)': {
      gridTemplateColumns: 'repeat(3, auto)',
    },
  },
  filtersLoader: {
    justifySelf: 'center',
  },
  chipLabel: {
    fontSize: '0.875rem',
  },
  addNewSetElement: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  addNewSetElementIcon: {
    color: theme.palette.primary.main,
  },
  editButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
