import getExercises, { parseVariables } from '../_helpers/getExercises';
import exercisesInitialStorage from '../exercises/exercisesInitialStorage';

const setCurriculum = (set, get) => async curriculum => {
  set(state => ({
    ...exercisesInitialStorage,
    section: state.section,
    subsection: state.subsection,
    orderBy: state.orderBy,
    userMarks: state.userMarks,
    curriculum,
  }));
  const variables = parseVariables(get(), { curriculum });
  const exercises = await getExercises(variables);
  set(exercises);
};

export default setCurriculum;
