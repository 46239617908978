export const INTERNAL_PREFIX = '';
export const AUTH_PREFIX = 'auth';
export const TEACHER_PREFIX = 'teacher';

interface Paths {
  [key: string]: string;
}

const addPrefix = (paths: Paths, prefix: string): Paths =>
  Object.entries(paths).reduce((acc, [key, value]) => ({ ...acc, [key]: value ? `/${prefix}/${value}` : `/${prefix}` }), {});

export const USER_SETTINGS_DIALOGS = {
  FAILURE: 'payment-failure',
  SUCCESS: 'payment-success',
  SUBSCRIBE: 'subscribe',
};

export const INTERNAL_PATHS = {
  ROOT: '/',
  EXERCISES: '/exercises',
  SHEETS: '/sheets',
  USER_SETTINGS: '/my-account',
  AUTH: '/auth',
  SUBJECT_SELECT: '/subject-select',
  EXERCISES_SETS: '/chosen_exercises',
  MY_PROGRESS: '/my-progress',
  HELP: '/help',
};

export const PRIVATE_PATHS = {
  DOCUMENTS: 'documents/:documentType',
};

export const AUTH_PATHS = {
  LOGIN: 'login',
  LOGIN_BY_SCHOOL: 'login-by-school',
  REGISTER: 'register',
  REGISTER_BY_MAIL: 'register/by-email',
  PASSWORD_RESET: 'password-reset/:token/:uidb',
};

export const TEACHER_PATHS = {
  TEACHER_DASHBOARD: 'dashboard',
  CLASSROOM: 'classroom/:classroomId',
  CLASSROOM_EXERCISES: 'classroom-exercises/:classroomId',
};

const PATHS: Paths = {
  ...PRIVATE_PATHS,
  ...INTERNAL_PATHS,
  ...addPrefix(AUTH_PATHS, AUTH_PREFIX),
  ...addPrefix(TEACHER_PATHS, TEACHER_PREFIX),
};

export default PATHS;
