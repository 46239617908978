import React from 'react';

import { List, ListItem, ListItemText } from '@material-ui/core';
import { generatePath } from 'react-router-dom';

import PaperWithHeader from 'components/PaperWithHeader/PaperWithHeader';
import PATHS from 'constants/paths';
import { useGlobalDialogContext } from 'context/GlobalDialogContext';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

import useStyles from './UserSettingsOptions.styles';

type Props = {
  openFeedbackDialog: () => void;
  openDeleteDialog: () => void;
  openSetNewPasswordDialog: () => void;
  openDataProcessingObjectionDialog: () => void;
  isSchoolAccount: boolean;
};

const UserSettingsOptions: React.FC<Props> = ({
  openDeleteDialog,
  openFeedbackDialog,
  openSetNewPasswordDialog,
  isSchoolAccount,
  openDataProcessingObjectionDialog,
}) => {
  const { logEvent } = useUserLogContext();
  const { addToGlobalDialogQueue, GLOBAL_DIALOG } = useGlobalDialogContext();

  const openTutorial = () => {
    logEvent('tutorial-open', { initial: false });
    addToGlobalDialogQueue({ type: GLOBAL_DIALOG.TUTORIAL });
  };

  const classes = useStyles();

  return (
    <PaperWithHeader className={classes.root} header='Więcej' contentClassName={classes.content}>
      <List>
        {!isSchoolAccount && (
          <ListItem button onClick={openTutorial}>
            <ListItemText>Tutorial</ListItemText>
          </ListItem>
        )}
        <ListItem
          button
          component='a'
          href={`/${generatePath(PATHS.DOCUMENTS, { documentType: 'terms' })}`}
          rel='noreferrer noopener'
          target='_blank'
        >
          <ListItemText primary='Regulamin' />
        </ListItem>
        <ListItem
          button
          component='a'
          href={`/${generatePath(PATHS.DOCUMENTS, { documentType: 'privacy_policy' })}`}
          rel='noreferrer noopener'
          target='_blank'
        >
          <ListItemText primary='Polityka Prywatności' />
        </ListItem>
        <ListItem button onClick={openFeedbackDialog}>
          <ListItemText primary='Twoja opinia' />
        </ListItem>
        <ListItem button onClick={openSetNewPasswordDialog}>
          <ListItemText primary='Zmień hasło' />
        </ListItem>
        <ListItem button onClick={openDataProcessingObjectionDialog}>
          <ListItemText primary='Przetwarzanie danych osobowych' />
        </ListItem>
        <ListItem button onClick={openDeleteDialog}>
          <ListItemText primary='Usuń konto' />
        </ListItem>
      </List>
    </PaperWithHeader>
  );
};

export default UserSettingsOptions;
