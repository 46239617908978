import { useState } from 'react';

import { TableHeader } from 'components/ExercisesTable/ExercisesTable';

const useComplexQuery = <HEADERS_TYPES>(perPage: number, headers: TableHeader<HEADERS_TYPES>[]) => {
  const [page, setPage] = useState(0);
  const onPageChange = (e: unknown, newPage: number) => setPage(newPage);

  const [orderBy, setOrderBy] = useState<HEADERS_TYPES | undefined>(headers.find(({ initial }) => initial)?.id);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const queryParams = {
    pagination: { page, perPage },
    order: { key: orderBy, direction: order },
  };

  const tableProps = {
    page,
    onPageChange,
    orderBy,
    order,
    setOrder,
    setOrderBy,
    setPage,
    rowsPerPage: perPage,
  };

  return { queryParams, tableProps };
};

export default useComplexQuery;
