// A4 sizes
export const a4Long = 297;
export const a4Short = 210;
export const a4RatioHorizontal = a4Short / a4Long;
export const a4RatioVertical = a4Long / a4Short;

export default {
  a4Long,
  a4Short,
  a4RatioHorizontal,
  a4RatioVertical,
};
