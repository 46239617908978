const legacySpacing = (...args) =>
  args
    .slice(0, 4)
    .map(e => `${e * 0.25}rem`)
    .join(' ');
const bpValues = {
  xs: 0,
  s: 600,
  m: 960,
  l: 1280,
  xl: 1920,
};
const generateMQ = (rule, value) => {
  const predefined = Object.keys(bpValues).includes(value);
  const resultValue = predefined ? bpValues[value] : value;
  return `@media (${rule}-width: ${resultValue}px)`;
};

export { bpValues };
export default {
  legacySpacing,
  containers: {
    formS: '280px',
    formM: '500px',
  },
  font: {
    family: 'Poppins, sans-serif',
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    xs: '0.7rem',
    s: '.85rem',
    m: '1rem',
    l: '1.25rem',
    xl: '1.5rem',
    absolute: {
      xs: '12px',
      s: '16px',
      m: '20px',
      l: '25px',
    },
  },
  container: {
    main: '1920px',
    narrow: '1640px',
    exercise: '1226px',
    exerciseImage: '768',
    exerciseImageShrink: '580',
    form: {
      s: '340px',
      m: '400px',
      l: '780px',
    },
  },
  bp: {
    values: bpValues,
    max: v => generateMQ('max', v),
    min: v => generateMQ('min', v),
  },
  customShadows: {
    light: '1px 1px 2px #E6E6E6',
    medium: '0px 3px 3px rgba(0,0,0,0.1)',
    strong: '0px 3px 6px rgba(0,0,0,0.29)',
  },
  shape: {
    borderRadiusS: '2px',
    borderRadius: '5px',
    borderRadiusL: '10px',
    borderRadiusXL: '16px',
  },
  time: {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    long: 350,
    longer: 400,
    longest: 450,
    enteringScreen: 225,
    leavingScreen: 195,
  },
  zIndex: {
    header: 100,
    overlay: 200,
    modal: 300,
  },
  elements: {
    logo: {
      width: 74,
    },
    button: {
      height: 60,
    },
    input: {
      width: 240,
    },
    icon: {
      xl: 100,
      l: 80,
      m: 60,
      s: 30,
      xs: 24,
    },
  },
};
