import { ExerciseFileDto, ExerciseFiles, ExerciseFileTypes } from '../exercises/exercises.types';

const parseExerciseFilesForUI = (rawFiles: ExerciseFileDto[]): ExerciseFiles =>
  rawFiles
    .sort((a, b) => a.file_index - b.file_index)
    .reduce<ExerciseFiles>((prev, { file_type, cropped_png_file }) => ({ ...prev, [file_type]: [...prev[file_type], cropped_png_file] }), {
      [ExerciseFileTypes.question]: [],
      [ExerciseFileTypes.attachment]: [],
      [ExerciseFileTypes.answer]: [],
      [ExerciseFileTypes.hint]: [],
    });

export default parseExerciseFilesForUI;
