import React, { useEffect, useState } from 'react';

import useExercisesStore from 'storages/exercises/exercises';

import FiltersBlock from 'uniqueComponents/Exercises/ExercisesFilters/FiltersBlock/FiltersBlock';
import GenericFiltersList from 'uniqueComponents/Exercises/ExercisesFilters/GenericFiltersList/GenericFiltersList';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

const DynamicFilters = ({ tagCategories }) => {
  const { logEvent } = useUserLogContext();
  const { filters, setFilters } = useExercisesStore();
  const [state, setState] = useState({});
  const [prevTagCategories, setPrevTagCategories] = useState(null);

  const getInitialChecked = arr => filters.filter(e => arr.includes(e));

  useEffect(() => {
    if (tagCategories) {
      const newState = {};
      tagCategories.forEach(({ id, tags }) => {
        const arrOfId = tags.map(tag => tag.id);
        const filtersChecked = getInitialChecked(arrOfId);
        newState[id] = {
          filters: arrOfId,
          filtersChecked,
          isAllSelected: filtersChecked.length === arrOfId.length,
        };
      });
      setState(newState);
      setPrevTagCategories(tagCategories);
    }
  }, [tagCategories]);

  const changeCallback =
    id =>
    ({ checked, value }) => {
      setState(prev => {
        const filtersChecked = checked ? [...prev[id].filtersChecked, value] : prev[id].filtersChecked.filter(v => v !== value);
        return {
          ...prev,
          [id]: {
            ...prev[id],
            filtersChecked,
            isAllSelected: filtersChecked.length === prev[id].filters.length,
          },
        };
      });
    };

  const onSelectAll = (id, name) => () => {
    const filtersChecked = state[id].isAllSelected ? [] : state[id].filters;
    const filtersToSet = filters.filter(e => !state[id].filters.includes(e));
    logEvent('select-filter-click', { filter: name, selectAll: true, value: !state[id].isAllSelected, feature: 'exercises' });
    if (!state[id].isAllSelected) filtersToSet.push(...state[id].filters);
    setFilters(filtersToSet);
    setState(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        filtersChecked,
        isAllSelected: filtersChecked.length === prev[id].filters.length,
      },
    }));
  };
  const categoriesSource = tagCategories || prevTagCategories;

  if (!categoriesSource) return null;
  return categoriesSource.map(({ name, tags, id }) => {
    if (!tags.length) return null;
    return (
      <FiltersBlock key={name} heading={name} isAllSelected={state[id]?.isAllSelected} isSelectable onSelectAll={onSelectAll(id, name)}>
        <GenericFiltersList
          changeCallback={changeCallback(id)}
          filterName='filters'
          filters={tags}
          heading={name}
          setFilters={setFilters}
        />
      </FiltersBlock>
    );
  });
};

export default DynamicFilters;
