import React from 'react';

import FiltersCountProvider from 'context/FiltersCountContext';
import GlobalDialogProvider from 'context/GlobalDialogContext';

type Props = { children: React.ReactNode };

const PrivateRouteProviders: React.FC<Props> = ({ children }) => (
  <FiltersCountProvider>
    <GlobalDialogProvider>{children}</GlobalDialogProvider>
  </FiltersCountProvider>
);

export default PrivateRouteProviders;
