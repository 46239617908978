import create, { StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';

import { AUTH_PREFIX } from 'constants/paths';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

const basePaths = {
  login: '/',
  logout: `/${AUTH_PREFIX}`,
};

interface UserPreferencesStore {
  redirectOnLogout: string;
  setRedirectOnLogout: (newPath: string) => void;
  pruneRedirectOnLogout: () => void;
  redirectOnLogin: string;
  setRedirectOnLogin: (newPath: string) => void;
  pruneRedirectOnLogin: () => void;
}

const redirectsStore: StateCreator<UserPreferencesStore> = set => ({
  // REDIRECTS
  redirectOnLogout: basePaths.logout,
  setRedirectOnLogout: newPath => set({ redirectOnLogout: newPath }),
  pruneRedirectOnLogout: () => set({ redirectOnLogout: basePaths.logout }),

  redirectOnLogin: basePaths.login,
  setRedirectOnLogin: newPath => set({ redirectOnLogin: newPath }),
  pruneRedirectOnLogin: () => set({ redirectOnLogin: basePaths.login }),
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useRedirectsStore = create(devtools(redirectsStore, { name: 'REDIRECTS', enabled: !isProduction }));

export default useRedirectsStore;
