import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

const useConditionalQuery = (condition, query, options) => {
  const [dataFromQuery, setDataFromQuery] = useState({});
  const [doQuery, { data, error, loading }] = useLazyQuery(query);
  useEffect(() => {
    if (condition) doQuery(options);
  }, [condition, doQuery]);
  useEffect(() => {
    setDataFromQuery({ data, error, loading });
  }, [data, error, loading, setDataFromQuery]);
  return dataFromQuery;
};

export default useConditionalQuery;

/** * Usage example:
const ExerciseAside = () => {
  const { state, updateState } = useExerciseContext();
  const { data, error, loading } = useConditionalQuery(
    state?.subject?.id,
    GET_SUBJECT_FILTERS,
    { variables: { id: state?.subject?.id } },
  );
  return (
    <div>
      {data?.getSubject.name}
    </div>
  );
};

 ** */
