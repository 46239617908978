import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';

import exercisesSetApi from 'api/rest/exercisesSets/exercisesSet';
import { ExerciseInSetDetails } from 'api/rest/exercisesSets/exercisesSet.types';
import { Id } from 'types/Id';

const useAddToExerciseSet = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const addMutation = useMutation(exercisesSetApi.update.generateQueryKey(), exercisesSetApi.update.request(), {
    ...exercisesSetApi.update.requestBaseSettings,
    onSuccess: response => {
      const { data } = response;
      enqueueSnackbar(`Zadanie zostało zapisane do zestawu "${data.name}"`, { variant: 'success' });
    },
  });

  const addToExercisesSet = useCallback(
    (setId: Id, exerciseToAdd: Omit<ExerciseInSetDetails, 'files' | 'globalId'>) => async () => {
      const setToUpdate = await queryClient.fetchQuery(
        exercisesSetApi.getDetails.generateQueryKey(setId),
        exercisesSetApi.getDetails.request(setId),
        exercisesSetApi.getDetails.requestBaseSettings,
      );
      if (setToUpdate.exercises.some(({ index, subject }) => index === exerciseToAdd.index && subject === exerciseToAdd.subject)) {
        enqueueSnackbar(`Zestaw "${setToUpdate.name}" zawiera już to zadanie!`, { variant: 'warning' });
        return;
      }

      const exercises = [...(setToUpdate?.exercises || []), exerciseToAdd];
      addMutation.mutate({ setId, input: { ...setToUpdate, exercises } });
    },
    [],
  );

  return { addToExercisesSet };
};

export default useAddToExerciseSet;
