import { useSnackbar } from 'notistack';

import messages from 'constants/dictionaries/messages';
import logError from 'utils/logError/logError';

export default () => {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * @param {Object} errorData
   * @param {String} customMessage
   * @returns {function(): void}
   */
  return (errorData, customMessage = messages.ERROR.GENERAL(), place = 'unknown place') => {
    enqueueSnackbar(customMessage, { variant: 'error' });
    logError({ ...errorData, customMessage }, place);
  };
};
