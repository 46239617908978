import React from 'react';

import { FormControl, Select } from '@material-ui/core';

import { useUserDataContext } from 'context/UserDataContext';
import parseValuesToSelect from 'services/common/parseValuesToSelect/parseValuesToSelect';
import useExercisesStore from 'storages/exercises/exercises';

import FiltersBlock from 'uniqueComponents/Exercises/ExercisesFilters/FiltersBlock/FiltersBlock';
import { useUserLogContext } from 'context/UserLogContext/UserLogContext';

const options = [
  {
    label: 'Od najnowszych',
    value: '-date',
  },
  {
    label: 'Od najstarszych',
    value: 'date',
  },
];

function Ordering() {
  const { logEvent } = useUserLogContext();

  const { orderBy, setOrder } = useExercisesStore();
  const { isMobile } = useUserDataContext();

  const onChange = ({ target: { value } }) => {
    logEvent('select-filter-click', { filter: 'Sortuj po dacie', value, feature: 'exercises' });
    setOrder(value);
  };

  return (
    <FiltersBlock heading='Sortuj po dacie'>
      <FormControl size='small' variant='outlined'>
        <Select native={isMobile} onChange={onChange} value={orderBy}>
          {parseValuesToSelect({ data: options, isMobile })}
        </Select>
      </FormControl>
    </FiltersBlock>
  );
}

export default Ordering;
