import React, { useCallback, useMemo } from 'react';

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';

import { useUserLogContext } from 'context/UserLogContext/UserLogContext';
import { OrderDirectionValues } from 'types/OrderDirectionValues';

import useStyles from './ExercisesTable.styles';

export type TableHeader<ID = string> = {
  label: string;
  id: ID;
  sortable: boolean;
  initial?: boolean;
};

export interface ExercisesTableProps<T, K = string> {
  data?: T[];
  headers: TableHeader<K>[];
  dataMapping: (param: T) => React.ReactNode[];
  onRowClick?: (param: T) => void;
  rowsPerPage?: number;
  tableId?: string;
  page: number;
  onPageChange: (e: unknown, newPage: number) => void;
  count?: number;
  order: OrderDirectionValues;
  orderBy?: K;
  setOrder: (newOrder: 'asc' | 'desc') => void;
  setOrderBy: (newOrderBy?: K) => void;
  isLoading?: boolean;
  fallbackMessage?: string;
}

// TODO loader
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ExercisesTable = <T extends unknown, K = string>({
  data,
  headers,
  dataMapping,
  onRowClick,
  rowsPerPage = 10,
  tableId,
  page,
  onPageChange,
  count = 0,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  isLoading,
  fallbackMessage = 'Brak danych',
}: ExercisesTableProps<T, K>) => {
  const { logEvent } = useUserLogContext();

  const createOnSort = (id: K) => () => {
    const isAsc = orderBy === id && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrderBy(id);
    setOrder(newOrder);
    logEvent('sort-exercise-table', { id, tableId, order: newOrder });
  };

  const dataLength = useMemo(() => data?.length || 0, [data]);

  const emptyRows = dataLength < rowsPerPage ? rowsPerPage - dataLength : 0;

  const generateOnRowClick = useCallback(
    (rowData: T) => {
      if (!onRowClick) return undefined;
      return () => onRowClick(rowData);
    },
    [onRowClick],
  );

  const classes = useStyles();

  const tableBodyContent = useMemo(() => {
    if (isLoading)
      return (
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      );
    if (!!data && dataLength) {
      return data.map((row, rowIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={rowIndex} className={classes.row} onClick={generateOnRowClick(row)}>
          {dataMapping(row).map((cell, cellIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableCell key={cellIndex}>{cell}</TableCell>
          ))}
        </TableRow>
      ));
    }
    return (
      <Typography variant='subtitle2' className={classes.fallbackMessage}>
        {fallbackMessage}
      </Typography>
    );
  }, [isLoading, data, dataLength, fallbackMessage, generateOnRowClick]);

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {headers.map(({ label, id, sortable }) => (
                <TableCell key={id as string}>
                  {sortable ? (
                    <TableSortLabel direction={orderBy === id ? order : 'asc'} active={id === orderBy} onClick={createOnSort(id)}>
                      {label}
                    </TableSortLabel>
                  ) : (
                    label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {tableBodyContent}
            {emptyRows > 0 && (
              <TableRow style={{ height: 35.85 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.paginationBar}
        labelDisplayedRows={() => `Strona ${page + 1} z ${Math.ceil(count / rowsPerPage)}`}
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default ExercisesTable;
