import React from 'react';

import { Button, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  headingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1rem',
  },
}));

function FiltersBlock({ heading, isSelectable, onSelectAll, isAllSelected, children }) {
  const classes = useStyles();
  return (
    <section>
      <div className={classes.headingWrapper}>
        <Typography className={classes.heading} component='h4'>
          {heading}
        </Typography>
        {isSelectable && (
          <Button onClick={onSelectAll} size='small'>
            {isAllSelected ? 'Wyczyść' : 'Wszystkie'}
          </Button>
        )}
      </div>
      {children}
    </section>
  );
}

FiltersBlock.defaultProps = {
  isSelectable: false,
  isAllSelected: false,
  onSelectAll: () => {},
};

export default FiltersBlock;
