import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  buttonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -9,
    marginLeft: -9,
  },
});

function ButtonWithLoader({ loading, children, disabled, ...rest }) {
  const classes = useStyles();
  return (
    <div className={classes.buttonWrapper}>
      {React.cloneElement(children, { disabled: loading || disabled, ...rest })}
      {loading && <CircularProgress className={classes.buttonProgress} size={18} />}
    </div>
  );
}

ButtonWithLoader.defaultProps = {
  loading: false,
  disabled: false,
  className: '',
};

export default ButtonWithLoader;
